<div>
  <div>
    <div class="flex">
      <div class="flex">
        <img alt="Divine Management Logo" height="100px" src="../assets/images/logo-transparent.png"/>
      </div>
      <div class="flex">
        <div style="margin-top: 50px">
          <h1>Enable Third-Party Cookies</h1>
        </div>
      </div>
    </div>
    <p>
      3rd party cookies are required for the site to operate correctly.
    </p>
  </div>
  <div>
    <h2>Google Chrome</h2>
    <ol>
      <li>Open Chrome and click on the three dots in the top right corner.</li>
      <li>Select "Settings" from the dropdown menu.</li>
      <li>Scroll down and click on "Privacy and security" in the left sidebar.</li>
      <li>Click on "Cookies and other site data."</li>
      <li>Toggle the switch to allow "Block third-party cookies" off.</li>
    </ol>

    <h2>Mozilla Firefox</h2>
    <ol>
      <li>Open Firefox and click on the three horizontal lines in the top right corner.</li>
      <li>Select "Options" (Windows) or "Preferences" (Mac).</li>
      <li>Go to the "Privacy & Security" tab.</li>
      <li>Scroll down to the "Cookies and Site Data" section.</li>
      <li>Uncheck the box for "Delete cookies and site data when Firefox is closed."</li>
    </ol>

    <h2>Microsoft Edge</h2>
    <ol>
      <li>Open Edge and click on the three dots in the top right corner.</li>
      <li>Select "Settings" from the dropdown menu.</li>
      <li>Scroll down and click on "View advanced settings."</li>
      <li>Under "Privacy and services," set "Cookies" to "Don't block cookies."</li>
    </ol>

    <h2>Apple Safari</h2>
    <ol>
      <li>Open Safari and click on "Safari" in the top menu.</li>
      <li>Select "Preferences."</li>
      <li>Go to the "Privacy" tab.</li>
      <li>Uncheck the box for "Prevent cross-site tracking."</li>
    </ol>

    <p>Remember to close and reopen your browser for changes to take effect.</p>
    <p>Once you have completed these steps, please <a href="https://app.simplidivine.com/login">Login</a> again.</p>

    <!--    <div class="float-left">-->
    <!--      Need additional help getting setup? <a href="javascript:void(0)" (click)="onScheduleSupportClicked()">Schedule-->
    <!--      Support</a> or <a href="javascript:void(0)" (click)="onNavigateToTicketClicked()">Create a Ticket</a>-->
    <!--    </div>-->
  </div>
</div>
