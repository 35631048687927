export class StringUtilities {
  static shortDisplayWords: string[] = [];

  static convertFromCamelCase(value: string, useReplacements: boolean = true) {
    if (value) {
      value = value.replace(/([A-Z])/g, ' $1');
      // TEMP UNTIL I FIGURE OUT HOW TO BETTER DO LEVEL1 ENUM
      value = value.replace(/([0-9])/g, ' $1');

      value = value.trim();

      if (useReplacements) {
        value = StringUtilities.convertCasingOnShortDisplayWords(value);
      }

      return value;
    }

    return null;
  }

  static convertCasingOnShortDisplayWords(value: string): string {
    if (value) {
      StringUtilities.shortDisplayWords.forEach(replacement => {
        const replacementString = ' ' + replacement + ' ';
        while (value.indexOf(replacementString) !== -1) {
          value = value.replace(replacementString, replacementString.toLowerCase());
        }
      });
    }

    return value;
  }

  static sanitizeFileName(value: string, addTimestamp: boolean = true): string {
    return value.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  }

  static isUUID(uuid: string) {
    uuid = '' + uuid;
    let s = uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }
}
