export class DocumentTokenConstants {
  //GLOBAL
  public static readonly businessLogo = '{{BUSINESS_LOGO}}'
  public static readonly businessName = '{{BUSINESS_NAME}}'
  public static readonly businessEmail = '{{BUSINESS_EMAIL}}'
  public static readonly businessPhone = '{{BUSINESS_PHONE}}'
  public static readonly businessAddress = '{{BUSINESS_ADDRESS}}'
  public static readonly today = '{{TODAY}}'
  public static readonly myFirstName = '{{MY_FIRST_NAME}}'
  public static readonly myLastName = '{{MY_LAST_NAME}}'

  //TEMPLATES
  public static readonly mainContent = "{{MAIN_CONTENT}}"

  //INVOICES
  public static readonly invoiceNumber = "{{NUMBER}}";
  public static readonly invoiceDate = "{{DATE}}";
  public static readonly invoiceDueDate = "{{DUE_DATE}}";
  public static readonly invoiceItemsContent = "{{ITEMS}}";
  public static readonly invoiceCustomerName = "{{CUSTOMER_NAME}}";
  public static readonly invoiceCustomerEmail = "{{CUSTOMER_EMAIL}}";
  public static readonly invoiceCustomerPhone = "{{CUSTOMER_PHONE}}";
  public static readonly invoiceCustomerAddress = "{{CUSTOMER_ADDRESS}}";
}
