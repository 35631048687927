import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  ICellRendererParams,
  ProcessCellForExportParams,
  ValueGetterParams
} from 'ag-grid-community';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { Observable, of } from 'rxjs';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';
import { TransactionService } from 'src/app/core/services/domain/transaction.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { TransactionSearchModel } from 'src/app/shared/models/domain/transaction-search.model';
import { NumberUtilities } from 'src/app/core/utilities/number.utilities';
import { BankingService } from 'src/app/core/services/domain/banking.service';
import { ObjectUtilities } from 'src/app/core/utilities/object.utilities';
import { Router } from '@angular/router';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { map } from 'rxjs/operators';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessFormComponent } from 'src/app/shared/components/forms/business-form/business-form.component';
import { BankingConstants } from 'src/app/shared/models/auth/constants/banking.constants';
import { TransactionRuleService } from 'src/app/core/services/domain/transaction-rule.service';

@Component({
  selector: 'app-transactions-grid',
  templateUrl: './transactions-grid.component.html',
  styleUrls: ['./transactions-grid.component.scss']
})
export class TransactionsGridComponent extends BaseGridComponent<TransactionModel> implements OnInit {
  @Input() showAddButton: boolean = true;
  @Input() showBankingActions: boolean = false;
  @Input() showDeleteButton: boolean = true;
  @Input() showBulkUpdate: boolean = true;
  @Input() showCheckbox: boolean = false;
  @Input() showTitle: boolean = true;
  @Input() startDate: string = null;
  @Input() endDate: string = null;
  @Input() vendorKey: string = null;
  @Input() invoiceKey: string = null;
  @Input() isRegistry: boolean = true;
  @Input() showHasLinkedTransaction: boolean = false;
  @Input() showLinkedInvoices: boolean = false;
  @Input() showMatchedTransactions: boolean = false;
  @Input() showBankingSelection: boolean = false;
  @Input() bankingChartOfAccountKey: string = null;
  @Input() chartOfAccountKey: string = null;
  @Input() showHeroNumbers = false;
  @Input() hideBankingActions = false;
  @Input() disableLinks = false;
  @Input() gridKey: string = null;
  @Input() disableSorting = false;
  @Input() startingBalance = 0;
  @Input() transactions: TransactionModel[] = null;
  @Input() columnsToHide: string[] = ['clearedDate', 'balance'];

  @Input() selectedBankAccount: string = null;
  @Output() selectedBankAccountChange = new EventEmitter<string>();

  public static readonly bankingChartOfAccountNameFieldName = 'bankingChartOfAccount.name';
  public static readonly vendorCustomerFieldName = 'vendorCustomer';
  public static readonly linkedInvoicesCountFieldName = 'linkedInvoicesCount';
  public static readonly chartOfAccountNameFieldName = 'chartOfAccount.name';
  public static readonly appliedAmountFieldName = 'appliedAmount';
  private static readonly creditsHeaderName = 'Credits';
  private static readonly debitsHeaderName = 'Debits';

  private readonly amountFieldName = 'amount';
  private showBalance = false;

  rollUpItems = [];
  bankingChartOfAccounts: ChartOfAccountModel[] = null;
  title: string;

  constructor(public authClientService: AuthClientService,
              private businessContextService: BusinessContextService,
              private notificationService: NotificationService,
              private transactionRuleService: TransactionRuleService,
              private transactionService: TransactionService,
              private bankingService: BankingService,
              private chartOfAccountService: ChartOfAccountService,
              private dialogService: DialogService,
              private router: Router) {
    super();
  }

  ngOnInit(): void {
    //TEMPORARY UNTIL I FIGURE OUT WHAT IS OF VALUE TO SHOW
    this.showHeroNumbers = false;
    this.gridKey = `Transactions_${this.gridKey ?? this.isRegistry}_v6`;
    this.showBalance = this.columnsToHide.indexOf('balance') === -1;

    if (this.isRegistry) {
      this.title = 'Registries';
    } else {
      this.title = 'Transactions';
    }

    this.subscriptions.add(this.dataLoaded.subscribe(data => {
      this.setHeroNumbers(data);
    }))

    this.subscriptions.add(this.dataChange.subscribe(data => {
      this.setHeroNumbers(data);
    }));

    if (this.showBankingSelection) {
      this.chartOfAccountService.search({businessKey: this.businessContextService.currentBusiness.businessKey}).pipe(map(chartOfAccounts => {
        return this.chartOfAccountService.filterToBankAccounts(chartOfAccounts);
      })).subscribe(chartOfAccounts => {
        this.bankingChartOfAccounts = chartOfAccounts;
      });
    }

    this.processExportCallback = (params: ProcessCellForExportParams): string => {
      if (params.column.getDefinition().headerName === TransactionsGridComponent.creditsHeaderName) {
        if (params.value > 0) {
          return NumberUtilities.formatAsCurrency(params.value);
        }

        return null;
      }

      if (params.column.getDefinition().headerName === TransactionsGridComponent.debitsHeaderName) {
        if (params.value < 0) {
          return NumberUtilities.formatAsCurrency(params.value);
        }

        return null;
      }

      return params.value;
    }

    super.ngOnInit();

    if (this.disableSorting) {
      this.gridOptions.defaultColDef.sortable = false;
    }

    this.subscriptions.add(this.selectedRowsChange.subscribe(_ => {
      this.calculateBalance();
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (!changes.transactions?.isFirstChange() && changes?.transactions) {
      this.refreshGridData().subscribe(_ => {
        this.calculateBalance();
      });
    }
  }

  onBankingChartOfAccountChanged(): void {
    this.selectedBankAccount = this.bankingChartOfAccountKey;
    this.selectedBankAccountChange.emit(this.bankingChartOfAccountKey);
    this.refreshGridData().subscribe();
  }

  protected setColumnDefinitions(): ColDef[] {
    let that = this;
    let columns = [];


    if (this.showBulkUpdate || this.showCheckbox) {
      columns.push(this.getCheckboxColumn(true));
    }

    columns = columns.concat(
      [
        this.getDateColumn('date', 'Date'),
        this.getDateColumn('clearedDate', 'Cleared Date'),
        {
          headerName: 'Status',
          field: 'status',
          valueGetter: (params: ValueGetterParams) => {
            return this.transactionService.getStatus(params.data);
          }
        },
        //this.getEnumColumn('type', 'Type', TransactionTypesEnum),
        //this.getDefaultColumn('referenceNumber', 'Reference #', !this.isRegistry),
        {
          headerName: 'Vendor/Customer',
          field: TransactionsGridComponent.vendorCustomerFieldName,
          cellClass: this.preventRowClickClass,
          hide: !!this.vendorKey,
          valueGetter: (params: ValueGetterParams) => {
            const rowData = <TransactionModel>params.data;
            return rowData.customer?.name ?? rowData.vendor?.name ?? rowData?.merchantName;
          },
          cellRenderer: function (params: ICellRendererParams): string {
            const rowData = params.data;
            const vendorName = ObjectUtilities.getValueOfProperty(rowData, 'vendor.name');
            const customerName = ObjectUtilities.getValueOfProperty(rowData, 'customer.name');

            if (that.disableLinks) {
              return <string>(vendorName ?? customerName ?? 'unassigned');
            }

            if (vendorName) {
              return `<a href="javascript:void(0)"><i class="fa fa-warehouse"></i>&nbsp;${vendorName}</a>`;
            } else if (customerName) {
              return `<a href="javascript:void(0)"><i class="fa fa fa-user-tag"></i>&nbsp;${customerName}</a>`;
            } else if (rowData.merchantName) {
              return `<a href="javascript:void(0)">${rowData.merchantName}</a>`;
            }

            return null;
          }
        },
        {
          headerName: TransactionsGridComponent.creditsHeaderName,
          field: 'amount',
          filter: 'agNumberColumnFilter',
          cellClass: this.preventRowClickClass,
          hide: false,
          cellRenderer: function (params: ICellRendererParams): string {
            if (params.data.amount > 0) {
              if (params.data.hasMatchedTransactions === true) {
                return `<a href="javascript:void(0)">${NumberUtilities.formatAsCurrency(params.data.amount)}</a>`;
              }

              return NumberUtilities.formatAsCurrency(params.data.amount);
            }

            return null;
          },
          getQuickFilterText: params => {
            if (params.data.amount > 0) {
              const rowData = params.data;
              const value = <number>ObjectUtilities.getValueOfProperty(rowData, 'amount');
              return NumberUtilities.formatAsCurrency(value);
            }

            return null;
          }
        },
        {
          headerName: TransactionsGridComponent.debitsHeaderName,
          field: 'amount',
          filter: 'agNumberColumnFilter',
          cellClass: this.preventRowClickClass,
          hide: false,
          cellRenderer: function (params: ICellRendererParams): string {
            if (params.data.amount <= 0) {
              if (params.data.hasMatchedTransactions === true) {
                return `<a href="javascript:void(0)">${NumberUtilities.formatAsCurrency(params.data.amount)}</a>`;
              }

              return NumberUtilities.formatAsCurrency(params.data.amount);
            }

            return null;
          },
          getQuickFilterText: params => {
            if (params.data.amount <= 0) {
              const rowData = params.data;
              const value = <number>ObjectUtilities.getValueOfProperty(rowData, 'amount');
              return NumberUtilities.formatAsCurrency(value);
            }

            return null;
          }
        },
        this.getCurrencyColumn('balance', 'Balance', null, null, !this.showBalance, '-'),
        this.getCurrencyColumn(TransactionsGridComponent.appliedAmountFieldName, 'Applied Total', null, null, !this.showLinkedInvoices),
        this.getYesNoColumn('hasLinkedTransaction', 'Linked', !this.showHasLinkedTransaction, null),
        this.getLinkColumn(TransactionsGridComponent.linkedInvoicesCountFieldName, 'Invoices/Bills', (params: ICellRendererParams) => {
          if (params.data[TransactionsGridComponent.linkedInvoicesCountFieldName]) {
            return params.data[TransactionsGridComponent.linkedInvoicesCountFieldName];
          } else {
            return '(link)';
          }
        }, !this.showLinkedInvoices),

        this.getLinkColumn(TransactionsGridComponent.chartOfAccountNameFieldName, 'Chart of Account', null, false, this.disableLinks),
        this.getLinkColumn(TransactionsGridComponent.bankingChartOfAccountNameFieldName, 'Bank', null),
        this.getDefaultColumn('description', 'Description')
      ]);

    return columns;
  }

  onBulkUpdateClicked(): void {
    let rows = this.selectedRows;

    if (rows.length === 0) {
      this.notificationService.showErrorNotification('Select rows before proceeding.');
      return;
    }

    let transactionKeys = rows.map(t => t.transactionKey);
    let matDialogRef = this.dialogService.openTransactionBulkUpdate(this.businessContextService.currentBusiness.businessKey, transactionKeys, !this.isRegistry);
    matDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshGridData().subscribe(_ => {

        });
      }
    });
  }

  onSyncTransactionsClicked(): void {
    this.showBlockingLoader = true;
    this.bankingService.listConnections(this.businessContextService.currentBusiness.businessKey).subscribe(bankingConnections => {
      let bankingAccounts = [];
      for (let currentBankingConnection of bankingConnections) {
        if (currentBankingConnection.bankingAccounts?.length) {
          bankingAccounts = bankingAccounts.concat(currentBankingConnection.bankingAccounts);
        }
      }

      if (this.bankingService.hasInvalidBankingAccountsToSync(bankingAccounts)) {
        this.showBlockingLoader = false;
        let matDialogResult = this.dialogService.openSyncConfirmationDialog(bankingAccounts, true);

        matDialogResult.afterClosed().subscribe(result => {
          if (result) {
            this.syncBankingConnection();
          }
        })
      } else {
        this.syncBankingConnection();
      }
    }, err => {
      this.showBlockingLoader = false;
    });
  }

  onNavigateToBankingClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.businessEdit.getNavigateUrl(this.businessContextService.currentBusiness.businessKey, BusinessFormComponent.bankingInformationTabIndex));
  }

  reloadGrid(): Observable<any[]> {
    return super.refreshGridData();
  }

  protected setRowData(): Observable<any[]> {
    if (this.transactions) {
      return of(this.transactions);
    }

    const searchModel = new TransactionSearchModel();
    if (this.chartOfAccountKey) {
      searchModel.chartOfAccountKeys = [this.chartOfAccountKey];
    }

    searchModel.bankingChartOfAccountKeys = !this.bankingChartOfAccountKey ? null : [this.bankingChartOfAccountKey];
    searchModel.vendorKey = this.vendorKey;
    searchModel.invoiceKey = this.invoiceKey;
    searchModel.businessKeys = [this.businessContextService.currentBusiness.businessKey];
    searchModel.isRegistry = this.isRegistry;
    searchModel.includeMatchedTransactions = this.showMatchedTransactions;
    searchModel.includeDeleted = this.includeDeleted;
    searchModel.startDate = this.startDate;
    searchModel.endDate = this.endDate;

    return this.transactionService.search(searchModel);
  }

  protected onGridReady(gridApi: GridApi): void {
    this.subscriptions.add(this.cellClick.subscribe((data: { data: TransactionModel, event: CellClickedEvent }) => {
      if (this.disableLinks) {
        this.rowClick.emit(data);
        return;
      }

      if (data.event.colDef.field === TransactionsGridComponent.vendorCustomerFieldName) {
        if (data.data.vendor?.vendorKey) {
          this.router.navigateByUrl(RouteUtilities.routes.application.vendorEdit.getNavigateUrl(data.data.vendor.vendorKey));
        } else if (data.data.customer?.customerKey) {
          this.router.navigateByUrl(RouteUtilities.routes.application.customerEdit.getNavigateUrl(data.data.customer.customerKey));
        } else if (data.data.merchantName) {
          let dialogRef = this.dialogService.openTransactionRule(null, data.data.merchantName)

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.refreshGridData().subscribe(t => {
              });
            }
          });
        }
      } else if (data.event.colDef.field == TransactionsGridComponent.linkedInvoicesCountFieldName) {
        let dialogRef = this.dialogService.openInvoiceTransactionLink(this.businessContextService.currentBusiness.businessKey, data.data.transactionKey);

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.refreshGridData().subscribe();
          }
        });
      } else if (data.event.colDef.field === this.amountFieldName) {
        if (data.data.hasMatchedTransactions) {
          let dialogRef = this.dialogService.openTransactionLink(this.businessContextService.currentBusiness.businessKey, data.data.transactionKey, null);

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.showBlockingLoader = true;
              let mergedTransaction = this.transactionService.merge(data.data, result);
              this.transactionService.update(mergedTransaction).subscribe(_ => {
                this.notificationService.showSuccessNotification(`${data.data.isRegistry ? 'Transaction' : 'Registry'} linked successfully.`);
                this.refreshGridData().subscribe(_ => {
                  this.showBlockingLoader = false;
                });
              }, err => {
                this.onHttpFailure(this.notificationService, err);
                this.refreshGridData().subscribe(_ => {
                  this.showBlockingLoader = false;
                });
              });
            }
          });
        } else {
          this.rowClick.emit(data);
        }
      } else if (data.event.colDef.field === TransactionsGridComponent.chartOfAccountNameFieldName) {
        if (data.data.chartOfAccount) {
          this.router.navigateByUrl(RouteUtilities.routes.application.chartOfAccountEdit.getNavigateUrl(data.data.chartOfAccount.chartOfAccountKey));
        } else {
          this.rowClick.emit(data);
        }
      } else if (data.event.colDef.field === TransactionsGridComponent.bankingChartOfAccountNameFieldName) {
        if (data.data.bankingChartOfAccount) {
          this.router.navigateByUrl(RouteUtilities.routes.application.chartOfAccountEdit.getNavigateUrl(data.data.bankingChartOfAccount.chartOfAccountKey));
        } else {
          this.rowClick.emit(data);
        }
      }
    }));
  }

  private syncBankingConnection(): void {
    this.showBlockingLoader = true;
    this.bankingService.sync(this.businessContextService.currentBusiness.businessKey).subscribe(_ => {
      this.notificationService.showSuccessNotification('Transactions synced successfully.  Applying Rules...');

      this.transactionRuleService.applyRule({businessKey: this.businessContextService.currentBusiness.businessKey}).subscribe(_ => {
        this.notificationService.showSuccessNotification('Transactions rules were applied successfully.');
        this.refreshGridData().subscribe(t => {
          this.showBlockingLoader = false;
        }, _ => {
          this.showBlockingLoader = false;
        });
      }, err => {
        this.onHttpFailure(this.notificationService, err);
        this.showBlockingLoader = false;
      });
    }, err => {
      if (err?.length) {
        let errorMsg = err[0].message;

        if (errorMsg == BankingConstants.BankingLoginRequired) {
          this.notificationService.showErrorNotification('A banking institution link has expired.');
          this.router.navigateByUrl(RouteUtilities.routes.application.businessEdit.getNavigateUrl(this.businessContextService.currentBusiness.businessKey, BusinessFormComponent.bankingInformationTabIndex))
        } else {
          this.onHttpFailure(this.notificationService, err);
        }
      }

      this.showBlockingLoader = false;
    });
  }

  private calculateBalance(): void {
    if (this.showBalance) {
      let previousBalance = this.startingBalance ?? 0;
      let selectedTransactionKeys = this.selectedRows.map(t => t.transactionKey);

      this.gridApi?.forEachNode( (rowNode) => {
        if (selectedTransactionKeys.indexOf(rowNode.data.transactionKey) !== -1) {
          rowNode.data.balance = previousBalance + (rowNode.data.amount ?? 0);
          previousBalance = rowNode.data.balance;
          rowNode.setDataValue('balance', rowNode.data.balance);
          rowNode.setSelected(true);
        } else {
          rowNode.setSelected(false);
          rowNode.setDataValue('balance', null);
        }
      });
    }

    // for (let transaction of this.transactions) {
    //   if (selectedTransactions.indexOf(transaction.transactionKey) !== -1) {
    //     transaction.balance = previousBalance + (transaction.amount ?? 0);
    //     previousBalance = transaction.balance;
    //   }
    // }

    //this.gridApi.setRowData(this.transactions);
  }

  private setHeroNumbers(data: TransactionModel[]): void {
    // let totalUnknown = 0;
    // let totalUnknownAmount = 0;
    // let totalCredits = 0;
    // let totalCreditAmount = 0;
    // let totalDebits = 0;
    // let totalDebitAmount = 0;
    //
    // data.forEach((dataRow: TransactionModel) => {
    //   if (dataRow.type === TransactionTypesEnum.Unknown) {
    //     totalUnknown++;
    //     totalUnknownAmount += dataRow.total;
    //   } else if (dataRow.type === TransactionTypesEnum.Credit) {
    //     totalCredits++;
    //     totalCreditAmount += (dataRow.total);
    //   } else if (dataRow.type === TransactionTypesEnum.Debit) {
    //     totalDebits++;
    //     totalDebitAmount += dataRow.total;
    //   }
    // });
    //
    // const totalUnknownAmountDisplay = NumberUtilities.formatAsCurrency(totalUnknownAmount);
    // const totalCreditAmountDisplay = NumberUtilities.formatAsCurrency(totalCreditAmount);
    // const totalDebitAmountDisplay = NumberUtilities.formatAsCurrency(totalDebitAmount);
    //
    // const items: RollUpItemModel[] = [
    //   {header: '# Uncategorized Expenses', value: totalCredits.toString()},
    //   {header: 'Credit Total', value: totalCreditAmountDisplay},
    //   {header: '# Debit', value: totalDebits.toString()},
    //   {header: 'Debit Total', value: totalDebitAmountDisplay},
    //   {header: '# Unknown', value: totalUnknown.toString()},
    //   {header: 'Unknown Total', value: totalUnknownAmountDisplay},
    // ]
    //
    // this.rollUpItems = [...items];
  }
}


