import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarDataModel } from 'src/app/shared/models/app/snackbar-data.model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  message: string;
  messages: string[];

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarDataModel, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if (this.data.message instanceof Array) {
      this.messages = this.data.message;
    } else {
      this.message = this.data.message;
    }
  }

  onNotificationClicked(): void {
    this.snackBar.dismiss();
  }
}
