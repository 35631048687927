<div class="base-page-template">
  <div *ngIf="pageTitle" class="header-container">
    <h1>{{pageTitle}}</h1>
  </div>
  <div class="base-page-template-content">
    <ng-content select="app-base-page-content-template">
    </ng-content>
  </div>
</div>

