import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { RollUpItemModel } from 'src/app/shared/models/app/roll-up-item.model';

@Component({
  selector: 'app-roll-up-hero',
  templateUrl: './roll-up-hero.component.html',
  styleUrls: ['./roll-up-hero.component.scss']
})
export class RollUpHeroComponent extends BaseComponent implements OnInit {
  @Input() rollupItems: RollUpItemModel[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
