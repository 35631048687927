<div class="form-section">
  <div class="form-section-header">
    Offering Categories
  </div>
  <div class="mt-2">
    <mat-form-field class="example-form-field mr-1">
      <mat-label>Add new category . . .</mat-label>
      <input matInput type="text" [(ngModel)]="categoryToAdd">
      <button matSuffix mat-icon-button (click)="onAddCategoryClicked()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="flex-full-auto text-center">
    <mat-chip-list>
      <mat-chip *ngFor="let category of categories" (removed)="onRemoveCategoryClicked(category)">
        {{category}}
        <button matChipRemove [attr.aria-label]="'remove ' + category">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-list>
  </div>
  <div class="mt-2">
  <span class="mr-2">
    <app-save-button (click)="onSaveClicked()" [isDisabled]="!isDirty || showBlockingLoader" [isSaving]="showBlockingLoader"></app-save-button>
  </span>
    <span class="mr-2">
    <button mat-flat-button color="primary" (click)="onCancelClicked()">Cancel</button>
  </span>
  </div>
</div>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>


