import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { EmployeeService } from 'src/app/core/services/domain/employee.service';
import { EmployeeModel } from 'src/app/shared/models/domain/employee.model';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';

@Component({
  selector: 'app-employee-page',
  templateUrl: './employee-page.component.html',
  styleUrls: ['./employee-page.component.scss']
})
export class EmployeePageComponent extends BaseEntryPageComponent<EmployeeModel> implements OnInit {
  fileList: FileList;

  constructor(
    public authClientService: AuthClientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private businessContextService: BusinessContextService,
    private clientEmployeeService: EmployeeService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    const employeeKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.employeeKey, this.activatedRoute.snapshot);

    if (employeeKey) {
      this.clientEmployeeService.get(employeeKey).subscribe(document => {
        this.value = document;
      })
    } else {
      this.value = <EmployeeModel>{
        businessKey: this.businessContextService.currentBusiness.businessKey
      };
    }
  }

  onSaveClicked(): void {
    this.isSaving = true;
    if (!this.value.employeeKey) {
      this.clientEmployeeService.create(this.value).subscribe(_ => {
        this.onSaveSuccess('Employee was created successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.clientEmployeeService.update(this.value).subscribe(_ => {
        this.onSaveSuccess('Employee was updated successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }
}
