import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TaxRateModel } from 'src/app/shared/models/domain/tax-rate.model';
import { TaxRateSearchModel } from 'src/app/shared/models/domain/tax-rate-seach.model';

@Injectable()
export class TaxRateService {
  private url = environment.apiUrl + '/tax-rates/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<TaxRateModel> {
    return this.http.get<TaxRateModel>(`${this.url}${key}`);
  }

  search(model: TaxRateSearchModel): Observable<TaxRateModel[]> {
    return this.http.post<TaxRateModel[]>(`${this.url}search`, model);
  }

  create(model: TaxRateModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model)
  }

  update(model: TaxRateModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model)
  }
}
