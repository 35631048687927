<form [formGroup]="formGroupRef">
  <mat-tab-group #matTabGroup [(selectedIndex)]="selectedTabIndex">
    <mat-tab label="General Information">
      <div class="form-section">
        <div class="form-section-content">
          <div class="mt-2">
            <mat-form-field class="mr-2">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name">
            </mat-form-field>
            <mat-slide-toggle formControlName="isDeleted">Deleted?</mat-slide-toggle>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2">
              <mat-label>Phone Number</mat-label>
              <input matInput type="text" formControlName="phoneNumber">
            </mat-form-field>
            <mat-form-field class="mr-2">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email">
            </mat-form-field>
          </div>
        </div>
      </div>
</mat-tab>
    <mat-tab label="Addresses">
      <div class="form-section mt-2">
                  <ng-container formGroupName="address">
            <app-address-sub-form
            [(value)]="value.address"
                                  [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('address'))">
          </app-address-sub-form>
          </ng-container>
        </div>
          </mat-tab>
    <mat-tab label="Tax Information">
      <div class="form-section">
        <div class="form-section-content">
          <div class="mt-2">
            <mat-slide-toggle formControlName="is1099Contractor">1099 Contractor</mat-slide-toggle>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2">
              <mat-label>SSN</mat-label>
              <input matInput type="text" placeholder="xxx-xx-xxxx" formControlName="socialSecurityNumber">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="mr-2">
              <mat-label>EIN</mat-label>
              <input matInput formControlName="employerIdentificationNumber">
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Transactions" *ngIf="value?.vendorKey">
      <div class="mt-2">
        <app-transactions-grid
          [showTitle]="false"
          [showHeroNumbers]="true"
          [hideBankingActions]="true"
          [vendorKey]="value.vendorKey"
          (rowClick)="onTransactionsRowClicked($event)"
          (addButtonClick)="onTransactionsAddButtonClicked()">
        </app-transactions-grid>
      </div>
    </mat-tab>
    <mat-tab label="Bills" *ngIf="value?.vendorKey">
      <div class="mt-2">
        <app-invoices-grid
          [showTitle]="false"
          [showHeroNumbers]="true"
          [vendorKey]="value.vendorKey"
          (rowClick)="onInvoicesRowClicked($event)"
          (addButtonClick)="onInvoicesAddButtonClicked()">
        </app-invoices-grid>
      </div>
    </mat-tab>
    <mat-tab label="Attachments" *ngIf="value?.vendorKey">
      <div class="mt-2">
        <app-business-files-grid
          [vendorKey]="value?.vendorKey">
        </app-business-files-grid>
      </div>
    </mat-tab>
  </mat-tab-group>
</form>
