import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { isBefore, isSameDay } from 'date-fns';
import { Observable } from 'rxjs';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { InvoiceService } from 'src/app/core/services/domain/invoice.service';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { NumberUtilities } from 'src/app/core/utilities/number.utilities';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { InvoiceStatusesEnum } from 'src/app/shared/enums/domain/invoice-statuses.enum';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { RollUpItemModel } from 'src/app/shared/models/app/roll-up-item.model';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { InvoiceSearchModel } from 'src/app/shared/models/domain/invoice-search.model';
import { InvoiceModel } from 'src/app/shared/models/domain/invoice.model';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';

@Component({
  selector: 'app-invoices-grid',
  templateUrl: './invoices-grid.component.html',
  styleUrls: ['./invoices-grid.component.scss']
})
export class InvoicesGridComponent extends BaseGridComponent<InvoiceModel> implements OnChanges {
  @Input() invoiceType: InvoiceTypesEnum = InvoiceTypesEnum.Invoice;
  @Input() vendorKey: string = null;
  @Input() jobKey: string = null;
  @Input() customerKey: string = null;
  @Input() showHeroNumbers = false;
  @Input() showTitle = true;
  @Input() includeAccountsReceivableOnly = false;
  @Input() includeAccountsPayableOnly = false;

  title: string = 'Invoices';
  rollUpItems = [];

  private vendorFieldName: string = 'vendor.name';
  private customerFieldName: string = 'customer.name';

  constructor(public authClientService: AuthClientService,
              private businessContextService: BusinessContextService,
              private invoiceService: InvoiceService,
              private dialogService: DialogService,
              private router: Router) {
    super();
    this.gridKey = 'Invoices_v1';
  }

  ngOnInit() {
    this.showHeroNumbers = false;

    this.subscriptions.add(this.dataLoaded.subscribe(data => {
      this.setHeroNumbers(data);
    }))

    this.subscriptions.add(this.dataChange.subscribe(data => {
      this.setHeroNumbers(data);
    }));

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.setColumnDefinitions());
    }

    if (changes.invoiceType && !changes.invoiceType.isFirstChange()) {
      this.refreshGridData().subscribe();
    }

    if (this.vendorKey) {
      this.invoiceType = InvoiceTypesEnum.Bill;
    }
    this.title = EnumUtilities.getDisplayName(InvoiceTypesEnum, this.invoiceType) + 's';

    super.ngOnChanges(changes);
  }

  protected setColumnDefinitions(): ColDef[] {
    let columns = [];

    columns.push({
      headerName: '',
      field: this.actionFieldName,
      width: 80,
      minWidth: 80,
      sortable: false,
      filter : false,
      resizable: false,
      cellClass: this.preventRowClickClass,
      cellRenderer: params => {
        let html = '';
        const rowData = <DocumentModel>params.data;
        html += '<i title="Download" class="cursor-pointer fas fa-download prevent-row-click download-invoice mr-1"></i>';
        html += '<i title="Email" class="cursor-pointer fas fa-envelope email-invoice prevent-row-click"></i>'

        return html;
      }
    });

    columns.push(this.getEnumColumn('status', 'Status', InvoiceStatusesEnum));
    columns.push(
      {
        headerName: 'Number',
        field: 'number'
      });

    columns.push(
      this.getDateColumn('date', 'Date'),
      this.getDateColumn('issuedPendingPaymentOnDateTime', 'Issued Date'),
      this.getDateColumn('dueDate', 'Due Date'));

    if (this.invoiceType == InvoiceTypesEnum.Bill) {
      columns.push(this.getLinkColumn(this.vendorFieldName, 'Vendor'));
    } else {
      columns.push(this.getLinkColumn(this.customerFieldName, 'Customer'));
    }

    columns.push(this.getYesNoColumn('isCredit', 'Credit', this.invoiceType != InvoiceTypesEnum.Invoice));

    columns.push(this.getCurrencyColumn('total', 'Total'));

    if (this.invoiceType === InvoiceTypesEnum.Invoice) {
      columns.push(this.getCurrencyColumn('amountPaid', 'Amount Paid'));
      columns.push(this.getCurrencyColumn('balanceDue', 'Balance Due'));
    }

    return columns;
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((cellClick: { data: InvoiceModel, event: CellClickedEvent }) => {
      if (cellClick.event.colDef.field === this.actionFieldName) {
        if (this.hasCellClass(cellClick.event, 'download-invoice')) {
          this.dialogService.openDownloadInvoice(cellClick.data.invoiceKey, this.businessContextService.currentBusiness.businessKey);
        } else if (this.hasCellClass(cellClick.event, 'email-invoice')) {
          const model = {
            invoiceKey: cellClick.data.invoiceKey,
            email: {
              toEmailAddress: null,
              subject: 'Invoice - ' + cellClick.data.number,
            }
          }

          this.dialogService.openEmailInvoice(model, cellClick.data.businessKey);
        }
      } else if (cellClick.event.colDef.field === this.vendorFieldName) {
        this.router.navigateByUrl(RouteUtilities.routes.application.vendorEdit.getNavigateUrl(cellClick.data.vendor.vendorKey));
      } else if (cellClick.event.colDef.field === this.customerFieldName) {
        this.router.navigateByUrl(RouteUtilities.routes.application.customerEdit.getNavigateUrl(cellClick.data.customer.customerKey));
      }
    }));
  }

  protected setRowData(): Observable<any[]> {
    const searchModel = new InvoiceSearchModel();
    searchModel.type = this.invoiceType;
    searchModel.vendorKey = this.vendorKey;
    searchModel.customerKey = this.customerKey;
    searchModel.jobKeys = (this.jobKey ? [this.jobKey] : null);
    searchModel.businessKeys = [this.businessContextService.currentBusiness.businessKey];
    searchModel.includeDeleted = this.includeDeleted;
    searchModel.includeAccountsReceivableOnly = this.includeAccountsReceivableOnly;
    searchModel.includeAccountsPayableOnly = this.includeAccountsPayableOnly;

    return this.invoiceService.search(searchModel);
  }

  private setHeroNumbers(data: InvoiceModel[]): void {
    let totalPaid = 0;
    let totalPaidAmount = 0;
    let totalUnpaid = 0;
    let totalUnpaidAmount = 0;
    let totalPassedDue = 0;
    let totalPassedDueAmount = 0;
    let today = new Date();

    data.forEach((dataRow: InvoiceModel) => {
      if(dataRow.status === InvoiceStatusesEnum.Paid) {
        totalPaid++;
        totalPaidAmount += dataRow.amountPaid
      }

      if (isBefore(new Date(dataRow.dueDate), today) || isSameDay(new Date(dataRow.dueDate), today)) {
        totalPassedDue++;
        totalPassedDueAmount -= dataRow.balanceDue;
      }
    })

    const totalPaidAmountDisplay = NumberUtilities.formatAsCurrency(totalPaidAmount);
    const totalUnpaidAmountDisplay = NumberUtilities.formatAsCurrency(totalUnpaidAmount);
    const totalPassedDueAmountDisplay = NumberUtilities.formatAsCurrency(totalPassedDueAmount);

    const items: RollUpItemModel[] = [
      {header: '# Paid', value: totalPaid.toString()},
      {header: 'Paid Total', value: totalPaidAmountDisplay},
      {header: '# Unpaid', value: totalUnpaid.toString()},
      {header: 'Unpaid Total', value: totalUnpaidAmountDisplay},
      {header: '# Passed Due', value: totalPassedDue.toString()},
      {header: 'Passed Due Total', value: totalPassedDueAmountDisplay},
    ]

    this.rollUpItems = [...items];
  }
}
