import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { tap } from 'rxjs/operators';
import { ReconciliationModel } from 'src/app/shared/models/domain/reconciliation.model';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';

@Component({
  selector: 'app-reconciliation-form',
  templateUrl: './reconciliation-form.component.html',
  styleUrls: ['./reconciliation-form.component.scss']
})
export class ReconciliationFormComponent extends BaseFormComponent<ReconciliationModel> implements OnInit, AfterViewInit {
  @Output() transactionDataChange = new EventEmitter();
  @Output() endingBankBalanceChange = new EventEmitter();

  private chartOfAccountTrigger: MatAutocompleteTrigger;

  @ViewChild('chartOfAccountInput', {read: MatAutocompleteTrigger}) set chartOfAccountContent(content: MatAutocompleteTrigger) {
    if (content && !this.chartOfAccountTrigger) {
      this.chartOfAccountTrigger = content;
      this.subscriptions.add(this.chartOfAccountTrigger.panelClosingActions.subscribe(e => {
        if (!(e && e.source)) {
          this.chartOfAccountKeyControl.setValue(null);
          this.chartOfAccountTrigger.closePanel();
        } else {
          let chartOfAccount = this.chartOfAccounts.find(t => t.chartOfAccountKey === e.source.value);

          if (!chartOfAccount) {
            this.chartOfAccountKeyControl.setValue(null);
            this.chartOfAccountTrigger.closePanel();
          } else {
            this.chartOfAccountKeyControl.setValue(e.source.value);
          }
        }

        this.formGroupRef.updateValueAndValidity();

        this.executeTransactionChange();
      }));
    }
  }

  chartOfAccounts: ChartOfAccountModel[];
  filteredChartOfAccounts: ChartOfAccountModel[] = [];
  chartOfAccountKeyControl: UntypedFormControl;
  chartOfAccountContact: string;

  constructor(private chartOfAccountService: ChartOfAccountService, private dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {
    this.chartOfAccountService.search({businessKey: this.value.businessKey }).subscribe(chartOfAccounts => {
      let bankingChartOfAccounts = this.chartOfAccountService.filterToBankAccounts(chartOfAccounts);
      this.chartOfAccounts = bankingChartOfAccounts;
      this.filteredChartOfAccounts = bankingChartOfAccounts;
    });

    super.ngOnInit();

    this.subscriptions.add(this.chartOfAccountKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredChartOfAccounts = this.chartOfAccounts;
        } else {
          this.filteredChartOfAccounts = this.chartOfAccounts.filter(chartOfAccount => chartOfAccount.name.toLowerCase()
            .indexOf(value.toLowerCase()) !== -1);
        }
      }),
    ).subscribe());
  }

  chartOfAccountDisplayFn(chartOfAccountKey: string): string {
    return this.chartOfAccounts.find(t => t.chartOfAccountKey === chartOfAccountKey)?.name ?? '';
  }

  onEndingBankBalanceChanged(): void {
    this.endingBankBalanceChange.emit(this.value.endingBankBalance);
  }

  protected getFormGroup(): UntypedFormGroup {
    this.chartOfAccountKeyControl = new UntypedFormControl(this.value?.chartOfAccount?.chartOfAccountKey);

    const chartOfAccountFormGroup = new UntypedFormGroup({
      chartOfAccountKey: this.chartOfAccountKeyControl
    });

    return new UntypedFormGroup({
      reconciliationKey: new UntypedFormControl(this.value?.reconciliationKey),
      businessKey: new UntypedFormControl(this.value?.businessKey),
      chartOfAccount: chartOfAccountFormGroup,
      isDraft: new UntypedFormControl(this.value?.isDraft ?? true),
      notes: new UntypedFormControl(this.value?.notes),
      isDeleted: new UntypedFormControl(this.value?.isDeleted ?? false),
      startDate: new UntypedFormControl(this.value?.startDate),
      endDate: new UntypedFormControl(this.value?.endDate),
      startingBankBalance: new UntypedFormControl(this.value?.startingBankBalance),
      endingBankBalance: new UntypedFormControl(this.value?.endingBankBalance),
      calculatedEndingBankBalance: new UntypedFormControl(this.value?.calculatedEndingBankBalance),
      credits: new UntypedFormControl(this.value?.credits),
      debits: new UntypedFormControl(this.value?.debits)
    })
  };

  private executeTransactionChange(): void {
    //NEED FORM TO UPDATE FOR CONSUMERS
    setTimeout(_ => {
      this.transactionDataChange.emit();
    })
  }
}
