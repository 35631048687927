<app-base-page-template>
  <app-base-page-content-template>
    <app-transactions-grid
      [bankingChartOfAccountKey]="bankingChartOfAccountKey"
      [showHasLinkedTransaction]="true"
      [showBulkUpdate]="false"
      [showLinkedInvoices]="true"
      [showBankingSelection]="true"
      [enableRowClick]="true"
      [showHeroNumbers]="true"
      [hideBankingActions]="true"
      [isRegistry]="true"
      [showMatchedTransactions]="true"
      (addButtonClick)="onAddButtonClicked()"
      (rowClick)="onRowClicked($event)"
      [(selectedBankAccount)]="selectedBankAccount">
    </app-transactions-grid>
  </app-base-page-content-template>
</app-base-page-template>
