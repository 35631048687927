import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InvoiceTransactionLinkSearchModel } from 'src/app/shared/models/domain/invoice-transaction-link-search.model';
import { InvoiceTransactionLinkModel } from 'src/app/shared/models/domain/invoice-transaction-link.model';

@Injectable()
export class InvoiceTransactionLinkService {
  private url = environment.apiUrl + '/invoice-transaction-link/';

  constructor(private http: HttpClient) {
  }

  search(searchModel: InvoiceTransactionLinkSearchModel): Observable<InvoiceTransactionLinkModel[]> {
    return this.http.post<InvoiceTransactionLinkModel[]>(this.url + 'search', searchModel);
  }

  create(model: InvoiceTransactionLinkModel): Observable<string> {
    return this.http.post<string>(this.url, model);
  }

  update(model: InvoiceTransactionLinkModel): Observable<boolean> {
    return this.http.put<boolean>(this.url, model);
  }

  delete(key: string): Observable<boolean> {
    return this.http.delete<boolean>(this.url + key);
  }
}
