<app-base-grid-template [gridTitle]="showTitle ? title : null" [showDeleteButton]="showDeleteButton"
  (showDeletedClick)="onShowDeletedToggled($event)" [showAddButton]="showAddButton"
  (addButtonClick)="onAddButtonClicked()" (filterTextChange)="onFilterTextChanged($event)"
  (resetGridClick)="onResetGridClicked()" (exportGridClick)="onExportGridClicked()">
  <app-base-grid-header-template>
    <div class="flex flex-wrap">
      <ng-container *ngIf="showBulkUpdate">
        <div class="mr-1 mb-1">
          <button mat-flat-button color="primary" (click)="onBulkUpdateClicked()">Bulk Update</button>
        </div>
      </ng-container>
      <ng-container *ngIf="showBankingActions">
        <div class="mr-1 mb-1">
          <button mat-flat-button color="primary" (click)="onNavigateToBankingClicked()">Manage Banking Connections</button>
        </div>
        <div class="mr-1 mb-1">
          <button mat-flat-button color="primary" (click)="onSyncTransactionsClicked()" class="mr-1">Sync Transactions</button>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="showBankingSelection">
      <mat-form-field class="mr-2">
        <mat-label>Bank Account</mat-label>
        <mat-select [(value)]="bankingChartOfAccountKey" (selectionChange)="onBankingChartOfAccountChanged()">
          <mat-option [value]="''">All</mat-option>
          <mat-option *ngFor="let chartOfAccount of bankingChartOfAccounts"
            [value]="chartOfAccount.chartOfAccountKey">{{chartOfAccount.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </app-base-grid-header-template>
  <app-base-grid-content-template>
    <app-roll-up-hero *ngIf="showHeroNumbers" [rollupItems]="rollUpItems"></app-roll-up-hero>
    <ag-grid-angular
      *ngIf="gridOptions"
      class="ag-theme-alpine"
      [gridOptions]="gridOptions">
    </ag-grid-angular>
    <app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
  </app-base-grid-content-template>
</app-base-grid-template>



