import { Pipe, PipeTransform } from '@angular/core';
import { DateUtilities } from 'src/app/core/utilities/date.utilities';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';

@Pipe({name: 'enumFormatter'})
export class EnumFormatterPipe implements PipeTransform {
  transform(value: any, enumObject: any): string {
    return EnumUtilities.getDisplayName(enumObject, value);
  }
}
