<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content class="sidenav-content">
    <mat-toolbar class="top-nav" color="primary">
      <div class="header-logo-container cursor-pointer" [routerLink]="routeUtilities.routes.application.dashboard.getNavigateUrl()">
        <img class="logo" alt="Divine Management Logo" height="48" src="../assets/images/logo-transparent.png"/>
      </div>
      <button mat-button [matMenuTriggerFor]="business">My Businesses
      </button>
      <mat-menu #business="matMenu">
        <div mat-menu-item *ngIf="businessContextService.currentBusiness?.businessKey"
          [routerLink]="[routeUtilities.routes.application.businessEdit.getNavigateUrl(businessContextService.currentBusiness.businessKey)]">{{businessContextService.currentBusiness.name}}
        </div>
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}"
             [routerLink]="[routeUtilities.routes.application.dashboard.getNavigateUrl()]">Dashboard
        </div>
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.employees.getNavigateUrl()]">Employees
        </div>
        <div mat-menu-item *ngIf="authClientService.hasAuthorization(authorization.admin) || authClientService.hasAuthorization(authorization.superAdmin)"
             [routerLink]="[routeUtilities.routes.application.businesses.getNavigateUrl()]">Manage All
          Businesses
        </div>
      </mat-menu>
      <button [ngClass]="{'invalid-license-menu': !hasValidLicense}" mat-flat-button color="primary" [matMenuTriggerFor]="accounting">Accounting
      </button>
      <mat-menu #accounting="matMenu" >
        <div [ngClass]="{'invalid-license-menu': !hasValidLicense}" mat-menu-item (click)="onNavigateClicked(routeUtilities.routes.application.vendors.getNavigateUrl())" [matMenuTriggerFor]="vendors">Vendors
        </div>
        <mat-menu #vendors="matMenu">
          <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.invoices.getNavigateUrl()]"
               [queryParams]="getInvoiceTypeQueryParams(invoiceTypesEnum.Bill)">Bills
          </div>
        </mat-menu>

        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [matMenuTriggerFor]="customers" (click)="onNavigateClicked(routeUtilities.routes.application.customers.getNavigateUrl())">Customers
        </div>
        <mat-menu #customers="matMenu">
          <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.invoices.getNavigateUrl()]"
               [queryParams]="getInvoiceTypeQueryParams(invoiceTypesEnum.Invoice)">Invoices
          </div>
          <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.offerings.getNavigateUrl()]">Offerings
          </div>
          <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.jobs.getNavigateUrl()]">Jobs
          </div>
        </mat-menu>

        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" (click)="onNavigateClicked(routeUtilities.routes.application.chartOfAccounts.getNavigateUrl())" [matMenuTriggerFor]="accounts">Accounts
        </div>
        <mat-menu #accounts="matMenu">
          <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.chartOfAccounts.getNavigateUrl()]">Chart of
            Accounts
          </div>
          <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.registries.getNavigateUrl()]">Registries
          </div>
          <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.transactions.getNavigateUrl()]">Transactions
          </div>
          <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.transactionRules.getNavigateUrl()]">Transaction Rules
          </div>
          <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.reconciliations.getNavigateUrl()]">Reconciliations
          </div>
        </mat-menu>
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.documents.getNavigateUrl()]"
             [queryParams]="getDocumentTypeQueryParams(documentTypesEnum.InvoiceTemplate)">Templates
        </div>
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.reports.getNavigateUrl()]">Reports
        </div>
      </mat-menu>
      <button [ngClass]="{'invalid-license-menu': !hasValidLicense}" mat-flat-button color="primary" (click)="onPayrollClicked()">Payroll
      </button>
      <button [ngClass]="{'invalid-license-menu': !hasValidLicense}" mat-flat-button color="primary" [matMenuTriggerFor]="documents">Documents
      </button>
      <mat-menu #documents="matMenu">
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.documents.getNavigateUrl()]">All Documents
        </div>
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.documents.getNavigateUrl()]"
          [queryParams]="getDocumentTypeQueryParams(documentTypesEnum.Template)">Templates
        </div>
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.documents.getNavigateUrl()]"
          [queryParams]="getDocumentTypeQueryParams(documentTypesEnum.Legal)">Legal
        </div>
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.documents.getNavigateUrl()]"
          [queryParams]="getDocumentTypeQueryParams(documentTypesEnum.Letter)">Letters
        </div>
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.documents.getNavigateUrl()]"
          [queryParams]="getDocumentTypeQueryParams(documentTypesEnum.BusinessCard)">Cards
        </div>
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.documents.getNavigateUrl()]"
          [queryParams]="getDocumentTypeQueryParams(documentTypesEnum.Form)">Forms
        </div>
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.files.getNavigateUrl()]">Files
        </div>
      </mat-menu>
      <button mat-button [matMenuTriggerFor]="services">Leads/Services
      </button>
      <mat-menu #services="matMenu">
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.services.getNavigateUrl()]">Find a Lead/Service
        </div>
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.businessServiceRequests.getNavigateUrl()]">Business Lead/Service Requests
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.myServiceRequests.getNavigateUrl()]">My Lead/Service Requests
        </div>
      </mat-menu>
      <button mat-button
        *ngIf="authClientService.hasAuthorization(authorization.admin) || authClientService.hasAuthorization(authorization.superAdmin)"
        [matMenuTriggerFor]="admin">
        Admin
      </button>
      <mat-menu #admin="matMenu">
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.dashboard.getNavigateUrl()]">Dashboard
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.clients.getNavigateUrl()]">Clients
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.businesses.getNavigateUrl()]">
          Businesses
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.businessServiceRequests.getNavigateUrl()]">
          Service Requests
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.documents.getNavigateUrl()]">Documents
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.files.getNavigateUrl()]">Files
        </div>
        <div *ngIf="authClientService.hasAuthorization(authorization.superAdmin)" mat-menu-item
          [routerLink]="[routeUtilities.routes.application.admin.sandbox.getNavigateUrl()]">Sandbox
        </div>
      </mat-menu>

      <span class="spacer"></span>
      <ng-container *ngIf="businessContextService.allBusinesses?.length > 1">
        <div *ngIf="businessContextService.currentBusiness" class="current-business cursor-pointer"
          [mat-menu-trigger-for]="companies">{{businessContextService.currentBusiness.name}} <span><i
          class="fa fa-chevron-down"></i></span>
        </div>
        <mat-menu #companies="matMenu" [hidden]="businessContextService.allBusinesses?.length > 1">
          <ng-container *ngFor="let b of businessContextService.allBusinesses">
            <button *ngIf="b.businessKey !== businessContextService.currentBusiness.businessKey"
              class="menu-item-button businesses-menu-item"
              mat-menu-item (click)="onChangeBusinessClicked(b)">
              {{b.name}}
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
      <ng-container *ngIf="businessContextService.allBusinesses.length <= 1">
        <div *ngIf="businessContextService.currentBusiness"
          class="current-business">{{businessContextService.currentBusiness.name}}</div>
      </ng-container>

<!--      <mat-icon class="cursor-pointer mr-1" [matMenuTriggerFor]="notifications" matBadge="2" matBadgeColor="accent">-->
<!--        notifications-->
<!--      </mat-icon>-->

<!--      <mat-menu #notifications="matMenu">-->
<!--        <button class="menu-item-button" mat-menu-item>-->
<!--          <mat-icon>new_releases</mat-icon>-->
<!--          <span>Larry's Lumber has joined</span>-->
<!--        </button>-->
<!--        <button class="menu-item-button" mat-menu-item>-->
<!--          <mat-icon>new_releases</mat-icon>-->
<!--          <span>12 new users have been added to Mary's Maltshop</span>-->
<!--        </button>-->
<!--      </mat-menu>-->
      <button mat-flat-button color="primary" [matMenuTriggerFor]="help">
        <mat-icon>help</mat-icon>
      </button>
      <mat-menu #help="matMenu">
        <div mat-menu-item [ngClass]="{'invalid-license-menu': !hasValidLicense}" [routerLink]="[routeUtilities.routes.application.help.getNavigateUrl()]">Help/Training
        </div>
        <div mat-menu-item (click)="onNavigateToHelpSchedulingClicked()">Schedule Support
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.ticket.getNavigateUrl()]">Create a Ticket
        </div>
      </mat-menu>
      <button [matTooltip]="authClientService.client?.email" mat-icon-button aria-label="profile-icon"
        [matMenuTriggerFor]="profile">
        <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #profile="matMenu">
        <div mat-menu-item [routerLink]="routeUtilities.routes.application.profile.getNavigateUrl()">
          <span class="mr-1"><i class="fa fa-user"></i></span>Profile
        </div>
        <div *ngIf="!authClientService.hasAuthorization(authorization.admin) && !authClientService.hasAuthorization(authorization.superAdmin)" mat-menu-item [routerLink]="routeUtilities.routes.application.subscriptions.getNavigateUrl()">
          <span class="mr-1"><i class="fa fa-dollar-sign"></i></span>Subscriptions
        </div>
        <div mat-menu-item [routerLink]="routeUtilities.routes.application.profileSetup.getNavigateUrl()">
          <span class="mr-1"><i class="fa fa-info"></i></span>Setup Wizard
        </div>
        <div mat-menu-item (click)="onLogoutClicked()">
          <span class="mr-1"><i class="fa fa-sign-out"></i></span>Logout
        </div>
      </mat-menu>
    </mat-toolbar>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div class="secondary-footer">
      <div class="footer-items">

        <div class="footer-logo-container">
          <img class="footer-logo" src="../assets/images/logo-transparent.png">
        </div>
        <div class="footer-menu-item not-hoverable">
          Copyright {{year}} DivineManagement, Inc All Rights Reserved
        </div>
        <div class="footer-menu-item" [routerLink]="routeUtilities.routes.application.privacyPolicy.getNavigateUrl()"
          [routerLinkActive]="['link-active']">
          Privacy Policy
        </div>
        <div class="footer-menu-item" [routerLink]="routeUtilities.routes.application.terms.getNavigateUrl()"
          [routerLinkActive]="['link-active']">
          Terms & Conditions
        </div>
        <a href="https://www.linkedin.com/company/divine-management-services/" target="_blank" mat-mini-fab class="social-button">
          <i class="fab fa-linkedin-in"></i>
        </a>
        <a href="https://instagram.com/simplidivine4u" target="_blank" mat-mini-fab class="social-button">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://x.com/divinemanage?s=11" target="_blank" mat-mini-fab class="social-button">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="https://www.facebook.com/profile.php?id=61550343154621&mibextid=2JQ9oc" target="_blank" mat-mini-fab class="social-button">
          <i class="fab fa-facebook-f"></i>
        </a>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
