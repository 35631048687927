import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { InvoicePaymentModel } from 'src/app/shared/models/domain/invoice-payment.model';
import { InvoiceModel } from 'src/app/shared/models/domain/invoice.model';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { InvoiceService } from 'src/app/core/services/domain/invoice.service';

@Component({
  selector: 'app-invoice-payment-form',
  templateUrl: './invoice-payment-form.component.html',
  styleUrls: ['./invoice-payment-form.component.scss']
})
export class InvoicePaymentFormComponent extends BaseFormComponent<InvoicePaymentModel> implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  isInvoice: boolean;
  invoice: InvoiceModel = null;

  constructor(private invoiceService: InvoiceService) {
    super();
  }

  ngOnInit(): void {
    this.isInvoice = this.value?.invoice?.type === InvoiceTypesEnum.Invoice;

    this.invoiceService.get(this.value.invoice.invoiceKey).subscribe(invoice => {
      this.invoice = invoice;

      if (!this.value.amount) {
        this.formGroupRef.get('amount').setValue(invoice.balanceDue);
      }
    });

    super.ngOnInit();

    this.formGroupRef.markAsDirty();
  }

  protected getFormGroup(): UntypedFormGroup {
    const invoiceFormGroup = new UntypedFormGroup({
      invoiceKey: new UntypedFormControl(this.value?.invoice?.invoiceKey)
    })

    const formGroup = new UntypedFormGroup({
      invoicePaymentKey: new UntypedFormControl(this.value?.invoicePaymentKey),
      invoice: invoiceFormGroup,
      date: new UntypedFormControl(this.value?.date ?? new Date()),
      isDeleted: new UntypedFormControl(this.value?.isDeleted ?? false),
      notes: new UntypedFormControl(this.value?.notes),
      amount: new UntypedFormControl(this.value?.amount || 0),
    });

    return formGroup;
  };
}


