import { Component, Inject, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { UntypedFormGroup } from '@angular/forms';
import { EmailInvoiceModel } from 'src/app/shared/models/domain/email-invoice.model';
import { InvoiceService } from 'src/app/core/services/domain/invoice.service';

@Component({
  selector: 'app-email-invoice-dialog',
  templateUrl: './email-invoice-dialog.component.html',
  styleUrls: ['./email-invoice-dialog.component.scss']
})
export class EmailInvoiceDialogComponent extends BaseComponent implements OnInit {
  isAdmin: boolean = false;
  businessKey: string = null;
  value: EmailInvoiceModel;
  formGroupRef: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private dialogRef: MatDialogRef<EmailInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {emailDocument: EmailInvoiceModel, businessKey: string },
    protected notificationService: NotificationService, private invoiceService: InvoiceService) {
    super();
  }

  ngOnInit(): void {
    this.value = this.dialogData.emailDocument;
    this.businessKey = this.dialogData.businessKey;
  }

  onSendEmailClicked(): void {
    if (!this.formGroupRef.dirty || this.isSaving) {
      return;
    }

    this.isSaving = true;

    this.invoiceService.email(this.value).subscribe(result => {
      this.isSaving = false;

      if (result) {
        this.notificationService.showSuccessNotification('Email sent successfully.');
        this.dialogRef.close(true);
      } else {
        this.notificationService.showErrorNotification('Email failed to be sent.');
      }
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    });
  }

  onCancelClicked(): void {
    if (this.isSaving) {
      return;
    }

    this.dialogRef.close(false);
  }
}
