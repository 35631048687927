import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class NavigationResolver {
  constructor(private authService: AuthService) {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | any {

  }
}
