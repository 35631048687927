export class ObjectUtilities {
  static nameOf = <T>(name: keyof T) => name;
  static nameOfSubProperty = <T1, T2>(name: keyof T1, name2: keyof T2) => `${name}.${name2}`;
  static nameOfSubProperties = <T1, T2, T3>(name: keyof T1, name2: keyof T2, name3: keyof T3) => `${name}.${name2}.${name3}`;
  static nameOfThreeSubProperties = <T1, T2, T3, T4>(name: keyof T1, name2: keyof T2, name3: keyof T3, name4: keyof T4) => `${name}.${name2}.${name3}.${name4}`;
  static nameOfFourSubProperties = <T1, T2, T3, T4, T5>(name: keyof T1, name2: keyof T2, name3: keyof T3, name4: keyof T4,  name5: keyof T5) => `${name}.${name2}.${name3}.${name4}.${name5}`;

  static getValueOfProperty<T>(object: any, propertyNames: string): T {
    return propertyNames.split('.').reduce((prev: string, curr: string) => {
      return prev ? prev[curr] : null;
    }, object || self);
  }

  static isNotNullOrUndefined<T>(value: T): boolean {
    return !this.isNullOrUndefined(value);
  }

  static isNullOrUndefined<T>(value: T): boolean {
    return value === null || value === undefined;
  }
}
