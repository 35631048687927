<form [formGroup]="formGroupRef" *ngIf="value">
  <div class="form-section-pinned-right">
    <div class="text-right mr-2 mt-1">
      <ng-container *ngIf="!value?.isDeleted && value?.type === invoiceTypes.Invoice">
        <span *ngIf="value?.issuedPendingPaymentOnDateTime" class="mr-2">
          Issued on: <span>{{value.issuedPendingPaymentOnDateTime | dateFormatter}}</span>
        </span>
        <div class="inline-block mr-2">
          <button matTooltip="Download Invoice" mat-mini-fab color="primary" (click)="onDownloadInvoiceClicked()">
            <mat-icon>download</mat-icon>
          </button>
        </div>
        <div class="inline-block">
          <button matTooltip=" Email Invoice" mat-mini-fab color="primary" (click)="onEmailInvoiceClicked()">
            <mat-icon>email</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="flex form-section-pinned-right-offset">
    <div class="mt-1 flex">
      <div class="flex">
        <div class="form-section">
          <div class="form-section-header">
            <span>General Information</span>
          </div>
          <div class="subtle-text bold d-flex align-items-center">
            <span class="mr-1">Status: {{status}}</span>
            <span class="mr-1" *ngIf="value?.balanceDue">Balance Due: {{value?.balanceDue | currencyFormatter}}</span>
            <mat-icon matSuffix matTooltip="Status of invoice">
              help_outline
            </mat-icon>
          </div>
          <!--            <div class="mt-2">-->
          <!--              <mat-form-field class="mr-2">-->
          <!--                <mat-label>Status</mat-label>-->
          <!--                <mat-select formControlName="status" (selectionChange)="onStatusChanged()">-->
          <!--                  <mat-option *ngFor="let invoiceStatus of invoiceStatuses"-->
          <!--                              [value]="invoiceStatus.value">{{invoiceStatus.label}}</mat-option>-->
          <!--                </mat-select>-->
          <!--              </mat-form-field>-->
          <!--              <mat-slide-toggle formControlName="isCredit">Credit</mat-slide-toggle>-->
          <!--            </div>-->
          <div class="mt-2">
            <mat-form-field class="mr-2">
              <mat-label>Number</mat-label>
              <input matInput type="text" formControlName="number">
              <mat-icon matSuffix matTooltip="Invoice number that appears on your invoice">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mat-form-field-date mr-2">
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="datePicker" formControlName="date">
              <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
              <mat-icon matSuffix matTooltip="The date the invoice was created">
                help_outline
              </mat-icon>
            </mat-form-field>
            <mat-form-field class="mat-form-field-date mr-2">
              <mat-label>Due Date</mat-label>
              <input matInput [matDatepicker]="dueDatepicker" formControlName="dueDate">
              <mat-datepicker-toggle matSuffix [for]="dueDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #dueDatepicker></mat-datepicker>
              <mat-icon matSuffix matTooltip="The date the invoice is due">
                help_outline
              </mat-icon>
            </mat-form-field>
            <mat-form-field class="mat-form-field-date" *ngIf="value?.type !== invoiceTypes.Bill">
              <mat-label>Paid Date</mat-label>
              <input matInput [matDatepicker]="paidDatepicker" formControlName="paidDate">
              <mat-datepicker-toggle matSuffix [for]="paidDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #paidDatepicker></mat-datepicker>
              <mat-icon matSuffix matTooltip="The date the invoice is paid off">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="form-section" *ngIf="value?.type !== invoiceTypes.Bill">
          <div class="form-section-header" style="margin-top:50px">
          </div>
          <div>
            <ng-container formGroupName="customer">
              <div style="margin-top:40px">
                <mat-form-field class="mat-form-field-double" *ngIf="customers">
                  <mat-label>Customer</mat-label>
                  <input type="text" #customerInput matInput placeholder="Search for customer . . ."
                    formControlName="customerKey" [matAutocomplete]="auto">
                  <mat-autocomplete [displayWith]="customerDisplayFn.bind(this)" autoActiveFirstOption
                    #auto="matAutocomplete">
                    <mat-option value="-1">
                      <span class="bold">Add New Customer</span>
                    </mat-option>
                    <mat-option *ngFor="let customer of filteredCustomers" [value]="customer.customerKey">
                      {{customer.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-icon matSuffix matTooltip="A person or company you commit your service to">
                    help_outline
                  </mat-icon>
                </mat-form-field>
              </div>
              <div *ngIf="customerAddress" class="smaller">
                <div>
                  {{customerAddress}}
                </div>
                <div>
                  {{customerContact}}
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="form-section" *ngIf="value?.type === invoiceTypes.Bill">
          <div class="form-section-header" style="height:50px;">
            &nbsp;
          </div>
          <div>
            <ng-container formGroupName="vendor">
              <div class="mt-2">
                <mat-form-field class="mat-form-field-double" *ngIf="vendors">
                  <mat-label>Vendor</mat-label>
                  <input type="text" #vendorInput matInput placeholder="Search for vendor . . ."
                    formControlName="vendorKey" [matAutocomplete]="auto">
                  <mat-autocomplete [displayWith]="vendorDisplayFn.bind(this)" autoActiveFirstOption
                    #auto="matAutocomplete">
                    <mat-option class="bold" value="-1">Add New Vendor</mat-option>
                    <mat-option *ngFor="let vendor of filteredVendors" [value]="vendor.vendorKey">
                      {{vendor.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="form-section">
      <ng-container *ngIf="value.type !== invoiceTypes.Bill">
        <div class="mt-2" [formGroup]="offeringFormGroupRef">
          <mat-form-field class="mr-2 mat-form-field-double" *ngIf="filteredOfferings">
            <mat-label>Search for Offerings...</mat-label>
            <input type="text" #offeringInput matInput placeholder="Search for offering . . ."
              formControlName="offeringKey" [matAutocomplete]="auto">
            <mat-autocomplete [displayWith]="offeringDisplayFn.bind(this)" autoActiveFirstOption
              #auto="matAutocomplete">
              <mat-option class="bold" value="-1">Add New Offering</mat-option>
              <mat-option class="bold" value="-2">Add New Free Form Offering</mat-option>
              <mat-option *ngFor="let offering of filteredOfferings" [value]="offering.offeringKey">
                {{offering.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix matTooltip="An offering is a category attached to the invoice for service or product">
              help_outline
            </mat-icon>
          </mat-form-field>
          <button class="inline-block" mat-mini-fab color="primary" (click)="onAddFreeFormClicked()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="value.type === invoiceTypes.Bill">
        <div class="flex flex-full-auto">
          <div class="flex-end">
            <button mat-mini-fab color="primary" (click)="onAddFreeFormClicked()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <div class="mt-2" cdkDropList (cdkDropListDropped)="onItemDropped($event)">
        <ng-container *ngIf="getFormArrayItems().controls.length">
          <ng-container *ngFor="let item of getFormArrayItems().controls; let i = index">
            <div class="mt-3" [formGroup]="getFormGroupFromAbstractControl(item)" cdkDrag>
              <div class="flex-full-auto">
                <div class="flex flex-direction-column"
                  [ngClass]="{'bill-action-container': value.type === invoiceTypes.Bill, 'invoice-action-container': value.type !== invoiceTypes.Bill}">
                  <div class="flex flex-shrink" style="justify-content: center">
                    <span cdkDragHandle class="mr-1">
                      <i title="Move" class="cursor-pointer fas fa-grip-lines"></i>
                    </span>
                    <span (click)="onRemoveInvoiceItemClicked(i)">
                      <i title="Remove" class="cursor-pointer fas fa-trash"></i>
                    </span>
                  </div>
                  <div class="flex bold" style="justify-content: center">
                    # {{i + 1}}
                  </div>
                </div>
                <!-- INVOICE LINE ITEM START -->
                <ng-container *ngIf="value.type !== invoiceTypes.Bill">
                  <div class="flex flex-grow flex-direction-column">
                    <div class="flex-full-auto">
                      <div class="flex flex-grow flex-direction-column">
                        <div class="bold">
                          <div class="inline-block">
                            <mat-form-field class="mr-2" style="width:540px !important">
                              <mat-label>Name</mat-label>
                              <input matInput [readonly]="item.value.offering?.offeringKey" type="text"
                                formControlName="name">
                              <mat-icon matSuffix matTooltip="A descrpitive name of the line item I am working on">
                                help_outline
                              </mat-icon>
                            </mat-form-field>
                          </div>
                        </div>
                        <div>
                          <div class="inline-block">
                            <ng-container>
                              <div class="inline-block">
                                <mat-form-field style="width:260px !important" class="mr-2" *ngIf="chartOfAccounts">
                                  <mat-label>Chart of Account</mat-label>
                                  <input type="text" [readonly]="item.value.offering?.offeringKey"
                                    placeholder="Chart of Account" matInput
                                    (focus)="filteredChartOfAccounts = chartOfAccounts"
                                    (keyup)="onChartOfAccountKeyUp(item)" formControlName="chartOfAccount"
                                    (blur)="onChartOfAccountChanged($event, item)" [matAutocomplete]="coa">
                                  <mat-autocomplete [displayWith]="chartOfAccountDisplayFn.bind(this)"
                                    #coa="matAutocomplete">
                                    <mat-option value="-1" (click)="onAddChartOfAccountClicked(item)">
                                      <span class="bold">Add New Chart Of Account</span>
                                    </mat-option>
                                    <mat-option *ngFor="let chartOfAccount of filteredChartOfAccounts"
                                      [value]="chartOfAccount">
                                      <span *ngIf="!chartOfAccount.parentChartOfAccountKey" class="bold">{{chartOfAccount.name}}</span>
                                      <span *ngIf="chartOfAccount.parentChartOfAccountKey">{{chartOfAccount.fullDisplayPath}}</span>
                                    </mat-option>
                                  </mat-autocomplete>
                                  <mat-icon matSuffix
                                    matTooltip="A list of account names that a company uses in its general ledger for recording transactions">
                                    help_outline
                                  </mat-icon>
                                </mat-form-field>
                              </div>
                              <div class="inline-block">
                                <mat-form-field style="width:260px !important"
                                  *ngIf="jobs && value?.customer?.customerKey">
                                  <mat-label>Job</mat-label>
                                  <input type="text" placeholder="Job" matInput formControlName="jobKey"
                                    (focus)="onJobKeyUp(null)" (keyup)="onJobKeyUp(item)"
                                    (blur)="onJobChanged($event, item)" [matAutocomplete]="auto">
                                  <mat-autocomplete [displayWith]="jobDisplayFn.bind(this)" #auto="matAutocomplete">
                                    <mat-option class="bold" value="-1" (click)="onAddJobClicked(item)">Add New
                                      Job</mat-option>
                                    <mat-option *ngFor="let job of filteredJobs" [value]="job.jobKey">
                                      {{job.name}}
                                    </mat-option>
                                  </mat-autocomplete>
                                  <mat-icon matSuffix
                                    matTooltip="A listing of each client and all cost associated with it">
                                    help_outline
                                  </mat-icon>
                                </mat-form-field>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div>
                          <mat-form-field class="mat-form-field-small mr-2">
                            <mat-label>Type</mat-label>
                            <mat-select formControlName="type">
                              <mat-option *ngFor="let invoiceItemType of invoiceItemTypes"
                                [value]="invoiceItemType.value">{{invoiceItemType.label}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field class="mr-2 mat-form-field" *ngIf="categories">
                            <mat-label>Category</mat-label>
                            <input type="text" [readonly]="item.value.offering?.offeringKey" placeholder="Category"
                              matInput (focus)="filteredCategories = categories" (keyup)="onCategoryKeyUp(item)"
                              (blur)="onCategoryChanged(item)" formControlName="category" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option *ngFor="let category of filteredCategories" [value]="category">
                                {{category}}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-icon matSuffix
                              matTooltip="Products are physical items sold by the company, and Services are typically intangible items">
                              help_outline
                            </mat-icon>
                          </mat-form-field>
                          <mat-form-field class="mr-2 mat-form-field">
                            <mat-label>SKU</mat-label>
                            <input matInput [readonly]="item.value.offering?.offeringKey" type="text"
                              formControlName="sku">
                            <mat-icon matSuffix
                              matTooltip="SKU codes are to identify different internal products in your inventory">
                              help_outline
                            </mat-icon>
                          </mat-form-field>
                          <mat-form-field class="mat-form-field-small"
                            *ngIf="serviceFrequencyTypes && item?.value?.type == offeringTypes.Service">
                            <mat-label>Frequency</mat-label>
                            <mat-select formControlName="frequency">
                              <mat-option *ngFor="let serviceFrequencyType of serviceFrequencyTypes"
                                [value]="serviceFrequencyType.value">{{serviceFrequencyType.label}}</mat-option>
                            </mat-select>
                            <mat-icon matSuffix matTooltip="How often your client uses your service">
                              help_outline
                            </mat-icon>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="flex flex-end">
                        <div>
                          <table>
                            <tr>
                              <td class="d-inline-flex align-items-end justify-content-end me-1 w-100-pixels">
                                Unit Price
                                <mat-icon matSuffix matTooltip="A single quantity of a product or service">
                                  help_outline
                                </mat-icon>
                              </td>
                              <td class="d-inline-flex align-items-end justify-content-end me-1 w-100-pixels">
                                Quantity
                                <mat-icon matSuffix matTooltip="Number of goods or services you offer your customer">
                                  help_outline
                                </mat-icon>
                              </td>
                              <td class="d-inline-flex align-items-end justify-content-end me-1 w-60-pixels" *ngIf="value.type !== invoiceTypes.Bill">
                                Taxable
                              </td>
                              <td class="d-inline-flex align-items-end justify-content-end me-1 w-100-pixels" *ngIf="value.type === invoiceTypes.Bill">
                                Tax
                              </td>
                              <td class="d-inline-flex align-items-end justify-content-end me-1 w-75-pixels">
                                Total
                                <mat-icon matSuffix matTooltip="United price multiplied by quantity">
                                  help_outline
                                </mat-icon>
                              </td>
                            </tr>
                            <tr>
                              <td class="d-inline-flex align-items-end justify-content-end me-1 w-100-pixels">
                                <mat-form-field class="mat-form-field-smaller">
                                  <input matInput type="text" currencyMask formControlName="unitPrice" min="0">
                                </mat-form-field>
                              </td>
                              <td class="d-inline-flex align-items-end justify-content-end me-1 w-100-pixels">
                                <mat-form-field class="mat-form-field-smaller">
                                  <input matInput type="text" currencyMask formControlName="quantity"
                                    [options]="{ prefix: '', precision: item?.value?.type === offeringTypes.Service ? '2' : '0' }" min="0">
                                </mat-form-field>
                              </td>
                              <td class="d-inline-flex align-items-end justify-content-end me-1 w-60-pixels">
                                <mat-slide-toggle formControlName="isTaxable"
                                  (change)="onIsTaxableChanged()"></mat-slide-toggle>
                                <!--                                <mat-form-field class="mat-form-field-smaller">-->
                                <!--                                  <input matInput type="text" currencyMask formControlName="taxRate"-->
                                <!--                                         [options]="{ prefix: '%', precision: '3' }"-->
                                <!--                                         min="0">-->
                                <!--                                </mat-form-field>-->
                              </td>
                              <td class="d-inline-flex align-items-end justify-content-end me-1 w-75-pixels">
                                <mat-form-field class="mat-form-field-smaller">
                                  <input readonly="true" matInput type="text" currencyMask formControlName="total">
                                </mat-form-field>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="full-width">
                      <mat-form-field style="width: 100%">
                        <mat-label>Description</mat-label>
                        <textarea class="full-width" matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
                          cdkAutosizeMaxRows="3" formControlName="description">
                          </textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
                <!-- INVOICE LINE ITEM END -->
                <!-- BILL LINE ITEM START -->
                <ng-container *ngIf="value.type === invoiceTypes.Bill">
                  <div class="flex flex-grow flex-direction-column">
                    <div class="flex-full-auto" style="height: 60px;">
                      <div class="flex flex-grow flex-direction-column">
                        <div>
                          <div class="inline-block">
                            <ng-container>
                              <mat-form-field style="width:260px !important" class="mr-2" *ngIf="chartOfAccounts">
                                <mat-label>Chart of Account</mat-label>
                                <input type="text" [readonly]="item.value.offering?.offeringKey"
                                       placeholder="Chart of Account" matInput
                                       (focus)="filteredChartOfAccounts = chartOfAccounts"
                                       (keyup)="onChartOfAccountKeyUp(item)" formControlName="chartOfAccount"
                                       (blur)="onChartOfAccountChanged($event, item)" [matAutocomplete]="coa">
                                <mat-autocomplete [displayWith]="chartOfAccountDisplayFn.bind(this)"
                                                  #coa="matAutocomplete">
                                  <mat-option value="-1" (click)="onAddChartOfAccountClicked(item)">
                                    <span class="bold">Add New Chart Of Account</span>
                                  </mat-option>
                                  <mat-option *ngFor="let chartOfAccount of filteredChartOfAccounts"
                                              [value]="chartOfAccount">
                                    {{chartOfAccount.fullDisplayPath}}
                                  </mat-option>
                                </mat-autocomplete>
                                <mat-icon matSuffix
                                          matTooltip="A list of account names that a company uses in its general ledger for recording transactions">
                                  help_outline
                                </mat-icon>
                              </mat-form-field>
                            </ng-container>
                          </div>
                          <div class="inline-block">
                            <mat-form-field class="mr-2 mat-form-field-double" *ngIf="jobs">
                              <mat-label>Job</mat-label>
                              <input type="text" placeholder="Job" matInput formControlName="jobKey"
                                (blur)="onJobChanged($event, item)" [matAutocomplete]="auto">
                              <mat-autocomplete [displayWith]="jobDisplayFn.bind(this)" #auto="matAutocomplete">
                                <mat-option *ngFor="let job of jobs" [value]="job.jobKey">
                                  {{job.customer.name}} - {{job.name}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-end">
                        <div>
                          <table>
                            <tr>
                              <td *ngIf="value.type === invoiceTypes.Bill">
                                Tax
                              </td>
                              <td>
                                Total
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field class="mat-form-field-smaller">
                                  <input matInput type="text" currencyMask formControlName="totalTax">
                                </mat-form-field>
                              </td>
                              <td>
                                <mat-form-field class="mat-form-field-smaller">
                                  <input matInput type="text" currencyMask formControlName="total">
                                </mat-form-field>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="full-width">
                      <mat-form-field style="width: 100%">
                        <mat-label>Items Description</mat-label>
                        <textarea class="full-width" matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
                          cdkAutosizeMaxRows="3" formControlName="description">
                          </textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
                <!-- BILL LINE ITEM END -->
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!getFormArrayItems().controls.length">
          <div class="text-center full-width bold" *ngIf="value.type !== invoiceTypes.Bill">
            Search or add an offering above to begin . . .
          </div>
          <div class="text-center full-width bold" *ngIf="value.type === invoiceTypes.Bill">
            Add an expense to begin . . .
          </div>
        </ng-container>
      </div>
      <div class="flex">
        <div class="notes-container">
          <mat-form-field>
            <mat-label>Notes</mat-label>
            <textarea #autosize="cdkTextareaAutosize" matInput cdkTextareaAutosize cdkAutosizeMinRows="15"
              formControlName="notes">
          </textarea>
            <mat-icon matSuffix matTooltip="Notes that the customer can view pertaining to the customer invoice">
              help_outline
            </mat-icon>
          </mat-form-field>
        </div>
        <div class="total-container">
          <div class="w-100" *ngIf="value.type !== invoiceTypes.Bill">
            <mat-form-field class="mat-form-field-full">
              <mat-label>Subtotal</mat-label>
              <input readonly="true" matInput type="text" currencyMask formControlName="subTotal">
              <mat-icon class="ms-1" matSuffix
                matTooltip="The sum of part of a series of figures/amounts. Somewhat less than complete: almost compete">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="w-100 text-end">
            <ng-container *ngIf="value.type === invoiceTypes.Invoice">
              <mat-form-field *ngIf="taxRates?.length" class="mat-form-field-with-settings-button">
                <mat-label>Tax</mat-label>
                <mat-select formControlName="taxRate" [compareWith]="taxRateComparer"
                  (selectionChange)="onTaxRateChanged($event)" #selectedTaxRate>
                  <mat-select-trigger>
                    {{ selectedTaxRate?.value?.rate | percentFormatter:3 }}
                  </mat-select-trigger>
                  <mat-option [value]="null"></mat-option>
                  <mat-option *ngFor="let taxRate of taxRates" [value]="taxRate">{{taxRate.rate | percentFormatter:3}}
                    ({{taxRate.name}})
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="inline-block vertical-align-middle">
                <mat-icon class="cursor-pointer ms-1" title="Manage Tax Rates" (click)="onManageTaxRatesClicked()">
                  settings
                </mat-icon>
              </div>
            </ng-container>
            <mat-form-field class="mat-form-field-with-settings-button ms-1">
              <mat-label>Total Tax</mat-label>
              <input [readonly]="value.type !== invoiceTypes.Bill" (blur)="onTotalTaxChanged()" matInput type="text"
                currencyMask formControlName="totalTax">
              <mat-icon class="ms-1" matSuffix matTooltip="Enter you tax information here">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="w-100">
            <mat-form-field class="mat-form-field-full">
              <mat-label>Total</mat-label>
              <input readonly="true" matInput type="text" currencyMask formControlName="total">
              <mat-icon class="ms-1" matSuffix matTooltip="Subtotal + Total Tax = Total">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="w-100">
            <mat-form-field class="mat-form-field-full">
              <mat-label>Balance Due</mat-label>
              <input readonly="true" matInput type="text" currencyMask [(ngModel)]="balanceDue"
                [ngModelOptions]="{standalone: true}">
              <mat-icon class="ms-1" matSuffix matTooltip="Balance Due  is the sum the client owes your company">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="w-100">
            <mat-form-field class="mat-form-field-full">
              <mat-label>{{value?.type === invoiceTypes.Bill ? 'Paid' : 'Received'}}</mat-label>
              <input readonly="true" matInput type="text" currencyMask [(ngModel)]="amountPaid"
                [ngModelOptions]="{standalone: true}">
              <mat-icon class="ms-1" matSuffix matTooltip="Received is the amount you received from the client">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
