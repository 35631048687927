import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { TransactionRuleService } from 'src/app/core/services/domain/transaction-rule.service';
import { TransactionRuleModel } from 'src/app/shared/models/domain/transaction-rule.model';
import { TransactionRuleFieldsEnum } from 'src/app/shared/enums/domain/transaction-rule-fields.enum';
import { TransactionRuleConditionsEnum } from 'src/app/shared/enums/domain/transaction-rule-conditions.enum';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';

@Component({
  selector: 'app-transaction-rule-form-dialog',
  templateUrl: './transaction-rule-form-dialog.component.html'
})
export class TransactionRuleFormDialogComponent extends BaseEntryPageComponent<TransactionRuleModel> implements OnInit, AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<TransactionRuleFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { transactionRuleKey: string, merchantName: string, vendorKey: string, chartOfAccountKey: string },
    private businessContextService: BusinessContextService,
    private transactionRuleService: TransactionRuleService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    if (this.dialogData.transactionRuleKey) {
      this.transactionRuleService.get(this.dialogData.transactionRuleKey).subscribe(transaction => {
        this.value = transaction;
      });
    } else {
      this.value = <TransactionRuleModel>{
        businessKey: this.businessContextService.currentBusiness.businessKey
      };

      if (this.dialogData.merchantName) {
        this.value.ruleOneField = TransactionRuleFieldsEnum.Merchant;
        this.value.ruleOneCondition = TransactionRuleConditionsEnum.Equals;
        this.value.ruleOneTerm = this.dialogData.merchantName;
      }

      if (this.dialogData.chartOfAccountKey) {
        this.value.chartOfAccount = <ChartOfAccountModel>{chartOfAccountKey: this.dialogData.chartOfAccountKey}
      }

      if (this.dialogData.vendorKey) {
        this.value.vendor = <VendorModel>{vendorKey: this.dialogData.vendorKey}
      }
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (!this.value?.transactionRuleKey && this.dialogData.merchantName) {
      this.formGroupRef.markAsDirty();
    }
  }

  onApplyRuleClicked(): void {
    this.isSaving = true;
    this.transactionRuleService.applyRule({
      businessKey: this.value.businessKey,
      transactionRuleKeys: [this.value?.transactionRuleKey]
    }).subscribe(_ => {
      this.onSaveSuccess('Transaction rule was applied successfully.');
      this.dialogRef.close(this.value?.transactionRuleKey);
    }, err => {
      this.onSaveFailed(err);
    });
  }

  onSaveClicked(applyRule: boolean = false): void {
    this.isSaving = true;
    if (!this.value.transactionRuleKey) {
      this.isSaving = true;
      this.transactionRuleService.create(this.value).subscribe(key => {
        this.formGroupRef.get('transactionRuleKey').setValue(key);
        this.formGroupRef.updateValueAndValidity();

        if (applyRule) {
          this.onSaveSuccess('Transaction rule was created successfully, applying rule...');
          this.isSaving = true;

          this.transactionRuleService.applyRule({
            businessKey: this.value.businessKey,
            transactionRuleKeys: [key]
          }).subscribe(_ => {
            this.onSaveSuccess('Transaction rule was applied successfully.');
            this.dialogRef.close(key);
          }, err => {
            this.onSaveFailed(err);
          });
        } else {
          this.isSaving = false;
          this.onSaveSuccess('Transaction rule was created successfully.');
          this.dialogRef.close(this.value?.transactionRuleKey);
        }
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }

  onDeleteClicked(): void {
    this.transactionRuleService.update(this.value).subscribe(key => {
      this.onSaveSuccess('Transaction rule was updated successfully');
      this.dialogRef.close(false);
    }, err => {
      this.onSaveFailed(err);
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
