import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessTypesEnum } from 'src/app/shared/enums/domain/business-types.enum';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { MatTabGroup } from '@angular/material/tabs';
import { BankingService } from 'src/app/core/services/domain/banking.service';
import { NumberUtilities } from 'src/app/core/utilities/number.utilities';
import { StringUtilities } from 'src/app/core/utilities/string.utilities';
import { DateUtilities } from 'src/app/core/utilities/date.utilities';
import { Router } from '@angular/router';
import { BankingConnectionModel } from 'src/app/shared/models/domain/banking-connection.model';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { MonthsEnum } from 'src/app/shared/enums/domain/months.enum';
import { IncomeTaxYearTypesEnum } from 'src/app/shared/enums/domain/income-tax-year-types.enum';
import { AccountingMethodsEnum } from 'src/app/shared/enums/domain/accounting-methods.enum';
import { environment } from 'src/environments/environment';
import {MatCheckboxChange} from "@angular/material/checkbox";
import { BankingAccountModel } from 'src/app/shared/models/domain/banking-account.model';
import { ViewModesEnum } from 'src/app/shared/enums/app/view-modes.enum';
import { BankingConstants } from 'src/app/shared/models/auth/constants/banking.constants';
import { TransactionRuleService } from 'src/app/core/services/domain/transaction-rule.service';

@Component({
  selector: 'app-business-form',
  templateUrl: './business-form.component.html',
  styleUrls: ['./business-form.component.scss']
})
export class BusinessFormComponent extends BaseFormComponent<BusinessModel> implements OnInit, AfterViewInit {
  @Input() isProfileSetup = false;
  @Input() hasValidSubscription = false;
  @Input() selectedTabIndex: number = 0;
  @Output() selectedTabIndexChange = new EventEmitter();
  @Input() requiresBankingLogin: boolean = false;
  @Input() fileList: FileList;
  @Output() fileListChange = new EventEmitter<FileList>();
  @Output() downloadLogoClick = new EventEmitter<BusinessModel>()

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  //USED BY PROFILE SETUP
  @ViewChild('matTabGroup') matTabGroup: MatTabGroup;

  plaidHandler: any = null;
  businessTypes: EnumModel[] = [];
  months: EnumModel[] = [];
  incomeTaxYearTypes: EnumModel[] = [];
  accountingMethods: EnumModel[] = [];

  previewFileUrl: string = null;
  bankingConnections: BankingConnectionModel[] = null;
  numberUtilities = NumberUtilities;
  dateUtilities = DateUtilities;
  stringUtilities = StringUtilities;
  colors: {
    primaryColor: string,
    secondaryColor: string
  } = {primaryColor: null, secondaryColor:null};

  static bankingInformationTabIndex = 6;
  isSandboxBanking = false;

  constructor(private chartOfAccountService: ChartOfAccountService, private notificationService: NotificationService,
              private bankingService: BankingService, private router: Router, private dialogService: DialogService, private transactionRuleService: TransactionRuleService) {
    super();
  }

  ngOnInit(): void {
    this.businessTypes = EnumUtilities.convertToSelectModels(BusinessTypesEnum, true);
    this.months = EnumUtilities.convertToSelectModels(MonthsEnum, true);
    this.incomeTaxYearTypes = EnumUtilities.convertToSelectModels(IncomeTaxYearTypesEnum, true);
    this.accountingMethods = EnumUtilities.convertToSelectModels(AccountingMethodsEnum, true);

    this.isSandboxBanking = environment.isSandboxBanking;

    if (this.value?.businessKey && !this.isProfileSetup && this.hasValidSubscription) {
      this.bankingService.listConnections(this.value?.businessKey).subscribe(connections => {
        this.bankingConnections = connections;
      });
    }

    super.ngOnInit();

    this.subscriptions.add(this.formGroupRef.get('primaryColor').valueChanges.subscribe(val => {
      this.colors.primaryColor = val;
    }));

    this.subscriptions.add(this.formGroupRef.get('secondaryColor').valueChanges.subscribe(val => {
      this.colors.secondaryColor = val;
    }));
  }

  ngAfterViewInit(): void {
    if (this.matTabGroup) {
      this.matTabGroup.selectedIndex = this.selectedTabIndex;
    }
  }

  onSelectedTabChanged(): void {
    this.selectedTabIndexChange.emit(this.selectedTabIndex);
  }

  onFilesUploaded(fileList: FileList): void {
    if (fileList.length) {
      const mimeType = fileList[0].type;
      if (mimeType.match(/image\/*/) == null) {
        this.notificationService.showErrorNotification('Only images are supported.');
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(fileList[0]);
      reader.onload = (_event) => {
        this.previewFileUrl = <string>reader.result;
      }

      this.fileList = fileList;
      this.fileListChange.emit(this.fileList);
      this.formGroupRef.markAsDirty();
      this.value = this.formGroupRef.value;
      this.valueChange.emit(this.value);
    }
  }

  onDownloadLogoClicked(): void {
    this.downloadLogoClick.emit(this.value);
  }

  onWebsiteBlurred(): void {
    let value = this.value.website;

    if (value && !value.startsWith('http')) {
      this.formGroupRef.get('website').setValue('https://' + this.value.website);
    }
  }

  onColorChanged(field: string, $event: any): void {
    this.colors =  {
      primaryColor: (field === 'primaryColor' ? $event : this.colors.primaryColor),
      secondaryColor: (field === 'secondaryColor' ? $event : this.colors.secondaryColor)
    }
    this.formGroupRef.get(field).setValue($event);
    this.formGroupRef.markAsDirty();
    this.formGroupRef.updateValueAndValidity();
  }

  onNavigateToBankingChartOfAccountClicked(bankingAccountId: string): void {
    this.chartOfAccountService.search({bankingAccountIds: [bankingAccountId], businessKey: this.value.businessKey}).subscribe(chartOfAccount => {
      if (chartOfAccount.length) {
        this.router.navigateByUrl(RouteUtilities.routes.application.chartOfAccountEdit.getNavigateUrl(chartOfAccount[0].chartOfAccountKey));
      }
    });
  }

  onDeleteBankAccountClicked(bankingConnectionKey: string): void {
    let matDialogRef = this.dialogService.openConfirmationDialog();

    matDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bankingService.deleteConnection(bankingConnectionKey).subscribe(_ => {
          this.notificationService.showSuccessNotification('Banking institution has be deleted successfully.');

          this.bankingService.listConnections(this.value?.businessKey).subscribe(connections => {
            this.bankingConnections = connections;
          });
        }, err => {
          super.onHttpFailure(this.notificationService, err);
        });
      }
    });
  }

  onSyncInstitutionClicked(bankingConnection: BankingConnectionModel = null): void {
    let bankingAccounts = [];
    if (bankingConnection == null) {
      for (let currentBankingConnection of this.bankingConnections) {
        bankingAccounts = bankingAccounts.concat(currentBankingConnection.bankingAccounts);
      }
    } else {
      bankingAccounts = bankingConnection.bankingAccounts
    }

    if (this.bankingService.hasInvalidBankingAccountsToSync(bankingAccounts)) {
      let matDialogResult = this.dialogService.openSyncConfirmationDialog(bankingAccounts, false);

      matDialogResult.afterClosed().subscribe(result => {
        if (result) {
          this.syncBankingConnection(bankingConnection);
        }
      })
    } else {
      this.syncBankingConnection(bankingConnection);
    }
  }

  onBankAccountEnableChange(event: MatCheckboxChange, businessKey: string, bankingAccountId: string): void {
      this.bankingService.updateBankingAccountIsActive(businessKey,bankingAccountId,event.checked).subscribe(_ => {
        this.notificationService.showSuccessNotification(['Banking account connection toggled successfully.']);
        this.showBlockingLoader = false;
        this.bankingService.listConnections(this.value?.businessKey).subscribe(connections => {
          this.bankingConnections = connections;
        });
      }, err => {
        this.showBlockingLoader = false;
        this.onHttpFailure(this.notificationService, err);
      })
  }

  onStartingBalanceModalClicked(bankingAccount: BankingAccountModel): void {
    this.chartOfAccountService.search({bankingAccountIds: [bankingAccount.bankingAccountId], businessKey: this.value.businessKey}).subscribe(chartOfAccount => {
      if (chartOfAccount?.length === 1) {
        let matDialogRef = this.dialogService.openChartOfAccountForm(null, chartOfAccount[0].chartOfAccountKey, ViewModesEnum.Banking);

        matDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.bankingService.listConnections(this.value?.businessKey).subscribe(connections => {
              this.bankingConnections = connections;
            });
          }
        });
      }
    });
  }

  onTxStartDateChange(newDate: Date, businessKey: string, bankingAccountId: string): void {
    this.bankingService.updateBankingAccountTxImportStartDate(businessKey,bankingAccountId,newDate).subscribe(_ => {
      this.notificationService.showSuccessNotification(['Banking account transaction start import date changed successfully.']);
      this.showBlockingLoader = false;
      this.bankingService.listConnections(this.value?.businessKey).subscribe(connections => {
        this.bankingConnections = connections;
      });
    }, err => {
      this.showBlockingLoader = false;
      this.onHttpFailure(this.notificationService, err);
    })
  }

  onConnectBankAccountClicked(): void {
    this.showBlockingLoader = true;

    this.bankingService.getAccessToken(this.value?.businessKey, this.requiresBankingLogin).subscribe(token => {
      // @ts-ignore
      this.plaidHandler = window.Plaid.create({
        token: token,
        country_codes: ['US'],
        language: 'en',
        onSuccess: (public_token, metadata) => {
          this.showBlockingLoader = true;

          this.bankingService.createConnection(this.value.businessKey, public_token).subscribe(result => {
            if (!result) {
              this.notificationService.showErrorNotification('An error occurred while linking your banking institution.');
              this.showBlockingLoader = false;
              return;
            }

            this.bankingService.listConnections(this.value?.businessKey).subscribe(connections => {
              this.bankingConnections = connections;

              this.notificationService.showSuccessNotification(['Banking institution has been linked successfully.']);
              this.showBlockingLoader = false;

              this.bankingService.upsertAllBankAccounts(this.value?.businessKey).subscribe(_ => {
                this.showBlockingLoader = false;
                this.notificationService.showSuccessNotification('Banking accounts have been linked successfully.');
              }, _ => {
                this.notificationService.showErrorNotification('An unknown error occurred while linking your banking institution.');
                this.showBlockingLoader = false;
              });
            });
          }, err => {
            this.notificationService.showErrorNotification('An unknown error occurred while linking your banking institution.');
            this.showBlockingLoader = false;
          })
        },
        onLoad: () => {
          // console.log('load');
          // console.log(arguments);
        },
        onExit: (err, metadata) => {
          this.showBlockingLoader = false;
          // console.log('exit');
          // console.log(arguments);
        },
        onEvent: (eventName, metadata) => {
          // console.log('onEvent');
          // console.log(arguments);
        },
        //required for OAuth; if not using OAuth, set to null or omit:
        //receivedRedirectUri: window.location.href,
      });

      this.plaidHandler.open();
    }, err => {
      this.onHttpFailure(this.notificationService, err);
      this.showBlockingLoader = false;
    });
  }

  protected getFormGroup(): UntypedFormGroup {
    this.colors.primaryColor = this.value?.primaryColor;
    this.colors.secondaryColor = this.value?.secondaryColor;

    return new UntypedFormGroup({
      businessKey: new UntypedFormControl(this.value?.businessKey),
      parentBusinessKey: new UntypedFormControl(this.value?.parentBusinessKey),
      name: new UntypedFormControl(this.value?.name),
      legalName: new UntypedFormControl(this.value?.legalName),
      type: new UntypedFormControl(this.value?.type || 0),
      description: new UntypedFormControl(this.value?.description),
      phoneNumber: new UntypedFormControl(this.value?.phoneNumber),
      website: new UntypedFormControl(this.value?.website),
      email: new UntypedFormControl(this.value?.email),
      primaryColor: new UntypedFormControl(this.value?.primaryColor),
      secondaryColor: new UntypedFormControl(this.value?.secondaryColor),
      file: new UntypedFormGroup({
        fileKey:  new UntypedFormControl(this.value?.file?.fileKey),
        name:  new UntypedFormControl(this.value?.file?.name),
        contentType:  new UntypedFormControl(this.value?.file?.contentType),
        uri: new UntypedFormControl(this.value?.file?.uri)
      }),
      address: new UntypedFormGroup({
        fullAddress: new UntypedFormControl(this.value?.address?.fullAddress),
        addressLineOne: new UntypedFormControl(this.value?.address?.addressLineOne),
        addressLineTwo: new UntypedFormControl(this.value?.address?.addressLineTwo),
        city: new UntypedFormControl(this.value?.address?.city),
        state: new UntypedFormControl(this.value?.address?.state),
        postalCode: new UntypedFormControl(this.value?.address?.postalCode),
        country: new UntypedFormControl(this.value?.address?.country),
        latitude: new UntypedFormControl(this.value?.address?.latitude),
        longitude: new UntypedFormControl(this.value?.address?.longitude),

        isMailingAddressSame: new UntypedFormControl(this.value?.address?.isMailingAddressSame ?? false),
        fullAddressMailing: new UntypedFormControl(this.value?.address?.fullAddressMailing),
        addressLineOneMailing: new UntypedFormControl(this.value?.address?.addressLineOneMailing),
        addressLineTwoMailing: new UntypedFormControl(this.value?.address?.addressLineTwoMailing),
        cityMailing: new UntypedFormControl(this.value?.address?.cityMailing),
        stateMailing: new UntypedFormControl(this.value?.address?.stateMailing),
        postalCodeMailing: new UntypedFormControl(this.value?.address?.postalCodeMailing),
        countryMailing: new UntypedFormControl(this.value?.address?.countryMailing),
        latitudeMailing: new UntypedFormControl(this.value?.address?.latitudeMailing),
        longitudeMailing: new UntypedFormControl(this.value?.address?.longitudeMailing),
      }),
      service: new UntypedFormGroup({
        isDisabled: new UntypedFormControl(this.value?.service?.isDisabled ?? false),
        discountRate: new UntypedFormControl(this.value?.service?.discountRate),
        notes: new UntypedFormControl(this.value?.service?.notes),
      }),
      accounting: new UntypedFormGroup({
        employerIdentificationNumber: new UntypedFormControl(this.value?.accounting?.employerIdentificationNumber),
        fiscalTaxYearMonth: new UntypedFormControl(this.value?.accounting?.fiscalTaxYearMonth || 0),
        incomeTaxYearMonth: new UntypedFormControl(this.value?.accounting?.incomeTaxYearMonth || 0),
        accountingMethod: new UntypedFormControl(this.value?.accounting?.accountingMethod || 0),
        closingDate: new UntypedFormControl(this.value?.accounting?.closingDate),
        defaultInvoiceNotes: new UntypedFormControl(this.value?.accounting?.defaultInvoiceNotes),
      })
    })
  };

  private syncBankingConnection(bankingConnection: BankingConnectionModel = null): void {
    this.showBlockingLoader = true;
    this.bankingService.sync(this.value.businessKey, bankingConnection?.bankingConnectionKey).subscribe(_ => {
      this.notificationService.showSuccessNotification('Transactions synced successfully.  Applying Rules...');

      this.transactionRuleService.applyRule({businessKey: this.value.businessKey}).subscribe(_ => {
        this.notificationService.showSuccessNotification('Transactions rules were applied successfully.');
        this.bankingService.listConnections(this.value?.businessKey).subscribe(connections => {
          this.bankingConnections = connections;
          this.showBlockingLoader = false;
        }, err => {
          this.onHttpFailure(this.notificationService, err);
          this.showBlockingLoader = false;
        });
      }, err => {
        this.onHttpFailure(this.notificationService, err);
        this.showBlockingLoader = false;
      });
    }, err => {
      if (err?.length) {
        let errorMsg = err[0].message;

        if (errorMsg == BankingConstants.BankingLoginRequired) {
          this.notificationService.showErrorNotification('A banking institution link has expired.');
          this.router.navigateByUrl(RouteUtilities.routes.application.businessEdit.getNavigateUrl(this.value.businessKey, BusinessFormComponent.bankingInformationTabIndex))
        } else {
          this.onHttpFailure(this.notificationService, err);
        }
      }

      this.showBlockingLoader = false;
    });
  }
}

