import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { ChartOfAccountTypesEnum } from 'src/app/shared/enums/domain/chart-of-account-types.enum';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chart-of-account-form',
  templateUrl: './chart-of-account-form.component.html',
  styleUrls: ['./chart-of-account-form.component.scss']
})
export class ChartOfAccountFormComponent extends BaseFormComponent<ChartOfAccountModel> implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  private parentChartOfAccountTrigger: MatAutocompleteTrigger;

  @ViewChild(MatAutocompleteTrigger) set content(content: MatAutocompleteTrigger) {
    if (content && !this.parentChartOfAccountTrigger) {
      this.parentChartOfAccountTrigger = content;
      this.subscriptions.add(this.parentChartOfAccountTrigger.panelClosingActions.subscribe(e => {
        if (!(e && e.source)) {
          this.parentChartOfAccountKeyControl.setValue(null);
          this.parentChartOfAccountTrigger.closePanel();
          this.formGroupRef.get('fullBankingAccountNumber').setValue(null);
          this.isBankCOA = false;
        } else {
          let parentChartOfAccount = this.parentChartOfAccounts.find(t => t.chartOfAccountKey === e.source.value)
          this.formGroupRef.get('type').setValue(parentChartOfAccount.type);
          this.isBankCOA = this.chartOfAccountService.isBankAccount(parentChartOfAccount);
          this.onTypeChanged();
        }
      }));
    }
  }

  types: EnumModel[] = [];
  allChartOfAccounts: ChartOfAccountModel[] = [];
  filteredParentChartOfAccounts: ChartOfAccountModel[] = [];
  parentChartOfAccounts: ChartOfAccountModel[];
  parentChartOfAccountKeyControl: UntypedFormControl;
  typesEnum = ChartOfAccountTypesEnum;
  isBankCOA = false;

  constructor(private chartOfAccountService: ChartOfAccountService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.types = EnumUtilities.convertToSelectModels(ChartOfAccountTypesEnum, true);
    this.isBankCOA = this.chartOfAccountService.isBankAccount(this.value);

    super.ngOnInit();

    this.chartOfAccountService.search({businessKey: this.value.businessKey}).subscribe(chartOfAccounts => {
      this.allChartOfAccounts = chartOfAccounts;
      let topLevelChartOfAccounts = chartOfAccounts.filter(t => this.isTopLevelAccount(t));
      this.parentChartOfAccounts = topLevelChartOfAccounts;
      this.filteredParentChartOfAccounts = topLevelChartOfAccounts;
    });

    this.subscriptions.add(this.parentChartOfAccountKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredParentChartOfAccounts = this.parentChartOfAccounts;
        } else {
          this.filteredParentChartOfAccounts = this.parentChartOfAccounts.filter(coa => coa.name.toLowerCase()
            .indexOf(value.toLowerCase()) !== -1);
        }
      }),
    ).subscribe());
  }

  parentChartOfAccountDisplayFn(chartOfAccountKey: string): string {
    let foundResult = this.parentChartOfAccounts?.find(t => t.chartOfAccountKey === chartOfAccountKey);
    return this.getParentChartOfAccountDisplayName(foundResult);
  }

  getParentChartOfAccountDisplayName(chartOfAccount: ChartOfAccountModel): string {
    if (chartOfAccount?.parentChartOfAccountName) {
      return `${chartOfAccount.parentChartOfAccountName} >> ${chartOfAccount.name}`;
    }

    return chartOfAccount?.name ?? '';
  }

  onTypeChanged(): void {
    this.chartOfAccountService.getLatestAccountNumberByType(this.value.businessKey, this.value.type).subscribe(num => {
      this.formGroupRef.get('number').setValue(num);
    });
  }

  isTopLevelAccount(chartOfAccount: ChartOfAccountModel): boolean {
    return !chartOfAccount.parentChartOfAccountKey || !this.allChartOfAccounts.find(x => x.chartOfAccountKey === chartOfAccount.parentChartOfAccountKey)?.parentChartOfAccountKey
  }

  protected getFormGroup(): UntypedFormGroup {
    this.parentChartOfAccountKeyControl = new UntypedFormControl(this.value?.parentChartOfAccountKey);

    return new UntypedFormGroup({
      chartOfAccountKey: new UntypedFormControl(this.value?.chartOfAccountKey),
      parentChartOfAccountKey: this.parentChartOfAccountKeyControl,
      parentDisplayPath: new UntypedFormControl(this.value?.parentDisplayPath),
      businessKey: new UntypedFormControl(this.value?.businessKey),
      isSystemGenerated: new UntypedFormControl(this.value?.isSystemGenerated ?? false),
      name: new UntypedFormControl(this.value?.name),
      fullBankingAccountNumber: new UntypedFormControl(this.value?.fullBankingAccountNumber),
      number: new UntypedFormControl(this.value?.number),
      type: new UntypedFormControl(this.value?.type ?? ChartOfAccountTypesEnum.Unknown),
      description: new UntypedFormControl(this.value?.description),
      startingBalance: new UntypedFormControl(this.value?.startingBalance),
      isDeleted: new UntypedFormControl(this.value?.isDeleted ?? false),
      bankingAccount: new FormGroup({
        bankingAccountKey: new UntypedFormControl(this.value?.bankingAccount?.bankingAccountKey),
        displayName: new UntypedFormControl(this.value?.bankingAccount?.displayName),
        institution: new FormGroup({
          name:  new UntypedFormControl(this.value?.bankingAccount?.institution?.name)
        }),
        type: new UntypedFormControl(this.value?.bankingAccount?.type),
        subType: new UntypedFormControl(this.value?.bankingAccount?.subType)
      })
    })
  };
}
