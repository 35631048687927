import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { JobModel } from 'src/app/shared/models/domain/job.model';

@Component({
  selector: 'app-transaction-rules',
  templateUrl: './transaction-rules-page.component.html',
  styleUrls: ['./transaction-rules-page.component.scss']
})
export class TransactionRulesPageComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  onRowClicked(event: { data: JobModel }): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.jobEdit.getNavigateUrl(event.data.jobKey));
  }

  onAddButtonClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.jobAdd.getNavigateUrl());
  }
}
