<app-base-entry-page-template entryTitle="Manage Registry" [value]="value" [formGroupRef]="formGroupRef"
                              [showSave]="!value?.isDeleted"
                              [isSaving]="isSaving" [isDisabled]="isSaving || isDeleting || value?.isDeleted" (saveClick)="onSaveClicked(true)">
  <app-base-entry-page-content-template>
    <div>
      <app-transaction-form *ngIf="value"
                        [(formGroupRef)]="formGroupRef"
                        [(value)]="value">
      </app-transaction-form>
    </div>
  </app-base-entry-page-content-template>
  <app-base-entry-page-footer-template>
    <span class="mr-2" *ngIf="!value?.transactionKey">
      <button  mat-flat-button color="primary" (click)="onSaveClicked(false, false, true)">Save & Add New</button>
    </span>
    <span class="mr-2" *ngIf="value?.transactionKey && !value?.isReconciled">
      <button mat-flat-button color="primary" [disabled]="isSaving || isDeleting" (click)="onDeleteClicked()">{{value.isDeleted ? 'Save & Undelete' : 'Delete'}}</button>
    </span>
    <span>
      <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving || isDeleting || value?.isDeleted">Cancel</button>
    </span>
  </app-base-entry-page-footer-template>
</app-base-entry-page-template>
