<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-header">General Information</div>
    <div class="form-section-content">
      <div class="mt-2">
        <mat-form-field class="mat-form-field-double">
          <mat-label>Name</mat-label>
          <input matInput type="text" maxlength="100" formControlName="name">
          <mat-icon matSuffix
                    matTooltip="A descriptive name of the line item I am working on">
            help_outline
          </mat-icon>
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field class="mr-2">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type" (selectionChange)="onOfferingTypeChanged()">
            <mat-option *ngFor="let offeringType of offeringTypes"
                        [value]="offeringType.value">{{offeringType.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="mat-form-field" *ngIf="categories">
          <mat-label>Category</mat-label>
          <input type="text" placeholder="Category"
                 matInput (focus)="filteredCategories = categories" (keyup)="onCategoryKeyUp()"
                 (blur)="onCategoryChanged()" formControlName="category" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let category of filteredCategories" [value]="category">
              {{category}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix
                    matTooltip="Products are physical items sold by the company, and Services are typically intangible items">
            help_outline
          </mat-icon>
        </mat-form-field>
      </div>
      <div class="mt-2" formGroupName="chartOfAccount" *ngIf="chartOfAccounts">
        <mat-form-field class="mat-form-field-double">
          <mat-label>Income Account</mat-label>
          <input type="text"
                 #chartOfAccountInput
                 matInput
                 placeholder="Search for chart of account . . ."
                 formControlName="chartOfAccountKey"
                 [matAutocomplete]="auto">
          <mat-autocomplete [displayWith]="chartOfAccountDisplayFn.bind(this)" autoActiveFirstOption #auto="matAutocomplete">
            <mat-option value="-1">
              <span class="bold">Add Chart Of Account</span>
            </mat-option>
            <mat-option *ngFor="let chartOfAccount of filteredChartOfAccounts"
                        [value]="chartOfAccount.chartOfAccountKey">
              <span *ngIf="!chartOfAccount.parentChartOfAccountKey" class="bold">{{chartOfAccount.name}}</span>
              <span *ngIf="chartOfAccount.parentChartOfAccountKey">{{chartOfAccount.fullDisplayPath}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field class="mat-form-field-double">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            rows="3"
            formControlName="description">
          </textarea>
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field class="mr-2">
          <mat-label>SKU</mat-label>
          <input matInput type="text" formControlName="sku">
          <mat-icon matSuffix
                    matTooltip="SKU codes are to identify different internal products in your inventory">
            help_outline
          </mat-icon>
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field class="mat-form-field-smaller mr-5">
          <mat-label>Price</mat-label>
          <input matInput type="text" currencyMask formControlName="price">
        </mat-form-field>
        <mat-slide-toggle formControlName="isTaxable">Taxable?</mat-slide-toggle>
<!--        <mat-form-field class="mat-form-field-smaller mr-2">-->
<!--          <mat-label>Tax Rate</mat-label>-->
<!--          <input matInput type="text" currencyMask formControlName="taxRate" [options]="{ prefix: '%' }"-->
<!--                 min="0">-->
<!--        </mat-form-field>-->
      </div>
      <div class="mt-2" *ngIf="showServiceFields">
        <mat-form-field class="mr-2">
          <mat-label>Frequency</mat-label>
          <mat-select formControlName="frequency">
            <mat-option *ngFor="let offeringServiceFrequency of offeringServiceFrequencies"
                        [value]="offeringServiceFrequency.value">{{offeringServiceFrequency.label}}</mat-option>
          </mat-select>
          <mat-icon matSuffix
                    matTooltip="How often your client uses your service">
            help_outline
          </mat-icon>
        </mat-form-field>
      </div>
<!--      <div class="mt-2" *ngIf="showProductFields">-->
<!--        <mat-form-field>-->
<!--          <mat-label>Inventory Total</mat-label>-->
<!--          <input matInput type="text" currencyMask formControlName="inventoryTotal"-->
<!--                 [options]="{ prefix: '', precision: '0' }" min="0">-->
<!--        </mat-form-field>-->
<!--      </div>-->
    </div>
  </div>
</form>
