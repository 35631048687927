import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { DocumentSearchModel } from 'src/app/shared/models/domain/document-search.model';
import { DocumentRenderModel } from 'src/app/shared/models/domain/document-render.model';
import { EmailDocumentModel } from 'src/app/shared/models/domain/email-document.model';

@Injectable()
export class DocumentService {
  private url = environment.apiUrl + '/documents/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<DocumentModel> {
    return this.http.get<DocumentModel>(this.url + key);
  }

  search(searchModel: DocumentSearchModel): Observable<DocumentModel[]> {
    return this.http.post<DocumentModel[]>(this.url + 'search', searchModel);
  }

  download(document: DocumentModel): Observable<void> {
    return this.http.get(`${this.url}${document.documentKey}/download`, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(tap((data: any) => {
      if (data.type === HttpEventType.Response) {
        saveAs(data.body, document.file?.name);
      }
    }), catchError(err => {
      if (err?.text) {
        return from(err.text()).pipe(map(result => {
          throw JSON.parse(<string>result)
        }));
      }

      return err;
    }));
  }

  getRenderedHtml(model: DocumentRenderModel): Observable<{ renderedHtml: string }> {
    return this.http.post<{ renderedHtml: string }>(`${this.url}render-html`, model);
  }

  downloadRendered(model: DocumentRenderModel): Observable<void> {
    let url = `${this.url}download-rendered`;

    return this.http.post(url, model, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(tap((data: any) => {
      if (data.type === HttpEventType.Response) {
        saveAs(data.body, data.file?.name);
      }
    }), catchError(err => {
      if (err?.text) {
        return from(err.text()).pipe(map(result => {
          throw JSON.parse(<string>result)
        }));
      }

      return err;
    }));
  }

  emailRendered(model: EmailDocumentModel): Observable<string[]> {
    return this.http.post<string[]>(`${this.url}email-rendered`, model);
  }

  create(document: DocumentModel, fileList: FileList): Observable<string> {
    return this.http.post<string>(this.url, this.buildFormData(document, fileList));
  }

  update(document: DocumentModel, fileList: FileList): Observable<string> {
    return this.http.put<string>(this.url, this.buildFormData(document, fileList));
  }

  delete(key: string): Observable<boolean> {
    return this.http.delete<boolean>(this.url + key);
  }

  private buildFormData(document: DocumentModel, fileList: FileList): FormData {
    const formData = new FormData();
    formData.append('model', JSON.stringify(document));

    if (fileList != null && fileList.length === 1) {
      formData.append(fileList[0].name, fileList[0]);
    }

    return formData;
  }
}
