<div style="width:880px" class="mb-4 cursor-pointer" *ngIf="servicesWarningText"
     (click)="onNavigateToBusinessClicked()">
  <mat-icon class="cursor-pointer" style="color:red"
            matTooltip="Incomplete business profile!">warning
  </mat-icon>
  &nbsp;<span style="color:red" class="bold">{{servicesWarningText}}</span>
</div>
<div>
  <div style="max-width:768px">
    <app-location-search cssClass="mat-form-field-full" placeholderText="Enter your address or location . . ."
                         (locationSelect)="onLocationSelected($event)"></app-location-search>
    <img height="30px" class="cursor-pointer" [src]="myMarkerOptions.icon" alt="Find me"
         title="Find my current location"
         (click)="onFindMeClicked()"/>
    <span class="subtle-text">
    Search for businesses that are closest to you.
  </span>
  </div>
  <div>
    <div>
      <div class="full-width">
        <google-map *ngIf="locationService.isApiLoaded && options"
                    width="100%"
                    height="400px"
                    [options]="options"
                    (mapClick)="onMapClicked()">
          <map-marker
            *ngIf="currentLocation?.longitude && currentLocation?.latitude"
            [position]="{lat: currentLocation.latitude, lng: currentLocation.longitude}"
            [options]="myMarkerOptions">
          </map-marker>
          <map-marker
            #marker="mapMarker"
            *ngFor="let currentMarker of markers"
            [position]="currentMarker.position"
            [options]="markerOptions"
            (mapClick)="onOpenInfoWindowClicked(marker, currentMarker.business)">
          </map-marker>
          <map-info-window>
            <div class="info-window-container" *ngIf="selectedBusinessMarker">
              <div class="flex">
                <div class="flex" *ngIf="selectedBusinessMarker?.file?.uri">
                  <img height="100px" [src]="selectedBusinessMarker.file.uri" alt="logo"/>
                </div>
                <div class="flex title">
                  {{selectedBusinessMarker.name}}
                </div>
              </div>
              <div class="mt-1">
                <div class="bold"
                     *ngIf="selectedBusinessMarker.address?.distance">{{selectedBusinessMarker.address.distance?.toFixed(1) ?? '?'}}
                  miles away
                </div>
                <div>
                  {{selectedBusinessMarker.address.fullAddress}}
                </div>
              </div>
              <div class="mt-1">
                <span class="text-area-display">{{selectedBusinessMarker.description}}</span>
              </div>
            </div>
          </map-info-window>
        </google-map>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dataSource" class="mt-3 flex flex-direction-column">
  <div class="flex" style="max-width: 768px;">
    <div class="flex">
      <mat-form-field class="mat-form-field-full">
        <mat-label>Search for businesses by keywords . . .</mat-label>
        <input matInput (keyup)="onApplyFilterKeyUp($event)" placeholder="Search by any field . . ." #input>
      </mat-form-field>
    </div>
  </div>

  <table style="width:100%" mat-table matTableResponsive [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Business</th>
      <td mat-cell class="name-column" *matCellDef="let element" style="vertical-align:top">
        <div *ngIf="element?.file?.uri">
          <img [src]="element.file.uri" [alt]="element.name"/>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description</th>
      <td mat-cell class="description-column" *matCellDef="let element">
        <div class="bold" class="flex">
          <div class="flex flex-start" style="font-size:24px">
            {{element.name}}
          </div>
          <div class="flex flex-end">
            <span *ngIf="element.address?.distance" class="bold">{{element.address.distance.toFixed(1)}}
              miles</span>
          </div>
        </div>
        <div class="mt-2" style="text-align:left">
          <span class="text-area-display">{{element.description | slice : 0 : 500}}...</span>
        </div>
      </td>
    </ng-container>

    <!--    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
    <tr class="cursor-pointer" (click)="onRowClicked(row)" mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>
  <div class="flex mt-2">
    <div class="subtle-text flex flex-start">
      Businesses are ranked by distance.
    </div>
    <div class="flex flex-end">
      <mat-paginator [pageSizeOptions]="[5, 10, 25]" aria-label="Select page size"></mat-paginator>
    </div>
  </div>


  <div style="height:50px"></div>
</div>

