import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OfferingModel } from 'src/app/shared/models/domain/offering.model';
import { OfferingSearchModel } from 'src/app/shared/models/domain/offering-search.model';

@Injectable()
export class OfferingService {
  private url = environment.apiUrl + '/offerings/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<OfferingModel> {
    return this.http.get<OfferingModel>(`${this.url}${key}`);
  }

  search(model: OfferingSearchModel): Observable<OfferingModel[]> {
    return this.http.post<OfferingModel[]>(`${this.url}search`, model);
  }

  listCategories(businessKey: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.url}categories/${businessKey}`);
  }

  create(model: OfferingModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model);
  }

  update(model: OfferingModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model);
  }

  updateCategories(businessKey: string, model: string[]): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}categories/${businessKey}`, model);
  }

  delete(key: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}${key}`);
  }
}
