import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

@Injectable()
export class ScriptService {
  constructor() {
  }

  loadScript(path: string): Observable<any> {
    return from(
      new Promise((resolve, reject) => {
        //load script
        let script = <any>document.createElement('script');
        script.type = 'text/javascript';
        script.src = path;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {  //Others
          script.onload = () => {
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
    }));
  }
}
