import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';

export class InvoiceSearchModel {
  type: InvoiceTypesEnum;
  number?: string;
  customerKey?: string;
  vendorKey?: string;
  jobKeys?: string[];
  businessKeys?: string[];
  invoiceKeys?: string[];
  includeFinalized?: boolean = true;
  includeDeleted?: boolean = false;
  includeAccountsReceivableOnly?: boolean = false;
  includeAccountsPayableOnly?: boolean = false;
  excludeNoBalanceDue?: boolean = false;
  includeSalesOnly?: boolean = false;
}
