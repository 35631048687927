import { Component, OnInit } from '@angular/core';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { ClientModel } from 'src/app/shared/models/domain/client.model';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ClientService } from 'src/app/core/services/domain/client.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent extends BaseEntryPageComponent<ClientModel> implements OnInit {
  constructor(private clientService: ClientService,
              private authClientService: AuthClientService,
              routingService: RoutingService,
              notificationService: NotificationService,
              pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.clientService.get(this.authClientService.client.clientKey).subscribe((res) => {
      this.value = <ClientModel>res;
    })
  }

  onSaveClicked(): void {
    this.isSaving = true;
    this.clientService.update(this.value).subscribe(_ => {
      this.notificationService.showSuccessNotification('Your profile has been updated successfully.');
      this.isSaving = false;
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    });
  }
}
