<div class="video-container" *ngIf="content?.src">
  <div class="flex">
    <div class="flex flex-grow">
      <div class="title full-width">{{content.title}}</div>
    </div>
    <div class="flex flex-shrink flex-end" style="margin-top:-30px" *ngIf="showCancel">
      <button matSuffix mat-icon-button aria-label="Clear" (click)="onCancelClicked()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="description">
    {{content.description}}
  </div>
  <video controls autoplay *ngIf="autoPlay">
    <source [src]="content.src" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  <video controls *ngIf="!autoPlay">
    <source [src]="content.src" type="video/mp4">
    Your browser does not support the video tag.
  </video>
</div>
