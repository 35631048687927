<app-base-entry-dialog-template [value]="value" [formGroupRef]="formGroupRef" [isSaving]="isSaving"
                                (saveClick)="onSaveClicked()" (cancelClick)="onCancelClicked()" [showFooter]="false">
  <app-transaction-rule-form
    *ngIf="value"
    [(formGroupRef)]="formGroupRef"
    [(value)]="value">
  </app-transaction-rule-form>
  <div>
    <ng-container *ngIf="value">
<!--          <span class="mr-2" *ngIf="!value?.transactionRuleKey">-->
<!--            <app-save-button (click)="onSaveClicked()" [text]="'Save'" [processingText]="'Saving'" [isDisabled]="!formGroupRef.dirty || isSaving" [isSaving]="isSaving"></app-save-button>-->
<!--          </span>-->
      <span class="mr-2" *ngIf="!value?.transactionRuleKey">
            <button mat-flat-button color="primary"  (click)="onSaveClicked(true)" [disabled]="isSaving">Save & Apply</button>
          </span>
      <span class="mr-2" *ngIf="value?.transactionRuleKey">
            <button mat-flat-button color="primary"  (click)="onApplyRuleClicked()" [disabled]="!formGroupRef.dirty || isSaving">Apply to Transactions</button>
          </span>
      <span class="mr-2" *ngIf="value?.transactionRuleKey">
            <button mat-flat-button color="primary"  (click)="onDeleteClicked()" [disabled]="isSaving">{{value.isDeleted ? 'Undelete' : 'Delete'}}</button>
          </span>
      <span class="mr-2">
            <button mat-flat-button color="primary"  (click)="onCancelClicked()" [disabled]="isSaving">Cancel</button>
          </span>
      <ng-content select="app-base-entry-page-footer-template"></ng-content>
    </ng-container>
  </div>
</app-base-entry-dialog-template>
