<app-base-page-template>
  <app-base-page-content-template>
    <app-transactions-grid
      [showBankingActions]="true"
      [showAddButton]="false"
      [showDeleteButton]="false"
      [enableRowClick]="true"
      [showHeroNumbers]="true"
      [isRegistry]="false"
      [showMatchedTransactions]="true"
      (rowClick)="onRowClicked($event)">
    </app-transactions-grid>
  </app-base-page-content-template>
</app-base-page-template>
