import { Component } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';

@Component({
  selector: 'app-businesses-grid',
  templateUrl: './businesses-grid.component.html',
  styleUrls: ['./businesses-grid.component.scss']
})
export class BusinessesGridComponent extends BaseGridComponent<BusinessModel> {
  constructor(public authClientService: AuthClientService,
              private businessService: BusinessService,
              private notificationService: NotificationService,
              private businessSubscriptionService: BusinessSubscriptionService,
              private dialogService: DialogService) {
    super();
    this.gridKey = 'Businesses_v3';
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: '',
        field: this.actionFieldName,
        width: 30,
        cellClass: this.preventRowClickClass,
        cellRenderer: params => {
          let html = '';
          const rowData = <BusinessModel>params.data;

          if (rowData.subscription?.isActive || rowData.hasFreeSubscription) {
            html += '<i title="Cancel Subscription" class="cursor-pointer cancel-subscription fas fa-ban prevent-row-click"></i>';
          } else {
            html += '<i title="Add Free Subscription" class="cursor-pointer add-free-subscription fas fa-plus prevent-row-click"></i>';
          }

          return html;
        }
      },
      this.getDefaultColumn('name', 'Name'),
      this.getDefaultColumn('phoneNumber', 'Phone #'),
      this.getDefaultColumn('email', 'Email'),
      this.getYesNoColumn('hasFreeSubscription', 'Free Subscription'),
      this.getYesNoColumn('subscription.isActive', 'Valid Subscription'),
      this.getYesNoColumn('subscription.includeOrientation', 'Requested Orientation'),
      this.getYesNoColumn('subscription.includePayroll', 'Requested Payroll')
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((cellClick: {data: BusinessModel, event: CellClickedEvent }) => {
      if (cellClick.event.colDef.field === this.actionFieldName) {
        if (cellClick.data.subscription?.isActive || cellClick.data.hasFreeSubscription) {
          let matDialogRef = this.dialogService.openConfirmationDialog('You are about to remove all access?');

          this.subscriptions.add(matDialogRef.afterClosed().subscribe(result => {
            if (result) {
              if (cellClick.data.subscription?.isActive) {
                this.businessSubscriptionService.deleteSubscription(cellClick.data.businessKey).subscribe(_ => {
                  this.notificationService.showSuccessNotification('Subscription has been cancelled successfully.');
                  this.refreshGridData().subscribe();
                }, err => {
                  this.onHttpFailure(this.notificationService, err);
                });
              } else {
                this.businessService.get(cellClick.data.businessKey).subscribe(business => {
                  business.hasFreeSubscription = false;

                  this.businessService.update(business).subscribe(_ => {
                    this.notificationService.showSuccessNotification('Free subscription has been removed successfully.');
                    this.refreshGridData().subscribe();
                  }, err => {
                    this.onHttpFailure(this.notificationService, err);
                  });
                });
              }
            }
          }));
        } else {
            let matDialogRef = this.dialogService.openConfirmationDialog('You are about to add a free subscription?');

            this.subscriptions.add(matDialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.businessService.get(cellClick.data.businessKey).subscribe(business => {
                  business.hasFreeSubscription = true;

                  this.businessService.update(business).subscribe(_ => {
                    this.notificationService.showSuccessNotification('Free subscription has been added successfully.');
                    this.refreshGridData().subscribe();
                  }, err => {
                    this.onHttpFailure(this.notificationService, err);
                  });
                });
              }
            }));
        }
      }
    }));
  }

  protected setRowData(): Observable<any[]> {
    return this.businessService.search({includeDeleted: this.includeDeleted, includeSubscriptionDetail: true});
  }
}
