<form [formGroup]="formGroupRef">
  <div>
    <div class="float-left">
      <div class="form-section-header">
        <span>General Information</span>
      </div>
    </div>
    <div class="float-right">
      <div class="inline-block mr-2">
        <button *ngIf="value?.transactionRuleKey" class="mr-2" color="primary" mat-flat-button (click)="onEditTransactionRuleClicked()">View Matching Transaction Rule
        </button>
      </div>
      <div class="inline-block">
        <button color="primary" *ngIf="!value?.isRegistry" mat-flat-button (click)="onManageTransactionRulesClicked()">Manage Transaction Rules
        </button>
      </div>
    </div>
    <div class="clear-both"></div>
  </div>
  <div class="form-section mt-2 subtle-text bold">
    <ng-container *ngIf="value?.transactionKey">
      Status:&nbsp;{{getTransactionStatus(value)}}
      <ng-container *ngIf="!value?.isDeleted">
          <span *ngIf="!value.hasLinkedTransaction">
            <div class="inline-block ml-1">
              <a href="javascript:void(0)"
                 (click)="onLinkToTransactionClicked()">Link to {{value?.isRegistry ? 'Transaction' : 'Registry'}}</a>
            </div>
          </span>
      </ng-container>
    </ng-container>
  </div>
  <div class="flex">
    <div class="form-section">
      <div>
        <mat-form-field class="mat-form-field-date">
          <mat-label>Date</mat-label>
          <input matInput [readonly]="!value?.isRegistry || value.isReconciled" [matDatepicker]="picker" formControlName="date">
          <mat-datepicker-toggle [disabled]="!value?.isRegistry || value.isReconciled" matSuffix
                                 [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="mat-form-field-date ml-2" *ngIf="value?.clearedDate">
          <mat-label>Cleared Date</mat-label>
          <input matInput [readonly]="!!value?.bankingTransactionId || value.isReconciled" [matDatepicker]="clearedPicker"
                 formControlName="clearedDate">
          <mat-datepicker-toggle [disabled]="!!value?.bankingTransactionId || value.isReconciled" matSuffix
                                 [for]="clearedPicker"></mat-datepicker-toggle>
          <mat-datepicker #clearedPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div style="margin-top:12px">
        <mat-form-field>
          <mat-label>Reference #</mat-label>
          <input matInput formControlName="referenceNumber">
        </mat-form-field>
      </div>
      <!--      <div class="mt-2">-->
      <!--        <mat-form-field>-->
      <!--          <mat-label>Type</mat-label>-->
      <!--          <mat-select formControlName="type">-->
      <!--            <mat-option *ngFor="let transactionType of transactionTypes"-->
      <!--                        [value]="transactionType.value">{{transactionType.label}}</mat-option>-->
      <!--          </mat-select>-->
      <!--        </mat-form-field>-->
      <!--      </div>-->
      <div style="margin-top:6px" formGroupName="chartOfAccount" *ngIf="chartOfAccounts">
        <mat-form-field class="mat-form-field-double">
          <mat-label>Chart of Account</mat-label>
          <input type="text"
                 #chartOfAccountInput
                 matInput
                 placeholder="Search for chart of account . . ."
                 formControlName="chartOfAccountKey"
                 [matAutocomplete]="autoChartOfAccount">
          <mat-autocomplete [displayWith]="chartOfAccountDisplayFn.bind(this)" autoActiveFirstOption
                            #autoChartOfAccount="matAutocomplete">
            <mat-option value="-1">
              <span class="bold">Add Chart Of Account</span>
            </mat-option>
            <mat-option *ngFor="let chartOfAccount of filteredChartOfAccounts"
                        [value]="chartOfAccount.chartOfAccountKey">
              <span *ngIf="!chartOfAccount.parentChartOfAccountKey" class="bold">{{chartOfAccount.name}}</span>
              <span *ngIf="chartOfAccount.parentChartOfAccountKey">{{chartOfAccount.fullDisplayPath}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="flex">
      <div class="form-section">
        <ng-container>
          <div style="margin-bottom:12px;">
            <mat-form-field class="mat-form-field-double" *ngIf="customerVendors">
              <mat-label>{{customerVendorLabel}}</mat-label>
              <input type="text"
                     #customerVendorInput
                     matInput
                     placeholder="Search for customer or vendor . . ."
                     formControlName="customerVendorKey"
                     [matAutocomplete]="autoVendor">
              <mat-autocomplete [displayWith]="customerVendorDisplayFn.bind(this)" autoActiveFirstOption
                                #autoVendor="matAutocomplete">
                <mat-option value="-2">
                  <span class="bold">Add Vendor</span>
                </mat-option>
                <mat-option value="-1">
                  <span class="bold">Add Customer</span>
                </mat-option>
                <mat-option *ngFor="let customerVendor of filteredCustomerVendors" [value]="customerVendor.key">
                  <i [ngClass]="customerVendor.isVendor ? 'fa fa-warehouse' : 'fa fa-user-tag'"></i><span
                  class="ml-1">{{customerVendor.name}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div *ngIf="value.vendor?.vendorKey" formGroupName="vendor">
            <mat-form-field class="mat-form-field-double">
              <mat-label>Notes</mat-label>
              <textarea
                #autosize="cdkTextareaAutosize"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="5"
                formControlName="notes">
          </textarea>
            </mat-form-field>
          </div>
          <div *ngIf="value.customer?.customerKey" formGroupName="customer">
            <mat-form-field class="mat-form-field-double">
              <mat-label>Notes</mat-label>
              <textarea
                #autosize="cdkTextareaAutosize"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="5"
                formControlName="notes">
          </textarea>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="mt-2 form-section">
    <div class="form-section-header">Banking Information
    </div>
    <div *ngIf="value?.merchantName && !value?.isRegistry">
      Merchant Name: {{value.merchantName}}
    </div>
    <div *ngIf="value?.categories?.length && !value?.isRegistry">
      Categories: <span *ngFor="let category of value.categories; last as isLast">{{category.name}}<span
      *ngIf="!isLast">, </span></span>
    </div>
    <ng-container *ngIf="value?.bankingChartOfAccount?.chartOfAccountKey">
      <div class="inline-block mr-1">
        <mat-label>Banking Account:
        </mat-label>
        <a href="javascript:void(0)" (click)="onChartOfAccountClicked()">{{value.bankingChartOfAccount.name}}</a>
      </div>
      <div class="inline-block vertical-align-middle" *ngIf="value?.isRegistry && !value?.isReconciled">
        <mat-icon class="cursor-pointer" title="Unlink from Transaction" (click)="onUnlinkTransactionClick()">link_off
        </mat-icon>
      </div>
    </ng-container>
    <div *ngIf="!showBankingChartOfAccountToLink">
      <div class="inline-block" *ngIf="!value?.bankingChartOfAccount?.chartOfAccountKey">
        <a href="javascript:void(0)" (click)="showBankingChartOfAccountToLink = true">Link to Banking Chart of
          Account</a>
      </div>
    </div>
    <div [hidden]="!showBankingChartOfAccountToLink">
      <ng-container formGroupName="bankingChartOfAccount">
        <mat-form-field class="mat-form-field-double">
          <mat-label>Chart of Account</mat-label>
          <input type="text"
                 #bankingChartOfAccountInput
                 matInput
                 placeholder="Search for bank chart of account . . ."
                 formControlName="chartOfAccountKey"
                 [matAutocomplete]="autoBankingChartOfAccount">
          <mat-autocomplete [displayWith]="bankingChartOfAccountDisplayFn.bind(this)" autoActiveFirstOption
                            #autoBankingChartOfAccount="matAutocomplete">
            <mat-option *ngFor="let bankingChartOfAccount of filteredBankingChartOfAccounts"
                        [value]="bankingChartOfAccount.chartOfAccountKey">
              <span *ngIf="!bankingChartOfAccount.parentChartOfAccountKey"
                    class="bold">{{bankingChartOfAccount.name}}</span>
              <span
                *ngIf="bankingChartOfAccount.parentChartOfAccountKey">{{bankingChartOfAccount.fullDisplayPath}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  <div class="mt-3">
    <div class="form-section">
      <div class="form-section-header">Details
      </div>
      <div class="mt-2 flex">
        <div class="flex flex-grow mr-2">
          <mat-form-field class="full-width">
            <mat-label>Description</mat-label>
            <input [readonly]="value?.bankingTransactionId" matInput formControlName="description">
          </mat-form-field>
        </div>
        <div class="flex flex-shrink">
          <mat-form-field class="mat-form-field-smaller">
            <mat-label>Amount</mat-label>
            <input matInput type="text" [readonly]="value?.bankingTransactionId" currencyMask formControlName="amount">
          </mat-form-field>
        </div>
      </div>
      <div class="flex">
        <div class="notes-container">
          <mat-form-field>
            <mat-label>Notes</mat-label>
            <textarea
              #autosize="cdkTextareaAutosize"
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="10"
              formControlName="notes">
          </textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
