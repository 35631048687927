<app-base-entry-page-template entryTitle="Payments, Services, & Subscriptions" [value]="value"
                              [formGroupRef]="formGroupRef" cancelText="Go to Dashboard"
                              [isSaving]="isSaving" (saveClick)="onSaveClicked()"
                              [forceCancelEnabled]="true"
                              [isDisabled]="!hasValidSubscription && (creditCardForm?.isCalculatingRate || !creditCardForm?.formGroupRef?.value?.hasAcceptedTerms)"
                              (cancelClick)="onCancelClicked()" [saveText]="hasValidSubscription ? 'Update Services' : 'Submit & Subscribe'">
  <app-base-entry-page-content-template>
    <div *ngIf="hasValidSubscription !== null" style="padding-left:10px;" class="larger">
      Subscription Status:
      <span *ngIf="hasValidSubscription" class="bold" style="color:green">ACTIVE</span>
      <span *ngIf="!hasValidSubscription" class="bold" style="color:red">INACTIVE</span>
    </div>
    <div *ngIf="!hasAnySubscription" style="padding-left:10px;margin-top:10px" class="larger bold">
      FIRST 3 MONTHS FREE - YOU WILL NOT BE BILLED UNTIL 3 MONTHS AFTER YOU SUBSCRIBE
    </div>
    <div class="mt-5">
      <app-customer-payment-subscription-form *ngIf="value" [(formGroupRef)]="formGroupRef"
                                              [(value)]="value" [hasValidSubscription]="hasValidSubscription"
                                              (loaded)="onCreditCardLoaded()"></app-customer-payment-subscription-form>
    </div>
  </app-base-entry-page-content-template>
  <app-base-entry-page-footer-template>
  </app-base-entry-page-footer-template>
</app-base-entry-page-template>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
