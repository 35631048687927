import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TransactionRuleSearchModel } from 'src/app/shared/models/domain/transaction-rule-search.model';
import { TransactionRuleModel } from 'src/app/shared/models/domain/transaction-rule.model';
import { TransactionRuleApplyModel } from 'src/app/shared/models/domain/transaction-rule-apply.model';

@Injectable()
export class TransactionRuleService {
  private url = environment.apiUrl + '/transaction-rules/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<TransactionRuleModel> {
    return this.http.get<TransactionRuleModel>(`${this.url}${key}`);
  }

  search(model: TransactionRuleSearchModel): Observable<TransactionRuleModel[]> {
    return this.http.post<TransactionRuleModel[]>(`${this.url}search`, model);
  }

  create(model: TransactionRuleModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model)
  }

  update(model: TransactionRuleModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model)
  }

  applyRule(model: TransactionRuleApplyModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}apply-rule`, model)
  }
}
