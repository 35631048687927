<ng-container *ngIf="transaction && matchedTransactions">
  <div>
    <div class="float-left">
      <div class="form-section-header">
        {{transaction.isRegistry ? 'Registry' : 'Transaction'}} to Match
      </div>
    </div>
    <div class="float-right">
      <span class="mr-2">
        <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving">Cancel</button>
      </span>
    </div>
    <div class="clear-both"></div>
  </div>
  <div class="bold">
    <span class="mr-2">Date: {{transaction.date | dateFormatter}}</span>
    <span class="mr-2">Type: {{transaction.type | enumFormatter: transactionTypes }}</span>
    <span class="mr-2">Amount: {{transaction.amount ?? 0 | currencyFormatter}}</span>
  </div>
  <div>
    <span class="mr-2" *ngIf="transaction.vendor?.name">Vendor: {{transaction.vendor.name}}</span>
    <span class="mr-2" *ngIf="!transaction.vendor?.name && transaction.merchantName">Merchant: {{transaction.merchantName}}</span>
  </div>
  <div>
    <span class="mr-2" *ngIf="transaction.bankingChartOfAccount?.name">Banking Chart of Account: {{transaction.bankingChartOfAccount?.name}}</span>
  </div>
  <div>
    <span class="mr-2" *ngIf="transaction.chartOfAccount?.name">Chart of Account: {{transaction.chartOfAccount?.name}}</span>
  </div>
  <div class="subtle-text">
    <span class="mr-2">Description: {{transaction.description}}</span>
  </div>
  <div class="mt-2">
    <div class="form-section-header">Matched {{transaction.isRegistry ? 'Transactions' : 'Registries'}}</div>
    <ng-container *ngIf="matchedTransactions?.length">
      <div class="flex-full-auto text-center">
        <table class="full-width">
          <thead>
            <th style="width:20px"></th>
            <th>
            </th>
          </thead>
          <tbody>
          <ng-container *ngFor="let matchedTransaction of matchedTransactions">
            <tr>
              <td style="width: 20px;vertical-align: middle">
                <mat-icon class="cursor-pointer" title="Link" (click)="onLinkClicked(matchedTransaction)">link</mat-icon>
              </td>
              <td style="text-align: left">
                <div class="ml-2">
                  <div class="float-left">
                    <span class="mr-2">Date: {{matchedTransaction.date | dateFormatter}}</span>
                    <span class="mr-2">Type: {{matchedTransaction.type | enumFormatter: transactionTypes }}</span>
                    <span class="mr-2">Amount: {{matchedTransaction.amount ?? 0 | currencyFormatter}}</span>
                  </div>
                  <div class="float-right">
                    <mat-icon class="cursor-pointer" title="Edit" (click)="onTransactionInfoClicked(matchedTransaction)">edit</mat-icon>
                  </div>
                  <div class="clear-both"></div>
                </div>
                <div class="ml-2">
                  <span class="mr-2" *ngIf="matchedTransaction.vendor?.name">Vendor: {{matchedTransaction.vendor.name}}</span>
                  <span class="mr-2" *ngIf="!matchedTransaction.vendor?.name && matchedTransaction.merchantName">Merchant: {{matchedTransaction.merchantName}}</span>
                </div>
                <div class="ml-2">
                  <span class="mr-2" *ngIf="matchedTransaction.bankingChartOfAccount?.name">Banking Chart of Account: {{matchedTransaction.bankingChartOfAccount?.name}}</span>
                </div>
                <div class="ml-2">
                  <span class="mr-2" *ngIf="matchedTransaction.chartOfAccount?.name">Chart of Account: {{matchedTransaction.chartOfAccount?.name}}</span>
                </div>
                <div class="ml-2 subtle-text">
                  <span class="mr-2">Description: {{matchedTransaction.description}}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <hr/>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
    <ng-container *ngIf="!matchedTransactions?.length">
      <div class="text-center">
        No matches found.
      </div>
    </ng-container>
  </div>
</ng-container>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
