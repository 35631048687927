<app-base-page-template>
  <app-base-page-content-template>
    <app-invoices-grid
      [invoiceType]="invoiceType"
      [showHeroNumbers]="true"
      [enableRowClick]="true"
      (addButtonClick)="onAddButtonClicked()"
      (rowClick)="onRowClicked($event)">
    </app-invoices-grid>
  </app-base-page-content-template>
</app-base-page-template>
