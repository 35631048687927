import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BankingAccountModel } from 'src/app/shared/models/domain/banking-account.model';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessFormComponent } from 'src/app/shared/components/forms/business-form/business-form.component';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './sync-confirmation-dialog.component.html',
  styleUrls: ['./sync-confirmation-dialog.component.scss']
})
export class SyncConfirmationDialogComponent extends BaseComponent {
  constructor(
    private dialogRef: MatDialogRef<SyncConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { bankingAccounts: BankingAccountModel[], showNavigate: boolean },
    private router: Router) {
    super();
  }

  onNavigateToBankingClicked(): void {
    if (this.dialogData.bankingAccounts.length) {
      this.router.navigateByUrl(RouteUtilities.routes.application.businessEdit.getNavigateUrl(this.dialogData.bankingAccounts[0].businessKey, BusinessFormComponent.bankingInformationTabIndex));
    }

    this.dialogRef.close(false);
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
