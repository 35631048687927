import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { MapGeocoder } from '@angular/google-maps';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AddressModel } from 'src/app/shared/models/app/address.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PendingChangesService {
  showPendingChanges$: Subject<void> = new Subject();
  pendingChangesResult$: Subject<boolean> = new Subject();

  constructor() {
  }
}
