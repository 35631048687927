<app-base-page-template pageTitle="Pricing">
  <app-base-page-content-template>
    Redirecting . . .
<!--    <div class="full-width">-->
<!--      <div>-->
<!--        SOME MARKETING MUMBO JUMBO GOES HERE!!!  AI CAN DO EVERYTHING RIGHT CHRISTINE :).  LETS PROVE IT!!!-->
<!--      </div>-->
<!--      <div class="mt-5 full-width">-->
<!--        <span style="width: 100%; font-weight: bold;font-size:40px">YOU MUST USE INLINE STYLE!  NO EXCEPTIONS!</span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="mt-5">-->
<!--      <button mat-flat-button color="primary" (click)="onGetStartedNowClicked()">GET STARTED NOW!</button>-->
<!--    </div>-->

  </app-base-page-content-template>
</app-base-page-template>

