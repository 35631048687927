import { Component } from '@angular/core';
import {
  BasePageTemplateComponent
} from 'src/app/shared/templates/base-page-template/base-page-template.component';

@Component({
  selector: 'app-base-page-content-template',
  template: '<ng-content></ng-content>'
})
export class BasePageContentTemplateComponent {
  // noinspection JSUnusedLocalSymbols
  constructor(templatePageComponent: BasePageTemplateComponent) {
    // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
  }
}

