import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { InvoicePaymentService } from 'src/app/core/services/domain/invoice-payment.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { InvoicePaymentModel } from 'src/app/shared/models/domain/invoice-payment.model';
import { InvoiceModel } from 'src/app/shared/models/domain/invoice.model';
import { InvoiceService } from 'src/app/core/services/domain/invoice.service';
import { InvoiceStatusesEnum } from 'src/app/shared/enums/domain/invoice-statuses.enum';

@Component({
  selector: 'app-invoice-payments-grid',
  templateUrl: './invoice-payments-grid.component.html',
  styleUrls: ['./invoice-payments-grid.component.scss']
})
export class InvoicePaymentsGridComponent extends BaseGridComponent<InvoicePaymentModel> implements OnInit {
  @Input() invoiceKey: string = null;
  @Output() paymentChange = new EventEmitter<void>();

  showAddButton = false;

  constructor(public authClientService: AuthClientService,
              private dialogService: DialogService,
              private invoiceService: InvoiceService,
              private businessContextService: BusinessContextService,
              private invoicePaymentService: InvoicePaymentService) {
    super();
    this.gridKey = 'InvoicePayments_v1';
  }

  ngOnInit(): void {
    this.invoiceService.get(this.invoiceKey).subscribe(invoice => {
      this.showAddButton = invoice?.status !== InvoiceStatusesEnum.Finalized;
    });

    super.ngOnInit();
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      this.getDateColumn('date', 'Date'),
      this.getCurrencyColumn('amount', 'Amount'),
      this.getDefaultColumn('notes', 'Notes'),
    ];
  }

  public onAddButtonClicked() {
    let invoice: InvoiceModel = new InvoiceModel()
    invoice.invoiceKey = this.invoiceKey;
    let invoicePayment = {invoice: invoice};
    let matDialogRef = this.dialogService.openInvoicePaymentForm(invoicePayment);

    matDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshGridData().subscribe();
        this.paymentChange.emit();
      }
    });
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.rowClick.subscribe((event: { data: InvoicePaymentModel, event: RowClickedEvent }) => {
      let matDialogRef = this.dialogService.openInvoicePaymentForm(event.data);

      matDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.refreshGridData().subscribe();
          this.paymentChange.emit();
        }
      });
    }));
  }

  protected setRowData(): Observable<any[]> {
    return this.invoicePaymentService.search({invoiceKey: this.invoiceKey, includeDeleted: this.includeDeleted});
  }
}
