export enum ReportTypesEnum {
  Unknown = 0,
  ChartOfAccountSummary = 1,
  ProfitLoss = 2,
  BalanceSheet = 3,
  JobCosting = 4,
  ChartOfAccountDetail = 5,
  ARAPInvoice = 6,
  CustomerInvoices = 7,
  ARAPSummary = 8,
  ARAPRegistry = 9
}
