<form [formGroup]="formGroupRef" *ngIf="formGroupRef">
  <div class="form-section mt-2">
    <div class="mt-2">
      <mat-form-field class="mr-2">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name">
        <mat-icon matSuffix
                  matTooltip="A descriptive name of the document">
          help_outline
        </mat-icon>
      </mat-form-field>
    </div>
    <div class="mt-2">
      <mat-form-field class="mat-form-field-full">
        <mat-label>Description</mat-label>
        <textarea
          #autosize="cdkTextareaAutosize"
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="5"
          formControlName="description">
              </textarea>
        <mat-icon matSuffix
                  matTooltip="A brief description of the document">
          help_outline
        </mat-icon>
      </mat-form-field>
    </div>
    <div class="mt-2">
          <span class="mr-2">
            <app-upload [label]="fileList?.length || value.file?.fileKey ? 'Replace File' : 'Upload File'"
                        (fileListChange)="onFilesUploaded($event)"></app-upload>
          </span>
    </div>
  </div>
</form>

