import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { ReportTypesEnum } from 'src/app/shared/enums/domain/report-types.enum';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent extends BasePageComponent implements OnInit {
  dataCards: {name: string, icon: string, path: string}[] = [];

  constructor(
    private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.dataCards.push({name: 'Chart of Accounts Summary', icon: 'fa-bars', path: RouteUtilities.routes.application.reportEdit.getNavigateUrl(ReportTypesEnum.ChartOfAccountSummary)})
    this.dataCards.push({name: 'Chart of Account Detail', icon: 'fa-tasks', path: RouteUtilities.routes.application.reportEdit.getNavigateUrl(ReportTypesEnum.ChartOfAccountDetail)})
    this.dataCards.push({name: 'Profit & Loss', icon: 'fa-file-invoice-dollar', path: RouteUtilities.routes.application.reportEdit.getNavigateUrl(ReportTypesEnum.ProfitLoss)})
    this.dataCards.push({name: 'Balance Sheet', icon: 'fa-balance-scale', path: RouteUtilities.routes.application.reportEdit.getNavigateUrl(ReportTypesEnum.BalanceSheet)})
    this.dataCards.push({name: 'Job Costing', icon: 'fa-user-tag', path: RouteUtilities.routes.application.reportEdit.getNavigateUrl(ReportTypesEnum.JobCosting)})
    this.dataCards.push({name: 'Customer Invoices', icon: 'fa fa-user-tag', path: RouteUtilities.routes.application.reportEdit.getNavigateUrl(ReportTypesEnum.CustomerInvoices)})
    this.dataCards.push({name: 'AR & AP Summary', icon: 'fa-wallet', path: RouteUtilities.routes.application.reportEdit.getNavigateUrl(ReportTypesEnum.ARAPSummary)})
    this.dataCards.push({name: 'AR & AP Invoice', icon: 'fa-money-check-alt', path: RouteUtilities.routes.application.reportEdit.getNavigateUrl(ReportTypesEnum.ARAPInvoice)})
    this.dataCards.push({name: 'AR & AP Registry', icon: 'fa-money-bill', path: RouteUtilities.routes.application.reportEdit.getNavigateUrl(ReportTypesEnum.ARAPRegistry)})
    this.dataCards.push({name: 'Offerings', icon: 'fa-shopping-cart', path: RouteUtilities.routes.application.offerings.getNavigateUrl()})
  }

  onDataCardClicked(path:string): void {
    this.router.navigate([path]);
  }
}
