<div class="flex flex-direction-column flex-start" *ngIf="business">
  <div>
    <div class="flex flex-shrink header-container">
      <div class="flex flex-shrink" style="margin-right:10px" *ngIf="business?.file?.uri">
        <img height="100px" [src]="business.file.uri" alt="logo"/>
      </div>
      <div class="flex flex-grow flex-direction-column">
        <div class="form-section-header mb-1 mt-1">{{business.name}}</div>
        <div>{{business.address.fullAddress}}</div>
        <div>{{business.phoneNumber}}</div>
        <div *ngIf="business.website"><a [href]="business.website" target="_blank">{{business.website}}</a></div>
        <div *ngIf="business.email"><a href="mailto:{{business.email}}">{{business.email}}</a></div>
      </div>
    </div>
  </div>
  <div *ngIf="business.service?.discountRate" class="mt-1 bold">
    <div>
      <div>
        <span class="bold mr-2">Discount</span>
        <span class="mr-1">{{business.service?.discountRate}}%</span>
      </div>
      <div>
        <span class="subtle-text">Discount will only be applied to service requests through the application.</span>
      </div>
    </div>
  </div>
  <div *ngIf="business.description">
    <div class="flex flex-shrink mt-5 form-section-header">Description</div>
    <div class="flex flex-grow mt-1 text-area-display">{{business.description}}</div>
  </div>
  <div *ngIf="business.service?.notes">
    <div class="flex flex-shrink mt-5 form-section-header">Service Provider Notes</div>
    <div class="flex flex-grow mt-1 text-area-display">{{business.service?.notes}}</div>
  </div>
</div>
