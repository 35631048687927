import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { NavigationResolver } from 'src/app/core/resolvers/navigation.resolver';
import { ApplicationResolver } from 'src/app/core/resolvers/application.resolver';
import { AuthAdminGuard } from 'src/app/core/guards/auth-admin.guard';
import { BusinessGuard } from 'src/app/core/guards/business.guard';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes.guard';
import { SubscriptionGuard } from 'src/app/core/guards/subscription-guard.service';

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:false})],
  exports: [RouterModule],
  providers: [
    ApplicationResolver,
    AuthGuard,
    AuthAdminGuard,
    BusinessGuard,
    NavigationResolver,
    PendingChangesGuard,
    SubscriptionGuard
  ]
})
export class AppRoutingModule {
}
