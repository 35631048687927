import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

//https://pdfviewer.net/extended-pdf-viewer/getting-started

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent extends BaseComponent implements OnInit {
  @Input() pdfSrc: string | Blob;
  @Input() height: string = '95%';

  page = 1;
  pageLabel = 'Test';
  pdfSource = null;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (typeof (this.pdfSrc) === 'string') {
      this.pdfSource = this.pdfSrc;
    } else {
      let arrayBuffer;
      let fileReader = new FileReader();
      fileReader.onload = function (event) {
        arrayBuffer = event.target.result;
      };
      fileReader.readAsArrayBuffer(this.pdfSrc);

      this.pdfSource = arrayBuffer;
    }
  }
}
