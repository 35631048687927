import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { ReportTypesEnum } from 'src/app/shared/enums/domain/report-types.enum';

export class RouteUtilities {
  static routes = {
    application: {
      // @ts-ignore
      base: {
        modulePath: 'application',
        getNavigateUrl: () => {
          return '/application';
        }
      },
      redirect: {
        modulePath: 'redirect',
        getNavigateUrl: () => {
          return '/application/redirect';
        }
      },
      dashboard: {
        modulePath: 'dashboard',
        getNavigateUrl: () => {
          return '/application/dashboard';
        }
      },
      businesses: {
        modulePath: 'businesses',
        getNavigateUrl: () => {
          return '/application/businesses';
        }
      },
      businessAdd: {
        modulePath: 'business',
        getNavigateUrl: () => {
          return '/application/businesses/business';
        }
      },
      businessEdit: {
        modulePath: `business/:${RoutingKeysUtilities.routeParams.businessKey}`,
        getNavigateUrl: (businessKey: string, tabIndex: number = null) => {
          let url = `/application/businesses/business/${businessKey}`;

          if (tabIndex) {
            url += '?' + RoutingKeysUtilities.queryParams.selectedTabIndex + '=' + tabIndex;
          }

          return url;
        }
      },
      businessAccess: {
        modulePath: `access`,
        getNavigateUrl: (businessKey: string) => {
          return `/application/businesses/business/${businessKey}/access`;
        }
      },
      employees: {
        modulePath: 'employees',
        getNavigateUrl: () => {
          return '/application/employees';
        }
      },
      employeeAdd: {
        modulePath: 'employee',
        getNavigateUrl: () => {
          return '/application/employees/employee';
        }
      },
      employeeEdit: {
        modulePath: `employee/:${RoutingKeysUtilities.routeParams.employeeKey}`,
        getNavigateUrl: (employeeKey: string) => {
          return `/application/employees/employee/${employeeKey}`;
        }
      },
      customers: {
        modulePath: 'accounting/customers',
        getNavigateUrl: () => {
          return '/application/accounting/customers';
        }
      },
      customerAdd: {
        modulePath: 'customer',
        getNavigateUrl: () => {
          return '/application/accounting/customers/customer';
        }
      },
      customerEdit: {
        modulePath: `customer/:${RoutingKeysUtilities.routeParams.customerKey}`,
        getNavigateUrl: (customerKey: string) => {
          return `/application/accounting/customers/customer/${customerKey}`;
        }
      },
      jobs: {
        modulePath: 'accounting/jobs',
        getNavigateUrl: () => {
          return '/application/accounting/jobs';
        }
      },
      jobAdd: {
        modulePath: 'job',
        getNavigateUrl: (customerKey: string = null) => {
          let url = '/application/accounting/jobs/job';

          if (customerKey != null) {
            url += `?${RoutingKeysUtilities.queryParams.customerKey}=${customerKey}`;
          }

          return url;
        }
      },
      jobEdit: {
        modulePath: `job/:${RoutingKeysUtilities.routeParams.jobKey}`,
        getNavigateUrl: (jobKey: string) => {
          return `/application/accounting/jobs/job/${jobKey}`;
        }
      },
      offerings: {
        modulePath: 'accounting/offerings',
        getNavigateUrl: () => {
          return '/application/accounting/offerings';
        }
      },
      offeringAdd: {
        modulePath: 'offering',
        getNavigateUrl: () => {
          return '/application/accounting/offerings/offering';
        }
      },
      offeringEdit: {
        modulePath: `offering/:${RoutingKeysUtilities.routeParams.offeringKey}`,
        getNavigateUrl: (key: string) => {
          return `/application/accounting/offerings/offering/${key}`;
        }
      },
      reports: {
        modulePath: 'accounting/reports',
        getNavigateUrl: () => {
          return '/application/accounting/reports';
        }
      },
      reportEdit: {
        modulePath: `report/:${RoutingKeysUtilities.routeParams.reportTypeId}`,
        getNavigateUrl: (key: ReportTypesEnum, reportKey: string = null) => {
          let url = `/application/accounting/reports/report/${key}`;

          if (reportKey) {
            url += `?${RoutingKeysUtilities.queryParams.reportKey}=${reportKey}`;
          }

          return url;
        }
      },
      // data: {
      //   modulePath: 'data',
      //   getNavigateUrl: () => {
      //     return '/application/data';
      //   }
      // },
      chartOfAccounts: {
        modulePath: 'accounting/chart-of-accounts',
        getNavigateUrl: () => {
          return '/application/accounting/chart-of-accounts';
        }
      },
      chartOfAccountAdd: {
        modulePath: 'chart-of-account',
        getNavigateUrl: () => {
          return '/application/accounting/chart-of-accounts/chart-of-account';
        }
      },
      chartOfAccountEdit: {
        modulePath: `chart-of-account/:${RoutingKeysUtilities.routeParams.chartOfAccountKey}`,
        getNavigateUrl: (key: string, tabIndex: number = null) => {
          let url = `/application/accounting/chart-of-accounts/chart-of-account/${key}`;

          if (tabIndex) {
            url += '?' + RoutingKeysUtilities.queryParams.selectedTabIndex + '=' + tabIndex;
          }

          return url;
        }
      },
      reconciliations: {
        modulePath: 'accounting/reconciliations',
        getNavigateUrl: () => {
          return '/application/accounting/reconciliations';
        }
      },
      reconciliationAdd: {
        modulePath: 'reconciliation',
        getNavigateUrl: () => {
          return '/application/accounting/reconciliations/reconciliation';
        }
      },
      reconciliationEdit: {
        modulePath: `reconciliation/:${RoutingKeysUtilities.routeParams.reconciliationKey}`,
        getNavigateUrl: (key: string) => {
          return `/application/accounting/reconciliations/reconciliation/${key}`;
        }
      },
      vendors: {
        modulePath: 'accounting/vendors',
        getNavigateUrl: () => {
          return '/application/accounting/vendors';
        }
      },
      vendorAdd: {
        modulePath: 'vendor',
        getNavigateUrl: () => {
          return '/application/accounting/vendors/vendor';
        }
      },
      vendorEdit: {
        modulePath: `vendor/:${RoutingKeysUtilities.routeParams.vendorKey}`,
        getNavigateUrl: (vendorKey: string) => {
          return `/application/accounting/vendors/vendor/${vendorKey}`;
        }
      },
      invoices: {
        modulePath: 'accounting/invoices',
        getNavigateUrl: (invoiceType: InvoiceTypesEnum = null) => {
          let url = '/application/accounting/invoices';

          if (invoiceType != null) {
            url += `?${RoutingKeysUtilities.queryParams.invoiceType}=` + invoiceType;
          }

          return url;
        }
      },
      invoiceAdd: {
        modulePath: 'invoice',
        getNavigateUrl: (invoiceType: InvoiceTypesEnum, customerKey: string = null, vendorKey: string = null) => {
          let url = '/application/accounting/invoices/invoice';

          if (invoiceType != null) {
            url += `?${RoutingKeysUtilities.queryParams.invoiceType}=${invoiceType}`;
          }

          if(customerKey !== null) {
            url.includes('?') ? url += '&' : url += '?';
            url += `${RoutingKeysUtilities.queryParams.customerKey}=${customerKey}`
          }

          if(vendorKey !== null) {
            url.includes('?') ? url += '&' : url += '?';
            url += `${RoutingKeysUtilities.queryParams.vendorKey}=${vendorKey}`
          }

          return url;
        }
      },
      invoiceEdit: {
        modulePath: `invoice/:${RoutingKeysUtilities.routeParams.invoiceKey}`,
        getNavigateUrl: (invoiceKey: string) => {
          return `/application/accounting/invoices/invoice/${invoiceKey}`;
        }
      },
      transactions: {
        modulePath: 'accounting/transactions',
        getNavigateUrl: () => {
          return '/application/accounting/transactions';
        }
      },
      transactionAdd: {
        modulePath: 'transaction',
        getNavigateUrl: () => {
          return '/application/accounting/transactions/transaction';
        }
      },
      transactionEdit: {
        modulePath: `transaction/:${RoutingKeysUtilities.routeParams.transactionKey}`,
        getNavigateUrl: (transactionKey: string) => {
          return `/application/accounting/transactions/transaction/${transactionKey}`;
        }
      },
      transactionRules: {
        modulePath: 'transaction-rules',
        getNavigateUrl: () => {
          return '/application/accounting/transactions/transaction-rules';
        }
      },
      registries: {
        modulePath: 'accounting/registries',
        getNavigateUrl: (bankingChartOfAccountKey: string = null) => {
          let url = '/application/accounting/registries';

          if (bankingChartOfAccountKey) {
            url += '?' + RoutingKeysUtilities.queryParams.bankingChartOfAccountKey + '=' + bankingChartOfAccountKey;
          }

          return url;
        }
      },
      registryAdd: {
        modulePath: 'registry',
        getNavigateUrl: (chartOfAccountKey: string = null, bankingChartOfAccountKey: string = null) => {
          let url = '/application/accounting/registries/registry';

          if (chartOfAccountKey !== null) {
            url += '?' + RoutingKeysUtilities.queryParams.chartOfAccountKey + '=' + chartOfAccountKey;
          } else if (bankingChartOfAccountKey !== null) {
            url += '?' + RoutingKeysUtilities.queryParams.bankingChartOfAccountKey + '=' + bankingChartOfAccountKey;
          }

          return url;
        }
      },
      registryEdit: {
        modulePath: `registry/:${RoutingKeysUtilities.routeParams.transactionKey}`,
        getNavigateUrl: (transactionKey: string) => {
          return `/application/accounting/registries/registry/${transactionKey}`;
        }
      },
      documents: {
        modulePath: 'documents',
        getNavigateUrl: (documentType: DocumentTypesEnum = null) => {
          let url = '/application/documents';

          if (documentType != null) {
            url += `?${RoutingKeysUtilities.queryParams.documentType}=` + documentType;
          }

          return url;
        }
      },
      documentAdd: {
        modulePath: 'document',
        getNavigateUrl: (documentType: DocumentTypesEnum = null) => {
          let url = '/application/documents/document';

          if (documentType != null) {
            url += `?${RoutingKeysUtilities.queryParams.documentType}=` + documentType;
          }

          return url;
        }
      },
      documentEdit: {
        modulePath: `document/:${RoutingKeysUtilities.routeParams.documentKey}`,
        getNavigateUrl: (documentKey: string) => {
          return `/application/documents/document/${documentKey}`;
        }
      },
      files: {
        modulePath: 'documents/files',
        getNavigateUrl: () => {
          let url = '/application/documents/files';

          return url;
        }
      },
      services: {
        modulePath: 'services',
        getNavigateUrl: () => {
          return '/application/services';
        }
      },
      businessServiceRequests: {
        modulePath: 'business-service-requests',
        getNavigateUrl: () => {
          return '/application/business-service-requests';
        }
      },
      myServiceRequests: {
        modulePath: 'my-service-requests',
        getNavigateUrl: () => {
          return '/application/my-service-requests';
        }
      },
      service: {
        modulePath: `service/:${RoutingKeysUtilities.routeParams.businessKey}`,
        getNavigateUrl: (businessKey: string) => {
          return `/application/services/service/${businessKey}`;
        }
      },
      serviceEdit: {
        modulePath: `:${RoutingKeysUtilities.routeParams.businessServiceRequestKey}`,
        getNavigateUrl: (businessKey: string, businessServiceRequestKey: string = null) => {
          return `/application/services/service/${businessKey}/${businessServiceRequestKey}`;
        }
      },
      profile: {
        modulePath: 'profile',
        getNavigateUrl: () => {
          return '/application/profile';
        }
      },
      profileSetup: {
        modulePath: 'profile-setup',
        getNavigateUrl: () => {
          return '/application/profile-setup';
        }
      },
      admin: {
        base: {
          modulePath: 'admin',
          getNavigateUrl: () => {
            return '/application/admin';
          }
        },
        dashboard: {
          modulePath: 'dashboard',
          getNavigateUrl: () => {
            return '/application/admin/dashboard';
          }
        },
        clients: {
          modulePath: 'clients',
          getNavigateUrl: () => {
            return '/application/admin/clients';
          }
        },
        clientAdd: {
          modulePath: 'client',
          getNavigateUrl: () => {
            return '/application/admin/clients/client';
          }
        },
        clientEdit: {
          modulePath: `client/:${RoutingKeysUtilities.routeParams.clientKey}`,
          getNavigateUrl: (clientKey: string) => {
            return `/application/admin/clients/client/${clientKey}`;
          }
        },
        businesses: {
          modulePath: 'businesses',
          getNavigateUrl: () => {
            return '/application/admin/businesses';
          }
        },
        businessAdd: {
          modulePath: 'business',
          getNavigateUrl: () => {
            return '/application/admin/businesses/business';
          }
        },
        businessEdit: {
          modulePath: `business/:${RoutingKeysUtilities.routeParams.businessKey}`,
          getNavigateUrl: (businessKey: string, tabIndex: number = null) => {
            let url = `/application/admin/businesses/business/${businessKey}`;

            if (tabIndex) {
              url += '?' + RoutingKeysUtilities.queryParams.selectedTabIndex + '=' + tabIndex;
            }

            return url;
          }
        },
        businessAccess: {
          modulePath: `access`,
          getNavigateUrl: (businessKey: string) => {
            return `/application/admin/businesses/business/${businessKey}/access`;
          }
        },
        businessServiceRequests: {
          modulePath: 'service-requests',
          getNavigateUrl: () => {
            return '/application/admin/service-requests';
          }
        },
        businessServiceRequestsEdit: {
          modulePath: `:${RoutingKeysUtilities.routeParams.businessKey}/:${RoutingKeysUtilities.routeParams.businessServiceRequestKey}`,
          getNavigateUrl: (businessKey: string, businessServiceRequestKey: string) => {
            return `/application/admin/service-requests/${businessKey}/${businessServiceRequestKey}`;
          }
        },
        documents: {
          modulePath: 'documents',
          getNavigateUrl: () => {
            return '/application/admin/documents';
          }
        },
        documentAdd: {
          modulePath: 'document',
          getNavigateUrl: (documentType: DocumentTypesEnum = DocumentTypesEnum.Unknown) => {
            let url = '/application/admin/documents/document';

            if (documentType) {
              url += '?' + RoutingKeysUtilities.queryParams.documentType + '=' + documentType;
            }

            return url;
          }
        },
        documentEdit: {
          modulePath: `document/:${RoutingKeysUtilities.routeParams.documentKey}`,
          getNavigateUrl: (documentKey: string) => {
            return `/application/admin/documents/document/${documentKey}`;
          }
        },
        files: {
          modulePath: 'documents/files',
          getNavigateUrl: () => {
            let url = '/application/admin/documents/files';

            return url;
          }
        },
        sandbox: {
          modulePath: 'sandbox',
          getNavigateUrl: () => {
            return '/application/admin/sandbox';
          }
        }
      },
      privacyPolicy: {
        modulePath: 'privacy-policy',
        getNavigateUrl: () => {
          return '/application/privacy-policy';
        }
      },
      help: {
        modulePath: 'help',
        getNavigateUrl: () => {
          return '/application/help';
        }
      },
      ticket: {
        modulePath: 'ticket',
        getNavigateUrl: () => {
          return '/application/ticket';
        }
      },
      subscriptions: {
        modulePath: 'subscriptions',
        getNavigateUrl: () => {
          return '/application/subscriptions';
        }
      },
      pricing: {
        modulePath: 'pricing',
        getNavigateUrl: () => {
          return '/application/pricing';
        }
      },
      terms: {
        modulePath: 'terms-conditions',
        getNavigateUrl: () => {
          return '/application/terms-conditions';
        }
      }
    },
    static: {
      base: {
        modulePath: '',
        getNavigateUrl: () => {
          return '#home';
        }
      },
      login: {
        modulePath: 'login',
        getNavigateUrl: () => {
          return '/login';
        }
      },
      pricing: {
        modulePath: `pricing`,
        getNavigateUrl: () => {
          return `/pricing`;
        }
      },
      services: {
        modulePath: `services`,
        getNavigateUrl: () => {
          return `/services`;
        }
      },
      service: {
        modulePath: `service/:${RoutingKeysUtilities.routeParams.businessKey}`,
        getNavigateUrl: (businessKey: string) => {
          return `/services/service/${businessKey}`;
        }
      },
      serviceEdit: {
        modulePath: `:${RoutingKeysUtilities.routeParams.businessServiceRequestKey}`,
        getNavigateUrl: (businessKey: string, businessServiceRequestKey: string = null) => {
          return `/services/service/${businessKey}/${businessServiceRequestKey}`;
        }
      },
      privacyPolicy: {
        modulePath: 'privacy-policy',
        getNavigateUrl: () => {
          return '/privacy-policy';
        }
      },
      terms: {
        modulePath: 'terms-conditions',
        getNavigateUrl: () => {
          return '/terms-conditions';
        }
      }
    }
  }
}
