import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { ViewModesEnum } from 'src/app/shared/enums/app/view-modes.enum';

@Component({
  selector: 'app-chart-of-account-form-dialog',
  templateUrl: './chart-of-account-form-dialog.component.html'
})
export class ChartOfAccountFormDialogComponent extends BaseEntryPageComponent<ChartOfAccountModel> {
  viewMode: ViewModesEnum;

  constructor(
    public dialogRef: MatDialogRef<ChartOfAccountFormDialogComponent>,
    private businessContextService: BusinessContextService,
    private chartOfAccountService: ChartOfAccountService,
    @Inject(MAT_DIALOG_DATA) private dialogData: {chartOfAccountKey: string, name: string, viewMode: ViewModesEnum},
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.viewMode = this.dialogData.viewMode ?? ViewModesEnum.All;

    if (this.dialogData.chartOfAccountKey) {
      this.chartOfAccountService.get(this.dialogData.chartOfAccountKey).subscribe(chartOfAccount => {
        this.value = chartOfAccount;
      });
    } else {
      this.value = <ChartOfAccountModel>{
        name: this.dialogData?.name ?? null,
        businessKey: this.businessContextService.currentBusiness.businessKey
      };
    }
  }

  onSaveClicked(): void {
    this.isSaving = true;

    if (this.value.chartOfAccountKey) {
      this.chartOfAccountService.update(this.value).subscribe(key => {
        this.onSaveSuccess('Chart of account was updated successfully.');
        this.dialogRef.close(key);
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.chartOfAccountService.create(this.value).subscribe(key => {
        this.onSaveSuccess('Chart of account was created successfully.');
        this.dialogRef.close(key);
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
