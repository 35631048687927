import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { OfferingService } from 'src/app/core/services/domain/offering.service';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { OfferingModel } from 'src/app/shared/models/domain/offering.model';
import { RoutingService } from 'src/app/core/services/app/routing.service';

@Component({
  selector: 'app-offering-form-dialog',
  templateUrl: './offering-form-dialog.component.html'
})
export class OfferingFormDialogComponent extends BaseEntryPageComponent<OfferingModel> {
  constructor(
    public dialogRef: MatDialogRef<OfferingFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {name: string },
    private businessContextService: BusinessContextService,
    private offeringService: OfferingService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.value = <OfferingModel>{
      name: this.dialogData?.name ?? null,
      businessKey: this.businessContextService.currentBusiness.businessKey
    };
  }

  onSaveClicked(): void {
    this.isSaving = true;
    this.offeringService.create(this.value).subscribe(key => {
      this.onSaveSuccess('Offering was created successfully.');
      this.dialogRef.close(key);
    }, err => {
      this.onSaveFailed(err);
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
