import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { VendorService } from 'src/app/core/services/domain/vendor.service';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';

@Component({
  selector: 'app-vendor-page',
  templateUrl: './vendor-page.component.html',
  styleUrls: ['./vendor-page.component.scss']
})
export class VendorPageComponent extends BaseEntryPageComponent<VendorModel> implements OnInit {
  fileList: FileList;

  constructor(
    public authClientService: AuthClientService,
    private activatedRoute: ActivatedRoute,
    private businessContextService: BusinessContextService,
    private vendorService: VendorService,
    notificationService: NotificationService,
    routingService: RoutingService,
    pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    const vendorKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.vendorKey, this.activatedRoute.snapshot);

    if (vendorKey) {
      this.vendorService.get(vendorKey).subscribe(document => {
        this.value = document;
      })
    } else {
      this.value = <VendorModel>{
        businessKey: this.businessContextService.currentBusiness.businessKey
      };
    }
  }

  onSaveClicked(): void {
    this.isSaving = true;
    if (!this.value.vendorKey) {
      this.vendorService.create(this.value).subscribe(_ => {
        this.onSaveSuccess('Vendor was created successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.vendorService.update(this.value).subscribe(_ => {
        this.onSaveSuccess('Vendor was updated successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }
}
