<div>
  <mat-form-field class="mr-2">
    <mat-label>Template</mat-label>
    <mat-select [(value)]="selectedTemplateDocumentKey"
                (selectionChange)="onTemplateChange($event)">
      <mat-option [value]="null">(No Template)</mat-option>
      <mat-option *ngFor="let template of documentTemplates"
                  [value]="template.documentKey">{{template.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="mt-2">
  <span class="mr-2">
    <app-save-button (click)="onDownloadClicked()" [isSaving]="showBlockingLoader" text="Download" processingText="Downloading"></app-save-button>
  </span>
  <span class="mr-2">
    <button mat-flat-button color="primary" (click)="onCancelClicked()">Cancel</button>
  </span>
</div>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
