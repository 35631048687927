import { StringUtilities } from 'src/app/core/utilities/string.utilities';
import { EnumModel } from 'src/app/shared/models/app/enum.model';

export class EnumUtilities {
  static getDisplayName(enumObject: any, enumItem: number): string {
    if (enumObject[enumItem] && isNaN(enumObject[enumItem])) {
      return StringUtilities.convertFromCamelCase(enumObject[enumItem]);
    }

    return null;
  }

  static convertToSelectModels(enumObject: any, includeZeroValue: boolean = false): EnumModel[] {
    const selectModels: EnumModel[] = [];

    for (const enumItem in enumObject) {
      if (enumObject[enumItem] && isNaN(enumObject[enumItem]) && (includeZeroValue || (!includeZeroValue && parseInt(enumItem, 10) !== 0))) {
        const stringValue = StringUtilities.convertFromCamelCase(enumObject[enumItem]);
        selectModels.push({ value: parseInt(enumItem, 10), label: stringValue, });
      }
    }

    return selectModels.sort((a, b) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }

      return 0;
    });
  }
}
