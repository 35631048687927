import { Component } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { ClientService } from 'src/app/core/services/domain/client.service';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { ClientModel } from 'src/app/shared/models/domain/client.model';

@Component({
  selector: 'app-clients-grid',
  templateUrl: './clients-grid.component.html',
  styleUrls: ['./clients-grid.component.scss']
})
export class ClientsGridComponent extends BaseGridComponent<ClientModel> {
  constructor(public authClientService: AuthClientService,
              private clientService: ClientService) {
    super();
    this.gridKey = 'Clients_v1';
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: 'Last Name',
        field: 'lastName'
      },
      {
        headerName: 'First Name',
        field: 'firstName'
      },
      {
        headerName: 'Email',
        field: 'email',
        valueFormatter: (params: ValueFormatterParams) => {
          const rowData = <ClientModel>params.data;

          return `${rowData.email}${(!rowData.isEmailVerified ? ' (UNVERIFIED)' : '')}`;
        }
      },
      this.getYesNoColumn('isLockedOut', 'Locked out'),
      {
        headerName: 'Phone #',
        field: 'phoneNumber'
      },
      {
        headerName: 'Authorization',
        field: 'policies',
        valueFormatter: (params: ValueFormatterParams) => {
          const rowData = <ClientModel>params.data;
          return (rowData.policies || []).join(', ');
        }
      },
      {
        headerName: 'Auth User Id',
        field: 'authUserId',
        hide: !this.authClientService.hasAuthorization(this.authorization.superAdmin)
      },
    ];
  }

  protected onGridReady(): void {
  }

  protected setRowData(): Observable<any[]> {
    return this.clientService.search();
  }
}
