export class LocalStorageUtilities {
  static delete(name: string): void {
    localStorage.removeItem(name);
  }

  static get(name: string): any {
    const item = localStorage.getItem(name);
    if (item === 'undefined') {
      return undefined;
    }
    return item;
  }

  static set(name: string, item: string): void {
    localStorage.setItem(name, item);
  }
}
