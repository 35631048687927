import { Component, Inject, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { UntypedFormGroup } from '@angular/forms';
import { EmailInvoiceModel } from 'src/app/shared/models/domain/email-invoice.model';
import { FileModel } from 'src/app/shared/models/domain/file.model';

@Component({
  selector: 'app-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss']
})
export class PdfViewerDialogComponent extends BaseComponent implements OnInit {
  file: FileModel;
  businessKey: string = null;
  value: EmailInvoiceModel;
  formGroupRef: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private dialogRef: MatDialogRef<PdfViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {file: FileModel },
    protected notificationService: NotificationService) {
    super();
  }

  ngOnInit(): void {
    this.file = this.dialogData.file;
  }

  onCancelClicked(): void {
    if (this.isSaving) {
      return;
    }

    this.dialogRef.close(false);
  }
}
