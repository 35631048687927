import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { ReconciliationModel } from 'src/app/shared/models/domain/reconciliation.model';

@Component({
  selector: 'app-reconciliations',
  templateUrl: './reconciliations-page.component.html',
  styleUrls: ['./reconciliations-page.component.scss']
})
export class ReconciliationsPageComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  onRowClicked(event: { data: ReconciliationModel }): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.reconciliationEdit.getNavigateUrl(event.data.reconciliationKey));
  }

  onAddButtonClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.reconciliationAdd.getNavigateUrl());
  }
}
