import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { DocumentSearchModel } from '../../../models/domain/document-search.model';
import { DocumentTypesEnum } from '../../../enums/domain/document-types.enum';
import { DocumentService } from '../../../../core/services/domain/document.service';
import { DocumentModel } from '../../../models/domain/document.model';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-template-selector-dialog',
  templateUrl: './template-selector-dialog.component.html',
  styleUrls: ['./template-selector-dialog.component.scss']
})
export class TemplateSelectorDialogComponent extends BaseComponent {
  documentTemplates: DocumentModel[] = null;

  constructor(
    public dialogRef: MatDialogRef<TemplateSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { templateType: DocumentTypesEnum },
    private businessContextService: BusinessContextService,
    private documentService: DocumentService) {
    super();
  }

  ngOnInit(): void {
    const searchModel: DocumentSearchModel = {
      documentTypes: [this.dialogData.templateType],
      businessKeys: [this.businessContextService.currentBusiness.businessKey]
    }

    this.documentService.search(searchModel).subscribe(documentTemplates => {
      this.documentTemplates = documentTemplates.filter(t => t.file?.uri);
    });
  }

  onTemplateSelected(documentTemplateKey: string): void {
    this.dialogRef.close(documentTemplateKey);
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
