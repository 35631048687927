import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { SupportService } from 'src/app/core/services/domain/support.service';

@Component({
  selector: 'app-ticket-page',
  templateUrl: './ticket-page.component.html',
  styleUrls: ['./ticket-page.component.scss']
})
export class TicketPageComponent extends BasePageComponent implements OnInit {
  constructor(private supportService: SupportService) {
    super();
  }

  ngOnInit(): void {
  }

  onScheduleSupportClicked(): void {
    this.supportService.initScheduleSupport();
  }
}

