<!--https://pdfviewer.net/extended-pdf-viewer/getting-started-->
<!--<ngx-extended-pdf-viewer-->
<!--  *ngIf="pdfSrc"-->
<!--  [src]="pdfSrc"-->
<!--  [height]="height"-->
<!--  [useBrowserLocale]="true"-->
<!--  [textLayer]="false"-->
<!--  delayFirstView="5000"-->
<!--  [showHandToolButton]="false"-->
<!--  [showPresentationModeButton]="false"-->
<!--  [(page)]="page"-->
<!--  [(pageLabel)]="pageLabel">-->
<!--</ngx-extended-pdf-viewer>-->

<pdf-viewer [src]="pdfSource"
            [fit-to-page]="false"
            [render-text]="true"
            [original-size]="false"
            style="width: 100%; height: 95%">
</pdf-viewer>

