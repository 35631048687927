<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content class="sidenav-content">
    <mat-toolbar class="top-nav" color="primary">
      <div class="header-logo-container cursor-pointer" style="display:flex; align-items: center; padding:10px" [routerLink]="routeUtilities.routes.static.services.getNavigateUrl()">
        <img class="logo" alt="Divine Management Logo" height="48" src="../assets/images/logo-transparent.png"/>
        <div class="ml-2 inline-block">
          SimpliDivine
        </div>
      </div>
    </mat-toolbar>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div class="secondary-footer">
      <div class="footer-items">
<!--        <div class="footer-logo-container">-->
<!--          <img class="footer-logo" src="../assets/images/logo-transparent.png">-->
<!--        </div>-->
<!--        <div class="footer-menu-item not-hoverable">-->
<!--          Copyright {{year}} DivineManagement, Inc All Rights Reserved-->
<!--        </div>-->
<!--        <div class="footer-menu-item" [routerLink]="routeUtilities.routes.static.privacyPolicy.getNavigateUrl()"-->
<!--             [routerLinkActive]="['link-active']">-->
<!--          Privacy Policy-->
<!--        </div>-->
<!--        <div class="footer-menu-item" [routerLink]="routeUtilities.routes.static.terms.getNavigateUrl()"-->
<!--             [routerLinkActive]="['link-active']">-->
<!--          Terms & Conditions-->
<!--        </div>-->
        <a href="https://www.linkedin.com/company/divine-management-services/" target="_blank" mat-mini-fab class="social-button">
          <i class="fab fa-linkedin-in"></i>
        </a>
        <a href="https://instagram.com/simplidivine4u" target="_blank" mat-mini-fab class="social-button">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://x.com/divinemanage?s=11" target="_blank" mat-mini-fab class="social-button">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="https://www.facebook.com/profile.php?id=61550343154621&mibextid=2JQ9oc" target="_blank" mat-mini-fab class="social-button">
          <i class="fab fa-facebook-f"></i>
        </a>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
