import { Component, OnInit } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { CellClickedEvent, ColDef, ICellRendererParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { ChartOfAccountTypesEnum } from 'src/app/shared/enums/domain/chart-of-account-types.enum';
import { NumberUtilities } from 'src/app/core/utilities/number.utilities';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { Router } from '@angular/router';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { ReportTypesEnum } from 'src/app/shared/enums/domain/report-types.enum';

@Component({
  selector: 'app-chart-of-accounts-grid',
  templateUrl: './chart-of-accounts-grid.component.html',
  styleUrls: ['./chart-of-accounts-grid.component.scss']
})
export class ChartOfAccountsGridComponent extends BaseGridComponent<ChartOfAccountModel> implements OnInit {
  constructor(public authClientService: AuthClientService,
              private businessContextService: BusinessContextService,
              private chartOfAccountService: ChartOfAccountService,
              private router: Router) {
    super();
    this.gridKey = 'ChartOfAccounts_v1';
  }

  private readonly totalAmountFieldName = 'totalAmount';

  ngOnInit(): void {
    super.ngOnInit();

    this.gridOptions.defaultColDef.sortable = false;
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: '',
        field: this.actionFieldName,
        width: 70,
        cellClass: this.preventRowClickClass,
        cellRenderer: params => {
          let html = '';
          const rowData = <DocumentModel>params.data;

          html += '<i title="Report" class="cursor-pointer fas fa-file-alt prevent-row-click go-to-reports mr-1"></i>';

          return html;
        }
      },
      this.getNumberColumn('number', 'Number', false, 80),
      this.getEnumColumn('type', 'Type', ChartOfAccountTypesEnum, true),
      this.getDefaultColumn('fullDisplayPath', 'Account', false, 400),
      this.getLinkColumn(this.totalAmountFieldName, 'Total', (params: ICellRendererParams) => {
        if (!params.data.totalAmount) {
          return '';
        }

        return `${NumberUtilities.formatAsCurrency(params.data[this.totalAmountFieldName])} (${params.data['totalCount']})`;
      }),
      {
        headerName: 'Sub-Total',
        field: 'subTotalAmount',
        cellClass: this.preventRowClickClass,
        hide: false,
        cellRenderer: function(params: ICellRendererParams): string {
          if (!params.data.subTotalRollUpCount) {
            return '';
          }

          return `${NumberUtilities.formatAsCurrency(params.data.subTotalRollUpAmount)} (${params.data.subTotalRollUpCount})`;
        }
      },
      {
        headerName: 'Grand Total',
        field: 'grandTotalRollUpAmount',
        cellClass: this.preventRowClickClass,
        hide: false,
        cellRenderer: function(params: ICellRendererParams): string {
          if (!params.data.grandTotalRollUpAmount) {
            return '';
          }

          return `${NumberUtilities.formatAsCurrency(params.data.grandTotalRollUpAmount)} (${params.data.grandTotalRollUpCount})`;
        }
      },
      this.getDefaultColumn('description', 'Description', false, 300)
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((data: { data: ChartOfAccountModel, event: CellClickedEvent }) => {
      if (data.event.colDef.field === this.totalAmountFieldName) {
        this.router.navigateByUrl(RouteUtilities.routes.application.chartOfAccountEdit.getNavigateUrl(data.data.chartOfAccountKey));
      } else if (data.event.colDef.field === this.actionFieldName) {
          this.router.navigateByUrl(RouteUtilities.routes.application.reportEdit.getNavigateUrl(ReportTypesEnum.ChartOfAccountDetail, data.data.chartOfAccountKey));
      }
    }));
  }

  protected setRowData(): Observable<any[]> {
    return this.chartOfAccountService.search({businessKey: this.businessContextService.currentBusiness.businessKey, includeDeleted: this.includeDeleted, includeTotals: true});
  }
}
