import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { InvoiceService } from 'src/app/core/services/domain/invoice.service';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { DocumentSearchModel } from 'src/app/shared/models/domain/document-search.model';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-download-invoice-dialog',
  templateUrl: './download-invoice-dialog.component.html',
  styleUrls: ['./download-invoice-dialog.component.scss']
})
export class DownloadInvoiceDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
  businessKey: string = null;
  invoiceTemplates: DocumentModel[] = [];
  selectedTemplateDocumentKey: string;

  constructor(
    private dialogRef: MatDialogRef<DownloadInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { invoiceKey: string, businessKey: string },
    protected notificationService: NotificationService, private invoiceService: InvoiceService,
    private documentService: DocumentService) {
    super();
  }

  ngOnInit(): void {
    const searchModel: DocumentSearchModel = {
      documentTypes: [DocumentTypesEnum.InvoiceTemplate],
      businessKeys: [this.dialogData.businessKey]
    }

    this.documentService.search(searchModel).subscribe(invoiceTemplates => {
      this.invoiceTemplates = invoiceTemplates;
    })
  }

  ngAfterViewInit()
  {
    this.selectedTemplateDocumentKey = this.getDefaultInvoiceTemplate();
  }

  onDownloadClicked(): void {
    this.showBlockingLoader = true;

    this.invoiceService.download({
      invoiceKey: this.dialogData.invoiceKey,
      invoiceTemplateDocumentKey: this.selectedTemplateDocumentKey
    }).subscribe(_ => {
      this.showBlockingLoader = false;

      this.notificationService.showSuccessNotification('Invoice downloaded successfully.');
      this.dialogRef.close(true);
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }

  private readonly _defaultInvoiceTemplateKey = 'defaultInvoiceTemplate' + this.dialogData.businessKey;

  onInvoiceTemplateChange($event: MatSelectChange) {
    localStorage.setItem(this._defaultInvoiceTemplateKey, $event.value);
  }

  getDefaultInvoiceTemplate() : string {
    return localStorage.getItem(this._defaultInvoiceTemplateKey) ?? null;
  }
}
