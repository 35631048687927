<form [formGroup]="formGroupRef">
  <mat-tab-group #matTabGroup [(selectedIndex)]="selectedTabIndex">
    <mat-tab label="General Information">
      <div class="form-section">
        <div class="form-section-content">
          <div class="mt-2">
            <mat-form-field class="mr-2">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name">
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2">
              <mat-label>Phone Number</mat-label>
              <input matInput type="text" formControlName="phoneNumber">
            </mat-form-field>
            <mat-form-field class="mr-2">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email">
            </mat-form-field>
          </div>
        </div>
      </div>
</mat-tab>
    <mat-tab label="Addresses">
      <div class="form-section mt-2">
                  <ng-container formGroupName="address">
            <app-address-sub-form
            [(value)]="value.address"
            [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('address'))">
          </app-address-sub-form>
          </ng-container>
        </div>
          </mat-tab>
    <mat-tab label="Tax Information">
      <div class="form-section-content is-last">
        <div class="mt-2">
          <mat-form-field class="mr-2">
            <mat-label>EIN</mat-label>
            <input matInput formControlName="employerIdentificationNumber">
          </mat-form-field>
        </div>
        <div>
          <span class="subtle-text">Applying a employer identification number will make this customer as exempt</span>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Invoices" *ngIf="value.customerKey">
      <div class="mt-2">
        <app-invoices-grid
          [showTitle]="false"
          [showHeroNumbers]="true"
          [customerKey]="value.customerKey"
          (rowClick)="onInvoicesRowClicked($event)"
          (addButtonClick)="onInvoicesAddButtonClicked()">
        </app-invoices-grid>
      </div>
      <div>
        <mat-tab label="Jobs" *ngIf="value?.customerKey">
          <div class="mt-2">
            <app-jobs-grid
              (addButtonClick)="onJobAddClicked()"
              (rowClick)="onJobRowClicked($event)"
              [customerKey]="value.customerKey">
            </app-jobs-grid>
          </div>
        </mat-tab>
      </div>
      <div>
        <mat-tab label="Attachments" *ngIf="value?.customerKey">
          <div class="mt-2">
            <app-business-files-grid
              [customerKey]="value.customerKey">
            </app-business-files-grid>
          </div>
        </mat-tab>
      </div>
    </mat-tab>
  </mat-tab-group>
</form>
