import { Component, OnInit } from '@angular/core';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { Router, RoutesRecognized } from '@angular/router';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { filter, pairwise } from 'rxjs/operators';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router,
    private dialogService: DialogService,
    private pendingChangesService: PendingChangesService,
    private businessContextService: BusinessContextService,
    private authClientService: AuthClientService,
    private subscriptionService: BusinessSubscriptionService,
    private routingService: RoutingService) {
    super();

    document.getElementsByClassName('main-loading-spinner-container')[0].remove();

    if (this.authClientService.isAuthenticated) {
      if (window['noCookiesSupported']) {
        let dialogRef = this.dialogService.openNoCookiesDialog();
        dialogRef.afterClosed().subscribe(_ => {

        });

        return;
      } else {
        if (!this.authClientService.isSetupCompleted() || !this.businessContextService.currentBusiness) {
          this.router.navigateByUrl(this.routeUtilities.routes.application.profileSetup.getNavigateUrl());
        } else if (this.authClientService.isAuthenticated && this.authClientService.client && (this.isApplicationRoute() || window.location.href === window.location.origin + '/' || window.location.href === window.location.origin + '/#/login')) {
          this.subscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey).subscribe(result => {
            if (result) {
              if (window.location.href === window.location.origin + '/' || window.location.href.indexOf('login') !== -1) {
                this.router.navigateByUrl(this.routeUtilities.routes.application.base.getNavigateUrl());
              }
            } else {
              this.router.navigateByUrl(this.routeUtilities.routes.application.subscriptions.getNavigateUrl());
            }
          });
        } else {
          this.router.navigateByUrl(this.routeUtilities.routes.application.services.getNavigateUrl());
        }
      }
    }
    else if (window.location.href.indexOf('login') !== -1) {
      this.router.navigateByUrl(this.routeUtilities.routes.static.login.getNavigateUrl());
    }
    else if (window.location.href === window.location.origin + '/' || window.location.href === window.location.origin + '/#/') {
      this.router.navigateByUrl(this.routeUtilities.routes.static.services.getNavigateUrl())
    }
  }

  ngOnInit(): void {
    if (window.screen.width < 1240 && !localStorage.getItem("mobile_ack")) {
      let dialogRef = this.dialogService.openConfirmationDialog('<b>Mobile Device Detected</b><br><br/>A mobile experience is on the way!<br><br>In the meantime, to have the best experience, use a device with a larger screen.', null, null, '250px', '350px', null, true);
      dialogRef.afterClosed().subscribe(_ => {
        localStorage.setItem("mobile_ack", '1');
      });
    }

    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.routingService.previousRoutes$.next(events[0].urlAfterRedirects);
      });

    this.subscriptions.add(this.pendingChangesService.showPendingChanges$.subscribe(_ => {
      let matDialogRef = this.dialogService.openConfirmationDialog('You have unsaved changes, do you want to continue?', 'Yes', 'No', '130px', '400px');
      matDialogRef.afterClosed().subscribe(result => {
        this.pendingChangesService.pendingChangesResult$.next(result);
      });
    }));
  }
}
