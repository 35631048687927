<app-base-grid-template gridTitle="Payments" [showAddButton]="showAddButton" [showDeleteButton]="true"
                        (addButtonClick)="onAddButtonClicked()" (filterTextChange)="onFilterTextChanged($event)"
                        (resetGridClick)="onResetGridClicked()" (exportGridClick)="onExportGridClicked()"
                        (showDeletedClick)="onShowDeletedToggled($event)">
  <app-base-grid-content-template>
    <ag-grid-angular *ngIf="gridOptions"
                     class="ag-theme-alpine"
                     [gridOptions]="gridOptions">
    </ag-grid-angular>
  </app-base-grid-content-template>
</app-base-grid-template>



