import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { DocumentRenderModel } from 'src/app/shared/models/domain/document-render.model';
import { EmailDocumentModel } from 'src/app/shared/models/domain/email-document.model';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { RoutingService } from 'src/app/core/services/app/routing.service';

@Component({
  selector: 'app-document-page',
  templateUrl: './document-page.component.html',
  styleUrls: ['./document-page.component.scss']
})
export class DocumentPageComponent extends BaseEntryPageComponent<DocumentModel> implements OnInit {
  businessKey: string = null;
  isAdmin: boolean = false;
  fileList: FileList;
  isGlobalDocument: boolean = false;
  documentType: DocumentTypesEnum;

  constructor(private activatedRoute: ActivatedRoute,
              private documentService: DocumentService,
              private businessContextService: BusinessContextService,
              private dialogService: DialogService,
              routingService: RoutingService,
              notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.isAdmin = this.isAdminRoute();
    this.businessKey = this.businessContextService.currentBusiness.businessKey;
    const documentKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.documentKey, this.activatedRoute.snapshot);

    if (documentKey) {
      this.documentService.get(documentKey).subscribe(document => {
        if (document.businessKey == null && !this.isAdmin) {
          document.documentKey = null;
          this.isGlobalDocument = true;
        }

        this.value = document;
        this.value.businessKey = this.businessContextService.currentBusiness.businessKey;
        this.documentType = document.type;
      })
    } else {
      this.documentType = +this.getQueryParameterFromRoute(this.routingKeysUtilities.queryParams.documentType, this.activatedRoute.snapshot) || DocumentTypesEnum.Unknown;

      this.value = <DocumentModel>{};
      this.value.documentKey = null;
      this.value.type = this.documentType;
      this.value.businessKey = this.businessContextService.currentBusiness.businessKey;
    }
  }

  onSaveClicked(): void {
    this.isSaving = true;

    if (!this.value.documentKey) {
      this.documentService.create(this.value, this.fileList).subscribe(_ => {
        this.onSaveSuccess('Document was created successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.documentService.update(this.value, this.fileList).subscribe(_ => {
        this.onSaveSuccess('Document was updated successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }

  onCancelClicked(): void {
    this.redirectAfterSave();
  }

  onDeleteClicked(): void {
    if (this.isSaving || this.isDeleting) {
      return;
    }

    let matDialogRef = this.dialogService.openConfirmationDialog();

    matDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isDeleting = true;
        this.documentService.delete(this.value.documentKey).subscribe(result => {
          if (result) {
            this.onSaveSuccess('Document has been deleted successfully.');
          } else {
            this.onSaveFailed('Document failed to be deleted.')
          }

          this.redirectAfterSave();
        }, (err) => {
          this.onSaveFailed(err);
        });
      }
    });
  }

  onDownloadRenderedClicked(model: DocumentRenderModel): void {
    this.showBlockingLoader = true;
    this.documentService.downloadRendered(model).subscribe(_ => {
      this.notificationService.showSuccessNotification('Document downloaded successfully.')
      this.showBlockingLoader = false;
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    });
  }

  onEmailRenderedClicked(model: EmailDocumentModel): void {
    this.dialogService.openEmailDocumentRenderer(model, this.isAdmin);
  }
}
