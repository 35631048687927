<app-base-page-template pageTitle="Create a Ticket">
  <app-base-page-content-template>
    <div class="float-left" style="width: 50%; height: 100%">
      <iframe sandbox="allow-scripts allow-popups allow-forms allow-same-origin" width="100%" height="700px"
              style="border: 0; overflow: hidden; overflow-x: auto; margin-right: 20px"
              src="https://forms.helpdesk.com?licenseID=1473690608&contactFormID=e3763c59-52cb-42b9-81df-6617efa30514">
        Your browser does not allow embedded content.
      </iframe>
    </div>
    <div class="float-right" style="width: 50%; height: 100%">
      <iframe sandbox="allow-scripts allow-popups allow-forms allow-same-origin" width="100%" height="700px"
              style="border: 0; overflow: hidden; overflow-x: auto; margin-left: 20px"
              src="https://forms.helpdesk.com?licenseID=1473690608&contactFormID=e0a1ab64-a183-422f-8458-7e744ea51541">
        Your browser does not allow embedded content.
      </iframe>
    </div>
    <div class="clear-both"></div>
  </app-base-page-content-template>
</app-base-page-template>

