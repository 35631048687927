export class ValidationUtilities {
  static getFailureHttpResponseMessages(err: any[] | { message: string} | string): string[] {
    const errorMessages = [];

    if (err instanceof Array) {
      err.forEach(t => {
        errorMessages.push(t.message || 'An unexpected error has occurred (code 2).');
      })
    } else {
      if (!(err instanceof String) && typeof err !==  'string') {
        errorMessages.push('An unexpected error has occurred (code 3).');
      } else {
        // @ts-ignore
        errorMessages.push(err);
      }
    }

    return errorMessages;
  }
}
