import { Component } from '@angular/core';
import { BaseGridTemplateComponent } from 'src/app/shared/templates/base-grid-template/base-grid-template.component';

@Component({
  selector: 'app-base-grid-content-template',
  template: '<ng-content></ng-content>'
})
export class BaseGridContentTemplateComponent {
  // noinspection JSUnusedLocalSymbols
  constructor(templatePageComponent: BaseGridTemplateComponent) {
    // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
  }
}
