<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-header">Rule Definition
    </div>
    <div class="subtle-text">
      Define the condition for which you would like to automatically apply to a transaction . . .
    </div>
    <div class="mt-2">
      <mat-form-field class="mr-2">
        <mat-label>Field</mat-label>
        <mat-select formControlName="ruleOneField">
          <mat-option *ngFor="let transactionRuleField of transactionRuleFields"
                      [value]="transactionRuleField.value">{{transactionRuleField.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mr-2">
        <mat-label>Condition</mat-label>
        <mat-select formControlName="ruleOneCondition">
          <mat-option *ngFor="let transactionRuleCondition of transactionRuleConditions"
                      [value]="transactionRuleCondition.value">{{transactionRuleCondition.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Value</mat-label>
        <input matInput formControlName="ruleOneTerm">
      </mat-form-field>
    </div>
    <div class="subtle-text">
      Additional condition (optional) . . .
    </div>
    <div class="mt-2">
      <mat-form-field class="mr-2">
        <mat-label>Field</mat-label>
        <mat-select formControlName="ruleTwoField">
          <mat-option value="0">(Not Set)</mat-option>
          <mat-option *ngFor="let transactionRuleField of transactionRuleFields"
                      [value]="transactionRuleField.value">{{transactionRuleField.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mr-2">
        <mat-label>Condition</mat-label>
        <mat-select formControlName="ruleTwoCondition">
          <mat-option value="0">(Not Set)</mat-option>
          <mat-option *ngFor="let transactionRuleCondition of transactionRuleConditions"
                      [value]="transactionRuleCondition.value">{{transactionRuleCondition.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Value</mat-label>
        <input matInput formControlName="ruleTwoTerm">
      </mat-form-field>
    </div>
    <div class="subtle-text mt-3">
      When the condition(s) are met, the following fields will be automatically set on each transaction . . .
    </div>
    <div>
      <ng-container formGroupName="chartOfAccount" *ngIf="chartOfAccounts">
        <mat-form-field class="mat-form-field-double mr-2">
          <mat-label>Chart of Account</mat-label>
          <input type="text"
                 #chartOfAccountInput
                 matInput
                 placeholder="Search for chart of account . . ."
                 formControlName="chartOfAccountKey"
                 [matAutocomplete]="autoChartOfAccount">
          <mat-autocomplete [displayWith]="chartOfAccountDisplayFn.bind(this)" autoActiveFirstOption
                            #autoChartOfAccount="matAutocomplete">
            <mat-option value="-1">
              <span class="bold">Add Chart Of Account</span>
            </mat-option>
            <mat-option *ngFor="let chartOfAccount of filteredChartOfAccounts"
                        [value]="chartOfAccount.chartOfAccountKey">
              <span *ngIf="!chartOfAccount.parentChartOfAccountKey" class="bold">{{chartOfAccount.name}}</span>
              <span *ngIf="chartOfAccount.parentChartOfAccountKey">{{chartOfAccount.fullDisplayPath}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
      <ng-container>
        <mat-form-field class="mat-form-field-double" *ngIf="customerVendors">
          <mat-label>{{customerVendorLabel}}</mat-label>
          <input type="text"
                 #customerVendorInput
                 matInput
                 placeholder="Search for customer or vendor . . ."
                 formControlName="customerVendorKey"
                 [matAutocomplete]="autoVendor">
          <mat-autocomplete [displayWith]="customerVendorDisplayFn.bind(this)" autoActiveFirstOption
                            #autoVendor="matAutocomplete">
            <mat-option value="-2">
              <span class="bold">Add Vendor</span>
            </mat-option>
            <mat-option value="-1">
              <span class="bold">Add Customer</span>
            </mat-option>
            <mat-option *ngFor="let customerVendor of filteredCustomerVendors" [value]="customerVendor.key">
              <i [ngClass]="customerVendor.isVendor ? 'fa fa-warehouse' : 'fa fa-user-tag'"></i><span
              class="ml-1">{{customerVendor.name}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  <div class="form-section">
    <div class="form-section-header">
      <span>Additional Information</span>
    </div>
    <div class="mt-2">
      <mat-form-field class="mr-2">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field class="mat-form-field-tiny mr-2">
        <mat-label>Priority</mat-label>
        <input matInput type="number" formControlName="priority">
      </mat-form-field>
<!--      <mat-slide-toggle class="mr-2" formControlName="continueExecuting">Continue executing rules?</mat-slide-toggle>-->
          </div>
    <div class="flex">
      <div class="notes-container">
        <mat-form-field>
          <mat-label>Notes</mat-label>
          <textarea
            #autosize="cdkTextareaAutosize"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            formControlName="notes">
          </textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
