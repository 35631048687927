import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { ReportSearchModel } from 'src/app/shared/models/domain/report-search.model';
import { catchError, map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { ReportTypesEnum } from 'src/app/shared/enums/domain/report-types.enum';
import { DateUtilities } from 'src/app/core/utilities/date.utilities';
import { StringUtilities } from 'src/app/core/utilities/string.utilities';
import { EmailReportModel } from 'src/app/shared/models/domain/email-report.model';

@Injectable()
export class ReportService {
  private url = environment.apiUrl + '/reports/';

  constructor(private http: HttpClient) {
  }

  getRenderedHtml(model: ReportSearchModel): Observable<{renderedHtml: string}> {
    return this.http.post<{renderedHtml: string}>(`${this.url}render-html`, model);
  }

  download(model: ReportSearchModel): Observable<Blob> {
    let fileName = `${EnumUtilities.getDisplayName(ReportTypesEnum, model.type)}-${DateUtilities.getDateFileString()}.pdf`;
    fileName = StringUtilities.sanitizeFileName(fileName);

    return this.http.post(`${this.url}download`, model, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(tap((data: any) => {
      if (data.type === HttpEventType.Response) {
        saveAs(data.body, fileName);
      }
    }),
      catchError(err => {
      if (err?.text) {
        return from(err.text()).pipe(map(result => {
          throw JSON.parse(<string>result)
        }));
      }

      return err;
    }));
  }

  email(model: EmailReportModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.url}email`, model);
  }

  downloadStream(model: ReportSearchModel): Observable<Blob> {
    return this.http.post(`${this.url}download`, model, { responseType: 'blob' });
  }
}
