import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';

@Component({
  selector: 'app-dashboard-quick-links',
  templateUrl: './dashboard-quick-links.component.html',
  styleUrls: ['./dashboard-quick-links.component.scss', '../shared/dashboard.component.scss']
})
export class DashboardQuickLinksComponent extends BaseComponent implements OnInit {
  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {

  }

  onNavigateToRegistriesClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.registries.getNavigateUrl());
  }

  onNavigateToInvoicesClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.invoices.getNavigateUrl(InvoiceTypesEnum.Invoice));
  }

  onNavigateToBillsClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.invoices.getNavigateUrl(InvoiceTypesEnum.Bill));
  }

  onNavigateToVendorsClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.vendors.getNavigateUrl());
  }

  onNavigateToCustomersClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.customers.getNavigateUrl());
  }
}
