import { Injectable } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import { AuthClientModel } from 'src/app/shared/models/domain/auth-client.model';
import { ClaimsConstants } from 'src/app/shared/models/auth/constants/claim.constants';
import { AuthorizationConstants } from 'src/app/shared/constants/authorization.constants';
import { first, map, mergeMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { ClientService } from 'src/app/core/services/domain/client.service';

@Injectable()
export class AuthClientService {
  client: AuthClientModel | null = null;
  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  isAuthenticated: boolean = false;

  clientContextChange$ = new Subject<AuthClientModel>();

  constructor(private authService: AuthService, private clientService: ClientService) {
    this.authService.isAuthenticated$.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
    })
  }

  setClientContext(user:  User | null | undefined): void {
    if (!user) {
      this.client = null;
      this.clientContextChange$.next(null);
      return;
    }

    const policies = user[ClaimsConstants.policies] ? user[ClaimsConstants.policies].split(',') : [];

    const authClient: AuthClientModel = {
      clientKey: user[ClaimsConstants.clientKey],
      authUserId: <string>user[ClaimsConstants.authUserId],
      firstName: <string>user[ClaimsConstants.firstName],
      lastName: <string>user[ClaimsConstants.lastName],
      email: <string>user[ClaimsConstants.email],
      isEmailVerified: <boolean>user[ClaimsConstants.emailVerified],
      policies: policies,
      picture: <string>user[ClaimsConstants.picture]
    };

    this.client = authClient;
    this.isAdmin = this.hasAuthorization(AuthorizationConstants.superAdmin) || this.hasAuthorization(AuthorizationConstants.admin);
    this.isSuperAdmin = this.hasAuthorization(AuthorizationConstants.superAdmin);

    this.clientContextChange$.next(this.client);
  }

  isSetupCompleted(): boolean {
    return this.client?.isEmailVerified && !!this.client?.clientKey;
  }

  hasAuthorization(authorization: AuthorizationConstants): boolean {
    if (this.client == null) {
      return false;
    }

    return !!(this.client.policies.indexOf(AuthorizationConstants.superAdmin) !== -1 ||
      this.client.policies.indexOf(authorization.toString()) !== -1);
  }

  refreshUser(): Observable<void> {
    return this.authService.getAccessTokenSilently({ ignoreCache: true }).pipe(mergeMap((_ => {
      return this.authService.user$.pipe(first(), map(user => {
        this.setClientContext(user);
      }));
    })));
  }
}
