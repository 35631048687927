<app-base-entry-page-template entryTitle="Manage Chart Of Account" [value]="value" [formGroupRef]="formGroupRef"
                              [isSaving]="isSaving" (saveClick)="onSaveClicked()" [showFooter]="selectedTabIndex === 1 || !value?.chartOfAccountKey">
  <app-base-entry-page-content-template>
    <mat-tab-group #matTabGroup [(selectedIndex)]="selectedTabIndex" *ngIf="value?.chartOfAccountKey">
      <mat-tab [label]="chartOfAccountService.listCalculatedChartOfAccounts().indexOf(value?.name) === - 1 ? 'Registries' : (value.name === chartOfAccountConstants.accountsPayable ? 'Bills' : 'Invoices')">
        <div class="mt-2" *ngIf="chartOfAccountKey || bankingChartOfAccountKey">
          <app-transactions-grid
            *ngIf="chartOfAccountService.listCalculatedChartOfAccounts().indexOf(this.value?.name) === -1"
            [showTitle]="false"
            [showHasLinkedTransaction]="true"
            [showLinkedInvoices]="true"
            [showBulkUpdate]="false"
            [enableRowClick]="true"
            [showHeroNumbers]="true"
            [hideBankingActions]="true"
            [isRegistry]="true"
            [chartOfAccountKey]="chartOfAccountKey"
            [bankingChartOfAccountKey]="bankingChartOfAccountKey"
            (addButtonClick)="onTransactionAddClicked()"
            (rowClick)="onTransactionsRowClicked($event)">
          </app-transactions-grid>
          <app-invoices-grid
            *ngIf="chartOfAccountService.listCalculatedChartOfAccounts().indexOf(this.value?.name) > -1"
            [invoiceType]="value?.name === chartOfAccountConstants.accountsPayable ? invoiceTypes.Bill : invoiceTypes.Invoice"
            [includeAccountsPayableOnly]="value?.name === chartOfAccountConstants.accountsPayable"
            [includeAccountsReceivableOnly]="value?.name === chartOfAccountConstants.accountsReceivable"
            [enableRowClick]="true"
            (addButtonClick)="onAddInvoiceClicked()"
            (rowClick)="onInvoiceRowClicked($event)">
          </app-invoices-grid>
        </div>
      </mat-tab>
      <mat-tab label="General Information">
        <app-chart-of-account-form *ngIf="value"
                                   [(formGroupRef)]="formGroupRef"
                                   [(value)]="value">
        </app-chart-of-account-form>
      </mat-tab>
    </mat-tab-group>
    <div *ngIf="!value?.chartOfAccountKey">
      <app-chart-of-account-form *ngIf="value"
                                 [(formGroupRef)]="formGroupRef"
                                 [(value)]="value">
      </app-chart-of-account-form>
    </div>
  </app-base-entry-page-content-template>
  <app-base-entry-page-footer-template>
    <span class="mr-2">
      <button *ngIf="value?.chartOfAccountKey" mat-flat-button color="primary"
              (click)="onDeleteClicked()">{{value.isDeleted ? 'Save & Undelete' : 'Delete'}}</button>
    </span>
    <span>
      <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving">Cancel</button>
    </span>
  </app-base-entry-page-footer-template>
</app-base-entry-page-template>




