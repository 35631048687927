<app-base-entry-page-template entryTitle="Manage Offering" [value]="value" [formGroupRef]="formGroupRef"
                              [isSaving]="isSaving" [isDisabled]="isSaving || isDeleting" (saveClick)="onSaveClicked()" (cancelClick)="onCancelClicked()">
  <app-base-entry-page-content-template>
    <app-offering-form *ngIf="value"
                       [(formGroupRef)]="formGroupRef"
                       [(value)]="value">
    </app-offering-form>
  </app-base-entry-page-content-template>
  <app-base-entry-page-footer-template>
    <app-save-button *ngIf="value?.offeringKey" text="Delete" processingText="Deleting" [isSaving]="isDeleting" [isDisabled] ="isSaving || isDeleting" (click)="onDeleteClicked()"></app-save-button>
  </app-base-entry-page-footer-template>
</app-base-entry-page-template>




