<form [formGroup]="formGroupRef">
  <div class="mt-2">
    <input type="text" matInput formControlName="companyName" placeholder="Company Name"/>
  </div>
  <div class="mt-2">
    <input type="text" matInput formControlName="address" placeholder="Company Address"/>
  </div>
  <div class="mt-2">
    <input type="text" matInput formControlName="phone" placeholder="Company Phone"/>
  </div>
  <div class="mt-2">
    <input type="text" matInput formControlName="website" placeholder="Website"/>
  </div>
  <div class="mt-2">
    <textarea type="text" matInput formControlName="description" placeholder="Company Description" rows="10"></textarea>
  </div>
  <div class="mt-2">
    <button mat-flat-button color="primary" class="submit-button m-0" type="submit" [disabled]="!formGroupRef.valid"
      (click)="onSubmitClicked()">
      Submit
    </button>
  </div>
</form>
