  <app-tax-rate-form *ngIf="value"
    [(formGroupRef)]="formGroupRef"
    [(value)]="value">
  </app-tax-rate-form>
  <div class="mt-2">
    <span class="mr-2">
      <app-save-button (click)="onSaveClicked()" [isDisabled]="!formGroupRef.dirty || isSaving" [isSaving]="isSaving" text="Save" processingText="Saving"></app-save-button>
    </span>
    <span class="mr-2">
      <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving">Cancel</button>
    </span>
  </div>

  <div class="mt-5" *ngIf="taxRates">
    <div class="form-section-header mb-2">Tax Rates</div>
    <ng-container *ngIf="taxRates?.length">
      <div class="flex-full-auto text-center">
        <table class="full-width">
          <thead>
          <th style="width:20px"></th>
          <th>
            Name
          </th>
          <th>
            Agency
          </th>
          <th>
            Rate
          </th>
          <th>
            Deleted
          </th>
          </thead>
          <tbody>
          <tr *ngFor="let taxRate of taxRates">
            <td style="width: 20px;vertical-align: middle">
              <mat-icon class="cursor-pointer" title="Unlink from Transaction" (click)="onEditClicked(taxRate)">edit</mat-icon>
            </td>
            <td>
              {{taxRate.name}}
            </td>
            <td>
              {{taxRate.agencyName}}
            </td>
            <td>
              {{taxRate.rate | percentFormatter:3}}
            </td>
            <td>
              {{taxRate.isDeleted | yesNoFormatter}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <ng-container *ngIf="!taxRates?.length">
      <div class="text-center">
        No tax rates found.
      </div>
    </ng-container>
  </div>
