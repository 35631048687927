import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';
import { TransactionService } from 'src/app/core/services/domain/transaction.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { RoutingUtilities } from 'src/app/routing/routing.utilities';

@Component({
  selector: 'app-registry-page',
  templateUrl: './registry-page.component.html',
  styleUrls: ['./registry-page.component.scss']
})
export class RegistryPageComponent extends BaseEntryPageComponent<TransactionModel> implements OnInit {
  isAdmin: boolean = false;

  constructor(private router: Router,
              private dialogService: DialogService,
              private activatedRoute: ActivatedRoute,
              private transactionService: TransactionService,
              private businessContextService: BusinessContextService,
              routingService: RoutingService,
              notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.isAdmin = this.isAdminRoute();
    const transactionKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.transactionKey, this.activatedRoute.snapshot);
    const chartOfAccountKey = this.getQueryParameterFromRoute(RoutingKeysUtilities.queryParams.chartOfAccountKey, this.activatedRoute.snapshot);
    const bankingChartOfAccountKey = this.getQueryParameterFromRoute(RoutingKeysUtilities.queryParams.bankingChartOfAccountKey, this.activatedRoute.snapshot);

    if (transactionKey) {
      this.transactionService.get(transactionKey).subscribe(transaction => {
        this.value = transaction;
      })
    } else {
      this.value = <TransactionModel>{
        businessKey: this.businessContextService.currentBusiness.businessKey,
        isRegistry: true,
        chartOfAccount: {
          chartOfAccountKey: chartOfAccountKey ?? null
        },
        bankingChartOfAccount: {
          chartOfAccountKey: bankingChartOfAccountKey ?? null
        }
      };
    }
  }

  onSaveClicked(redirect: boolean = false, isUndeleting = false, saveAndAddNew: boolean = false): void {
    if (this.isSaving || this.isDeleting) {
      return;
    }

    this.isSaving = true;
    if (!this.value.transactionKey) {
      this.transactionService.create(this.value).subscribe(_ => {
        this.onSaveSuccess('Registry was created successfully.');
        if (saveAndAddNew) {
          RoutingUtilities.reloadPage(this.router);
        } else if (redirect) {
          this.redirectAfterSave();
        }
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      if (isUndeleting) {
        this.value.isDeleted = false;
      }

      this.transactionService.update(this.value).subscribe(_ => {
        this.formGroupRef.get('isDeleted').setValue(false);
        this.onSaveSuccess('Registry was updated successfully.');
        if (redirect) {
          this.redirectAfterSave();
        }
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }

  onCancelClicked() {
    this.redirectAfterSave();
  }

  redirectAfterSave(): boolean {
    let hasRedirect = super.redirectAfterSave();

    if (!hasRedirect) {
      if (this.value.isRegistry) {
        this.router.navigateByUrl(RouteUtilities.routes.application.registries.getNavigateUrl());
      } else {
        this.router.navigateByUrl(RouteUtilities.routes.application.transactions.getNavigateUrl());
      }
    }
    return true;
  }

  onDeleteClicked(): void {
    if (this.isSaving || this.isDeleting) {
      return;
    }

    // if (this.formGroupRef.dirty) {
    //   this.notificationService.showErrorNotification('Save any changes before deleting.');
    //   return;
    // }

    if (!this.value.isDeleted) {
      let matDialogRef = this.dialogService.openConfirmationDialog();

      matDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isDeleting = true;
          this.transactionService.delete(this.value.transactionKey).subscribe(result => {
            if (result) {
              this.onSaveSuccess('Transaction has been deleted successfully.');
            } else {
              this.onSaveFailed('Transaction failed to be deleted.')
            }

            this.redirectAfterSave();
          }, (err) => {
            this.onSaveFailed(err);
          });
        }
      });
    } else {
      this.onSaveClicked(false, true);
    }
  }
}
