import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { ReconciliationService } from 'src/app/core/services/domain/reconciliation.service';
import { ReconciliationModel } from 'src/app/shared/models/domain/reconciliation.model';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import {
  TransactionsGridComponent
} from 'src/app/shared/components/grids/transactions-grid/transactions-grid.component';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { RowClassParams } from 'ag-grid-community';
import { NumberUtilities } from 'src/app/core/utilities/number.utilities';
import { RollUpItemModel } from 'src/app/shared/models/app/roll-up-item.model';

@Component({
  selector: 'app-reconciliation-page',
  templateUrl: './reconciliation-page.component.html',
  styleUrls: ['./reconciliation-page.component.scss']
})
export class ReconciliationPageComponent extends BaseEntryPageComponent<ReconciliationModel> implements OnInit {
  showBlockingLoader = false;
  transactions: TransactionModel[] = [];
  selectedTransactions: TransactionModel[] = [];
  transactionReconciliationKey: string = null;
  transactionColumnsToHide = [TransactionsGridComponent.bankingChartOfAccountNameFieldName, TransactionsGridComponent.appliedAmountFieldName, TransactionsGridComponent.linkedInvoicesCountFieldName];
  isLoadingTransactions = false;
  rollUpItems = [];
  getRowClassFunc: (params: RowClassParams) => string[];

  constructor(
    public authClientService: AuthClientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private businessContextService: BusinessContextService,
    private reconciliationService: ReconciliationService,
    private dialogService: DialogService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    const reconciliationKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.reconciliationKey, this.activatedRoute.snapshot);

    if (reconciliationKey) {
      this.reconciliationService.get(reconciliationKey).subscribe(reconciliation => {
        this.transactionReconciliationKey = `Reconcilation_grid_${reconciliation?.isDraft ?? false}_v1`;

        if (!reconciliation.isDraft) {
          this.transactionColumnsToHide.push('balance');
        }

        this.value = reconciliation;
        this.transactions = reconciliation.transactions;
        this.selectedTransactions = !reconciliation.isDraft ? reconciliation.transactions : reconciliation.transactions.filter(t => reconciliation.selectedDraftTransactionKeys.indexOf(t.transactionKey) !== -1);
        this.setHeroNumbers();
      });
    } else {
      this.value = <ReconciliationModel>{
        businessKey: this.businessContextService.currentBusiness.businessKey,
        isDraft: true
      };
    }

    this.getRowClassFunc = (params: RowClassParams): string[] => {
      let classes = [];

      if (params.node.data.date < this.value.startDate) {
        classes.push('ag-row-text-red');
      }

      return classes;
    }
  }

  onSaveClicked(isDraft: boolean = false): void {
    if (!isDraft) {
      let message = this.value.endingBankBalance === this.value.calculatedEndingBankBalance ? 'This action is irreversible, are you sure you want to continue?' : 'Balances have a difference, this action is irreversible, are you sure you want to continue?';
      let dialogRef = this.dialogService.openConfirmationDialog(message, 'Save', 'Cancel', '150px');
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.executeSave(isDraft);
        }
      });
    } else {
      this.executeSave(isDraft);
    }
  }

  onDeleteClicked(): void {
    let message = 'This action is irreversible, are you sure you want to continue?';
    let dialogRef = this.dialogService.openConfirmationDialog(message, 'Delete', 'Cancel', '150px');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reconciliationService.delete(this.value.reconciliationKey).subscribe(_ => {
          this.onSaveSuccess('Reconciliation was deleted successfully.');
          this.redirectAfterSave();
        }, err => {
          this.onSaveFailed(err);
        });
      }
    });
  }

  redirectAfterSave(): boolean {
    let hasRedirect = super.redirectAfterSave();

    if (!hasRedirect) {
      this.router.navigateByUrl(RouteUtilities.routes.application.reconciliations.getNavigateUrl());
    }

    return true;
  }

  onCancelClicked() {
    this.redirectAfterSave();
  }

  onTransactionsRowClicked(data: any): void {
    const dialogRef = this.dialogService.openTransactionForm(data.data.transactionKey);
    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.onReconciliationTransactionDataChanged();
      }
    });
  }

  calculateAggregates(): void {
    //NEED OTHER SUBSCRIPTION TO FIRE OFF FIRST TO GET UPDATED BALANCE, I COULD USE ANOTHER EVENT BUT MEH
    setTimeout(_ => {
      if (this.selectedTransactions?.length) {
        let endingBankBalance = this.selectedTransactions[this.selectedTransactions.length - 1].balance;
        this.formGroupRef.get('calculatedEndingBankBalance').setValue(endingBankBalance);
      } else {
        this.formGroupRef.get('calculatedEndingBankBalance').setValue(0);
      }

      this.formGroupRef.get('debits').setValue(this.selectedTransactions.filter(t => t.amount < 0).map(t => t.amount).reduce((partialSum, a) => partialSum + a, 0));
      this.formGroupRef.get('credits').setValue(this.selectedTransactions.filter(t => t.amount > 0).map(t => t.amount).reduce((partialSum, a) => partialSum + a, 0));
      this.setHeroNumbers();
      this.formGroupRef.markAsDirty();
    });
  }

  setHeroNumbers(): void {
    const items: RollUpItemModel[] = [
      {header: 'Transactions', value: this.selectedTransactions.length.toString()},
      {header: 'Credits', value: NumberUtilities.formatAsCurrency(this.value.credits)},
      {header: 'Debits', value: NumberUtilities.formatAsCurrency(this.value.debits)}];

    if (this.value.isDraft) {
      items.push({
        header: 'Transaction Balance',
        value: NumberUtilities.formatAsCurrency(this.value.calculatedEndingBankBalance)
      });
    }

    items.push(
      {
        header: 'Difference',
        value: (this.value.endingBankBalance ? NumberUtilities.formatAsCurrency((this.value.endingBankBalance ?? 0) - this.value.calculatedEndingBankBalance) : '-')
    });

    this.rollUpItems = items;
  }

  onReconciliationTransactionDataChanged(): void {
    if (this.value.isDraft && this.value.chartOfAccount?.chartOfAccountKey) {
      this.isLoadingTransactions = true;

      this.reconciliationService.getDefault({
        businessKey: this.businessContextService.currentBusiness.businessKey,
        chartOfAccountKey: this.value.chartOfAccount?.chartOfAccountKey,
        startDate: this.value.startDate,
        endDate: this.value.endDate
      }).subscribe(reconciliation => {
        this.formGroupRef.patchValue(reconciliation, {emitEvent: false, onlySelf: true});
        this.formGroupRef.updateValueAndValidity();
        this.formGroupRef.markAsDirty();

        this.transactions = reconciliation.transactions;
        if (!this.selectedTransactions?.length) {
          this.selectedTransactions = reconciliation.transactions.filter(t => {
            return t.date >= this.value.startDate
          });
        } else {
          let transactionKeys = reconciliation.transactions.map(t => t.transactionKey);
          this.selectedTransactions = this.selectedTransactions.filter(t => transactionKeys.indexOf(t.transactionKey) !== -1);
        }

        this.isLoadingTransactions = false;
      }, err => {
        this.transactions = [];
        this.selectedTransactions = [];
        this.calculateAggregates();
        this.onHttpFailure(this.notificationService, err);
        this.isLoadingTransactions = false;
      });
    }
  }

  private executeSave(isDraft: boolean): void {
    this.value.isDraft = isDraft;
    this.value.transactions = this.selectedTransactions;
    this.value.selectedDraftTransactionKeys = this.selectedTransactions.map(t => t.transactionKey);
    this.isSaving = true;

    if (!this.value.reconciliationKey) {
      this.reconciliationService.create(this.value).subscribe(_ => {
        this.onSaveSuccess('Reconciliation was created successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.reconciliationService.update(this.value).subscribe(_ => {
        this.onSaveSuccess('Reconciliation was updated successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }
}

