import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';
import { TransactionSearchModel } from 'src/app/shared/models/domain/transaction-search.model';
import { TransactionBulkUpdateModel } from 'src/app/shared/models/domain/transaction-bulk-update.model';

@Injectable()
export class TransactionService {
  private url = environment.apiUrl + '/transactions/';

  constructor(private http: HttpClient) {
  }

  get(transactionKey: string): Observable<TransactionModel> {
    return this.http.get<TransactionModel>(this.url + transactionKey);
  }

  getStatus(transaction: TransactionModel): string {
    if (!transaction) {
      return '';
    }

    if (transaction.isDeleted) {
      return 'DELETED';
    }

    if (transaction.isRegistry) {
      if (transaction.isReconciled) {
        return 'RECONCILED';
      } else if (transaction.clearedDate != null) {
        return 'CLEARED';
      }
    } else {
      if (transaction.hasLinkedTransaction) {
        return 'LINKED';
      }
    }

    return 'UNCLEARED';
  }

  search(searchModel: TransactionSearchModel): Observable<TransactionModel[]> {
    return this.http.post<TransactionModel[]>(this.url + 'search', searchModel);
  }

  create(model: TransactionModel): Observable<string> {
    return this.http.post<string>(this.url, model);
  }

  update(model: TransactionModel): Observable<boolean> {
    return this.http.put<boolean>(this.url, model);
  }

  merge(transactionOne: TransactionModel, transactionTwo: TransactionModel, isBankingTransaction: boolean = false): TransactionModel {
    let registryTransaction = (transactionOne.isRegistry ? transactionOne : transactionTwo);
    let bankingTransaction = (transactionOne.isRegistry ? transactionTwo : transactionOne);

    if (registryTransaction.transactionKey == bankingTransaction.transactionKey) {
      throw 'Registry and banking transactions appear to be same';
    }

    let mergedTransaction = (!isBankingTransaction ? JSON.parse(JSON.stringify(registryTransaction)) : JSON.parse(JSON.stringify(bankingTransaction)));

    mergedTransaction.hasLinkedTransaction = true;
    mergedTransaction.date =  registryTransaction.date ?? bankingTransaction.date;
    mergedTransaction.clearedDate =  bankingTransaction.date;
    mergedTransaction.referenceNumber = registryTransaction.referenceNumber ?? bankingTransaction.referenceNumber;

    mergedTransaction.description = registryTransaction.description ?? bankingTransaction.description;
    mergedTransaction.notes = registryTransaction.notes ?? bankingTransaction.notes;
    mergedTransaction.chartOfAccount = <any>{
      chartOfAccountKey: registryTransaction.chartOfAccount?.chartOfAccountKey ?? bankingTransaction.chartOfAccount?.chartOfAccountKey,
      name: registryTransaction.chartOfAccount?.name ?? bankingTransaction.chartOfAccount?.name,
    };
    mergedTransaction.vendor = <any>{
      vendorKey: registryTransaction.vendor?.vendorKey ?? bankingTransaction.vendor?.vendorKey,
      notes: registryTransaction.vendor?.notes ?? bankingTransaction.vendor?.notes
    };
    mergedTransaction.bankingChartOfAccount = <any>{
      chartOfAccountKey: bankingTransaction.bankingChartOfAccount?.chartOfAccountKey,
      name: bankingTransaction.bankingChartOfAccount?.name
    };
    mergedTransaction.merchantName = bankingTransaction.merchantName;
    mergedTransaction.merchantLocation = bankingTransaction.merchantLocation;
    mergedTransaction.bankingAccountId = bankingTransaction.bankingAccountId;
    mergedTransaction.bankingAccountName = bankingTransaction.bankingAccountName;
    mergedTransaction.bankingTransactionId = bankingTransaction.bankingTransactionId;

    return mergedTransaction;
  }

  updateBulk(model: TransactionBulkUpdateModel): Observable<boolean> {
    return this.http.put<boolean>(this.url + 'bulk', model);
  }

  bulkMarkAsDeleted(model: string[]): Observable<boolean> {
    return this.http.put<boolean>(this.url + 'bulk/mark-as-deleted', model);
  }

  delete(transactionKey: string): Observable<boolean> {
    return this.http.delete<boolean>(this.url + transactionKey);
  }
}
