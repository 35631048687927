import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { InvoiceModel } from 'src/app/shared/models/domain/invoice.model';
import { InvoiceSearchModel } from 'src/app/shared/models/domain/invoice-search.model';
import { InvoiceDownloadModel } from 'src/app/shared/models/domain/invoice-download.model';
import { EmailInvoiceModel } from 'src/app/shared/models/domain/email-invoice.model';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';

@Injectable()
export class InvoiceService {
  private url = environment.apiUrl + '/invoices/';

  constructor(private http: HttpClient) {
  }

  get(invoiceKey: string): Observable<InvoiceModel> {
    return this.http.get<InvoiceModel>(this.url + invoiceKey);
  }

  getLatestNumber(businessKey: string): Observable<number> {
    return this.http.get<number>(`${this.url}business/${businessKey}/latest-number`);
  }

  search(searchModel: InvoiceSearchModel): Observable<InvoiceModel[]> {
    return this.http.post<InvoiceModel[]>(this.url + 'search', searchModel);
  }

  getTypeName(type: InvoiceTypesEnum): string {
    return EnumUtilities.getDisplayName(InvoiceTypesEnum, type);
  }

  download(model: InvoiceDownloadModel): Observable<void> {
    let url = `${this.url}download`;

    return this.http.post(url, model, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(tap((data: any) => {
      if (data.type === HttpEventType.Response) {
        saveAs(data.body, data.file?.name);
      }
    }), catchError(err => {
      if (err?.text) {
        return from(err.text()).pipe(map(result => {
          throw JSON.parse(<string>result)
        }));
      }

      return err;
    }));
  }

  email(model: EmailInvoiceModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.url}email`, model);
  }

  create(model: InvoiceModel): Observable<string> {
    return this.http.post<string>(this.url, model);
  }

  update(model: InvoiceModel): Observable<boolean> {
    return this.http.put<boolean>(this.url, model);
  }

  delete(invoiceKey: string): Observable<boolean> {
    return this.http.delete<boolean>(this.url + invoiceKey);
  }
}
