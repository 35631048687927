<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-header">Bulk Update Transactions
    </div>
    <div class="subtle-text">
      {{dialogData.transactionKeys.length}} transactions will be affected
    </div>
<!--    <div class="mt-2">-->
<!--      <mat-form-field>-->
<!--        <mat-label>Type</mat-label>-->
<!--        <mat-select formControlName="type">-->
<!--          <mat-option *ngFor="let transactionType of transactionTypes"-->
<!--                      [value]="transactionType.value">{{transactionType.label}}</mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
<!--    </div>-->
    <div class="mt-2">
      <mat-checkbox
        formControlName="addToRegistry">Add to Registry
      </mat-checkbox>
    </div>
    <div class="mt-2" *ngIf="chartOfAccounts">
      <mat-form-field class="mat-form-field-double">
        <mat-label>Chart of Account</mat-label>
        <input type="text"
               #chartOfAccountInput
               matInput
               placeholder="Search for chart of account . . ."
               formControlName="chartOfAccountKey"
               [matAutocomplete]="auto">
        <mat-autocomplete [displayWith]="chartOfAccountDisplayFn.bind(this)"
                          #auto="matAutocomplete">
          <mat-option *ngFor="let chartOfAccount of filteredChartOfAccounts"
                      [value]="chartOfAccount.chartOfAccountKey">
            <span *ngIf="!chartOfAccount.parentChartOfAccountKey" class="bold">{{chartOfAccount.name}}</span>
            <span *ngIf="chartOfAccount.parentChartOfAccountKey">{{chartOfAccount.fullDisplayPath}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="mt-2">
      <mat-form-field class="mat-form-field-double" *ngIf="vendors">
        <mat-label>Vendor</mat-label>
        <input type="text"
               #vendorInput
               matInput
               placeholder="Search for vendor . . ."
               formControlName="vendorKey"
               [matAutocomplete]="auto">
        <mat-autocomplete [displayWith]="vendorDisplayFn.bind(this)"
                          #auto="matAutocomplete">
          <mat-option *ngFor="let vendor of filteredVendors" [value]="vendor.vendorKey">
            {{vendor.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</form>
<div class="mt-2">
  <span class="mr-2">
    <app-save-button (click)="onUpdateClicked()" [isDisabled]="!formGroupRef.dirty || isSaving" [isSaving]="isSaving" text="Update" processingText="Updating"></app-save-button>
  </span>
  <span class="mr-2">
    <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving">Cancel</button>
  </span>
</div>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
