import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RoutingUtilities } from 'src/app/routing/routing.utilities';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';

@Component({
  template: ''
})
export abstract class BasePageComponent extends BaseComponent implements OnDestroy {
  public routeUtilities = RouteUtilities;
  public routingKeysUtilities = RoutingKeysUtilities;

  protected constructor() {
    super();

  }

  protected isApplicationRoute(): boolean {
    return RoutingUtilities.isApplicationRoute();
  }

  protected isAdminRoute(): boolean {
    return window.location.href.indexOf(`${this.routeUtilities.routes.application.base.modulePath}/${this.routeUtilities.routes.application.admin.base.modulePath}/`) !== -1;
  }

  protected getParameterFromRoute(key: string, snapshot: ActivatedRouteSnapshot = null): any {
    return RoutingUtilities.getParameterFromActivatedRouteSnapshot(key, snapshot) ?? null;
  }

  protected getQueryParameterFromRoute(key: string, snapshot: ActivatedRouteSnapshot = null): any {
    return snapshot.queryParams[key] ?? null;
  }

  protected reloadPage(router: Router, route: string = null): void {
    RoutingUtilities.reloadPage(router, route);
  }
}
