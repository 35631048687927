import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { RollUpItemModel } from 'src/app/shared/models/app/roll-up-item.model';

@Component({
  selector: 'app-roll-up-item',
  templateUrl: './roll-up-item.component.html',
  styleUrls: ['./roll-up-item.component.scss']
})
export class RollUpItemComponent extends BaseComponent implements OnInit {
  @Input() item: RollUpItemModel;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
