import { Component } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';

@Component({
  selector: 'app-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss']
})
export class PrivacyPolicyPageComponent extends BasePageComponent {
  constructor() {
    super();
  }
}
