export class LocalStorageUtilites {
  static check(name: string): boolean {
    return !!localStorage.getItem(name);
  }

  static delete(name: string): void {
    localStorage.removeItem(name);
  }

  static get(name: string): any {
    const item = localStorage.getItem(name);
    if (item === 'undefined') {
      return undefined;
    }
    return JSON.parse(item);
  }

  static set(name: string, item: any): void {
    localStorage.setItem(name, JSON.stringify(item));
  }
}
