import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';

export class RoutingUtilities {
  public static isApplicationRoute(): boolean {
    return window.location.href.indexOf(`${RouteUtilities.routes.application.base.modulePath}/`) !== -1;
  }

  public static getStrippedUrl(url: string): string {
    const strippedUrlWithQueryParams = url.split('/').slice(2).join('/');
    const strippedUrl = strippedUrlWithQueryParams.replace('%253F', '?').split('?')[0];
    return strippedUrl;
  }

  public static getParameterFromActivatedRouteSnapshot(key: string, snapshot: ActivatedRouteSnapshot = null): any {
    while (snapshot) {
      if (snapshot.params?.[key]) {
        return snapshot.params?.[key];
      }
      snapshot = snapshot.parent;
    }
    return undefined;
  }

  // this is specifically used by top-level (in routing hierarchy) resolvers
  public static getChildParameterFromActivatedRouteSnapshot(key: string, snapshot: ActivatedRouteSnapshot = null): any {
    while (snapshot) {
      if (snapshot.params?.[key] != null && typeof snapshot.params?.[key] !== 'undefined') {
        return snapshot.params?.[key];
      }
      snapshot = snapshot.firstChild;
    }
    return undefined;
  }

  public static getParameterFromRouter(key: string, router: Router): any {
    let currentActivatedRoute = router.routerState.root;

    if (currentActivatedRoute === null) {
      return null;
    }

    while (currentActivatedRoute.firstChild) {
      currentActivatedRoute = currentActivatedRoute.firstChild;
    }

    return RoutingUtilities.getParameterFromActivatedRouteSnapshot(key, currentActivatedRoute.snapshot);
  }

  public static getDataFromActivatedRouteSnapshot(key: string, snapshot: ActivatedRouteSnapshot = null): any {
    let childFound = false;
    while (!childFound) {
      if (snapshot.firstChild) {
        snapshot = snapshot.firstChild;
      } else {
        childFound = true;
      }
    }

    return snapshot.data?.[key];
  }

  public static getSnapshotFromActivatedRouteSnapshotByUrl(url: string, snapshot: ActivatedRouteSnapshot = null): any {
    while (snapshot) {
      if (RoutingUtilities.getResolvedUrl(snapshot) === url) {
        return snapshot;
      }
      snapshot = snapshot.firstChild;
      RoutingUtilities.getSnapshotFromActivatedRouteSnapshotByUrl(url, snapshot);
    }
    return undefined;
  }

  public static getResolvedUrl(activatedRouteSnapshot: ActivatedRouteSnapshot): string {
    if (!activatedRouteSnapshot) {
      return null;
    }

    return activatedRouteSnapshot.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/').replace('//', '/');
  }

  public static reloadPage(router: Router, route: string = null): void {
    const currentRoute = route ?? router.url;

    router.navigateByUrl(RouteUtilities.routes.application.redirect.getNavigateUrl()).then(_ => {
      router.navigateByUrl(currentRoute);
    });
  }

  public static getConfiguredUrl(activatedRouteSnapshot: ActivatedRouteSnapshot): string {
    if (!activatedRouteSnapshot) {
      return null;
    }

    return '/' + activatedRouteSnapshot.pathFromRoot.filter(v => v.routeConfig).map(v => v.routeConfig?.path).join('/').replace('//', '/');
  }
}
