import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { DocumentSearchModel } from 'src/app/shared/models/domain/document-search.model';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import {MatSelectChange} from "@angular/material/select";
import { EmailReportModel } from 'src/app/shared/models/domain/email-report.model';

@Component({
  selector: 'app-email-report-form',
  templateUrl: './email-report-form.component.html',
  styleUrls: ['./email-report-form.component.scss']
})
export class EmailReportFormComponent extends BaseFormComponent<EmailReportModel> implements OnInit, AfterViewInit {
  @Input() isAdmin: boolean = false;
  @Input() businessKey: string = null;

  documentTemplates: DocumentModel[] = [];
  defaultDocTemplateValue: string = null;

  constructor(
    private documentService: DocumentService
  ) {
    super();
  }

  ngOnInit(): void {
    const searchModel: DocumentSearchModel = {
      documentTypes: [DocumentTypesEnum.Template],
      businessKeys: this.isAdmin ? null : [this.businessKey]
    }

    this.documentService.search(searchModel).subscribe(documentTemplates => {
      this.documentTemplates = documentTemplates;
    })

    super.ngOnInit();
  }

  ngAfterViewInit()
  {
    this.defaultDocTemplateValue = this.getDefaultDocTemplate();
  }

  protected sanitizeValue(value: EmailReportModel): EmailReportModel {
    value.templateKey = value.templateKey === "null" ? null : value.templateKey;
    return value;
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      businessKey: new UntypedFormControl(this.value.businessKey ?? null),
      type: new UntypedFormControl(this.value.type),
      startDate: new UntypedFormControl(this.value.startDate),
      endDate: new UntypedFormControl(this.value.endDate),
      email: new UntypedFormGroup({
        toEmailAddress: new UntypedFormControl(this.value.email?.toEmailAddress ?? null),
        toEmailAddressAlias: new UntypedFormControl(this.value.email?.toEmailAddressAlias ?? null),
        subject: new UntypedFormControl(this.value.email?.subject ?? null)
      }),
      templateKey: new UntypedFormControl(this.value.templateKey ?? null)
    });
  }

  private readonly defaultDocTemplateKey = 'defaultDocTemplate' + this.businessKey;

  onTemplateChange($event: MatSelectChange) {
    localStorage.setItem(this.defaultDocTemplateKey, $event.value);
  }

  getDefaultDocTemplate() : string {
    return localStorage.getItem(this.defaultDocTemplateKey) ?? null;
  }
}
