<app-base-entry-page-template entryTitle="Manage Vendor" [value]="value" [formGroupRef]="formGroupRef" [showFooter]="selectedTabIndex < 2"
                              [isSaving]="isSaving" (saveClick)="onSaveClicked()" (cancelClick)="onCancelClicked()">
  <app-base-entry-page-content-template>
    <app-vendor-form *ngIf="value"
                       [(selectedTabIndex)]="selectedTabIndex"
                       [(formGroupRef)]="formGroupRef"
                       [(value)]="value">
    </app-vendor-form>
  </app-base-entry-page-content-template>
</app-base-entry-page-template>




