import { Component, Input, OnInit } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BusinessServiceRequestService } from 'src/app/core/services/domain/business-service-request.service';
import { BusinessServiceRequestModel } from 'src/app/shared/models/domain/business-service-request.model';
import { InvoiceStatusesEnum } from 'src/app/shared/enums/domain/invoice-statuses.enum';
import { PrioritiesEnum } from 'src/app/shared/enums/app/priorities.enum';
import { BusinessServiceRequestStatusesEnum } from 'src/app/shared/enums/domain/business-service-request-statuses.enum';

@Component({
  selector: 'app-business-service-requests-grid',
  templateUrl: './business-service-requests-grid.component.html',
  styleUrls: ['./business-service-requests-grid.component.scss']
})
export class BusinessServiceRequestsGridComponent extends BaseGridComponent<BusinessServiceRequestModel> implements OnInit {
  @Input() businessKey: string = null;

  gridTitle: string;
  description: string;


  constructor(public authClientService: AuthClientService,
              private businessServiceRequestsService: BusinessServiceRequestService) {
    super();
  }

  ngOnInit(): void {
    this.gridKey = `BusinessServiceRequests_${this.businessKey === null}_${this.isAdminRoute()}_v3`;

    if (this.isAdminRoute()) {
      this.gridTitle  = 'Service Requests';
    } else {
      this.gridTitle = this.businessKey ? 'Business Lead/Service Requests' : 'My Lead/Service Requests'
      this.description = this.businessKey ? 'Service requests made to your business from a client.' : 'Requests you have made to another business for service.';
    }

    super.ngOnInit();
  }


  protected setColumnDefinitions(): ColDef[] {
    return [
      this.getDefaultColumn('business.name', 'Business', this.businessKey && !this.isAdminRoute()),
      this.getEnumColumn('status', 'Status', BusinessServiceRequestStatusesEnum),
      this.getDateColumn('requestedOn', 'Requested On'),
      //this.getNumberColumn('stars', 'Stars'),
      this.getEnumColumn('priority', 'Priority', PrioritiesEnum),
      this.getDefaultColumn('lastName', 'Last Name', this.businessKey === null && !this.isAdminRoute()),
      this.getDefaultColumn('firstName', 'First Name', this.businessKey === null && !this.isAdminRoute()),
      this.getDefaultColumn('email', 'Email', this.businessKey === null && !this.isAdminRoute()),
      this.getDefaultColumn('phoneNumber', 'Phone #', this.businessKey === null && !this.isAdminRoute()),
      this.getDefaultColumn('address.city', 'City', this.businessKey === null && !this.isAdminRoute()),
      this.getDefaultColumn('address.state', 'State', this.businessKey === null && !this.isAdminRoute())
    ];
  }

  protected onGridReady(): void {
  }

  protected setRowData(): Observable<any[]> {
    return this.businessServiceRequestsService.search({businessKey: this.businessKey, requestedByMe: !this.businessKey && !this.isAdminRoute() });
  }
}

