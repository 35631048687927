<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-content">
      <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.General">
        <div class="mt-2">
          <mat-form-field class="mat-form-field-double"
                          *ngIf="filteredParentChartOfAccounts && !value?.chartOfAccountKey">
            <mat-label>Top-Level Chart of Account</mat-label>
            <input type="text"
                   matInput
                   placeholder="Search for chart of account . . ."
                   formControlName="parentChartOfAccountKey"
                   [matAutocomplete]="auto">
            <mat-autocomplete [displayWith]="parentChartOfAccountDisplayFn.bind(this)" autoActiveFirstOption
                              #auto="matAutocomplete">
              <mat-option *ngFor="let parentChartOfAccount of filteredParentChartOfAccounts"
                          [value]="parentChartOfAccount.chartOfAccountKey">
                {{getParentChartOfAccountDisplayName(parentChartOfAccount)}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div *ngIf="value?.chartOfAccountKey">
          <mat-form-field class="mr-2 mat-form-field-double">
            <mat-label>Parent Chart of Account</mat-label>
            <input matInput type="text" [readonly]="true" formControlName="parentDisplayPath">
          </mat-form-field>
        </div>
        <div class="mt-2" *ngIf="!value.chartOfAccountKey">
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select [disabled]="true"
                        formControlName="type" (selectionChange)="onTypeChanged()">
              <mat-option *ngFor="let type of types"
                          [value]="type.value">{{type.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mt-1" style="display:none" *ngIf="value.chartOfAccountKey">
          Type: <span>{{enumUtilities.getDisplayName(typesEnum, value.type)}}</span>
        </div>
        <div class="mt-2">
          <mat-form-field class="mr-2 mat-form-field-double">
            <mat-label>Name</mat-label>
            <input matInput type="text" formControlName="name">
          </mat-form-field>
        </div>
        <div class="mt-2">
          <mat-form-field class="mat-form-field-small mr-2">
            <mat-label>Number</mat-label>
            <input [readonly]="!!value.chartOfAccountKey" matInput type="number" formControlName="number">
          </mat-form-field>
        </div>
        <div class="mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label>Description</mat-label>
            <textarea
              #autosize="cdkTextareaAutosize"
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="5"
              formControlName="description">
              </textarea>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="viewMode === viewModes.All || viewMode === viewModes.Banking">
        <div class="mt-2 bold" *ngIf="value?.bankingAccount?.displayName">
          {{value?.bankingAccount?.displayName}}
        </div>
        <div class="mt-2">
          <mat-form-field *ngIf="isBankCOA" class="mr-2 mat-form-field-double">
            <mat-label>Bank Account #</mat-label>
            <input matInput type="text" formControlName="fullBankingAccountNumber">
          </mat-form-field>
        </div>
        <ng-container *ngIf="value.bankingAccount.bankingAccountKey" formGroupName="bankingAccount">
          <div class="mt-2">
            <ng-container formGroupName="institution">
              <mat-form-field class="mr-2 mat-form-field-double">
                <mat-label>Institution</mat-label>
                <input matInput [readonly]="true" type="text" formControlName="name">
              </mat-form-field>
            </ng-container>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field">
              <mat-label>Type</mat-label>
              <input matInput [readonly]="true" type="text" formControlName="type">
            </mat-form-field>
            <mat-form-field *ngIf="isBankCOA" class="mr-2 mat-form-field">
              <mat-label>Sub-type</mat-label>
              <input matInput [readonly]="true" type="text" formControlName="subType">
            </mat-form-field>
          </div>
        </ng-container>
        <div class="mt-2" *ngIf="isBankCOA">
          <mat-form-field class="mat-form-field">
            <mat-label>Starting Balance</mat-label>
            <input matInput type="text" currencyMask formControlName="startingBalance">
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </div>
</form>
