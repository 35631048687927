<app-base-grid-template gridTitle="Access" (addButtonClick)="onAddButtonClicked()">
  <app-base-grid-header-template>
    <mat-form-field class="mr-2">
      <mat-label>Email Address</mat-label>
      <input matInput type="text" [(ngModel)]="emailToAdd">
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="onAddClientAccessByEmailClicked()">Add Access</button>
  </app-base-grid-header-template>
  <app-base-grid-content-template>
    <ag-grid-angular *ngIf="gridOptions"
                     class="ag-theme-alpine"
                     [gridOptions]="gridOptions">
    </ag-grid-angular>
  </app-base-grid-content-template>
</app-base-grid-template>



