import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { ClientModel } from 'src/app/shared/models/domain/client.model';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from 'src/app/core/services/domain/client.service';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessFormComponent } from 'src/app/shared/components/forms/business-form/business-form.component';
import { RoutingService } from 'src/app/core/services/app/routing.service';

@Component({
  selector: 'app-client-page',
  templateUrl: './client-page.component.html',
  styleUrls: ['./client-page.component.scss']
})
export class ClientPageComponent extends BaseEntryPageComponent<ClientModel> implements OnInit {
  hasClientKey: boolean = false;
  isSendingInvite = false;
  isSendingVerification = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private clientService: ClientService,
              private authClientService: AuthClientService,
              routingService: RoutingService,
              notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    const clientKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.clientKey, this.activatedRoute.snapshot);

    if (clientKey) {
      this.clientService.get(clientKey).subscribe((res) => {
        this.value = <ClientModel>res;
      });
    } else {
      this.value = <ClientModel>{};
    }
  }

  OnResendVerificationEmailClicked(): void {
    this.isSendingVerification = true;
    this.clientService.sendVerificationEmail(this.value.clientKey).subscribe(result => {
      if (result) {
        this.notificationService.showSuccessNotification('Verification sent successfully');
      } else {
        this.notificationService.showErrorNotification('Verification failed to be sent');
      }
      this.isSendingVerification = false;
    }, err => {
      this.isSendingVerification = false;
      this.onSaveFailed(err);
    });
  }

  onSendInviteClicked(): void {
    this.isSendingInvite = true;
    this.clientService.sendInviteEmail(this.value.clientKey).subscribe(result => {
      if (result) {
        this.notificationService.showSuccessNotification('Invite sent successfully');
      } else {
        this.notificationService.showErrorNotification('Invite failed to be sent');
      }
      this.isSendingInvite = false;
    }, err => {
      this.isSendingInvite = false;
      this.onSaveFailed(err);
    });
  }

  onSaveClicked(): void {
    this.isSaving = true;

    if (!this.value.clientKey) {
      this.clientService.create(this.value).subscribe(id => {
        this.onSaveSuccess('Client was created successfully.');
        this.router.navigateByUrl(RouteUtilities.routes.application.admin.clients.getNavigateUrl());
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.clientService.update(this.value).subscribe(_ => {
        this.onSaveSuccess('Client was updated successfully.');
        this.router.navigateByUrl(RouteUtilities.routes.application.admin.clients.getNavigateUrl());
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }
}
