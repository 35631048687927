<form [formGroup]="formGroupRef" *ngIf="invoice">
  <div class="form-section">
    <div class="form-section-header">
      Invoice #: {{invoice.number}} - {{invoice.balanceDue | currencyFormatter}}
    </div>
    <div class="mt-2">
      <mat-form-field class="mat-form-field-date mr-2">
        <mat-label>Date</mat-label>
        <input matInput [readonly] [matDatepicker]="picker" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="mat-form-field-small">
        <mat-label>Amount</mat-label>
        <input matInput type="text" currencyMask formControlName="amount"
               [options]="{ prefix: '$', precision: '2' }">
      </mat-form-field>
    </div>
    <div class="mt-2">
      <mat-form-field class="mat-form-field-double">
        <mat-label>Notes</mat-label>
        <textarea
          #autosize="cdkTextareaAutosize"
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="5"
          formControlName="notes">
          </textarea>
      </mat-form-field>
    </div>
  </div>
</form>
