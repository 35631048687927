import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/app/notification.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent extends BaseComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { text: string, okText: string, extraButtonText: string, cancelText: string, hideCancel: boolean },
    protected notificationService: NotificationService) {
    super();
  }

  onOkClicked(): void {
    this.dialogRef.close(1);
  }

  onExtraButtonClicked(): void {
    this.dialogRef.close(2);
  }

  onCancelClicked(): void {
    this.dialogRef.close(0);
  }
}
