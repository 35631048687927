import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EmployeeModel } from 'src/app/shared/models/domain/employee.model';
import { EmployeeSearchModel } from 'src/app/shared/models/domain/employee-search.model';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';
import { CustomerSearchModel } from 'src/app/shared/models/domain/customer-search.model';

@Injectable()
export class CustomerService {
  private url = environment.apiUrl + '/customers/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<CustomerModel> {
    return this.http.get<CustomerModel>(`${this.url}${key}`);
  }

  search(model: CustomerSearchModel): Observable<CustomerModel[]> {
    return this.http.post<CustomerModel[]>(`${this.url}search`, model);
  }

  create(model: CustomerModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model)
  }

  update(model: CustomerModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model)
  }
}
