import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { VendorService } from 'src/app/core/services/domain/vendor.service';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';
import { RoutingService } from 'src/app/core/services/app/routing.service';

@Component({
  selector: 'app-customer-form-dialog',
  templateUrl: './vendor-form-dialog.component.html'
})
export class VendorFormDialogComponent extends BaseEntryPageComponent<VendorModel> implements OnInit, AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<VendorFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {name: string },
    private businessContextService: BusinessContextService,
    private vendorService: VendorService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.value = <VendorModel>{
      name: this.dialogData?.name ?? null,
      businessKey: this.businessContextService.currentBusiness.businessKey
    };
  }

  ngAfterViewInit(): void {
    if (this.dialogData?.name) {
      this.formGroupRef.markAsDirty();
      this.formGroupRef.updateValueAndValidity();
    }

    super.ngAfterViewInit();
  }

  onSaveClicked(): void {
    this.isSaving = true;
    this.vendorService.create(this.value).subscribe(vendorKey => {
      this.onSaveSuccess('Vendor was created successfully.');
      this.dialogRef.close(vendorKey);
    }, err => {
      this.onSaveFailed(err);
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close(null);
  }
}
