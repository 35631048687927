<app-base-page-template>
  <app-base-page-content-template>
    <app-documents-grid
      [businessKey]="businessKey"
      [documentType]="documentType"
      [isAdminOnly]="isAdmin"
      [enableRowClick]="true"
      (addButtonClick)="onAddButtonClicked()"
      (rowClick)="onRowClicked($event)">
    </app-documents-grid>
  </app-base-page-content-template>
</app-base-page-template>
