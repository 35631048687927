import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';

@Component({
  selector: 'app-chart-of-accounts',
  templateUrl: './chart-of-accounts-page.component.html',
  styleUrls: ['./chart-of-accounts-page.component.scss']
})
export class ChartOfAccountsPageComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  onRowClicked(event: { data: ChartOfAccountModel }): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.chartOfAccountEdit.getNavigateUrl(event.data.chartOfAccountKey, 1));
  }

  onAddButtonClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.chartOfAccountAdd.getNavigateUrl());
  }
}
