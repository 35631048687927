import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceModel } from 'src/app/shared/models/domain/invoice.model';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoices-page.component.html',
  styleUrls: ['./invoices-page.component.scss']
})
export class InvoicesPageComponent extends BasePageComponent implements OnInit {
  invoiceType: InvoiceTypesEnum = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.invoiceType = +this.getQueryParameterFromRoute(this.routingKeysUtilities.queryParams.invoiceType, this.activatedRoute.snapshot) || InvoiceTypesEnum.Invoice;

    this.activatedRoute.queryParams.subscribe(_ => {
      this.invoiceType = +this.getQueryParameterFromRoute(this.routingKeysUtilities.queryParams.invoiceType, this.activatedRoute.snapshot) || InvoiceTypesEnum.Invoice;
    });
  }

  onRowClicked(event: {data: InvoiceModel}): void {
      this.router.navigateByUrl(this.routeUtilities.routes.application.invoiceEdit.getNavigateUrl(event.data.invoiceKey));
  }

  onAddButtonClicked(): void {
      this.router.navigateByUrl(this.routeUtilities.routes.application.invoiceAdd.getNavigateUrl(this.invoiceType));
  }
}
