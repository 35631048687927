import { Component } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';
import { CustomerService } from 'src/app/core/services/domain/customer.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';

@Component({
  selector: 'app-customers-grid',
  templateUrl: './customers-grid.component.html',
  styleUrls: ['./customers-grid.component.scss']
})
export class CustomersGridComponent extends BaseGridComponent<CustomerModel> {
  constructor(public authClientService: AuthClientService,
              private businessContextService: BusinessContextService,
              private customerService: CustomerService) {
    super();
    this.gridKey = 'Customers_v1';
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: 'Name',
        field: 'name'
      },
      {
        headerName: 'Email',
        field: 'email',
      },
      {
        headerName: 'Phone #',
        field: 'phoneNumber',
      }
    ];
  }

  protected onGridReady(): void {
  }

  protected setRowData(): Observable<any[]> {
    return this.customerService.search({businessKey: this.businessContextService.currentBusiness.businessKey});
  }
}
