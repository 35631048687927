import { Component, OnInit } from '@angular/core';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { BusinessServiceRequestModel } from 'src/app/shared/models/domain/business-service-request.model';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { BusinessServiceRequestService } from 'src/app/core/services/domain/business-service-request.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { PrioritiesEnum } from 'src/app/shared/enums/app/priorities.enum';
import { BusinessServiceRequestStatusesEnum } from 'src/app/shared/enums/domain/business-service-request-statuses.enum';
import { RouteUtilities } from 'src/app/routing/route.utilities';

@Component({
  selector: 'app-business-service-request-page',
  templateUrl: './business-service-request-page.component.html',
  styleUrls: ['./business-service-request-page.component.scss']
})
export class BusinessServiceRequestPageComponent extends BaseEntryPageComponent<BusinessServiceRequestModel> implements OnInit {
  business: BusinessModel;
  statuses: EnumModel[] = [];
  isMyServiceRequest = false;
  isMyBusinessRequest = false;

  constructor(
    public authClientService: AuthClientService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private businessService: BusinessService,
    private businessServiceRequestService: BusinessServiceRequestService,

    private businessContextService: BusinessContextService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.statuses = EnumUtilities.convertToSelectModels(BusinessServiceRequestStatusesEnum, true);
    const businessKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.businessKey, this.activatedRoute.snapshot);
    const businessServiceRequestKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.businessServiceRequestKey, this.activatedRoute.snapshot);

    if (businessServiceRequestKey) {
      this.businessServiceRequestService.get(businessServiceRequestKey).subscribe(businessServiceRequest => {
        this.value = businessServiceRequest;
        this.isMyServiceRequest = this.value.requestedBy.clientKey.toLowerCase() === this.authClientService.client.clientKey.toLowerCase();
        this.isMyBusinessRequest = this.businessContextService.currentBusiness.businessKey === businessServiceRequest.business.businessKey;

        if (this.isMyServiceRequest || this.isAdminRoute()) {
          this.businessService.search({includeMyBusinessesOnly: false, businessKey}).subscribe(businesses => {
            if (businesses.length === 1) {
              this.business = businesses[0];
            }
          });
        }
      });
    } else if (businessKey) {
      this.businessService.search({includeMyBusinessesOnly: false, businessKey}).subscribe(businesses => {
        if (businesses.length === 1) {
          this.isMyServiceRequest = true;
          this.business = businesses[0];

          this.value = <BusinessServiceRequestModel>{
            businessServiceRequestKey: null,
            business: {businessKey: this.business.businessKey}
          };

          if (this.authClientService.isAuthenticated) {
            this.value.address = this.businessContextService.currentBusiness.address;
            this.value.firstName = this.authClientService.client.firstName;
            this.value.lastName = this.authClientService.client.lastName;
            this.value.email = this.businessContextService.currentBusiness.email;
            this.value.phoneNumber = this.businessContextService.currentBusiness.phoneNumber;
          }
        }
      });
    }
  }

  onSaveClicked(): void {
    this.isSaving = true;
    if (!this.value.businessServiceRequestKey) {
      this.businessServiceRequestService.create(this.value).subscribe(_ => {
        this.onSaveSuccess('Service request was sent successfully.');
        if (this.authClientService.isAuthenticated) {
          this.router.navigateByUrl(RouteUtilities.routes.application.myServiceRequests.getNavigateUrl());
        } else {
          this.router.navigateByUrl(RouteUtilities.routes.static.services.getNavigateUrl());
        }
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.businessServiceRequestService.update(this.value).subscribe(_ => {
        this.onSaveSuccess('Service request was updated successfully.');
        this.router.navigateByUrl(RouteUtilities.routes.application.businessServiceRequests.getNavigateUrl());
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }
}
