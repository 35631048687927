<mat-card class="data-card-2x">
  <div class="data-card-header">
    <div class="float-left">
      Credits vs Debits
    </div>
    <div class="float-right">

    </div>
    <div class="clear-both"></div>
  </div>
  <mat-card-content>
    <div class="mt-2 data-container" style="height: 160px;padding-left:30px">
      <canvas
        *ngIf="hasData"
        baseChart
        class="chart"
        [data]="barChartData"
        [type]="chartType"
        [options]="barChartOptions">
      </canvas>
      <div class="no-data subtle-text" *ngIf="!hasData">
        No data found.
      </div>
    </div>
  </mat-card-content>
</mat-card>
