import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent extends BaseFormComponent<CustomerModel> implements OnInit {
  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onJobAddClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.jobAdd.getNavigateUrl(this.value.customerKey));
  }

  onJobRowClicked(event): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.jobEdit.getNavigateUrl(event.data.jobKey));
  }

  onInvoicesRowClicked(event): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.invoiceEdit.getNavigateUrl(event.data.invoiceKey));
  }

  onInvoicesAddButtonClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.invoiceAdd.getNavigateUrl(InvoiceTypesEnum.Invoice, this.value.customerKey));
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      customerKey: new UntypedFormControl(this.value?.customerKey),
      businessKey: new UntypedFormControl(this.value?.businessKey),

      email: new UntypedFormControl(this.value?.email),
      name: new UntypedFormControl(this.value?.name),

      employerIdentificationNumber: new UntypedFormControl(this.value.employerIdentificationNumber),

      address: new UntypedFormGroup({
        fullAddress: new UntypedFormControl(this.value?.address?.fullAddress),
        addressLineOne: new UntypedFormControl(this.value?.address?.addressLineOne),
        addressLineTwo: new UntypedFormControl(this.value?.address?.addressLineTwo),
        city: new UntypedFormControl(this.value?.address?.city),
        state: new UntypedFormControl(this.value?.address?.state),
        postalCode: new UntypedFormControl(this.value?.address?.postalCode),
        country: new UntypedFormControl(this.value?.address?.country),

        fullAddressMailing: new UntypedFormControl(this.value?.address?.fullAddressMailing),
        addressLineOneMailing: new UntypedFormControl(this.value?.address?.addressLineOneMailing),
        addressLineTwoMailing: new UntypedFormControl(this.value?.address?.addressLineTwoMailing),
        cityMailing: new UntypedFormControl(this.value?.address?.cityMailing),
        stateMailing: new UntypedFormControl(this.value?.address?.stateMailing),
        postalCodeMailing: new UntypedFormControl(this.value?.address?.postalCodeMailing),
        countryMailing: new UntypedFormControl(this.value?.address?.countryMailing),
        isMailingAddressSame: new UntypedFormControl(this.value?.address?.isMailingAddressSame ?? false)
      }),
      phoneNumber: new UntypedFormControl(this.value?.phoneNumber)
    })
  };
}
