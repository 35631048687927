import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';

@Component({
  selector: 'app-sandbox-page',
  templateUrl: './sandbox-page.component.html',
  styleUrls: ['./sandbox-page.component.scss']
})
export class SandboxPageComponent extends BasePageComponent implements OnInit {
  data: any[] = [];
  progress: any;

  document: DocumentModel
  business: BusinessModel;

  constructor(

  ) {
    super();
  }

  ngOnInit(): void {
    this.document = <DocumentModel>{
      'documentKey': '0ee339cb-ec48-4ef0-9219-d6d5ecba481c',
      'name': 'Template',
      'isActive': true,
      'type': 9,
      'language': 0,
      'description': 'Demo Template',
      'isDeleted': false,
      'htmlContent': '<p>{{BUSINESS_LOGO}}</p>\n<p>Hello from {{BUSINESS_NAME}}!</p>\n<p>{{MAIN_CONTENT}}</p>\n<p>Some generic footer here with something or another.</p>'
    };

    // @ts-ignore
    this.business = <BusinessModel>{
      'businessKey': '856b8c93-0d5a-4380-a372-5e49b2c97be5',
      'name': 'Minneapolis Development',
      'legalName': 'Minneapolis Development LLC',
      'type': 0,
      'description': 'Web development company.',
      'isActive': true,
      'isDeleted': false,
      'address': {
        'addressLineOne': '16487 83rd Ave N',
        'state': 'MN',
        'city': 'Maple Grove',
        'postalCode': '55311',
        'country': 'United States',
        'latitude': 0,
        'longitude': 0
      },
      'file': {
        'fileKey': 'b5a52ea3-b40e-44f3-8332-807b57403ab6',
        'name': 'Minneapolis Development-w Logo.png',
        'contentType': 'image/png',
        'containerName': 'client-documents',
        'uri': 'https://divinemanagementstorage.blob.core.windows.net/client-documents/856b8c93-0d5a-4380-a372-5e49b2c97be5/b5a52ea3-b40e-44f3-8332-807b57403ab6_Minneapolis%20Development-w%20Logo.png',
        'path': '856b8c93-0d5a-4380-a372-5e49b2c97be5',
        'fullFilePath': '856b8c93-0d5a-4380-a372-5e49b2c97be5/b5a52ea3-b40e-44f3-8332-807b57403ab6_Minneapolis Development-w Logo.png'
      },
      'createdByClientKey': '129f3b24-94c6-4da5-8f93-f45aa4ee5c6e',
      'createdOn': '2022-01-02T22:47:39.0209361+00:00',
      'lastModifiedByClientKey': '129f3b24-94c6-4da5-8f93-f45aa4ee5c6e',
      'lastModifiedOn': '2022-01-12T00:33:28.4965237+00:00'
    };
  }

  onRefreshClicked(): void {

  }
}
