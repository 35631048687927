import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class ApplicationResolver {
  constructor() {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | any {

  }
}
