import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EmailDocumentModel } from 'src/app/shared/models/domain/email-document.model';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { DocumentSearchModel } from 'src/app/shared/models/domain/document-search.model';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-email-document-form',
  templateUrl: './email-document-form.component.html',
  styleUrls: ['./email-document-form.component.scss']
})
export class EmailDocumentFormComponent extends BaseFormComponent<EmailDocumentModel> implements OnInit, AfterViewInit {
  @Input() isAdmin: boolean = false;
  @Input() businessKey: string = null;

  documentTemplates: DocumentModel[] = [];
  defaultDocTemplateValue: string = null;

  constructor(
    private documentService: DocumentService
  ) {
    super();
  }

  ngOnInit(): void {
    const searchModel: DocumentSearchModel = {
      documentTypes: [DocumentTypesEnum.Template],
      businessKeys: this.isAdmin ? null : [this.businessKey]
    }

    this.documentService.search(searchModel).subscribe(documentTemplates => {
      this.documentTemplates = documentTemplates;
    })

    super.ngOnInit();
  }

  ngAfterViewInit()
  {
    this.defaultDocTemplateValue = this.getDefaultDocTemplate();
  }

  protected sanitizeValue(value: EmailDocumentModel): EmailDocumentModel {
    value.documentTemplateKey = value.documentTemplateKey === "null" ? null : value.documentTemplateKey;
    return value;
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      businessKey: new UntypedFormControl(this.value.businessKey ?? null),
      documentKey: new UntypedFormControl(this.value.documentKey ?? null),
      html: new UntypedFormControl(this.value.html ?? null),
      email: new UntypedFormGroup({
        toEmailAddress: new UntypedFormControl(this.value.email?.toEmailAddress ?? null),
        toEmailAddressAlias: new UntypedFormControl(this.value.email?.toEmailAddressAlias ?? null),
        subject: new UntypedFormControl(this.value.email?.subject ?? null)
      }),
      documentTemplateKey: new UntypedFormControl(this.value.documentTemplateKey ?? null)
    });
  }

  private readonly defaultDocTemplateKey = 'defaultDocTemplate' + this.businessKey;

  onTemplateChange($event: MatSelectChange) {
    localStorage.setItem(this.defaultDocTemplateKey, $event.value);
  }

  getDefaultDocTemplate() : string {
    return localStorage.getItem(this.defaultDocTemplateKey) ?? null;
  }
}
