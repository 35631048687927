import { Component } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { EmployeeModel } from 'src/app/shared/models/domain/employee.model';
import { EmployeeService } from 'src/app/core/services/domain/employee.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';

@Component({
  selector: 'app-employees-grid',
  templateUrl: './employees-grid.component.html',
  styleUrls: ['./employees-grid.component.scss']
})
export class EmployeesGridComponent extends BaseGridComponent<EmployeeModel> {
  constructor(public authClientService: AuthClientService,
              private businessContextService: BusinessContextService,
              private clientEmployeeService: EmployeeService) {
    super();
    this.gridKey = 'Employees_v1';
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: 'Last name',
        field: 'lastName'
      },
      {
        headerName: 'First name',
        field: 'firstName'
      },
      {
        headerName: 'Email',
        field: 'email',
      },
      {
        headerName: 'Title',
        field: 'title'
      },
      this.getDateColumn('startDate', 'Start Date'),
      this.getDateColumn('endDate', 'End Date'),
    ];
  }

  protected onGridReady(): void {
  }

  protected setRowData(): Observable<any[]> {
    return this.clientEmployeeService.search({businessKey: this.businessContextService.currentBusiness.businessKey});
  }
}
