import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { BusinessFileClassificationsEnum } from 'src/app/shared/enums/domain/business-file-classifications.enum';

@Component({
  selector: 'app-files',
  templateUrl: './files-page.component.html',
  styleUrls: ['./files-page.component.scss']
})
export class FilesPageComponent extends BasePageComponent implements OnInit {
  isAdmin = false;
  classification = BusinessFileClassificationsEnum.Flyer;
  businessKey: string;

  constructor(
    private router: Router,
    private authClientService: AuthClientService,
    private businessContextService: BusinessContextService) {
    super();
  }

  ngOnInit(): void {
    this.isAdmin = this.authClientService.isAdmin;
    this.businessKey = !this.isAdmin ? this.businessContextService.currentBusiness.businessKey : null;
  }
}
