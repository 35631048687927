import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { BusinessServiceRequestModel } from 'src/app/shared/models/domain/business-service-request.model';
import { Router } from '@angular/router';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';

@Component({
  selector: 'app-business-service-requests-page',
  templateUrl: './business-service-requests-page.component.html',
  styleUrls: ['./business-service-requests-page.component.scss']
})
export class BusinessServiceRequestsPageComponent extends BasePageComponent implements OnInit {
  businessKey: string = null;
  requestedByMe = false;

  constructor(private router: Router, private businessContextService: BusinessContextService) {
    super();
  }

  ngOnInit(): void {
    if (!this.isAdminRoute()) {
      if (this.router.url.endsWith('my-service-requests')) {
        this.requestedByMe = true;
      } else {
        this.businessKey = this.businessContextService.currentBusiness.businessKey;
      }
    }
  }

  onRowClicked(event: { data: BusinessServiceRequestModel }): void {
    if (this.isAdminRoute()) {//test
      this.router.navigateByUrl(this.routeUtilities.routes.application.admin.businessServiceRequestsEdit.getNavigateUrl(event.data.business.businessKey, event.data.businessServiceRequestKey));
    } else {
      this.router.navigateByUrl(this.routeUtilities.routes.application.serviceEdit.getNavigateUrl(event.data.business.businessKey, event.data.businessServiceRequestKey));
    }
  }
}
