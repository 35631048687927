export class ClaimsConstants {
  public static readonly authUserId = 'sub';
  public static readonly clientKey = 'http://simplidivine.com/clientkey';
  public static readonly policies = 'http://simplidivine.com/policies';
  public static readonly emailVerified = 'email_verified';
  public static readonly email = 'email';
  public static readonly firstName = 'given_name';
  public static readonly lastName = 'family_name';
  public static readonly picture = 'picture'
}
