import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-base-entry-dialog-template',
  templateUrl: './base-entry-dialog-template.component.html',
  styleUrls: ['./base-entry-dialog-template.component.scss']
})
export class BaseEntryDialogTemplateComponent<T> implements OnInit {
  @Input() entryTitle: string;
  @Input() formGroupRef: UntypedFormGroup = new UntypedFormGroup({});

  @Input() saveText: string = 'Save';
  @Input() savingText: string = 'Saving';
  @Input() isSaving: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() showFooter: boolean = true;

  @Output() saveClick = new EventEmitter();
  @Output() deleteClick = new EventEmitter();
  @Output() cancelClick = new EventEmitter();

  @Input() value: T;

  showCancel: boolean = true;
  showDelete: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.showCancel = !!this.cancelClick.observers.length;
    this.showDelete = !!this.deleteClick.observers.length;
  }

  onSaveClicked(): void {
    if (!this.formGroupRef.dirty || this.isSaving || this.isDisabled) {
      return;
    }

    this.saveClick.emit();
  }

  onCancelClicked(): void {
    if (this.isSaving || this.isDisabled) {
      return;
    }

    this.cancelClick.emit();
  }

  onDeleteClicked(): void {
    if (this.isSaving || this.isDisabled) {
      return;
    }

    this.deleteClick.emit();
  }
}
