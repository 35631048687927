import { Component } from '@angular/core';
import {
  BaseEntryPageTemplateComponent
} from 'src/app/shared/templates/base-entry-page-template/base-entry-page-template.component';

@Component({
  selector: 'app-base-entry-page-content-template',
  template: '<ng-content></ng-content>'
})
export class BaseEntryPageContentTemplateComponent<T> {
  // noinspection JSUnusedLocalSymbols
  constructor(templateEntryPageComponent: BaseEntryPageTemplateComponent<T>) {
    // THIS WILL THROW AN ERROR IF ITS NOT NESTED WITHIN PARENT
  }
}
