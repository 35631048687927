import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authClientService: AuthClientService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router) {
  }

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(tap(isAuthenticated => {
      if (!isAuthenticated) {
        this.notificationService.showErrorNotification('Login required or session expired.');
        setTimeout(_ => {
          this.router.navigateByUrl(RouteUtilities.routes.static.login.getNavigateUrl());
        }, 500);

        return false;
      } else if (!this.authClientService.isSetupCompleted()) {
        if (routerStateSnapshot.url != '/' && routerStateSnapshot.url != RouteUtilities.routes.application.profileSetup.getNavigateUrl()) {
          this.notificationService.showNotification('Complete your profile before using the application.');
          this.router.navigateByUrl(RouteUtilities.routes.application.profileSetup.getNavigateUrl());

          return false;
        }
      }

      return true
    }));
  }
}
