import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { BusinessSearchModel } from 'src/app/shared/models/domain/business-search.model';

@Injectable()
export class BusinessService {
  private url = environment.apiUrl + '/businesses/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<BusinessModel> {
    return this.http.get<BusinessModel>(this.url + key);
  }

  search(searchModel: BusinessSearchModel = {}): Observable<BusinessModel[]> {
    return this.http.post<BusinessModel[]>(this.url + 'search', searchModel);
  }

  downloadLogo(business: BusinessModel): Observable<void>{
    return this.http.get(`${this.url}${business.businessKey}/download-logo`, { observe: 'response', responseType: 'blob' }).pipe(tap((data: any) => {
      if (data.type === HttpEventType.Response) {
        saveAs(data.body, business.file?.name);
      }
    }));
  }

  create(model: BusinessModel, fileList: FileList): Observable<string> {
    return this.http.post<string>(this.url, this.buildFormData(model, fileList));
  }

  update(model: BusinessModel, fileList: FileList = null): Observable<string> {
    return this.http.put<string>(this.url, this.buildFormData(model, fileList));
  }

  delete(key: string): Observable<boolean> {
    return this.http.delete<boolean>(this.url + key);
  }

  private buildFormData(document: BusinessModel, fileList: FileList): FormData {
    const formData = new FormData();
    formData.append('model', JSON.stringify(document));

    if (fileList != null && fileList.length === 1) {
      formData.append(fileList[0].name, fileList[0]);
    }

    return formData;
  }
}
