import { Component, Inject, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { UntypedFormGroup } from '@angular/forms';
import { ReportSearchModel } from 'src/app/shared/models/domain/report-search.model';
import { ReportService } from 'src/app/core/services/domain/report.service';
import { EmailReportModel } from 'src/app/shared/models/domain/email-report.model';

@Component({
  selector: 'app-email-report-dialog',
  templateUrl: './email-report-dialog.component.html',
  styleUrls: ['./email-report-dialog.component.scss']
})
export class EmailReportDialogComponent extends BaseComponent implements OnInit {
  isAdmin: boolean = false;
  businessKey: string = null;
  value: EmailReportModel;
  formGroupRef: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private dialogRef: MatDialogRef<EmailReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {emailReport: ReportSearchModel, isAdmin: boolean, businessKey: string },
    protected notificationService: NotificationService, private reportService: ReportService) {
    super();
  }

  ngOnInit(): void {
    this.isAdmin = this.dialogData.isAdmin;
    this.businessKey = this.dialogData.businessKey;
    this.value = this.dialogData.emailReport;
  }

  onSendEmailClicked(): void {
    if (!this.formGroupRef.dirty || this.isSaving) {
      return;
    }

    this.isSaving = true;

    this.showBlockingLoader = true;
    this.reportService.email(this.formGroupRef.value).subscribe(result => {
      this.notificationService.showSuccessNotification('Email sent successfully.');
      this.showBlockingLoader = false;
      this.dialogRef.close(true);
    }, err => {
      this.onHttpFailure(this.notificationService, err);
      this.showBlockingLoader = false;
    })
  }

  onCancelClicked(): void {
    if (this.isSaving) {
      return;
    }

    this.dialogRef.close(false);
  }
}
