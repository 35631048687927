<app-base-grid-template gridTitle="Manage Rules" [showAddButton]="true" (addButtonClick)="onAddButtonClicked()"
                        (filterTextChange)="onFilterTextChanged($event)" [showDeleteButton]="true"
                        (showDeletedClick)="onShowDeletedToggled($event)" (resetGridClick)="onResetGridClicked()"
                        (exportGridClick)="onExportGridClicked()">
  <app-base-grid-content-template>
    <ag-grid-angular *ngIf="gridOptions"
                     class="ag-theme-alpine"
                     [gridOptions]="gridOptions">
    </ag-grid-angular>
  </app-base-grid-content-template>
</app-base-grid-template>



