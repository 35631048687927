import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { OfferingModel } from 'src/app/shared/models/domain/offering.model';

@Component({
  selector: 'app-offerings',
  templateUrl: './offerings-page.component.html',
  styleUrls: ['./offerings-page.component.scss']
})
export class OfferingsPageComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  onRowClicked(event: { data: OfferingModel }): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.offeringEdit.getNavigateUrl(event.data.offeringKey));
  }

  onAddButtonClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.offeringAdd.getNavigateUrl());
  }
}
