import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';

@Component({
  selector: 'app-registries',
  templateUrl: './registries-page.component.html',
  styleUrls: ['./registries-page.component.scss']
})
export class RegistriesPageComponent extends BasePageComponent implements OnInit {
  selectedBankAccount: string = null;
  bankingChartOfAccountKey: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.bankingChartOfAccountKey = this.getQueryParameterFromRoute(RoutingKeysUtilities.queryParams.bankingChartOfAccountKey, this.activatedRoute.snapshot) ?? null;
  }

  onRowClicked(event: {data: TransactionModel}): void {
      this.router.navigateByUrl(this.routeUtilities.routes.application.registryEdit.getNavigateUrl(event.data.transactionKey));
  }

  onAddButtonClicked(): void {
      this.router.navigateByUrl(this.routeUtilities.routes.application.registryAdd.getNavigateUrl(null, this.selectedBankAccount));
  }
}
