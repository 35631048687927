import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JobSearchModel } from 'src/app/shared/models/domain/job-search.model';
import { JobModel } from 'src/app/shared/models/domain/job.model';

@Injectable()
export class JobService {
  private url = environment.apiUrl + '/jobs/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<JobModel> {
    return this.http.get<JobModel>(`${this.url}${key}`);
  }

  search(model: JobSearchModel): Observable<JobModel[]> {
    return this.http.post<JobModel[]>(`${this.url}search`, model);
  }

  create(model: JobModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model)
  }

  update(model: JobModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model)
  }
}
