export class ChartOfAccountConstants {
  public static readonly banks = "Banks";
  public static readonly expenses = "Expenses";
  public static readonly creditCards = "Credit Cards";
  public static readonly longTermLiabilities = "Long Term Liabilities";
  public static readonly otherAssets = "Other Assets";
  public static readonly uncategorizedExpenses = "Uncategorized Expenses";
  public static readonly accountsReceivable = "Accounts Receivable";
  public static readonly accountsPayable = "Accounts Payable";
  public static readonly sales = "Sales";
  public static readonly products = "Services";
  public static readonly services = "Products";
  public static readonly income = "Income";
}
