import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { ActivatedRoute } from '@angular/router';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';

@Component({
  selector: 'app-business-access-page',
  templateUrl: './business-access-page.component.html',
  styleUrls: ['./business-access-page.component.scss']
})
export class BusinessAccessPageComponent extends BasePageComponent implements OnInit {
  isAdmin = false;
  businessKey: string = null;

  constructor(
    private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.businessKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.businessKey, this.activatedRoute.snapshot);

    this.isAdmin = this.isAdminRoute();
  }
}
