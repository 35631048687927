import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReconciliationModel } from 'src/app/shared/models/domain/reconciliation.model';
import { ReconciliationSearchModel } from 'src/app/shared/models/domain/reconciliation-search.model';

@Injectable()
export class ReconciliationService {
  private url = environment.apiUrl + '/reconciliations/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<ReconciliationModel> {
    return this.http.get<ReconciliationModel>(`${this.url}${key}`);
  }

  getDefault(model: ReconciliationSearchModel): Observable<ReconciliationModel> {
    return this.http.post<ReconciliationModel>(`${this.url}defaults`, model);
  }

  search(model: ReconciliationSearchModel): Observable<ReconciliationModel[]> {
    return this.http.post<ReconciliationModel[]>(`${this.url}search`, model);
  }

  create(model: ReconciliationModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model)
  }

  update(model: ReconciliationModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model)
  }

  delete(key: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}${key}`);
  }
}
