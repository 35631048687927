<mat-card class="data-card" (click)="onCardClicked()">
  <div class="data-card-header">
    <div class="float-left" >
      <div class="flex flex-start">
        <div class="flex" class="title">
          {{title}}
        </div>
        <div class="flex">
          <mat-icon matSuffix *ngIf="infoText"
                    [matTooltip]="infoText">
            help_outline
          </mat-icon>
        </div>
      </div>
    </div>
    <div class="float-right">

    </div>
    <div class="clear-both"></div>
  </div>
  <mat-card-content style="margin-top:65px">
    <div *ngIf="value !== null" class="mt-2 data-container {{class}}">
      {{value}}
    </div>
    <div *ngIf="icon" class="mt-2 data-container" style="font-size:64px">
      <i class="fa {{icon}}"></i>
    </div>
  </mat-card-content>
</mat-card>
