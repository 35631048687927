<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-content">
      <div class="mt-2">
        <ng-container formGroupName="chartOfAccount">
            <mat-form-field class="mat-form-field-double mr-2" *ngIf="chartOfAccounts">
              <mat-label>Chart of Account</mat-label>
              <input type="text"
                     [readonly]="!value.isDraft"
                     #chartOfAccountInput
                     matInput
                     placeholder="Search for chart of account . . ."
                     formControlName="chartOfAccountKey"
                     [matAutocomplete]="auto">
              <mat-autocomplete [displayWith]="chartOfAccountDisplayFn.bind(this)" autoActiveFirstOption
                                #auto="matAutocomplete">
                <mat-option *ngFor="let chartOfAccount of filteredChartOfAccounts" [value]="chartOfAccount.chartOfAccountKey">
                  {{chartOfAccount.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="value?.chartOfAccount?.chartOfAccountKey">
          <mat-form-field class="mat-form-field-date mr-1">
            <mat-label>Start Date</mat-label>
            <input [readonly]="true" [disabled]="true" matInput [matDatepicker]="startPicker" formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="mat-form-field-date mr-2">
            <mat-label>End Date</mat-label>
            <input [readonly]="!value.isDraft" (blur)="executeTransactionChange()" (dateChange)="executeTransactionChange()" [disabled]="!value?.isDraft" matInput [matDatepicker]="endPicker" formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="mat-form-field mr-1">
            <mat-label>Starting Bank Balance</mat-label>
            <input matInput type="text" [readonly]="true" currencyMask formControlName="startingBankBalance">
          </mat-form-field>
          <mat-form-field class="mat-form-field">
            <mat-label>Ending Bank Balance</mat-label>
            <input matInput type="text" [readonly]="!value?.isDraft" (blur)="onEndingBankBalanceChanged()" currencyMask formControlName="endingBankBalance">
          </mat-form-field>
        </ng-container>
      </div>
      <div *ngIf="value?.chartOfAccount?.chartOfAccountKey">
        <mat-form-field class="mat-form-field full-width">
          <mat-label>Notes</mat-label>
          <textarea
            #autosize="cdkTextareaAutosize"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="5"
            formControlName="notes">
          </textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
