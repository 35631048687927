import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-base-entry-page-template',
  templateUrl: './base-entry-page-template.component.html',
  styleUrls: ['./base-entry-page-template.component.scss']
})
export class BaseEntryPageTemplateComponent<T> implements OnInit {
  @Input() entryTitle: string;
  @Input() formGroupRef: UntypedFormGroup = new UntypedFormGroup({});

  @Input() showSave: boolean = true;
  @Input() saveText: string = 'Save';
  @Input() cancelText: string = 'Cancel';
  @Input() savingText: string = 'Saving';
  @Input() isSaving: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() showFooter: boolean = true;
  @Input() forceCancelEnabled: boolean = false;

  @Output() saveClick = new EventEmitter();
  @Output() cancelClick = new EventEmitter();

  @Input() value: T;

  showCancel: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.showCancel = !!this.cancelClick.observers.length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showFooter && !changes.showFooter.isFirstChange()) {
      // setTimeout(() => {
      //   console.log('change');
      //   window.dispatchEvent(new Event('resize'));
      // }, 0);
    }
  }

  onSaveClicked(): void {
    if (!this.formGroupRef.dirty || this.isSaving || this.isDisabled) {
      return;
    }

    this.saveClick.emit();
  }

  onCancelClicked(): void {
    if (this.isSaving || this.isDisabled) {
      return;
    }

    this.cancelClick.emit();
  }
}
