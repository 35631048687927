import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ClientModel } from 'src/app/shared/models/domain/client.model';
import { ClientSearchModel } from 'src/app/shared/models/domain/client-search.model';

@Injectable()
export class ClientService {
  private url = environment.apiUrl + '/clients/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<ClientModel> {
    return this.http.get<ClientModel>(`${this.url}${key}`);
  }

  getClientKeyByEmail(email: string): Observable<string> {
    return this.http.post<string>(`${this.url}client-key-by-email`, {email});
  }

  search(model: ClientSearchModel = {}): Observable<ClientModel[]> {
    return this.http.post<ClientModel[]>(`${this.url}search`, model);
  }

  sendVerificationEmail(clientKey: string = null): Observable<boolean> {
    let url = `${this.url}verification-email`;
    if (clientKey) {
      url += '?clientKey=' + clientKey;
    }

    return this.http.post<boolean>(`${this.url}verification-email`, {});
  }

  sendInviteEmail(clientKey: string = null): Observable<boolean> {
    return this.http.post<boolean>(`${this.url}invite-email/client/` + clientKey, {});
  }

  create(clientModel: ClientModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, clientModel)
  }

  update(clientModel: ClientModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, clientModel)
  }
}
