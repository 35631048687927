import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { TransactionService } from 'src/app/core/services/domain/transaction.service';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';
import { RoutingService } from 'src/app/core/services/app/routing.service';

@Component({
  selector: 'app-transaction-form-dialog',
  templateUrl: './transaction-form-dialog.component.html'
})
export class TransactionFormDialogComponent extends BaseEntryPageComponent<TransactionModel> {
  constructor(
    public dialogRef: MatDialogRef<TransactionFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {transactionKey: string },
    private transactionService: TransactionService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    if (this.dialogData.transactionKey) {
      this.transactionService.get(this.dialogData.transactionKey).subscribe(transaction => {
        this.value = transaction;
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  onSaveClicked(): void {
    this.isSaving = true;
    this.transactionService.update(this.value).subscribe(key => {
      this.onSaveSuccess('Transaction was updated successfully.');
      this.dialogRef.close(true);
    }, err => {
      this.onSaveFailed(err);
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
