<div>
  <app-email-report-form *ngIf="value"
                     [businessKey]="businessKey"
                     [isAdmin]="isAdmin"
                     [(formGroupRef)]="formGroupRef"
                     [(value)]="value">
  </app-email-report-form>
</div>
<div class="mt-2">
  <span class="mr-2">
    <app-save-button (click)="onSendEmailClicked()" [isDisabled]="!formGroupRef.dirty || isSaving" [isSaving]="isSaving" text="Send" processingText="Sending"></app-save-button>
  </span>
  <span class="mr-2">
    <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving">Cancel</button>
  </span>
</div>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
