import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { TaxRateModel } from 'src/app/shared/models/domain/tax-rate.model';
import { TaxRateService } from 'src/app/core/services/domain/tax-rate.service';

@Component({
  selector: 'app-tax-rate-form-dialog',
  templateUrl: './tax-rate-form-dialog.component.html'
})
export class TaxRateFormDialogComponent extends BaseEntryPageComponent<TaxRateModel> {
  taxRates: TaxRateModel[] = null;

  constructor(
    public dialogRef: MatDialogRef<TaxRateFormDialogComponent>,
    private businessContextService: BusinessContextService,
    private taxRateService: TaxRateService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.value = <TaxRateModel>{
      businessKey: this.businessContextService.currentBusiness.businessKey
    };

    this.taxRateService.search({businessKey: this.businessContextService.currentBusiness.businessKey, includeDeleted: true}).subscribe(taxRates => {
      this.taxRates = taxRates;
    });
  }

  onEditClicked(taxRate: TaxRateModel): void {
    taxRate.businessKey = this.businessContextService.currentBusiness.businessKey;
    this.formGroupRef.patchValue(taxRate);
    this.formGroupRef.markAsPristine();
    this.formGroupRef.updateValueAndValidity();
  }

  onSaveClicked(): void {
    this.isSaving = true;

    if (!this.value.taxRateKey) {
      this.taxRateService.create(this.value).subscribe(key => {
        this.onSaveSuccess('Tax rate was created successfully.');
        this.dialogRef.close(key);
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.taxRateService.update(this.value).subscribe(key => {
        this.onSaveSuccess('Tax rate was updated successfully.');
        this.dialogRef.close(this.value.taxRateKey);
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
