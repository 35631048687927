import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransactionService } from 'src/app/core/services/domain/transaction.service';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { tap } from 'rxjs/operators';
import { InvoiceService } from 'src/app/core/services/domain/invoice.service';
import { InvoiceTransactionLinkService } from 'src/app/core/services/domain/invoice-transaction-link.service';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { InvoiceModel } from 'src/app/shared/models/domain/invoice.model';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';
import { DateUtilities } from 'src/app/core/utilities/date.utilities';
import { TransactionTypesEnum } from 'src/app/shared/enums/domain/transaction-types.enum';
import { InvoiceTransactionLinkModel } from 'src/app/shared/models/domain/invoice-transaction-link.model';
import { forkJoin } from 'rxjs';
import { NumberUtilities } from 'src/app/core/utilities/number.utilities';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';

@Component({
  selector: 'app-invoice-transaction-link-dialog',
  templateUrl: './invoice-transaction-link-dialog.component.html',
  styleUrls: ['./invoice-transaction-link-dialog.component.scss']
})
export class InvoiceTransactionLinkDialogComponent extends BaseComponent implements OnInit {
  businessKey: string = null;
  transactionKey: string = null;
  transaction: TransactionModel;
  transactionTitle: string = '';
  invoiceKey: string = null;
  selectedInvoice: InvoiceModel;
  filteredInvoices: InvoiceModel[] = [];
  invoices: InvoiceModel[];
  invoiceControl: UntypedFormControl;
  formGroupRef: UntypedFormGroup;
  transactionAmount: number = 0;
  totalUnappliedAmount: number = 0;
  totalAppliedAmount: number = 0;
  isInvoice = true;
  hasModification = false;

  existingLinkedInvoices: InvoiceTransactionLinkModel[] = [];

  private trigger: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger) set content(content: MatAutocompleteTrigger) {
    if (content && !this.trigger) {
      this.trigger = content;
      this.subscriptions.add(this.trigger.panelClosingActions.subscribe(e => {
        if (!(e && e.source)) {
          this.invoiceControl.setValue(null);
          this.trigger.closePanel();
        } else {
          let invoice = this.invoices.find(t => t.invoiceKey === e.source.value)

          if (invoice) {
            if (Math.abs(this.totalUnappliedAmount) > Math.abs(invoice.total)) {
              this.formGroupRef.get('amountApplied').setValue(invoice.total);
            } else {
              this.formGroupRef.get('amountApplied').setValue(this.totalUnappliedAmount);
            }
          }
        }
      }));
    }
  }

  constructor(private dialogRef: MatDialogRef<InvoiceTransactionLinkDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private dialogData: {invoiceKey: string, transactionKey: string, businessKey: string },
              private transactionService: TransactionService,
              private notificationService: NotificationService,
              private invoiceTransactionLinkService: InvoiceTransactionLinkService,
              private invoiceService: InvoiceService,
              private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.businessKey = this.dialogData.businessKey;
    this.invoiceKey = this.dialogData.invoiceKey;
    this.transactionKey = this.dialogData.transactionKey;

    this.invoiceControl = new UntypedFormControl(null);
    this.formGroupRef = new UntypedFormGroup({
      invoiceTransactionLinkKey: new UntypedFormControl(null),
      businessKey: new UntypedFormControl(this.businessKey),
      invoice: new UntypedFormGroup({
        invoiceKey: new UntypedFormControl(null)
      }),
      transaction: new UntypedFormGroup({
        transactionKey: new UntypedFormControl(this.transactionKey)
      }),
      amountApplied: new UntypedFormControl(null)
    });

    this.initUI();

    this.subscriptions.add(this.invoiceControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredInvoices = this.invoices;
        } else {
          this.filteredInvoices = this.invoices.filter(invoice => invoice.number.toLowerCase().indexOf(value.toLowerCase()) !== -1 || invoice.customer?.name?.toLowerCase()?.indexOf(value.toLowerCase()) !== -1);
        }
      }),
    ).subscribe());
  }

  onInvoiceSelected(): void {
    this.selectedInvoice = this.invoices.find(t => t.invoiceKey === this.formGroupRef.value.invoiceKey);
  }

  onInvoiceClicked(invoiceTransactionLinkModel: InvoiceTransactionLinkModel): void {
    this.dialogRef.close(false);
    this.router.navigateByUrl(RouteUtilities.routes.application.invoiceEdit.getNavigateUrl(invoiceTransactionLinkModel.invoice.invoiceKey));
  }

  onVendorOrCustomerClicked(invoiceTransactionLinkModel: InvoiceTransactionLinkModel): void {
    this.dialogRef.close(false);

    if (invoiceTransactionLinkModel.invoice.type === InvoiceTypesEnum.Invoice && invoiceTransactionLinkModel.invoice.customer?.customerKey) {
      this.router.navigateByUrl(RouteUtilities.routes.application.customerEdit.getNavigateUrl(invoiceTransactionLinkModel.invoice.customer?.customerKey));
    } else if (invoiceTransactionLinkModel.invoice.type === InvoiceTypesEnum.Bill && invoiceTransactionLinkModel.invoice.vendor?.vendorKey) {
      this.router.navigateByUrl(RouteUtilities.routes.application.vendorEdit.getNavigateUrl(invoiceTransactionLinkModel.invoice.vendor?.vendorKey));
    }
  }

  onUnlinkTransactionClick(invoiceTransactionLinkKey: string): void {
    this.invoiceTransactionLinkService.delete(invoiceTransactionLinkKey).subscribe(t => {
      this.notificationService.showSuccessNotification('Link removed successfully.');
      this.transaction = null;
      this.invoices = null;
      this.hasModification = true;

      this.initUI();
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    })
  }

  invoiceDisplayFn(invoiceKey: string): string {
    let foundInvoice = this.invoices.find(t => t.invoiceKey === invoiceKey);

    if (!foundInvoice) {
      return '';
    }

    let displayNm = foundInvoice?.number ?? '';

    if (this.isInvoice) {
      displayNm += ' - ' + foundInvoice.customer.name;
    } else if (!this.isInvoice) {
      displayNm += ' - ' + foundInvoice.vendor.name;
    }

    displayNm += ' - ' + NumberUtilities.formatAsCurrency(foundInvoice.total);

    return displayNm;
  }

  onSaveClicked(): void {
    if (!this.businessKey || !this.formGroupRef.value.transaction?.transactionKey || !this.formGroupRef.value.invoice?.invoiceKey) {
      return;
    }

    this.isSaving = true;
    this.invoiceTransactionLinkService.create(this.formGroupRef.value).subscribe(result => {
      this.isSaving = false;

      if (result) {
        let message = 'Link saved successfully.';

        this.notificationService.showSuccessNotification(message);
        this.dialogRef.close(true);
      } else {
        this.notificationService.showErrorNotification('Link failed to be sent.');
      }
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    });
  }

  onCancelClicked(): void {
    if (this.isSaving) {
      return;
    }

    this.dialogRef.close(this.hasModification);
  }

  private initUI(): void {
    forkJoin({
      existingLinkedInvoices: this.invoiceTransactionLinkService.search({businessKey: this.businessKey, transactionKey: this.transactionKey}),
      transaction: this.transactionService.get(this.transactionKey)
    }).subscribe(result => {
      this.existingLinkedInvoices = result.existingLinkedInvoices;

      this.transaction = result.transaction;
      if (this.transaction.amount <= 0) {
        this.isInvoice = false;
      }


      let request = {businessKeys: [this.businessKey], type: (this.isInvoice ? InvoiceTypesEnum.Invoice : InvoiceTypesEnum.Bill), vendorKey: this.transaction.vendor?.vendorKey, customerKey: this.transaction.customer?.customerKey, includeFinalized: false, excludeNoBalanceDue: true};
      this.invoiceService.search(request).subscribe(invoices => {
        this.invoices = invoices;
        this.filteredInvoices = invoices;
      });

      //this.transactionTitle = DateUtilities.format(this.transaction.date) + ' - ' + this.enumUtilities.getDisplayName(TransactionTypesEnum, this.transaction.type);
      this.transactionTitle = DateUtilities.format(this.transaction.date) + ' - ' + this.transaction.description;

      if (this.transaction.vendor?.name) {
        this.transactionTitle += ' - ' + this.transaction.vendor.name;
      } else if (this.transaction.merchantName) {
        this.transactionTitle += ' - ' + this.transaction.merchantName;
      }

      this.transactionAmount = Math.abs(this.transaction.amount);
      if (this.existingLinkedInvoices.length) {
        this.totalAppliedAmount = this.existingLinkedInvoices.map(x => Math.abs(x.amountApplied)).reduce((prev, next) => prev + next);
        this.totalUnappliedAmount = Math.abs(this.transaction.amount) - this.totalAppliedAmount;
      } else {
        this.totalUnappliedAmount = Math.abs(this.transaction.amount);
      }

      this.formGroupRef.get('amountApplied').setValue(this.totalUnappliedAmount ?? this.transaction.amount);
    });
  }
}
