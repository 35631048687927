import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardSearchModel } from 'src/app/shared/models/domain/dashboard-search.model';
import { DashboardInvoiceModel } from 'src/app/shared/models/domain/dashboard-invoice.model';
import { DashboardTransactionModel } from 'src/app/shared/models/domain/dashboard-transaction.model';

@Injectable()
export class DashboardService {
  private url = environment.apiUrl + '/dashboards/';

  constructor(private http: HttpClient) {
  }


  getInvoice(searchModel: DashboardSearchModel): Observable<DashboardInvoiceModel> {
    return this.http.post<DashboardInvoiceModel>(this.url + 'invoices', searchModel);
  }

  listTransactions(searchModel: DashboardSearchModel): Observable<DashboardTransactionModel> {
    return this.http.post<DashboardTransactionModel>(this.url + 'transactions', searchModel);
  }
}
