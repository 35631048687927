<form [formGroup]="formGroupRef">
  <div class="main-container">
    <div class="left-container">
      <div>
        <mat-form-field class="mr-2">
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name">
        </mat-form-field>
        <mat-slide-toggle formControlName="isActive">Active</mat-slide-toggle>
      </div>
      <div class="mt-2">
        <mat-form-field class="mr-2">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type" (selectionChange)="onDocumentTypeChanged()">
            <mat-option *ngFor="let documentType of documentTypes"
                        [value]="documentType.value">{{documentType.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select formControlName="language">
            <mat-option *ngFor="let language of languages" [value]="language.value">{{language.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field class="text-area">
          <mat-label>Description</mat-label>
          <textarea
            #autosize="cdkTextareaAutosize"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="5"
            formControlName="description">
        </textarea>
        </mat-form-field>
      </div>
      <div class="mt-2" *ngIf="isAdmin && (value.type === documentTypeTemplate || value?.type === documentTypeInvoiceTemplate)">
<!--        <div>-->
<!--          <img *ngIf="value.file?.uri" height="300px" width="200px" [src]="value.file.uri" [alt]="value.name">-->
<!--        </div>-->
        <div>
          <span class="mr-2">
            <app-upload [label]="fileList?.length || value.file?.fileKey ? 'Replace Thumbnail' : 'Upload Thumbnail'"
                        (fileListChange)="onFilesUploaded($event)"></app-upload>
          </span>
        </div>
        <div class="subtle-text">
          200 wide x 300 high
        </div>
      </div>
    </div>
    <div class="right-container">
      <div class="flex flex-direction-column">
        <div class="editor-header-container" *ngIf="businessKey">
          <ng-container *ngIf="!documentRender">
            <div class="editor-header-left-container">
              <mat-form-field class="mr-2">
                <mat-label>Insert Token</mat-label>
                <mat-select [(value)]="selectedDocumentToken" [ngModelOptions]="{standalone: true}"
                            (selectionChange)="onDocumentTokenChanged()">
                  <mat-option value="null">(Insert Token)</mat-option>
                  <mat-option *ngFor="let documentToken of documentTokens"
                              [value]="documentToken.value">{{documentToken.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="editor-header-right-container">
              <ng-container *ngIf="value.type !== documentTypeTemplate && value?.type !== documentTypeInvoiceTemplate">
                <mat-form-field class="mr-1">
                  <mat-label>Template</mat-label>
                  <mat-select [(value)]="selectedPreviewTemplateDocumentKey" [ngModelOptions]="{standalone: true}"
                              (selectionChange)="onTemplateChange($event)">
                    <mat-option [value]="null">(No Template)</mat-option>
                    <mat-option *ngFor="let template of documentTemplates" [value]="template.documentKey">
                      <ng-container *ngIf="!template.businessKey">
                        <i class="fas fa-globe-americas"></i>
                      </ng-container>
                      <ng-container *ngIf="template.businessKey">
                        <i class="fas fa-user"></i>
                      </ng-container>
                      <span class="inline-block ml-1">{{template.name}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span class="mr-2">
                  <button matTooltip="Search Templates" mat-mini-fab color="primary" (click)="onTemplateSelectorClicked()">
                    <mat-icon>search</mat-icon>
                  </button>
                </span>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="documentRender">
            <div class="editor-header-left-container">
              &nbsp;
            </div>
          </ng-container>
          <div>
            <span class="mr-2">
              <button [matTooltip]="documentRender ? 'Edit Document' : 'Preview Document'" mat-mini-fab color="primary"
                      (click)="onPreviewClicked()">
                <mat-icon *ngIf="documentRender">edit</mat-icon>
                <mat-icon *ngIf="!documentRender">visibility</mat-icon>
              </button>
            </span>
            <ng-container *ngIf="value.type !== documentTypeTemplate && value.type !== documentTypeInvoiceTemplate">
              <span class="mr-2">
                <button matTooltip="Download Document" mat-mini-fab color="primary"
                        (click)="onDownloadRenderedClicked()">
                  <mat-icon>download</mat-icon>
                </button>
              </span>
              <span>
                <button matTooltip="Email Document" mat-mini-fab color="primary" (click)="onEmailRenderedClicked()">
                  <mat-icon>email</mat-icon>
                </button>
              </span>
            </ng-container>
          </div>
          <div class="clearfix"></div>
        </div>
        <div [hidden]="documentRender" class="editor-override">
          <editor
            *ngIf="editorConfig"
            class="mt-2"
            formControlName="htmlContent"
            [apiKey]="tinyMceLicenseKey"
            [init]="editorConfig"
            (onSelectionChange)="onEditorChanged($event)">
          </editor>
        </div>
        <div *ngIf="documentRender" class="mt-2">
          <app-document-renderer-view
            [documentRender]="documentRender">
          </app-document-renderer-view>
        </div>
      </div>
    </div>
  </div>
</form>
