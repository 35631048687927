import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  CustomerPaymentSubscriptionModel
} from 'src/app/shared/models/domain/customer-payment-subscription.model';
import { tap } from 'rxjs/operators';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';

@Injectable()
export class BusinessSubscriptionService {
  cachedSubscriptions = {};
  private url = environment.apiUrl + '/business-subscriptions/';

  subscriptionChanged$: Subject<void> = new Subject();

  constructor(private http: HttpClient, private authClientService: AuthClientService) {
  }

  hasValidSubscription(businessKey: string): Observable<boolean> {
    if (this.cachedSubscriptions[businessKey]) {
      return of(this.cachedSubscriptions[businessKey]);
    }

    //NO BUSINESS KEY WHEN RUNNING THE CREATE ROUTE
    if (businessKey === null || this.authClientService.isAdmin) {
      return of(true);
    }

    return this.http.get<boolean>(`${this.url}${businessKey}/status`).pipe(tap(result => {
      this.cachedSubscriptions[businessKey] = result;
    }));
  }

  hasAnySubscription(businessKey: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}${businessKey}/has-any-subscription`);
  }

  getSubscriptionPayment(businessKey: string): Observable<CustomerPaymentSubscriptionModel> {
    return this.http.get<CustomerPaymentSubscriptionModel>(`${this.url}${businessKey}/payment`);
  }

  createSubscription(model: CustomerPaymentSubscriptionModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.url}`, model);
  }

  updateSubscription(model: CustomerPaymentSubscriptionModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model);
  }

  deleteSubscription(businessKey: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}${businessKey}`);
  }

  clearCache(): void {
    this.cachedSubscriptions = {};
    this.subscriptionChanged$.next();
  }
}
