import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { DashboardInvoiceModel } from 'src/app/shared/models/domain/dashboard-invoice.model';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { InvoiceStatusesEnum } from 'src/app/shared/enums/domain/invoice-statuses.enum';
import { NumberUtilities } from 'src/app/core/utilities/number.utilities';
import { ColorUtilities } from 'src/app/core/utilities/color.utilities';

//https://valor-software.com/ng2-charts/
//https://www.npmjs.com/package/ng2-charts/v/4.1.1

@Component({
  selector: 'app-dashboard-invoice-status',
  templateUrl: './dashboard-invoice-status.component.html',
  styleUrls: ['./dashboard-invoice-status.component.scss', '../shared/dashboard.component.scss']
})
export class DashboardInvoiceStatusComponent implements OnInit {
  @Input() type: InvoiceTypesEnum = InvoiceTypesEnum.Invoice;
  @Input() data: DashboardInvoiceModel = null;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  hasData: boolean = false;
  isInvoice: boolean = true;

  pieChartOptions: ChartConfiguration['options'] = null;
  pieChartData: ChartData<'pie', number[], string | string[]> = null;
  chartType = 'pie';

  constructor() {
  }

  ngOnInit(): void {
    this.isInvoice = this.type === InvoiceTypesEnum.Invoice;

    if (this.isInvoice) {
      this.hasData = this.data?.draftInvoices > 0 || this.data?.issuedInvoices > 0 || this.data?.paidInvoices > 0;
    } else {
      this.hasData = this.data?.draftBills > 0 || this.data?.pendingPaymentBills > 0 || this.data?.paidBills > 0;
    }

    this.initPieChartOptions();
    this.initPieChartData();
  }

  initPieChartOptions(): void {
    let that = this;

    this.pieChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            footer: (tooltipItems) => {
              let currentLabel = tooltipItems[0].label;

              let value = 0;
              if (that.isInvoice) {
                if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Draft) === currentLabel) {
                  value = that.data.paidInvoicesTotal;
                } else if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Issued) === currentLabel) {
                  value = that.data.issuedInvoicesTotal;
                } else if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Paid) === currentLabel) {
                  value = that.data.paidInvoicesTotal;
                }
              } else {
                if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Draft) === currentLabel) {
                  value = that.data.paidBillsTotal;
                } else if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.PendingPayment) === currentLabel) {
                  value = that.data.pendingPaymentBillsTotal;
                } else if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Paid) === currentLabel) {
                  value = that.data.paidBillsTotal;
                }
              }

              return `Total: ${NumberUtilities.formatAsCurrency(value)}`
            }
          }
        }
      },
    };
  }

  initPieChartData(): void {
    let colors = ColorUtilities.listColors();
    this.pieChartData = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: []
      }]
    }

    if (this.isInvoice) {
      if (this.data.draftInvoices) {
        this.pieChartData.labels.push(EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Draft))
        this.pieChartData.datasets[0].data.push(this.data.draftInvoices)
      }

      if (this.data.issuedInvoices) {
        this.pieChartData.labels.push(EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Issued))
        this.pieChartData.datasets[0].data.push(this.data.issuedInvoices)
      }

      if (this.data.paidInvoices) {
        this.pieChartData.labels.push(EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Paid))
        this.pieChartData.datasets[0].data.push(this.data.paidInvoices)
      }
    } else {
      if (this.data.draftBills) {
        this.pieChartData.labels.push(EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Draft))
        this.pieChartData.datasets[0].data.push(this.data.draftBills)
      }

      if (this.data.pendingPaymentBills) {
        this.pieChartData.labels.push(EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.PendingPayment))
        this.pieChartData.datasets[0].data.push(this.data.pendingPaymentBills)
      }

      if (this.data.paidBills) {
        this.pieChartData.labels.push(EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Paid))
        this.pieChartData.datasets[0].data.push(this.data.paidBills)
      }
    }

    this.pieChartData.datasets[0].backgroundColor = colors;
  }
}
