import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { DashboardService } from 'src/app/core/services/domain/dashboard.service';
import { DashboardInvoiceModel } from 'src/app/shared/models/domain/dashboard-invoice.model';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { DateUtilities } from 'src/app/core/utilities/date.utilities';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { BusinessServiceRequestService } from 'src/app/core/services/domain/business-service-request.service';
import { BusinessServiceRequestStatusesEnum } from 'src/app/shared/enums/domain/business-service-request-statuses.enum';
import { Router } from '@angular/router';
import { ChartOfAccountConstants } from 'src/app/shared/constants/chart-of-account.constants';
import { NumberUtilities } from 'src/app/core/utilities/number.utilities';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { LocalStorageUtilities } from 'src/app/core/utilities/local-storage.utilities';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { BankingService } from 'src/app/core/services/domain/banking.service';
import { BankingAccountModel } from 'src/app/shared/models/domain/banking-account.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent extends BasePageComponent implements OnInit {
  business: BusinessModel
  isLoading: boolean = false;
  invoiceData: DashboardInvoiceModel;
  coaData: ChartOfAccountModel[];
  formGroupRef: UntypedFormGroup;
  invoiceTypes = InvoiceTypesEnum;
  businessServiceRequestCount = null;
  myServiceRequestCount = null;
  totalAccountsPayable = null;
  totalAccountsReceivable = null;
  transactions = null;
  bankingAccounts: {bankingAccountId: string, accountName: string, accountBalance: string }[] = null;

  constructor(
    private router: Router,
    private businessContextService: BusinessContextService,
    private dashboardService: DashboardService,
    private businessServiceRequestsService: BusinessServiceRequestService,
    private chartOfAccountService: ChartOfAccountService,
    private bankingService: BankingService,
    private dialogService: DialogService
  ) {
    super()
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.initFormGroup();
    setTimeout(() => {
      this.initData();
      this.subscriptions.add(this.formGroupRef.valueChanges.subscribe(_ => {
        this.initData();
      }));
    }, 1000);

    if (!LocalStorageUtilities.get('intro_video')) {
      const dialogRef = this.dialogService.openVideo({
        title: 'Welcome to SimpliDivine',
        description: 'This video will provide you with a brief introduction of the platform.',
        src: '/assets/videos/intro-video.mp4'
      });
      dialogRef.afterClosed().subscribe((result) => {
        localStorage.setItem('intro_video', 'true');
      });
    }
  }

  onWalkthroughClicked(): void {
    let dialogRef = this.dialogService.openVideo({
      src: '/assets/videos/complete-walkthrough.mp4',
      title: 'Complete Walkthrough',
      description: 'This video will help you understand how to utilize the application.'
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  onNavigateToBankAccountsClicked(bankingAccountId: string): void {
    this.chartOfAccountService.search({businessKey: this.businessContextService.currentBusiness.businessKey, bankingAccountIds: [bankingAccountId] }).subscribe(chartOfAccounts => {
      let foundChartOfAccountKey = (chartOfAccounts.length === 1 ? chartOfAccounts[0].chartOfAccountKey : null);
      this.router.navigateByUrl(this.routeUtilities.routes.application.registries.getNavigateUrl(foundChartOfAccountKey));
    });

  }

  onConfigureDashboardClicked(): void {
    alert('Some future ability to configure the tiles');
  }

  private initData(): void {
    this.business = this.businessContextService.currentBusiness;
    this.isLoading = true;

    this.dashboardService.getInvoice(this.formGroupRef.value).subscribe(invoiceData => {
      this.invoiceData = invoiceData;
    });

    this.dashboardService.listTransactions(this.formGroupRef.value).subscribe(transactions => {
      this.transactions = transactions;
    });

    this.businessServiceRequestsService.search({
      businessKey: this.business.businessKey,
      requestedByMe: false
    }).subscribe(data => {
      this.businessServiceRequestCount = data.filter(t => t.status !== BusinessServiceRequestStatusesEnum.Completed).length;
    });

    this.businessServiceRequestsService.search({requestedByMe: true}).subscribe(data => {
      this.myServiceRequestCount = data.filter(t => t.status !== BusinessServiceRequestStatusesEnum.Completed).length;
    });

    let chartOfAccountSearch = this.formGroupRef.value;
    chartOfAccountSearch.includeTotals = true;
    this.chartOfAccountService.search(chartOfAccountSearch).subscribe(coaData => {
      this.coaData = coaData;
      this.totalAccountsPayable = NumberUtilities.formatAsCurrency(coaData.find(t => t.name === ChartOfAccountConstants.accountsPayable)?.totalAmount ?? 0, '', false);
      this.totalAccountsReceivable = NumberUtilities.formatAsCurrency(coaData.find(t => t.name === ChartOfAccountConstants.accountsReceivable)?.totalAmount ?? 0, '', false);
    });

    this.bankingService.listAccounts(this.businessContextService.currentBusiness.businessKey).subscribe(accounts => {
      this.bankingAccounts = accounts.map(t => {
        return {bankingAccountId: t.bankingAccountId, accountName: t.displayName, accountBalance: NumberUtilities.formatAsCurrency(t.currentBalance)};
      });
    });

    setTimeout(_ => {
      this.isLoading = false;
    }, 500);
  }

  setThisMonth(): void {
    let dates = DateUtilities.getThisMonth();

    this.formGroupRef.patchValue({
      startDate: dates.startDate,
      endDate: dates.endDate
    });
  }

  setLastMonth(): void {
    let dates = DateUtilities.getLastMonth();
    this.formGroupRef.patchValue({
      startDate: dates.startDate,
      endDate: dates.endDate
    });
  }

  setThisYear(): void {
    let dates = DateUtilities.getThisYear();
    this.formGroupRef.patchValue({
      startDate: dates.startDate,
      endDate: dates.endDate
    });
  }

  setLastYear(): void {
    let dates = DateUtilities.getLastYear();

    this.formGroupRef.patchValue({
      startDate: dates.startDate,
      endDate: dates.endDate
    });
  }

  onInvoicesClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.invoices.getNavigateUrl(InvoiceTypesEnum.Invoice));
  }

  onBillsClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.invoices.getNavigateUrl(InvoiceTypesEnum.Bill));
  }

  onTransactionsClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.registries.getNavigateUrl());
  }

  onPayrollClicked(): void {
    this.dialogService.openPayroll();
  }

  onNavigateToARClicked(): void {
    let coa = this.coaData.find(t => t.name === ChartOfAccountConstants.accountsReceivable);
    this.router.navigateByUrl(this.routeUtilities.routes.application.chartOfAccountEdit.getNavigateUrl(coa.chartOfAccountKey));
  }

  onNavigateToAPClicked(): void {
    let coa = this.coaData.find(t => t.name === ChartOfAccountConstants.accountsPayable);
    this.router.navigateByUrl(this.routeUtilities.routes.application.chartOfAccountEdit.getNavigateUrl(coa.chartOfAccountKey));
  }

  onBusinessServiceRequestsClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.businessServiceRequests.getNavigateUrl());
  }

  onMyServiceRequestsClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.myServiceRequests.getNavigateUrl());
  }

  private initFormGroup(): void {
    let today = new Date();

    this.formGroupRef = new UntypedFormGroup({
      businessKey: new FormControl(this.businessContextService.currentBusiness.businessKey),
      startDate: new FormControl(new Date(today.getFullYear(), 0, 1)),
      endDate: new FormControl(new Date(today.getFullYear(), 11, 31))
    })
  }
}

