import { Component, OnDestroy } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { AuthorizationConstants } from 'src/app/shared/constants/authorization.constants';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { ValidationUtilities } from 'src/app/core/utilities/validation-utilities';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';

@Component({
  template: ''
})
export abstract class BaseComponent implements OnDestroy {
  protected subscriptions = new Subscription();
  public authorization = AuthorizationConstants;
  public isSaving: boolean = false;
  public showBlockingLoader: boolean = false;
  public enumUtilities = EnumUtilities;

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected onHttpFailure(notificationService: NotificationService, err: any[] | { message: string} | string | any): void {
    const errorMessages = ValidationUtilities.getFailureHttpResponseMessages(err);
    notificationService.showErrorNotification(errorMessages);
    this.isSaving = false;
    this.showBlockingLoader = false;
  }
}
