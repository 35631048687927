import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent extends BaseComponent {
  @Input() text: string = 'Save';
  @Input() processingText: string = 'Saving';

  @Input() isDisabled: boolean = false;
  @Input() isSaving: boolean = false;

  @Input() color: 'primary' | 'accent' = 'primary'
}
