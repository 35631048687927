<mat-card class="data-card">
  <div class="data-card-header">
    <div class="float-left">
      Quick Links
    </div>
    <div class="float-right">

    </div>
    <div class="clear-both"></div>
  </div>
  <mat-card-content>
    <div class="mt-2">
      <div class="mt-2 data-container text-left">
        <a href="javascript:void(0)" (click)="onNavigateToVendorsClicked()">Vendors</a>
      </div>
      <div class="mt-2 data-container text-left">
        <a href="javascript:void(0)" (click)="onNavigateToBillsClicked()">Bills</a>
      </div>
      <div class="mt-2 data-container text-left">
        <a href="javascript:void(0)" (click)="onNavigateToRegistriesClicked()">Registries</a>
      </div>
      <div class="mt-2 data-container text-left">
        <a href="javascript:void(0)" (click)="onNavigateToCustomersClicked()">Customers</a>
      </div>
      <div class="mt-2 data-container text-left">
        <a href="javascript:void(0)" (click)="onNavigateToInvoicesClicked()">Invoices</a>
      </div>
    </div>
  </mat-card-content>
</mat-card>
