import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent extends BaseComponent {
  content: any;

  constructor(
    public dialogRef: MatDialogRef<VideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { video: {src: string, title: string, description: string} }) {
    super();
  }

  onDoNotShowClicked(): void {
    this.dialogRef.close(true);
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
