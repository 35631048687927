import { Injectable } from '@angular/core';
import {
  CustomerFormDialogComponent
} from 'src/app/shared/components/dialogs/customer-form-dialog/customer-form-dialog.component';
import {
  VendorFormDialogComponent
} from 'src/app/shared/components/dialogs/vendor-form-dialog/vendor-form-dialog.component';
import { EmailDocumentModel } from 'src/app/shared/models/domain/email-document.model';
import {
  EmailDocumentRendererDialogComponent
} from 'src/app/shared/components/dialogs/email-document-renderer-dialog/email-document-renderer-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocumentRenderModel } from 'src/app/shared/models/domain/document-render.model';
import {
  DownloadDocumentRendererDialogComponent
} from 'src/app/shared/components/dialogs/download-document-renderer-dialog/download-document-renderer-dialog.component';
import {
  ConfirmationDialogComponent
} from 'src/app/shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  DownloadInvoiceDialogComponent
} from 'src/app/shared/components/dialogs/download-invoice-dialog/download-invoice-dialog.component';
import {
  EmailInvoiceDialogComponent
} from 'src/app/shared/components/dialogs/email-invoice-dialog/email-invoice-dialog.component';
import { EmailInvoiceModel } from 'src/app/shared/models/domain/email-invoice.model';
import {
  OfferingFormDialogComponent
} from 'src/app/shared/components/dialogs/offering-form-dialog/offering-form-dialog.component';
import {
  InvoiceTransactionLinkDialogComponent
} from 'src/app/shared/components/dialogs/invoice-transaction-link-dialog/invoice-transaction-link-dialog.component';
import {
  ManageCategoriesDialogComponent
} from 'src/app/shared/components/dialogs/manage-categories-dialog/manage-categories-dialog.component';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';
import {
  TransactionLinkDialogComponent
} from 'src/app/shared/components/dialogs/transaction-link-dialog/transaction-link-dialog.component';
import {
  TransactionBulkUpdateDialogComponent
} from 'src/app/shared/components/dialogs/transaction-bulk-update-dialog/transaction-bulk-update-dialog.component';
import {
  InvoicePaymentFormDialogComponent
} from 'src/app/shared/components/dialogs/invoice-payment-form-dialog/invoice-payment-form-dialog.component';
import { InvoicePaymentModel } from 'src/app/shared/models/domain/invoice-payment.model';
import { BusinessFileModel } from 'src/app/shared/models/domain/business-file.model';
import {
  BusinessFileFormDialogComponent
} from 'src/app/shared/components/dialogs/business-file-form-dialog/business-file-form-dialog.component';
import {
  PdfViewerDialogComponent
} from 'src/app/shared/components/dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { FileModel } from 'src/app/shared/models/domain/file.model';
import {
  TaxRateFormDialogComponent
} from 'src/app/shared/components/dialogs/tax-rate-form-dialog/tax-rate-form-dialog.component';
import {
  ChartOfAccountFormDialogComponent
} from 'src/app/shared/components/dialogs/chart-of-account-form-dialog/chart-of-account-form-dialog.component';
import { JobFormDialogComponent } from 'src/app/shared/components/dialogs/job-form-dialog/job-form-dialog.component';
import {
  TransactionFormDialogComponent
} from 'src/app/shared/components/dialogs/transaction-form-dialog/transaction-form-dialog.component';
import {
  TransactionRuleFormDialogComponent
} from 'src/app/shared/components/dialogs/transaction-rule-form-dialog/transaction-rule-form-dialog.component';
import { DocumentTypesEnum } from '../../../shared/enums/domain/document-types.enum';
import {
  TemplateSelectorDialogComponent
} from '../../../shared/components/dialogs/template-selector-dialog/template-selector-dialog.component';
import { ViewModesEnum } from 'src/app/shared/enums/app/view-modes.enum';
import { environment } from 'src/environments/environment';
import { BankingAccountModel } from 'src/app/shared/models/domain/banking-account.model';
import {
  SyncConfirmationDialogComponent
} from 'src/app/shared/components/dialogs/sync-confirmation-dialog/sync-confirmation-dialog.component';
import { ReportSearchModel } from 'src/app/shared/models/domain/report-search.model';
import {
  EmailReportDialogComponent
} from 'src/app/shared/components/dialogs/email-report-dialog/email-report-dialog.component';
import { VideoDialogComponent } from 'src/app/shared/components/dialogs/video-dialog/video-dialog.component';
import {
  NoCookiesDialogComponent
} from 'src/app/shared/components/dialogs/no-cookies-dialog/no-cookies-dialog.component';

@Injectable()
export class DialogService {
  constructor(private matDialog: MatDialog) {
  }

  openConfirmationDialog(text: string = null, okText: string = null, cancelText: string = null, height: string = '130px', width: string = '350px', extraButtonText: string = null, hideCancel: boolean = false): MatDialogRef<ConfirmationDialogComponent> {
    return this.matDialog.open(ConfirmationDialogComponent, {
      height: height,
      width: width,
      data: {
        text,
        okText,
        cancelText,
        extraButtonText,
        hideCancel
      }
    });
  }

  openNoCookiesDialog(): MatDialogRef<NoCookiesDialogComponent> {
    return this.matDialog.open(NoCookiesDialogComponent, {

    });
  }


  openSyncConfirmationDialog(bankingAccounts: BankingAccountModel[], showNavigate: boolean = false): MatDialogRef<SyncConfirmationDialogComponent> {
    return this.matDialog.open(SyncConfirmationDialogComponent, {
      height: '300px',
      width: '600px',
      data: {
        bankingAccounts: bankingAccounts,
        showNavigate: showNavigate
      }
    });
  }

  openEmailDocumentRenderer(model: EmailDocumentModel, isAdmin: boolean): void {
    this.matDialog.open(EmailDocumentRendererDialogComponent, {
      height: '400px',
      width: '525px',
      data: {
        isAdmin,
        emailDocument: model
      }
    });
  }

  openDownloadDocumentRenderer(model: DocumentRenderModel, isAdmin: boolean): void {
    this.matDialog.open(DownloadDocumentRendererDialogComponent, {
      height: '170px',
      width: '350px',
      data: {
        isAdmin,
        document: model
      }
    });
  }

  openDownloadInvoice(invoiceKey: string, businessKey: string): MatDialogRef<DownloadInvoiceDialogComponent> {
    return this.matDialog.open(DownloadInvoiceDialogComponent, {
      height: '170px',
      width: '350px',
      data: {
        invoiceKey,
        businessKey
      }
    });
  }

  openEmailInvoice(model: EmailInvoiceModel, businessKey: string): MatDialogRef<EmailInvoiceDialogComponent> {
    return this.matDialog.open(EmailInvoiceDialogComponent, {
      height: '375px',
      width: '525px',
      data: {
        businessKey,
        emailDocument: model
      }
    });
  }

  openEmailReport(model: ReportSearchModel, businessKey: string): MatDialogRef<EmailReportDialogComponent> {
    return this.matDialog.open(EmailReportDialogComponent, {
      height: '375px',
      width: '525px',
      data: {
        businessKey: businessKey,
        emailReport: model
      }
    });
  }

  openVendorForm(name: string = null): MatDialogRef<VendorFormDialogComponent> {
    return this.matDialog.open(VendorFormDialogComponent, {
      data: {
        name: name
      }
    });
  }

  openCustomerForm(name: string = null): MatDialogRef<CustomerFormDialogComponent> {
    return this.matDialog.open(CustomerFormDialogComponent, {
      data: {
        name: name
      }
    });
  }

  openOfferingForm(name: string = null): MatDialogRef<OfferingFormDialogComponent> {
    return this.matDialog.open(OfferingFormDialogComponent, {
      data: {
        name: name
      }
    });
  }

  openChartOfAccountForm(name: string = null, chartOfAccountKey: string = null, viewMode: ViewModesEnum = ViewModesEnum.All): MatDialogRef<ChartOfAccountFormDialogComponent> {
    return this.matDialog.open(ChartOfAccountFormDialogComponent, {
      data: {
        chartOfAccountKey: chartOfAccountKey,
        name: name,
        viewMode: viewMode
      }
    });
  }

  openJobForm(name: string = null): MatDialogRef<JobFormDialogComponent> {
    return this.matDialog.open(JobFormDialogComponent, {
      data: {
        name: name
      }
    });
  }

  openTransactionRule(transactionRuleKey: string = null, merchantName: string = null, vendorKey: string = null, chartOfAccountKey: string = null): MatDialogRef<TransactionRuleFormDialogComponent> {
    return this.matDialog.open(TransactionRuleFormDialogComponent, {
      data: {
        transactionRuleKey: transactionRuleKey,
        merchantName: merchantName,
        vendorKey: vendorKey,
        chartOfAccountKey: chartOfAccountKey
      }
    });
  }

  openInvoiceTransactionLink(businessKey: string, transactionKey: string = null, invoiceKey: string = null): MatDialogRef<InvoiceTransactionLinkDialogComponent> {
    return this.matDialog.open(InvoiceTransactionLinkDialogComponent, {
      height: '565px',
      width: '775px',
      data: {
        businessKey,
        transactionKey,
        invoiceKey
      }
    });
  }

  openManageCategories(businessKey: string): MatDialogRef<ManageCategoriesDialogComponent> {
    return this.matDialog.open(ManageCategoriesDialogComponent, {
      height: '400px',
      width: '600px',
      data: {
        businessKey
      }
    });
  }

  openTransactionBulkUpdate(businessKey: string, transactionKeys: string[], isTransactionView: boolean): MatDialogRef<TransactionBulkUpdateDialogComponent> {
    return this.matDialog.open(TransactionBulkUpdateDialogComponent, {
      height: '375px',
      width: '500px',
      data: {
        businessKey,
        transactionKeys,
        isTransactionView
      }
    });
  }

  openInvoicePaymentForm(invoicePayment: InvoicePaymentModel): MatDialogRef<InvoicePaymentFormDialogComponent> {
    return this.matDialog.open(InvoicePaymentFormDialogComponent, {
      height: '400px',
      width: '600px',
      data: {
        invoicePayment
      }
    });
  }

  openBusinessFileForm(businessFile: BusinessFileModel): MatDialogRef<BusinessFileFormDialogComponent> {
    return this.matDialog.open(BusinessFileFormDialogComponent, {
      height: '420px',
      width: '600px',
      data: {
        businessFile
      }
    });
  }

  openTransactionLink(businessKey: string, transactionKey: string, transaction: TransactionModel): MatDialogRef<TransactionLinkDialogComponent> {
    return this.matDialog.open(TransactionLinkDialogComponent, {
      height: '565px',
      width: '775px',
      data: {
        businessKey,
        transactionKey,
        transaction
      }
    });
  }

  openPdfViewer(file: FileModel): MatDialogRef<PdfViewerDialogComponent> {
    return this.matDialog.open(PdfViewerDialogComponent, {
      height: '800px',
      width: '1000px',
      data: {
        file,
      }
    });
  }

  openTaxRateForm(): MatDialogRef<TaxRateFormDialogComponent> {
    return this.matDialog.open(TaxRateFormDialogComponent, {
      height: '600px',
      width: '775px'
    });
  }


  openTransactionForm(transactionKey: string): MatDialogRef<TransactionFormDialogComponent> {
    return this.matDialog.open(TransactionFormDialogComponent, {
      height: '850px',
      width: '1000px',
      data: {
        transactionKey
      }
    });
  }

  openTemplateSelector(templateType: DocumentTypesEnum): MatDialogRef<TemplateSelectorDialogComponent> {
    return this.matDialog.open(TemplateSelectorDialogComponent, {
      height: '850px',
      width: '1000px',
      data: {
        templateType
      }
    });
  }

  openPayroll(): void {
    let dialogRef = this.openConfirmationDialog('You will be redirected to a 3rd party website.  Do you want to continue?', 'Continue', 'Cancel', '150px')

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        window.open(environment.payrollLink);
      }
    });
  }

  openVideo(data: {src: string, title: string, description: string}): MatDialogRef<VideoDialogComponent> {
    return this.matDialog.open(VideoDialogComponent, {
      height: '520px',
      width: '650px',
      data: {
        video: data
      }
    });
  }
}
