import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { InvoiceModel } from 'src/app/shared/models/domain/invoice.model';
import { InvoiceService } from 'src/app/core/services/domain/invoice.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { InvoiceStatusesEnum } from 'src/app/shared/enums/domain/invoice-statuses.enum';
import { RowClickedEvent } from 'ag-grid-community';
import { InvoiceTransactionLinkModel } from 'src/app/shared/models/domain/invoice-transaction-link.model';

@Component({
  selector: 'app-invoice-page',
  templateUrl: './invoice-page.component.html',
  styleUrls: ['./invoice-page.component.scss']
})
export class InvoicePageComponent extends BaseEntryPageComponent<InvoiceModel> implements OnInit {
  isAdmin: boolean = false;
  invoiceType: InvoiceTypesEnum;
  title = '';
  customerKey = null;
  vendorKey = null;
  invoiceKey = null;
  invoiceStatuses = InvoiceStatusesEnum;
  originalStatus = InvoiceStatusesEnum.Draft;
  tabTitle: string;

  constructor(private router: Router,
              private dialogService: DialogService,
              private activatedRoute: ActivatedRoute,
              private invoiceService: InvoiceService,
              private businessContextService: BusinessContextService,
              routingService: RoutingService,
              notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.isAdmin = this.isAdminRoute();
    this.invoiceKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.invoiceKey, this.activatedRoute.snapshot) ?? null;
    this.customerKey = this.getQueryParameterFromRoute(RoutingKeysUtilities.queryParams.customerKey, this.activatedRoute.snapshot) ?? null;
    this.vendorKey = this.getQueryParameterFromRoute(this.routingKeysUtilities.queryParams.vendorKey, this.activatedRoute.snapshot) ?? null;

    if (this.invoiceKey) {
      this.loadExistingInvoice();
    } else {
      this.invoiceService.getLatestNumber(this.businessContextService.currentBusiness.businessKey).subscribe(val => {
        this.invoiceType = +this.getQueryParameterFromRoute(this.routingKeysUtilities.queryParams.invoiceType, this.activatedRoute.snapshot) || InvoiceTypesEnum.Invoice;

        this.value = <InvoiceModel>{
          type: this.invoiceType,
          businessKey: this.businessContextService.currentBusiness.businessKey,
          number: val.toString(),
          vendor: {
            vendorKey: this.vendorKey ?? null
          },
          customer: {
            customerKey: this.customerKey ?? null
          }
        };

        this.tabTitle = this.invoiceService.getTypeName(this.value.type);

        if (this.value.type === InvoiceTypesEnum.Invoice) {
          this.title = 'Manage Invoice - Customer';
        } else {
          this.title = 'Manage Bill - Vendor';
        }

        this.setOriginalValue(this.value);
      });
    }
  }

  onCustomSaveClicked(finalize: boolean = false, saveAsDraft: boolean, undelete: boolean = false, saveAddNew: boolean = false, saveAddFile: boolean = false): void {
    if (this.isSaving || this.isDeleting) {
      return;
    }

    this.isSaving = true;

    let previousStatus = this.value.status;

    if (saveAsDraft) {
      this.value.status = InvoiceStatusesEnum.Draft;
    } else if (this.value.status === InvoiceStatusesEnum.Draft) {
      if (this.value.type == InvoiceTypesEnum.Bill) {
        this.value.status = InvoiceStatusesEnum.PendingPayment;
      } else {
        this.value.status = InvoiceStatusesEnum.Issued;
      }
    } else if (finalize) {
      this.value.status = InvoiceStatusesEnum.Finalized;
    } else {
      this.value.status = this.originalStatus;
    }

    if (undelete) {
      this.value.isDeleted = false;
    }

    if (!this.value.invoiceKey) {
      this.invoiceService.create(this.value).subscribe(key => {
        this.onSaveSuccess(`${this.invoiceService.getTypeName(this.value.type)} was created successfully.`);
        this.value.invoiceKey = key;
        this.reloadPage(this.router, this.routeUtilities.routes.application.invoiceEdit.getNavigateUrl(this.value.invoiceKey));
      }, err => {
        this.formGroupRef.get('status').setValue(previousStatus);
        this.onSaveFailed(err);
      });
    } else {
      this.invoiceService.update(this.value).subscribe(_ => {
        this.onSaveSuccess(`${this.invoiceService.getTypeName(this.value.type)} was updated successfully.`);
        this.reloadPage(this.router, this.routeUtilities.routes.application.invoiceEdit.getNavigateUrl(this.value.invoiceKey));
       }, err => {
        this.formGroupRef.get('status').setValue(previousStatus);
        this.onSaveFailed(err);
      });
    }
  }

  onAddNewInvoiceClicked(): void {
    this.reloadPage(this.router, this.routeUtilities.routes.application.invoiceAdd.getNavigateUrl(this.invoiceType));
  }

  onDeleteClicked(): void {
    if (this.isSaving || this.isDeleting) {
      return;
    }

    let matDialogRef = this.dialogService.openConfirmationDialog();

    matDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isDeleting = true;
        this.invoiceService.delete(this.value.invoiceKey).subscribe(result => {
          if (result) {
            this.onSaveSuccess('Invoice has been deleted successfully.');
          } else {
            this.onSaveFailed('Invoice failed to be deleted.')
          }

          this.redirectAfterSave();
        }, (err) => {
          this.onSaveFailed(err);
        });
      }
    });
  }

  onDownloadInvoiceClicked(): void {
    if (this.formGroupRef.dirty || !this.value.invoiceKey) {
      this.notificationService.showErrorNotification('Save changes before downloading.');
    } else {
      let matDialogRef = this.dialogService.openDownloadInvoice(this.value.invoiceKey, this.value.businessKey);

      matDialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.value.status === InvoiceStatusesEnum.Draft) {
            this.formGroupRef.get('status').setValue(InvoiceStatusesEnum.Issued);
          }
        }
      });
    }
  }

  onEmailInvoiceClicked(): void {
    if (this.formGroupRef.dirty || !this.value.invoiceKey) {
      this.notificationService.showErrorNotification('Save changes before emailing.');
    } else {
      let matDialogRef = this.dialogService.openEmailInvoice({invoiceKey: this.value.invoiceKey}, this.businessContextService.currentBusiness.businessKey);

      matDialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.value.status === InvoiceStatusesEnum.Draft) {
            this.formGroupRef.get('status').setValue(InvoiceStatusesEnum.Issued);
          }
        }
      });
    }
  }

  onPaymentChanged(): void {
    this.loadExistingInvoice();
  }
  onInvoiceTransactionRowClicked(event: { data: InvoiceTransactionLinkModel, event: RowClickedEvent }): void {
    if (event?.data.transaction?.transactionKey) {
      this.router.navigateByUrl(this.routeUtilities.routes.application.registryEdit.getNavigateUrl(event?.data.transaction?.transactionKey));
    }
  }

  private loadExistingInvoice(): void {
    this.invoiceService.get(this.invoiceKey).subscribe(invoice => {
      this.value = invoice;
      this.invoiceType = invoice.type;
      this.title = 'Manage ' + this.invoiceService.getTypeName(this.value.type);
      this.originalStatus = this.value.status;
      this.tabTitle = this.invoiceService.getTypeName(this.value.type);
      this.setOriginalValue(this.value);
    });
  }
}

