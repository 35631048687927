import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';

@Component({
  selector: 'app-service-request-form',
  templateUrl: './service-request-form.component.html',
  styleUrls: ['./service-request-form.component.scss']
})
export class ServiceRequestFormComponent extends BaseFormComponent<any> {
  onSubmitClicked() {
    if (!this.formGroupRef.valid) {
      return;
    }
    // TODO: send email?
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      companyName: new UntypedFormControl(null, [Validators.required]),
      address: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [Validators.required]),
      website: new UntypedFormControl(null, [Validators.required]),
      description: new UntypedFormControl(null, [Validators.required]),
    });
  }
}
