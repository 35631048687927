<div class="mb-2" style="color:red">
  Banking accounts are missing information to sync.
</div>
<div *ngFor="let bankingAccount of dialogData.bankingAccounts">
  <div *ngIf="bankingAccount.isActive && !bankingAccount.hasStartingBalance" class="mb-2">
    <div class="bold">
      {{bankingAccount.name}}
    </div>
    <div *ngIf="!bankingAccount.hasStartingBalance">
      <span>Starting balance is required.</span>
    </div>
  </div>
</div>
<hr>
<div class="mt-2">
  <span class="mr-2" *ngIf="dialogData.showNavigate">
    <button color="primary" mat-flat-button color="primary" (click)="onNavigateToBankingClicked()">Go to Banking Information</button>
  </span>
  <span>
    <button mat-flat-button color="primary" (click)="onCancelClicked()">Cancel</button>
  </span>
</div>
