export class RoutingKeysUtilities {
  //NOTE NEEDS TO STAY IN SYNC WITH ROUTE.UTILITIES.TS
  public static routeParams = {
    businessKey: 'businessKey',
    businessServiceRequestKey: 'businessServiceRequestKey',
    clientKey: 'clientKey',
    chartOfAccountKey: 'chartOfAccountKey',
    customerKey: 'customerKey',
    vendorKey: 'vendorKey',
    employeeKey: 'employeeKey',
    documentKey: 'documentKey',
    invoiceKey: 'invoiceKey',
    jobKey: 'jobKey',
    reconciliationKey: 'reconciliationKey',
    transactionKey: 'transactionKey',
    offeringKey: 'offeringKey',
    reportTypeId: 'reportKey',
    fileKey: 'fileKey',
  }

  public static queryParams = {
    documentType: 'document-type',
    invoiceType: 'invoice-type',
    reportKey: 'report-key',
    selectedTabIndex: 'selected-tab-index',
    customerKey: 'customer',
    vendorKey: 'vendor',
    chartOfAccountKey: 'chart-of-account',
    bankingChartOfAccountKey: 'banking-chart-of-account',
    bankingAccountKey: 'banking-account-key'
  }
}

