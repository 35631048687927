<app-base-entry-page-template entryTitle="Lead/Service Detail Request" [value]="value" [formGroupRef]="formGroupRef"
                              [isSaving]="isSaving" [isDisabled]="isSaving" (saveClick)="onSaveClicked()"
                              [saveText]="!value?.businessServiceRequestKey ? 'Send Request' : 'Update Request'"
                              [savingText]="!value?.businessServiceRequestKey ? 'Sending' : 'Saving'"
                              (cancelClick)="onCancelClicked()">
  <app-base-entry-page-header-template class="full-width">
    <div class="full-width flex flex-end" *ngIf="formGroupRef && formGroupRef.get('status') && value?.businessServiceRequestKey" [formGroup]="formGroupRef">
      <mat-form-field class="mr-2">
        <mat-label>Status</mat-label>
        <mat-select [disabled]="!isMyBusinessRequest" formControlName="status">
          <mat-option *ngFor="let status of statuses"
                      [value]="status.value">{{status.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </app-base-entry-page-header-template>
  <app-base-entry-page-content-template>
    <div class="main-container">
      <div class="sub-container">
        <app-business-service-request-form
          *ngIf="value"
          [(formGroupRef)]="formGroupRef"
          [(value)]="value">
        </app-business-service-request-form>
      </div>
      <div class="sub-container">
        <app-request-service-view
          [business]="business">
        </app-request-service-view>
      </div>
    </div>
  </app-base-entry-page-content-template>
</app-base-entry-page-template>
