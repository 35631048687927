import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { BusinessFileModel } from 'src/app/shared/models/domain/business-file.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-business-file-form',
  templateUrl: './business-file-form.component.html',
  styleUrls: ['./business-file-form.component.scss']
})
export class BusinessFileFormComponent extends BaseFormComponent<BusinessFileModel> implements OnInit {
  @Input() fileList: FileList;
  @Output() fileListChange = new EventEmitter<FileList>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onFilesUploaded(fileList: FileList): void {
    if (fileList.length) {
      this.fileList = fileList;
      this.fileListChange.emit(this.fileList);
      this.formGroupRef.markAsDirty();
      this.value = this.formGroupRef.value;
      this.valueChange.emit(this.value);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      businessFileKey: new UntypedFormControl(this.value?.businessFileKey),
      businessKey: new UntypedFormControl(this.value?.businessKey),
      vendorKey: new UntypedFormControl(this.value?.vendorKey),
      customerKey: new UntypedFormControl(this.value?.customerKey),
      invoiceKey: new UntypedFormControl(this.value?.invoiceKey),
      classification: new UntypedFormControl(this.value?.classification),
      name: new UntypedFormControl(this.value?.name),
      description: new UntypedFormControl(this.value?.description),
      isDeleted: new UntypedFormControl(this.value?.isDeleted ?? false),
      uploadDateTime: new UntypedFormControl(this.value?.uploadDateTime),
      file: new UntypedFormGroup({
        fileKey: new UntypedFormControl(this.value?.file?.fileKey),
        name: new UntypedFormControl(this.value?.file?.name),
        contentType: new UntypedFormControl(this.value?.file?.contentType),
        uri: new UntypedFormControl(this.value?.file?.uri)
      })
    });
  }
}

