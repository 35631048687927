import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';

@Component({
  selector: 'app-customers',
  templateUrl: './customers-page.component.html',
  styleUrls: ['./customers-page.component.scss']
})
export class CustomersPageComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  onRowClicked(event: { data: CustomerModel }): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.customerEdit.getNavigateUrl(event.data.customerKey));
  }

  onAddButtonClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.customerAdd.getNavigateUrl());
  }
}
