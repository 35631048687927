<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-header">{{value?.taxRateKey ? 'Edit' : 'Create'}} Tax Rate</div>
    <div class="form-section-content">
      <div class="mt-2">
        <mat-form-field class="mat-form-field mr-2">
          <mat-label>Name</mat-label>
          <input matInput type="text" maxlength="100" formControlName="name">
        </mat-form-field>
        <mat-form-field class="mat-form-field mr-2">
          <mat-label>Agency Name</mat-label>
          <input matInput type="text" maxlength="100" formControlName="name">
        </mat-form-field>
        <mat-form-field class="mat-form-field-smaller mr-2">
          <mat-label>Tax Rate</mat-label>
          <input matInput type="text" currencyMask formControlName="rate" [options]="{ prefix: '%', precision: 3 }"
                 min="0">
        </mat-form-field>
        <mat-slide-toggle formControlName="isDeleted">Deleted</mat-slide-toggle>
      </div>
    </div>
  </div>
</form>
