import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';
import { TransactionService } from 'src/app/core/services/domain/transaction.service';
import { TransactionTypesEnum } from 'src/app/shared/enums/domain/transaction-types.enum';
import { Router } from '@angular/router';
import { flatMap, tap } from 'rxjs/operators';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { TransactionSearchModel } from 'src/app/shared/models/domain/transaction-search.model';

@Component({
  selector: 'app-transaction-link-dialog',
  templateUrl: './transaction-link-dialog.component.html',
  styleUrls: ['./transaction-link-dialog.component.scss']
})
export class TransactionLinkDialogComponent extends BaseComponent implements OnInit {
  businessKey: string = null;
  transactionKey: string = null;
  transaction: TransactionModel;
  transactionTypes = TransactionTypesEnum;

  matchedTransactions: TransactionModel[] = null;

  constructor(private dialogRef: MatDialogRef<TransactionLinkDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private dialogData: {transactionKey: string, businessKey: string, transaction: TransactionModel },
              private transactionService: TransactionService,
              private notificationService: NotificationService,
              private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.showBlockingLoader = true;
    this.businessKey = this.dialogData.businessKey;
    this.transaction = this.dialogData.transaction;
    this.transactionKey = this.dialogData.transactionKey;

    if (this.transaction) {
      let request: TransactionSearchModel = {businessKeys: [this.businessKey], isRegistry: !this.transaction.isRegistry, includeMatchedTransactions: true, amount: this.transaction.amount };

      if (this.transaction.bankingChartOfAccount?.chartOfAccountKey) {
        request.bankingChartOfAccountKeys = [this.transaction.bankingChartOfAccount.chartOfAccountKey];
      }

      this.transactionService.search(request)
        .pipe(tap((matchedTransactions: TransactionModel[]) => {
          this.matchedTransactions = matchedTransactions;
          this.showBlockingLoader = false;
        })).subscribe();
    } else if (this.transactionKey) {
      this.transactionService.get(this.transactionKey).pipe(tap((transaction: TransactionModel) => {
        this.transaction = transaction;
      }), flatMap(_ => {
        return this.transactionService.search({businessKeys: [this.businessKey], isRegistry: !this.transaction.isRegistry, includeMatchedTransactions: true, amount: this.transaction.amount})
          .pipe(tap((matchedTransactions: TransactionModel[]) => {
            this.matchedTransactions = matchedTransactions;
            this.showBlockingLoader = false;
          }));
      })).subscribe();
    }
  }

  onLinkClicked(matchedTransaction: TransactionModel): void {
    this.dialogRef.close(matchedTransaction);
  }

  onTransactionInfoClicked(matchedTransaction: TransactionModel): void {
    this.dialogRef.close(false);
    if (matchedTransaction.isRegistry) {
      this.router.navigateByUrl(RouteUtilities.routes.application.registryEdit.getNavigateUrl(matchedTransaction.transactionKey));
    } else {
      this.router.navigateByUrl(RouteUtilities.routes.application.transactionEdit.getNavigateUrl(matchedTransaction.transactionKey));
    }
  }

  onCancelClicked(): void {
    if (this.isSaving) {
      return;
    }

    this.dialogRef.close(false);
  }
}
