import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard-page.component.html',
  styleUrls: ['./admin-dashboard-page.component.scss']
})
export class AdminDashboardPageComponent extends BasePageComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
