import { Component } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { OfferingModel } from 'src/app/shared/models/domain/offering.model';
import { OfferingService } from 'src/app/core/services/domain/offering.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { OfferingTypesEnum } from 'src/app/shared/enums/domain/offering-types.enum';
import { OfferingServiceFrequencyEnum } from 'src/app/shared/enums/domain/offering-service-frequency.enum';
import { DialogService } from 'src/app/core/services/domain/dialog.service';

@Component({
  selector: 'app-offerings-grid',
  templateUrl: './offerings-grid.component.html',
  styleUrls: ['./offerings-grid.component.scss']
})
export class OfferingsGridComponent extends BaseGridComponent<OfferingModel> {
  constructor(public authClientService: AuthClientService,
              private businessContextService: BusinessContextService,
              private offeringService: OfferingService,
              private dialogService: DialogService) {
    super();
    this.gridKey = 'Offerings_v1';
  }

  onManageOfferingCategoriesClicked(): void {
    let matDialogRef = this.dialogService.openManageCategories(this.businessContextService.currentBusiness.businessKey);
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: 'Name',
        field: 'name'
      },
      this.getEnumColumn('type', 'Type', OfferingTypesEnum),
      {
        headerName: 'Category',
        field: 'category',
      },
      {
        headerName: 'SKU',
        field: 'sku',
      },

      this.getCurrencyColumn('price', 'Price'),
      this.getYesNoColumn('isTaxable', 'Taxable'),
      //this.getNumberColumn('inventoryTotal', 'Inventory Total'),
      this.getEnumColumn('frequency', 'Frequency', OfferingServiceFrequencyEnum),
      {
        headerName: 'Description',
        field: 'description'
      },
    ];
  }

  protected onGridReady(): void {
  }

  protected setRowData(): Observable<any[]> {
    return this.offeringService.search({businessKey: this.businessContextService.currentBusiness.businessKey, includeDeleted: this.includeDeleted});
  }
}
