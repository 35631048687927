<app-base-page-template>
  <app-base-page-content-template>
    <div class="main-container">
      <div [formGroup]="formGroupRef">
        <div class="float-left mt-2">
          <mat-form-field class="mat-form-field-date mr-2">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startDtPicker" formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDtPicker"></mat-datepicker-toggle>
            <mat-datepicker #startDtPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="mat-form-field-date mr-2">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="endDtPicker" formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="endDtPicker"></mat-datepicker-toggle>
            <mat-datepicker #endDtPicker></mat-datepicker>
          </mat-form-field>

          <div class="inline-block">
            <mat-chip-list>
              <mat-chip (click)="setThisMonth()">This Month</mat-chip>
              <mat-chip (click)="setLastMonth()">Last Month</mat-chip>
              <mat-chip (click)="setThisYear()">This Year</mat-chip>
              <mat-chip (click)="setLastYear()">Last Year</mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div class="float-right">
<!--          <mat-icon class="cursor-pointer mt-3" title="Configure Dashboard" (click)="onConfigureDashboardClicked()">-->
<!--            settings-->
<!--          </mat-icon>-->
        </div>
      </div>
      <div class="dashboard-container" *ngIf="!isLoading">
        <div>
          <app-dashboard-quick-links></app-dashboard-quick-links>
        </div>
        <div (click)="onTransactionsClicked()">
          <app-dashboard-transactions *ngIf="transactions" [data]="transactions"></app-dashboard-transactions>
        </div>
        <div (click)="onNavigateToARClicked()">
          <app-dashboard-hero-number class="font-size-32" *ngIf="totalAccountsPayable !== null" title="Accounts Receivable" infoText="Accounts receivable (AR) is the balance of money due to a firm for goods or services delivered or used but not yet paid for by customers. Accounts receivable is listed on the balance sheet as a current asset. Any amount of money owed by customers for purchases made on credit is AR." [value]="totalAccountsReceivable"></app-dashboard-hero-number>
        </div>
        <div (click)="onNavigateToAPClicked()">
          <app-dashboard-hero-number class="font-size-32" *ngIf="totalAccountsPayable !== null" title="Accounts Payable" infoText="Account Payable (AP) refer to the obligations incurred by a company during its operations. It include payables items supplier, invoices, legal & vendor.  Unpaid expenses." [value]="totalAccountsPayable"></app-dashboard-hero-number>
        </div>
        <div (click)="onInvoicesClicked()">
          <app-dashboard-invoice-status *ngIf="invoiceData" [type]="invoiceTypes.Invoice" [data]="invoiceData"></app-dashboard-invoice-status>
        </div>
        <div (click)="onBillsClicked()">
          <app-dashboard-invoice-status *ngIf="invoiceData"  [type]="invoiceTypes.Bill" [data]="invoiceData"></app-dashboard-invoice-status>
        </div>
        <div (click)="onBusinessServiceRequestsClicked()">
          <app-dashboard-hero-number  class="font-size-66" *ngIf="businessServiceRequestCount !== null" title="Lead/Service Requests" [value]="businessServiceRequestCount"></app-dashboard-hero-number>
        </div>
        <div (click)="onMyServiceRequestsClicked()">
          <app-dashboard-hero-number  class="font-size-66" *ngIf="myServiceRequestCount !== null" title="My Lead/Service Requests" [value]="myServiceRequestCount"></app-dashboard-hero-number>
        </div>
        <div (click)="onWalkthroughClicked()">
          <app-dashboard-hero-number title="Complete Walkthrough" icon="fa fa-video"></app-dashboard-hero-number>
        </div>
      </div>
      <div class="dashboard-container" *ngIf="!isLoading">
        <div (click)="onNavigateToBankAccountsClicked(bankingAccount.bankingAccountId)" *ngFor="let bankingAccount of bankingAccounts">
          <app-dashboard-hero-number class="font-size-32"  [title]="bankingAccount.accountName" [value]="bankingAccount.accountBalance"></app-dashboard-hero-number>
        </div>
      </div>
    </div>
  </app-base-page-content-template>
</app-base-page-template>
