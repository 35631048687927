<ng-container *ngIf="transaction && invoices">
  <form [formGroup]="formGroupRef">
    <div class="form-section">
      <div class="bold">
        Transaction: {{transactionTitle}}
      </div>
      <div class="bold">
        <span class="mr-2">Transaction Total: {{transactionAmount ?? 0 | currencyFormatter}}</span>
        <span class="mr-2">Unapplied Total: {{totalUnappliedAmount ?? 0 | currencyFormatter}}</span>
        <span>Applied Total: {{totalAppliedAmount ?? 0 | currencyFormatter}}</span>
      </div>
      <div class="mt-3">
        <ng-container formGroupName="invoice">
          <mat-form-field class="mat-form-field-double mr-2" *ngIf="invoices">
            <mat-label>{{isInvoice ? 'Invoice' : 'Bill'}} to Link</mat-label>
            <input type="text"
                   matInput
                   placeholder="Search for {{isInvoice ? 'invoice' : 'bill'}} . . ."
                   formControlName="invoiceKey"
                   [matAutocomplete]="auto">
            <mat-autocomplete [displayWith]="invoiceDisplayFn.bind(this)" (optionSelected)="onInvoiceSelected()"
                              autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let invoice of invoices" [value]="invoice.invoiceKey">
                {{invoice?.number}}
                <span *ngIf="isInvoice">{{invoice?.customer ? ' - ' + invoice?.customer.name : ''}}</span>
                <span *ngIf="!isInvoice">{{invoice?.vendor ? ' - ' + invoice?.vendor.name : ''}}</span>
                <span> - {{invoice?.total | currencyFormatter}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </ng-container>
        <mat-form-field class="mat-form-field-small mr-2">
          <mat-label>Amount to Apply</mat-label>
          <input matInput type="text" currencyMask formControlName="amountApplied"
                 [options]="{ prefix: '$', precision: '2' }">
        </mat-form-field>
      </div>
      <div>
        <span *ngIf="selectedInvoice"
              class="subtle-text">{{isInvoice ? 'Invoice' : 'Bill'}} Total: {{selectedInvoice.total | currencyFormatter}}</span>
      </div>
    </div>
  </form>
  <div class="mt-2">
    <span class="mr-2">
      <app-save-button (click)="onSaveClicked()"
                       [isDisabled]="!formGroupRef.get('invoice').get('invoiceKey').value || isSaving"
                       [isSaving]="isSaving" text="Link {{isInvoice ? 'Invoice' : 'Bill'}}" processingText="Saving"></app-save-button>
    </span>
    <span class="mr-2">
      <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving">Cancel</button>
    </span>
  </div>

  <div class="mt-5">
    <div class="form-section-header mb-2">Linked {{isInvoice ? 'Invoices' : 'Bills'}}</div>
    <ng-container *ngIf="existingLinkedInvoices?.length">
      <div class="flex-full-auto text-center">
        <table class="full-width">
          <thead>
          <th style="width:20px"></th>
          <th>
            {{isInvoice ? 'Invoice' : 'Bill'}} #
          </th>
          <th>
            {{isInvoice ? 'Invoice' : 'Bill'}} Date
          </th>
          <th>
            {{isInvoice ? 'Invoice' : 'Bill'}} Due Date
          </th>
          <th>
            {{isInvoice ? 'Customer' : 'Vendor'}}
          </th>
          <th>
            {{isInvoice ? 'Invoice' : 'Bill'}} Amount
          </th>
          <th>
            Applied Amount
          </th>
          </thead>
          <tbody>
          <tr *ngFor="let existingLinkedInvoice of existingLinkedInvoices">
            <td style="width: 20px;vertical-align: middle">
              <mat-icon class="cursor-pointer" title="Unlink from Transaction" (click)="onUnlinkTransactionClick(existingLinkedInvoice.invoiceTransactionLinkKey)">link_off</mat-icon>
            </td>
            <td>
              <a href="javascript:void(0)" (click)="onInvoiceClicked(existingLinkedInvoice)">{{existingLinkedInvoice.invoice.number}}</a>
            </td>
            <td>
              {{existingLinkedInvoice.invoice.date | dateFormatter}}
            </td>
            <td>
              {{existingLinkedInvoice.invoice.dueDate | dateFormatter}}
            </td>
            <td>
              <a href="javascript:void(0)" (click)="onVendorOrCustomerClicked(existingLinkedInvoice)">{{isInvoice ? existingLinkedInvoice.invoice.customer?.name : existingLinkedInvoice.invoice.vendor?.name}}</a>
            </td>
            <td>
              {{existingLinkedInvoice.invoice.total | currencyFormatter}}
            </td>
            <td>
              {{existingLinkedInvoice.amountApplied | currencyFormatter}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <ng-container *ngIf="!existingLinkedInvoices?.length">
      <div class="text-center">
        No linked {{isInvoice ? 'invoices' : 'bills'}} found.
      </div>
    </ng-container>
  </div>
</ng-container>
