import {
  HttpBackend,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingUtilities } from 'src/app/routing/routing.utilities';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private notificationService: NotificationService, private httpBackend: HttpBackend, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getAccessTokenSilently().pipe(catchError(err => {
      //RE-FIRES THE REQUEST WITHOUT THE INTERCEPTOR AS ITS AN UNAUTHENTICATED ROUTE (SEE SERVICES)
      if (!RoutingUtilities.isApplicationRoute()) {
        return this.httpBackend.handle(request).pipe(catchError(err => {
          return this.handleError(err);
        }));
      }

      if (err.error == 'login_required') {
        this.notificationService.showErrorNotification('Login required, redirecting . . .');
        setTimeout(_ => {
          this.router.navigateByUrl(RouteUtilities.routes.static.login.getNavigateUrl());
        }, 2000)
      }

      return throwError(err);
    }), mergeMap(token => {
      let headers: HttpHeaders = request.headers || new HttpHeaders();

      if (token) {
        headers = headers.append('Authorization', `Bearer ${token}`);
      }

      request = request.clone({
        headers,
        url: request.url
      })

      return next.handle(request).pipe(map((response: HttpEvent<any>) => {
        return response;
      }), catchError(err => {
        return this.handleError(err);
      }))
    }));
  }

  private handleError(err): Observable<never> {
    if (err.status === 422) {
      return throwError(err.error);
    } else if (err.status === 402) {
      this.notificationService.showErrorNotification('The subscription for your business is no longer valid.');
      this.router.navigateByUrl(RouteUtilities.routes.application.subscriptions.getNavigateUrl());
      return throwError('The subscription for your business is no longer valid.');
    } else if ([401, 403].indexOf(err.status) !== -1) {
      this.notificationService.showErrorNotification('You are not authorized to view this resource.');
      return throwError('You are not authorized to view this resource.');
    } else if (err.status === 500) {
        return throwError('A server error has occurred.  Please refresh the page and try again.  If the problem persists contact support.');
    } else if (!err.status) {
      this.notificationService.showErrorNotification('An network connectivity error has occurred.');
      return throwError('An network connectivity error has occurred.')
    }

    this.notificationService.showErrorNotification('A unknown error has occurred.  Please refresh the page and try again.  If the problem persists contact support.');
    return throwError('A unknown error has occurred.  Please refresh the page and try again.  If the problem persists contact support.')
  }
}
