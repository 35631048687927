export class TransactionSearchModel {
  vendorKey?: string;
  customerKey?: string;
  invoiceKey?: string;
  businessKeys?: string[];
  chartOfAccountKeys?: string[];
  isRegistry?: boolean;
  amount?: number;
  startDate?: string;
  endDate?: string;
  startingBankBalance?: number;
  hasReconciliation?: boolean;
  reconciliationKey?: string;
  bankingChartOfAccountKeys?: string[];
  includeMatchedTransactions?: boolean = false;
  includeDeleted?: boolean = false;
}
