import { formatCurrency } from '@angular/common';

export class NumberUtilities {
  static formatAsCurrency(value: number, code: string = '', includeDecimal = true): string {
    return formatCurrency(value || 0, 'en-US', (code === 'USD' || code === '' ? '$' : code), null, includeDecimal ? null : '1.0-0');
  }

  static formatAsPercentage(value: number, digits: number = 0): string {
    if (!value) {
      return null;
    }

    return `%${value.toFixed(digits)}`;
  }
}
