<mat-card class="data-card">
  <div class="data-card-header">
    <div class="float-left">
      Chart of Accounts
    </div>
    <div class="float-right">

    </div>
    <div class="clear-both"></div>
  </div>
  <mat-card-content>
    <div class="mt-2 data-container" >
      <canvas
        baseChart
        class="chart"
        [options]="doughnutChartOptions"
        [data]="doughnutChartData"
        [type]="chartType">
      </canvas>
      <div class="no-data subtle-text" *ngIf="!hasData">
        No data found.
      </div>
    </div>
  </mat-card-content>
</mat-card>
