import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @Input() content: {src: string, title: string, description: string};
  @Input() autoPlay: boolean = false;
  @Output() cancelClick = new EventEmitter<void>()

  showCancel: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.showCancel = !!this.cancelClick?.observers?.length ?? false;
  }

  onCancelClicked(): void {
    this.cancelClick.emit();
  }
}
