import { AfterContentInit, AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/core/guards/pending-changes.guard';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { MatTab, MatTabGroup, MatTabHeader } from '@angular/material/tabs';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';

@Component({
  template: ''
})
export abstract class BaseEntryPageComponent<T> extends BasePageComponent implements ComponentCanDeactivate, AfterViewInit {
  @ViewChild(MatTabGroup) tabs: MatTabGroup;

  value: T;
  formGroupRef: UntypedFormGroup = new UntypedFormGroup({});
  isSaving: boolean = false;
  isDeleting: boolean = false;

  originalValue: T;
  selectedTabIndex = 0;

  protected constructor(protected notificationService: NotificationService, protected routingService: RoutingService, protected pendingChangesService: PendingChangesService) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.tabs) {
      this.tabs._handleClick = this.interceptTabChange.bind(this);
    }
  }

  setOriginalValue(model: T): void {
    this.originalValue = JSON.parse(JSON.stringify(model));
  }

  onCancelClicked(): void {
    this.routingService.navigateToPreviousRoute();
  }

  protected redirectAfterSave(): boolean {
    return this.routingService.navigateToPreviousRoute();
  }

  protected onSaveSuccess(message: string = null): void {
    this.formGroupRef.markAsPristine();

    if (message) {
      this.notificationService.showSuccessNotification(message);
    }

    this.isSaving = false;
    this.isDeleting = false;
  }

  protected onSaveFailed(err: any[] | { message: string} | string): void {
    this.onHttpFailure(this.notificationService, err);

    this.isSaving = false;
    this.isDeleting = false;
  }

  canDeactivate(nextUrl: string, router: Router, dialogService: DialogService): Observable<boolean> | boolean {
    if (this.formGroupRef.dirty) {
      let matDialogRef = dialogService.openConfirmationDialog('You have unsaved changes, do you want to continue?', 'Yes', 'No', '130px', '400px');
      return matDialogRef.afterClosed();
    }

    return true;
  }

  private interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    if (this.selectedTabIndex === 0 && this.formGroupRef.dirty && idx !== this.selectedTabIndex) {
      this.pendingChangesService.showPendingChanges$.next();
      this.subscriptions.add(this.pendingChangesService.pendingChangesResult$.subscribe(result => {
        if (result) {
          this.value = this.originalValue;
          MatTabGroup.prototype._handleClick.apply(this.tabs, arguments);
        }
      }));
    } else {
      MatTabGroup.prototype._handleClick.apply(this.tabs, arguments);
    }
  }
}

