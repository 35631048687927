import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { TransactionService } from 'src/app/core/services/domain/transaction.service';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { VendorService } from 'src/app/core/services/domain/vendor.service';
import { tap } from 'rxjs/operators';
import { TransactionTypesEnum } from 'src/app/shared/enums/domain/transaction-types.enum';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';

@Component({
  selector: 'app-transaction-bulk-update-dialog',
  templateUrl: './transaction-bulk-update-link-dialog.component.html',
  styleUrls: ['./transaction-bulk-update-dialog.component.scss']
})
export class TransactionBulkUpdateDialogComponent extends BaseComponent implements OnInit {
  private chartOfAccountTrigger: MatAutocompleteTrigger;
  @ViewChild('chartOfAccountInput', { read: MatAutocompleteTrigger }) set chartOfAccountContent(content: MatAutocompleteTrigger) {
    if (content && !this.chartOfAccountTrigger) {
      this.chartOfAccountTrigger = content;
      this.subscriptions.add(this.chartOfAccountTrigger.panelClosingActions.subscribe(e => {
        if (!(e && e.source)) {
          this.chartOfAccountKeyControl.setValue(null);
          this.chartOfAccountTrigger.closePanel();
        }
      }));
    }
  }

  private vendorTrigger: MatAutocompleteTrigger;
  @ViewChild('vendorInput', { read: MatAutocompleteTrigger }) set vendorContent(content: MatAutocompleteTrigger) {
    if (content && !this.vendorTrigger) {
      this.vendorTrigger = content;
      this.subscriptions.add(this.vendorTrigger.panelClosingActions.subscribe(e => {
        if (!(e && e.source)) {
          this.vendorKeyControl.setValue(null);
          this.vendorTrigger.closePanel();
        }
      }));
    }
  }

  formGroupRef: UntypedFormGroup;

  isDirty = false;
  transactionTypes: EnumModel[];
  chartOfAccount: ChartOfAccountModel;
  vendor: VendorModel;

  filteredVendors: VendorModel[] = [];
  vendors: VendorModel[];
  vendorKeyControl: UntypedFormControl;

  filteredChartOfAccounts: ChartOfAccountModel[] = [];
  chartOfAccounts: ChartOfAccountModel[];
  chartOfAccountKeyControl: UntypedFormControl;

  constructor(
    private dialogRef: MatDialogRef<TransactionBulkUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { businessKey: string, transactionKeys: string[], isTransactionView: boolean },
    protected notificationService: NotificationService,
    private transactionService: TransactionService,
    private chartOfAccountService: ChartOfAccountService,
    private vendorService: VendorService) {
    super();
  }

  ngOnInit(): void {
    this.formGroupRef = this.getFormGroup();

    this.transactionTypes = EnumUtilities.convertToSelectModels(TransactionTypesEnum, true);
    this.transactionTypes[0].label = '';

    this.vendorService.search({businessKey: this.dialogData.businessKey}).subscribe(vendors => {
      this.vendors = vendors;
      this.filteredVendors = vendors;
    });

    this.chartOfAccountService.search({businessKey: this.dialogData.businessKey}).subscribe(chartOfAccounts => {
      this.chartOfAccounts = this.chartOfAccountService.filterToTransactionAccounts(chartOfAccounts);
      this.filteredChartOfAccounts = this.chartOfAccountService.filterToTransactionAccounts(chartOfAccounts);
    });

    this.subscriptions.add(this.vendorKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredVendors = this.vendors;
        } else {
          this.filteredVendors = this.vendors.filter(vendor => vendor.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        }
      })
    ).subscribe());

    this.subscriptions.add(this.chartOfAccountKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredChartOfAccounts = this.chartOfAccounts;
        } else {
          this.filteredChartOfAccounts = this.chartOfAccounts.filter(coa => coa.fullDisplayPath.toLowerCase()
            .indexOf(value.toLowerCase()) !== -1);
        }
      })
    ).subscribe());
  }

  onUpdateClicked(): void {
    this.showBlockingLoader = true;

    let value = this.formGroupRef.value;

    this.transactionService.updateBulk(this.formGroupRef.value).subscribe(_ => {
      this.notificationService.showSuccessNotification('Bulk update was successful.');
      this.showBlockingLoader = false;
      this.dialogRef.close(true);
    }, err => {
      super.onHttpFailure(this.notificationService, err);
      this.showBlockingLoader = false;
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }

  vendorDisplayFn(vendorKey: string): string {
    return this.vendors.find(t => t.vendorKey === vendorKey)?.name ?? '';
  }

  chartOfAccountDisplayFn(chartOfAccountKey: string): string {
    let foundResult = this.chartOfAccounts?.find(t => t.chartOfAccountKey === chartOfAccountKey);
    return foundResult?.fullDisplayPath ?? '';
  }

  protected getFormGroup(): UntypedFormGroup {
    this.vendorKeyControl = new UntypedFormControl(null);
    this.chartOfAccountKeyControl = new UntypedFormControl(null);

    const formGroup = new UntypedFormGroup({
      transactionKeys: new UntypedFormControl(this.dialogData.transactionKeys),
      businessKey: new UntypedFormControl(this.dialogData.businessKey),
      chartOfAccountKey: this.chartOfAccountKeyControl,
      type: new UntypedFormControl(TransactionTypesEnum.Unknown),
      addToRegistry: new UntypedFormControl(false),
      vendorKey: this.vendorKeyControl
    });

    return formGroup;
  };
}
