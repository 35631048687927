<app-base-entry-page-template [entryTitle]="title" [value]="value" [formGroupRef]="formGroupRef"
                              [isSaving]="isSaving" [isDisabled]="isSaving || isDeleting"
                              [showSave]="false"
                              (saveClick)="onCustomSaveClicked(false, false)" [showFooter]="selectedTabIndex === 0">
  <app-base-entry-page-header-template>
    <button class="inline-block" mat-mini-fab color="primary" (click)="onAddNewInvoiceClicked()">
      <mat-icon>add</mat-icon>
    </button>
  </app-base-entry-page-header-template>
  <app-base-entry-page-content-template>
    <div>
      <mat-tab-group #matTabGroup [(selectedIndex)]="selectedTabIndex">
        <mat-tab [label]="tabTitle">
          <app-invoice-form
            *ngIf="value && selectedTabIndex === 0"
            [(formGroupRef)]="formGroupRef"
            [(value)]="value"
            (emailInvoiceClick)="onEmailInvoiceClicked()"
            (downloadInvoiceClick)="onDownloadInvoiceClicked()">
          </app-invoice-form>
        </mat-tab>
        <mat-tab label="Registries" *ngIf="value?.invoiceKey">
          <div class="mt-2">
            <app-invoice-transactions-grid
              (rowClick)="onInvoiceTransactionRowClicked($event)"
              [invoiceKey]="value.invoiceKey">
            </app-invoice-transactions-grid>
          </div>
        </mat-tab>
        <mat-tab label="Payments" *ngIf="value?.invoiceKey">
          <div class="mt-2">
            <app-invoice-payments-grid
              [invoiceKey]="value.invoiceKey"
              (paymentChange)="onPaymentChanged()">
            </app-invoice-payments-grid>
          </div>
        </mat-tab>
        <mat-tab label="Attachments" *ngIf="value?.invoiceKey">
          <div class="mt-2">
            <app-business-files-grid
              [invoiceKey]="value?.invoiceKey">
            </app-business-files-grid>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </app-base-entry-page-content-template>
  <app-base-entry-page-footer-template>
    <ng-container *ngIf="value?.status !== invoiceStatuses.Finalized">
      <ng-container *ngIf="!value?.isDeleted">
        <app-save-button class="mr-2"
                         text="Save"
                         processingText="Saving" [isSaving]="isSaving"
                         [isDisabled]="isSaving || isDeleting"
                         (click)="onCustomSaveClicked(false, false)"></app-save-button>

        <app-save-button class="mr-2" *ngIf="value?.status === invoiceStatuses.Draft" text="Save as Draft"
                         processingText="Saving" [isSaving]="isSaving"
                         [isDisabled]="isSaving || isDeleting"
                         (click)="onCustomSaveClicked(false, true)"></app-save-button>

        <app-save-button class="mr-2" *ngIf="value?.status === invoiceStatuses.Paid || value?.paidDate"
                         text="Save & Finalize"
                         processingText="Saving" [isSaving]="isSaving"
                         [isDisabled]="isSaving || isDeleting"
                         (click)="onCustomSaveClicked(true, false)"></app-save-button>
      </ng-container>
      <span class="mr-2">
        <button mat-flat-button color="primary" *ngIf="!value?.invoiceKey" (click)="onCustomSaveClicked(false, false, false, true)" [disabled]="isSaving">Save & Add New</button>
        <button mat-flat-button color="primary" *ngIf="value?.invoiceKey && !value?.isDeleted" (click)="onDeleteClicked()" [disabled]="isSaving">Delete</button>
        <button mat-flat-button color="primary" *ngIf="value?.invoiceKey && value?.isDeleted" (click)="onCustomSaveClicked(false, false, true)" [disabled]="isSaving">Save & Undelete</button>
      </span>
    </ng-container>
    <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving">Cancel</button>
  </app-base-entry-page-footer-template>
</app-base-entry-page-template>
