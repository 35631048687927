import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { CustomerService } from 'src/app/core/services/domain/customer.service';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';
import { RoutingService } from 'src/app/core/services/app/routing.service';

@Component({
  selector: 'app-customer-form-dialog',
  templateUrl: './customer-form-dialog.component.html'
})
export class CustomerFormDialogComponent extends BaseEntryPageComponent<CustomerModel> {
  constructor(
    public dialogRef: MatDialogRef<CustomerFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {name: string },
    private businessContextService: BusinessContextService,
    private customerService: CustomerService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.value = <CustomerModel>{
      name: this.dialogData?.name ?? null,
      businessKey: this.businessContextService.currentBusiness.businessKey
    };
  }

  onSaveClicked(): void {
    this.isSaving = true;
    this.customerService.create(this.value).subscribe(key => {
      this.onSaveSuccess('Customer was created successfully.');
      this.dialogRef.close(key);
    }, err => {
      this.onSaveFailed(err);
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
