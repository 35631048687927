import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { tap } from 'rxjs/operators';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { StringUtilities } from 'src/app/core/utilities/string.utilities';

@Component({
  selector: 'app-vendor-form',
  templateUrl: './vendor-form.component.html',
  styleUrls: ['./vendor-form.component.scss']
})
export class VendorFormComponent extends BaseFormComponent<VendorModel> implements OnInit {
  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onTransactionsRowClicked(event): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.transactionEdit.getNavigateUrl(event.data.transactionKey));
  }

  onTransactionsAddButtonClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.transactionAdd.getNavigateUrl());
  }

  onInvoicesRowClicked(event): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.invoiceEdit.getNavigateUrl(event.data.invoiceKey));
  }

  onInvoicesAddButtonClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.invoiceAdd.getNavigateUrl(InvoiceTypesEnum.Bill, null, this.value.vendorKey));
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      vendorKey: new UntypedFormControl(this.value?.vendorKey),
      businessKey: new UntypedFormControl(this.value?.businessKey),

      email: new UntypedFormControl(this.value?.email),
      name: new UntypedFormControl(this.value?.name),
      isDeleted: new UntypedFormControl(this.value?.isDeleted ?? false),
      address: new UntypedFormGroup({
        fullAddress: new UntypedFormControl(this.value?.address?.fullAddress),
        addressLineOne: new UntypedFormControl(this.value?.address?.addressLineOne),
        addressLineTwo: new UntypedFormControl(this.value?.address?.addressLineTwo),
        city: new UntypedFormControl(this.value?.address?.city),
        state: new UntypedFormControl(this.value?.address?.state),
        postalCode: new UntypedFormControl(this.value?.address?.postalCode),
        country: new UntypedFormControl(this.value?.address?.country),

        fullAddressMailing: new UntypedFormControl(this.value?.address?.fullAddressMailing),
        addressLineOneMailing: new UntypedFormControl(this.value?.address?.addressLineOneMailing),
        addressLineTwoMailing: new UntypedFormControl(this.value?.address?.addressLineTwoMailing),
        cityMailing: new UntypedFormControl(this.value?.address?.cityMailing),
        stateMailing: new UntypedFormControl(this.value?.address?.stateMailing),
        postalCodeMailing: new UntypedFormControl(this.value?.address?.postalCodeMailing),
        countryMailing: new UntypedFormControl(this.value?.address?.countryMailing),
        isMailingAddressSame: new UntypedFormControl(this.value?.address?.isMailingAddressSame ?? false)
      }),
      phoneNumber: new UntypedFormControl(this.value?.phoneNumber),
      is1099Contractor: new UntypedFormControl(this.value?.is1099Contractor ?? false),
      socialSecurityNumber: new UntypedFormControl(this.value?.socialSecurityNumber),
      employerIdentificationNumber: new UntypedFormControl(this.value?.employerIdentificationNumber),
    })
  };
}
