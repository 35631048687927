import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TaxRateModel } from 'src/app/shared/models/domain/tax-rate.model';

@Component({
  selector: 'app-tax-rate-form',
  templateUrl: './tax-rate-form.component.html',
  styleUrls: ['./tax-rate-form.component.scss']
})
export class TaxRateFormComponent extends BaseFormComponent<TaxRateModel> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      taxRateKey: new UntypedFormControl(this.value?.taxRateKey),
      businessKey: new UntypedFormControl(this.value?.businessKey),
      name: new UntypedFormControl(this.value?.name),
      agencyName: new UntypedFormControl(this.value?.agencyName),
      isDeleted: new UntypedFormControl(this.value?.isDeleted ?? false),
      rate: new UntypedFormControl(this.value?.rate)
    })
  };
}
