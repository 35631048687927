import { Component, Input } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { InvoiceTransactionLinkService } from 'src/app/core/services/domain/invoice-transaction-link.service';
import { TransactionTypesEnum } from 'src/app/shared/enums/domain/transaction-types.enum';
import { InvoiceTransactionLinkModel } from 'src/app/shared/models/domain/invoice-transaction-link.model';

@Component({
  selector: 'app-invoice-transactions-grid',
  templateUrl: './invoice-transactions-grid.component.html',
  styleUrls: ['./invoice-transactions-grid.component.scss']
})
export class InvoiceTransactionsGridComponent extends BaseGridComponent<InvoiceTransactionLinkModel> {
  @Input() invoiceKey: string = null;

  constructor(public authClientService: AuthClientService,
              private businessContextService: BusinessContextService,
              private invoiceTransactionLinkService: InvoiceTransactionLinkService) {
    super();
    this.gridKey = 'InvoiceTransactions_v1';
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: 'Reference #',
        field: 'transaction.referenceNumber'
      },
      this.getDateColumn('transaction.date', 'Date'),
      //this.getEnumColumn('transaction.type', 'Type', TransactionTypesEnum),
      this.getCurrencyColumn('transaction.amount', 'Transaction Amount', null, true),
      this.getCurrencyColumn('amountApplied', 'Applied Amount'),
    ];
  }

  protected onGridReady(): void {
  }

  protected setRowData(): Observable<any[]> {
    return this.invoiceTransactionLinkService.search({businessKey: this.businessContextService.currentBusiness.businessKey, invoiceKey: this.invoiceKey});
  }
}
