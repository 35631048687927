import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http'
import { RollUpHeroComponent } from 'src/app/shared/components/views/roll-up/roll-up-hero.component';
import { RollUpItemComponent } from 'src/app/shared/components/views/roll-up/roll-up-item.component';
import {
  VendorFormDialogComponent
} from 'src/app/shared/components/dialogs/vendor-form-dialog/vendor-form-dialog.component';
import {
  BaseEntryDialogTemplateComponent
} from 'src/app/shared/templates/base-entry-dialog-template/base-entry-dialog-template.component';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { AgGridModule } from 'ag-grid-angular';

import {
  CustomerFormDialogComponent
} from 'src/app/shared/components/dialogs/customer-form-dialog/customer-form-dialog.component';
import {
  ServiceRequestFormComponent
} from './shared/components/forms/service-request-form/service-request-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@auth0/auth0-angular';
import { SandboxPageComponent } from 'src/app/pages/application/admin/sandbox-page/sandbox-page.component';
import { ClientService } from 'src/app/core/services/domain/client.service';
import { AppHttpInterceptor } from './core/interceptors/app-http.interceptor';
import { environment } from 'src/environments/environment';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { StaticPageComponent } from 'src/app/pages/static/static-page.component';
import { ApplicationPageComponent } from 'src/app/pages/application/application-page.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import { MatMenuModule } from '@angular/material/menu';
import {
  AdminDashboardPageComponent
} from 'src/app/pages/application/admin/admin-dashboard-page/admin-dashboard-page.component';
import { DashboardPageComponent } from 'src/app/pages/application/dashboard-page/dashboard-page.component';
import {
  DocumentsPageComponent
} from 'src/app/pages/application/shared/documents/documents-page/documents-page.component';
import { ClientsPageComponent } from 'src/app/pages/application/admin/clients-page/clients-page.component';
import {
  BusinessesAdminPageComponent
} from 'src/app/pages/application/admin/businesses-admin-page/businesses-admin-page.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmployeesPageComponent } from 'src/app/pages/application/employees-page/employees-page.component';
import { PrivacyPolicyPageComponent } from './pages/shared/privacy-policy-page/privacy-policy-page.component';
import { TermsConditionsPageComponent } from './pages/shared/terms-conditions-page/terms-conditions-page.component';
import { BasePageTemplateComponent } from './shared/templates/base-page-template/base-page-template.component';
import {
  BasePageContentTemplateComponent
} from 'src/app/shared/templates/base-page-template/base-page-content-template.component';
import { DocumentsGridComponent } from './shared/components/grids/documents-grid/documents-grid.component';
import {
  DocumentPageComponent
} from './pages/application/shared/documents/documents-page/document-page/document-page.component';
import { DocumentFormComponent } from './shared/components/forms/document-form/document-form.component';
import { MatSelectModule } from '@angular/material/select';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  MatNativeDateModule,
  MatOptionModule,
  NativeDateAdapter
} from '@angular/material/core';
import { UploadComponent } from './shared/components/controls/upload/upload.component';
import {
  BaseEntryPageTemplateComponent
} from 'src/app/shared/templates/base-entry-page-template/base-entry-page-template.component';
import {
  BaseEntryPageContentTemplateComponent
} from 'src/app/shared/templates/base-entry-page-template/base-entry-page-content-template.component';
import {
  BaseEntryPageHeaderTemplateComponent
} from 'src/app/shared/templates/base-entry-page-template/base-entry-page-header-template.component';
import {
  BaseGridHeaderTemplateComponent
} from 'src/app/shared/templates/base-grid-template/base-grid-header-template.component';
import {
  BaseGridContentTemplateComponent
} from 'src/app/shared/templates/base-grid-template/base-grid-content-template.component';
import {
  BaseEntryPageFooterTemplateComponent
} from 'src/app/shared/templates/base-entry-page-template/base-entry-page-footer-template.component';
import { BaseGridTemplateComponent } from 'src/app/shared/templates/base-grid-template/base-grid-template.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { NotificationComponent } from './shared/components/controls/notification/notification.component';
import { FluidHeightDirective } from 'src/app/shared/directives/fluid-height.directive';
import { MatBadgeModule } from '@angular/material/badge';
import { ProfilePageComponent } from './pages/application/profile-page/profile-page.component';
import { ClientFormComponent } from 'src/app/shared/components/forms/client-form/client-form.component';
import { AppInitService } from 'src/app/core/services/app/app-init.service';
import { ProfileSetupPageComponent } from './pages/application/profile-setup-page/profile-setup-page.component';
import { MatStepperModule } from '@angular/material/stepper';
import { BusinessesGridComponent } from './shared/components/grids/businesses-grid/businesses-grid.component';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { BusinessPageComponent } from 'src/app/pages/application/shared/business-page/business-page.component';
import { InvoicesPageComponent } from 'src/app/pages/application/accounting/invoices-page/invoices-page.component';
import { BusinessFormComponent } from './shared/components/forms/business-form/business-form.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './shared/components/controls/loader/loader.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AddressSubFormComponent } from './shared/components/forms/address-sub-form/address-sub-form.component';
import { BusinessesPageComponent } from 'src/app/pages/application/businesses-page/businesses-page.component';
import { ClientsGridComponent } from './shared/components/grids/clients-grid/clients-grid.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SafeHtmlPipe } from 'src/app/shared/pipes/safe-html.pipe';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { RedirectPageComponent } from 'src/app/pages/application/redirect-page/redirect-page.component';
import {
  DocumentRenderComponentView
} from 'src/app/shared/components/views/document-renderer-view/document-render-component-view.component';
import { ClientToBusinessAccessService } from 'src/app/core/services/domain/client-to-business-access.service';
import {
  BusinessAccessFormComponent
} from './shared/components/forms/business-access-form/business-access-form.component';
import {
  BusinessAccessGridComponent
} from './shared/components/grids/business-access-grid/business-access-grid.component';
import {
  BusinessAccessPageComponent
} from './pages/application/shared/business-page/business-access-page/business-access-page.component';
import { MatDividerModule } from '@angular/material/divider';
import { InvoicesGridComponent } from 'src/app/shared/components/grids/invoices-grid/invoices-grid.component';
import { InvoiceService } from 'src/app/core/services/domain/invoice.service';
import {
  InvoicePageComponent
} from 'src/app/pages/application/accounting/invoices-page/invoice-page/invoice-page.component';
import { InvoiceFormComponent } from 'src/app/shared/components/forms/invoice-form/invoice-form.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ClientPageComponent } from './pages/application/admin/clients-page/client-page/client-page.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SafeUrlPipe } from 'src/app/shared/pipes/safe-url.pipe';
import { SaveButtonComponent } from './shared/components/controls/save-button/save-button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BlockingLoaderComponent } from './shared/components/controls/blocking-loader/blocking-loader.component';
import {
  EmailDocumentFormComponent
} from 'src/app/shared/components/forms/email-document-form/email-document-form.component';
import {
  EmailDocumentRendererDialogComponent
} from 'src/app/shared/components/dialogs/email-document-renderer-dialog/email-document-renderer-dialog.component';
import { DialogService } from './core/services/domain/dialog.service';
import {
  DownloadDocumentRendererDialogComponent
} from './shared/components/dialogs/download-document-renderer-dialog/download-document-renderer-dialog.component';
import {
  ConfirmationDialogComponent
} from './shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  DownloadInvoiceDialogComponent
} from 'src/app/shared/components/dialogs/download-invoice-dialog/download-invoice-dialog.component';
import {
  EmailInvoiceDialogComponent
} from 'src/app/shared/components/dialogs/email-invoice-dialog/email-invoice-dialog.component';
import {
  EmailInvoiceFormComponent
} from 'src/app/shared/components/forms/email-invoice-form/email-invoice-form.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import {
  BusinessServicesPageComponent
} from 'src/app/pages/shared/business-services-page/business-services-page.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator';
import { EmployeePageComponent } from 'src/app/pages/application/employees-page/employee-page/employee-page.component';
import {
  EmployeesGridComponent
} from 'src/app/shared/components/grids/employees-grid/employees-grid.component';
import { EmployeeService } from 'src/app/core/services/domain/employee.service';
import {
  EmployeeFormComponent
} from 'src/app/shared/components/forms/employee-form/employee-form.component';
import { LocationSearchComponent } from './shared/components/controls/location-search/location-search.component';
import { GlobalErrorHandlerService } from 'src/app/core/services/app/global-error-handler.service';
import { TransactionService } from 'src/app/core/services/domain/transaction.service';
import {
  TransactionsPageComponent
} from 'src/app/pages/application/accounting/transactions-page/transactions-page.component';
import {
  TransactionPageComponent
} from './pages/application/accounting/transactions-page/transaction-page/transaction-page.component';
import {
  TransactionsGridComponent
} from 'src/app/shared/components/grids/transactions-grid/transactions-grid.component';
import { TransactionFormComponent } from 'src/app/shared/components/forms/transaction-form/transaction-form.component';
import {
  BusinessServiceRequestPageComponent
} from 'src/app/pages/shared/business-services-page/business-service-request-page/business-service-request-page.component';
import {
  BusinessServiceRequestFormComponent
} from 'src/app/shared/components/forms/business-service-request-form/business-service-request-form.component';
import {
  RequestServiceViewComponent
} from './shared/components/views/request-service-view/request-service-view.component';
import { BusinessServiceRequestService } from 'src/app/core/services/domain/business-service-request.service';
import {
  BusinessServiceRequestsPageComponent
} from './pages/application/shared/business-service-requests-page/business-service-requests-page.component';
import {
  BusinessServiceRequestsGridComponent
} from './shared/components/grids/business-service-requests-grid/business-service-requests-grid.component';
import { LoggingService } from 'src/app/core/services/app/logging.service';
import { BankingService } from 'src/app/core/services/domain/banking.service';
import { LoginComponent } from './pages/shared/login/login.component';
import { CustomersGridComponent } from 'src/app/shared/components/grids/customers-grid/customers-grid.component';
import { CustomersPageComponent } from 'src/app/pages/application/accounting/customers-page/customers-page.component';
import {
  CustomerPageComponent
} from 'src/app/pages/application/accounting/customers-page/customer-page/customer-page.component';
import { CustomerFormComponent } from 'src/app/shared/components/forms/customer-form/customer-form.component';
import { CustomerService } from 'src/app/core/services/domain/customer.service';
import { VendorService } from 'src/app/core/services/domain/vendor.service';
import { VendorsGridComponent } from 'src/app/shared/components/grids/vendors-grid/vendors-grid.component';
import { VendorsPageComponent } from 'src/app/pages/application/accounting/vendors-page/vendors-page.component';
import { VendorFormComponent } from 'src/app/shared/components/forms/vendor-form/vendor-form.component';
import {
  VendorPageComponent
} from 'src/app/pages/application/accounting/vendors-page/vendor-page/vendor-page.component';
import { OfferingService } from 'src/app/core/services/domain/offering.service';
import { OfferingsPageComponent } from 'src/app/pages/application/accounting/offerings-page/offerings-page.component';
import {
  OfferingPageComponent
} from 'src/app/pages/application/accounting/offerings-page/offering-page/offering-page.component';
import { OfferingsGridComponent } from 'src/app/shared/components/grids/offerings-grid/offerings-grid.component';
import { OfferingFormComponent } from 'src/app/shared/components/forms/offering-form/offering-form.component';
import {
  OfferingFormDialogComponent
} from 'src/app/shared/components/dialogs/offering-form-dialog/offering-form-dialog.component';
import {
  InvoiceTransactionLinkDialogComponent
} from 'src/app/shared/components/dialogs/invoice-transaction-link-dialog/invoice-transaction-link-dialog.component';
import { InvoiceTransactionLinkService } from 'src/app/core/services/domain/invoice-transaction-link.service';
import { CurrencyFormatterPipe } from 'src/app/shared/pipes/currency-formatter.pipe';
import { DateFormatterPipe } from 'src/app/shared/pipes/date-formatter.pipe';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { ReportsPageComponent } from 'src/app/pages/application/accounting/reports-page/reports-page.component';
import {
  ChartOfAccountsPageComponent
} from 'src/app/pages/application/accounting/chart-of-accounts-page/chart-of-accounts-page.component';
import {
  ChartOfAccountPageComponent
} from 'src/app/pages/application/accounting/chart-of-accounts-page/chart-of-account-page/chart-of-account-page.component';
import {
  ChartOfAccountsGridComponent
} from 'src/app/shared/components/grids/chart-of-accounts-grid/chart-of-accounts-grid.component';
import {
  ChartOfAccountFormComponent
} from 'src/app/shared/components/forms/chart-of-account-form/chart-of-account-form.component';
import { ColorPickerModule } from 'ngx-color-picker';
import {
  ManageCategoriesDialogComponent
} from './shared/components/dialogs/manage-categories-dialog/manage-categories-dialog.component';
import { MatChipsModule } from '@angular/material/chips';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import {
  RegistriesPageComponent
} from 'src/app/pages/application/accounting/registries-page/registries-page.component';
import {
  RegistryPageComponent
} from 'src/app/pages/application/accounting/registries-page/registry-page/registry-page.component';
import {
  TransactionLinkDialogComponent
} from 'src/app/shared/components/dialogs/transaction-link-dialog/transaction-link-dialog.component';
import {
  TransactionBulkUpdateDialogComponent
} from 'src/app/shared/components/dialogs/transaction-bulk-update-dialog/transaction-bulk-update-dialog.component';
import {
  InvoiceTransactionsGridComponent
} from 'src/app/shared/components/grids/invoice-transactions-grid/invoice-transactions-grid.component';
import {
  InvoicePaymentsGridComponent
} from 'src/app/shared/components/grids/invoice-payments-grid/invoice-payments-grid.component';
import { InvoicePaymentService } from 'src/app/core/services/domain/invoice-payment.service';
import {
  InvoicePaymentFormComponent
} from 'src/app/shared/components/forms/invoice-payment-form/invoice-payment-form.component';
import {
  InvoicePaymentFormDialogComponent
} from 'src/app/shared/components/dialogs/invoice-payment-form-dialog/invoice-payment-form-dialog.component';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { BusinessFileService } from './core/services/domain/business-file.service';
import {
  BusinessFilesGridComponent
} from 'src/app/shared/components/grids/business-files-grid/business-files-grid.component';
import {
  BusinessFileFormDialogComponent
} from 'src/app/shared/components/dialogs/business-file-form-dialog/business-file-form-dialog.component';
import { BusinessFileFormComponent } from './shared/components/forms/business-file-form/business-file-form.component';
import { EnumFormatterPipe } from 'src/app/shared/pipes/enum-formatter.pipe';
import { ReportPageComponent } from './pages/application/accounting/reports-page/report-page/report-page.component';
import { ReportService } from './core/services/domain/report.service';
import { PdfViewerComponent } from './shared/components/views/pdf-viewer/pdf-viewer.component';
import {
  PdfViewerDialogComponent
} from 'src/app/shared/components/dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { TaxRateService } from 'src/app/core/services/domain/tax-rate.service';
import { PercentFormatterPipe } from 'src/app/shared/pipes/percent-formatter.pipe';
import {
  TaxRateFormDialogComponent
} from 'src/app/shared/components/dialogs/tax-rate-form-dialog/tax-rate-form-dialog.component';
import { TaxRateFormComponent } from 'src/app/shared/components/forms/tax-rate-form/tax-rate-form.component';
import { YesNoFormatterPipe } from 'src/app/shared/pipes/yes-no-formatter.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { JobService } from 'src/app/core/services/domain/job.service';
import { JobsPageComponent } from 'src/app/pages/application/accounting/jobs-page/jobs-page.component';
import { JobPageComponent } from 'src/app/pages/application/accounting/jobs-page/job-page/job-page.component';
import { JobsGridComponent } from 'src/app/shared/components/grids/jobs-grid/jobs-grid.component';
import { JobFormComponent } from 'src/app/shared/components/forms/job-form/job-form.component';
import { DateUtilities } from 'src/app/core/utilities/date.utilities';
import { FilesPageComponent } from 'src/app/pages/application/shared/documents/files-page/files-page.component';
import {
  BusinessContextInitializationService
} from 'src/app/core/services/domain/business-context-initialization.service';
import {
  ChartOfAccountFormDialogComponent
} from 'src/app/shared/components/dialogs/chart-of-account-form-dialog/chart-of-account-form-dialog.component';
import { JobFormDialogComponent } from 'src/app/shared/components/dialogs/job-form-dialog/job-form-dialog.component';
import {
  ReconciliationGridComponent
} from 'src/app/shared/components/grids/reconciliation-grid/reconciliation-grid.component';
import { ReconciliationService } from 'src/app/core/services/domain/reconciliation.service';
import {
  ReconciliationsPageComponent
} from 'src/app/pages/application/accounting/reconciliations-page/reconciliations-page.component';
import { ReconciliationPageComponent } from './pages/application/accounting/reconciliations-page/reconciliation-page/reconciliation-page.component';
import { ReconciliationFormComponent } from 'src/app/shared/components/forms/reconciliation-form/reconciliation-form.component';
import {
  TransactionFormDialogComponent
} from 'src/app/shared/components/dialogs/transaction-form-dialog/transaction-form-dialog.component';
import { HelpPageComponent } from 'src/app/pages/application/help-page/help-page.component';
import { TransactionRuleService } from 'src/app/core/services/domain/transaction-rule.service';
import {
  TransactionRulesPageComponent
} from 'src/app/pages/application/accounting/transactions-page/transaction-rules-page/transaction-rules-page.component';
import {
  TransactionRulesGridComponent
} from 'src/app/shared/components/grids/transaction-rules-grid/transaction-rules-grid.component';
import {
  TransactionRuleFormDialogComponent
} from 'src/app/shared/components/dialogs/transaction-rule-form-dialog/transaction-rule-form-dialog.component';
import {
  TransactionRuleFormComponent
} from 'src/app/shared/components/forms/transaction-rule-form/transaction-rule-form.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SupportService } from 'src/app/core/services/domain/support.service';
import {
  TemplateSelectorDialogComponent
} from "./shared/components/dialogs/template-selector-dialog/template-selector-dialog.component";
import { NgChartsModule } from 'ng2-charts';
import { DashboardInvoiceStatusComponent } from 'src/app/shared/components/dashboard/dashboard-invoices/dashboard-invoice-status.component';
import { DashboardService } from 'src/app/core/services/domain/dashboard.service';
import {
  DashboardChartOfAccounts
} from 'src/app/shared/components/dashboard/dashboard-chart-of-accounts/dashboard-chart-of-accounts.component';
import {
  DashboardQuickLinksComponent
} from 'src/app/shared/components/dashboard/dashboard-quick-links/dashboard-quick-links.component';
import {
  DashboardHeroNumberComponent
} from 'src/app/shared/components/dashboard/dashboard-hero-number/dashboard-hero-number.component';
import {
  DashboardTransactionsComponent
} from 'src/app/shared/components/dashboard/dashboard-transactions/dashboard-transactions.component';
import {
  SyncConfirmationDialogComponent
} from 'src/app/shared/components/dialogs/sync-confirmation-dialog/sync-confirmation-dialog.component';
import {
  EmailReportDialogComponent
} from 'src/app/shared/components/dialogs/email-report-dialog/email-report-dialog.component';
import {
  EmailReportFormComponent
} from 'src/app/shared/components/forms/email-report-form/email-report-form.component';
import { VideoPlayerComponent } from './shared/components/controls/video-player/video-player.component';
import { BusinessSubscriptionsPageComponent } from 'src/app/pages/application/subscriptions/business-subscriptions-page.component';
import { ScriptService } from 'src/app/core/services/app/script.service';
import { CustomerPaymentSubscriptionFormComponent } from 'src/app/shared/components/forms/credit-card-form/customer-payment-subscription-form.component';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { VideoDialogComponent } from 'src/app/shared/components/dialogs/video-dialog/video-dialog.component';
import { SalesTaxRatesService } from 'src/app/core/services/domain/sales-tax-rates.service';
import { TicketPageComponent } from 'src/app/pages/application/ticket-page/ticket-page.component';
import { PricingPageComponent } from 'src/app/pages/shared/pricing-page/pricing-page.component';
import { GoogleLocationService } from 'src/app/core/services/app/google-location.service';
import { BypassHttpInterceptorService } from 'src/app/core/services/app/bypass-http-inteceptor.service';
import {
  NoCookiesDialogComponent
} from 'src/app/shared/components/dialogs/no-cookies-dialog/no-cookies-dialog.component';
import { MatTableResponsiveDirective } from 'src/app/shared/directives/mat-table-response.directive';

export function init_app(appInitService: AppInitService) {
  return () => appInitService.init();
}

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    return DateUtilities.format(date);
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'numeric'},
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

@NgModule({
  declarations: [
    //PIPES
    SafeHtmlPipe,
    SafeUrlPipe,
    CurrencyFormatterPipe,
    DateFormatterPipe,
    EnumFormatterPipe,
    PercentFormatterPipe,
    YesNoFormatterPipe,
    //COMPONENTS
    AppComponent,
    StaticPageComponent,
    SandboxPageComponent,
    ServiceRequestFormComponent,
    ApplicationPageComponent,
    AdminDashboardPageComponent,
    DashboardPageComponent,
    DocumentsPageComponent,
    BusinessesAdminPageComponent,
    ClientsPageComponent,
    EmployeesGridComponent,
    CustomersGridComponent,
    VendorsGridComponent,
    BusinessServicesPageComponent,
    CustomersPageComponent,
    OfferingsPageComponent,
    OfferingPageComponent,
    VendorsPageComponent,
    EmployeesPageComponent,
    PricingPageComponent,
    PrivacyPolicyPageComponent,
    TermsConditionsPageComponent,
    BaseEntryDialogTemplateComponent,
    BaseEntryPageTemplateComponent,
    BaseEntryPageContentTemplateComponent,
    BaseEntryPageFooterTemplateComponent,
    BaseEntryPageHeaderTemplateComponent,
    BasePageTemplateComponent,
    BasePageContentTemplateComponent,
    DocumentsGridComponent,
    InvoicesGridComponent,
    TransactionsGridComponent,
    BaseGridTemplateComponent,
    BaseGridContentTemplateComponent,
    BaseGridHeaderTemplateComponent,
    DocumentPageComponent,
    NoCookiesDialogComponent,
    DocumentFormComponent,
    UploadComponent,
    NotificationComponent,
    FluidHeightDirective,
    MatTableResponsiveDirective,
    ProfilePageComponent,
    HelpPageComponent,
    ClientFormComponent,
    CustomerPaymentSubscriptionFormComponent,
    ProfileSetupPageComponent,
    BusinessesPageComponent,
    BusinessesGridComponent,
    BusinessPageComponent,
    BusinessesAdminPageComponent,
    InvoicesPageComponent,
    TransactionsPageComponent,
    BusinessFormComponent,
    LoaderComponent,
    AddressSubFormComponent,
    ClientsGridComponent,
    RedirectPageComponent,
    DocumentRenderComponentView,
    BusinessAccessFormComponent,
    BusinessAccessGridComponent,
    BusinessAccessPageComponent,
    InvoicePageComponent,
    InvoiceFormComponent,
    TicketPageComponent,
    TransactionFormComponent,
    TransactionPageComponent,
    TransactionRulesPageComponent,
    InvoiceTransactionLinkDialogComponent,
    ClientPageComponent,
    SaveButtonComponent,
    BlockingLoaderComponent,
    EmailDocumentFormComponent,
    EmailReportFormComponent,
    EmailDocumentRendererDialogComponent,
    DownloadDocumentRendererDialogComponent,
    ConfirmationDialogComponent,
    SyncConfirmationDialogComponent,
    DownloadInvoiceDialogComponent,
    EmailInvoiceDialogComponent,
    EmailReportDialogComponent,
    EmailInvoiceFormComponent,
    EmployeePageComponent,
    EmployeeFormComponent,
    CustomerPageComponent,
    CustomerFormComponent,
    CustomerFormDialogComponent,
    JobFormDialogComponent,
    TransactionFormDialogComponent,
    TemplateSelectorDialogComponent,
    VideoDialogComponent,
    ChartOfAccountFormDialogComponent,
    OfferingFormDialogComponent,
    VendorPageComponent,
    VendorsPageComponent,
    VendorFormComponent,
    VendorFormDialogComponent,
    LocationSearchComponent,
    BusinessServiceRequestPageComponent,
    BusinessServiceRequestFormComponent,
    RequestServiceViewComponent,
    BusinessServiceRequestsPageComponent,
    BusinessServiceRequestsGridComponent,
    LoginComponent,
    RollUpHeroComponent,
    RollUpItemComponent,
    ReportsPageComponent,
    ChartOfAccountsPageComponent,
    ChartOfAccountPageComponent,
    ChartOfAccountsGridComponent,
    ChartOfAccountFormComponent,
    OfferingsGridComponent,
    OfferingFormComponent,
    ManageCategoriesDialogComponent,
    RegistriesPageComponent,
    RegistryPageComponent,
    TransactionLinkDialogComponent,
    TransactionRuleFormDialogComponent,
    TransactionBulkUpdateDialogComponent,
    InvoiceTransactionsGridComponent,
    DashboardChartOfAccounts,
    DashboardInvoiceStatusComponent,
    DashboardTransactionsComponent,
    DashboardQuickLinksComponent,
    DashboardHeroNumberComponent,
    InvoicePaymentsGridComponent,
    InvoicePaymentFormComponent,
    InvoicePaymentFormDialogComponent,
    BusinessFilesGridComponent,
    BusinessFileFormDialogComponent,
    BusinessFileFormComponent,
    TransactionRuleFormComponent,
    ReportPageComponent,
    PdfViewerComponent,
    PdfViewerDialogComponent,
    TaxRateFormDialogComponent,
    TaxRateFormComponent,
    JobsPageComponent,
    JobPageComponent,
    JobsGridComponent,
    TransactionRulesGridComponent,
    JobFormComponent,
    ReconciliationPageComponent,
    ReconciliationsPageComponent,
    FilesPageComponent,
    ReconciliationGridComponent,
    ReconciliationFormComponent,
    DashboardChartOfAccounts,
    DashboardInvoiceStatusComponent,
    DashboardTransactionsComponent,
    DashboardQuickLinksComponent,
    DashboardHeroNumberComponent,
    VideoPlayerComponent,
    BusinessSubscriptionsPageComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    ColorPickerModule,
    HttpClientModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PdfViewerModule,
    ReactiveFormsModule,
    EditorModule,
    CurrencyMaskModule,
    AgGridModule.withComponents([]),
    NgChartsModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      audience: environment.auth0.audience
    }),
    MatAutocompleteModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatExpansionModule,
    MatMenuModule,
    MatTooltipModule,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
    TextFieldModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDividerModule,
    DragDropModule
  ],
  providers: [
    AppInitService,
    AuthClientService,
    BypassHttpInterceptorService,
    LoggingService,
    NotificationService,
    DialogService,
    GoogleLocationService,
    PendingChangesService,
    RoutingService,
    ScriptService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [
        AppInitService
      ],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    BankingService,
    BusinessContextService,
    BusinessContextInitializationService,
    BusinessService,
    BusinessFileService,
    BusinessServiceRequestService,
    ChartOfAccountService,
    ClientToBusinessAccessService,
    ClientService,
    CustomerService,
    DashboardService,
    DocumentService,
    EmployeeService,
    InvoiceService,
    InvoiceTransactionLinkService,
    InvoicePaymentService,
    JobService,
    OfferingService,
    ReconciliationService,
    ReportService,
    SalesTaxRatesService,
    BusinessSubscriptionService,
    SupportService,
    TaxRateService,
    TransactionService,
    TransactionRuleService,
    VendorService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
