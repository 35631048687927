<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-content">
      <div class="subtle-text bold" *ngIf="value?.jobKey">
        <span class="mr-1">Profit: {{value.profit | currencyFormatter}}</span>
        <span class="mr-1">Income: {{value.income | currencyFormatter}}</span>
        <span class="mr-1">Expenses: {{value.expenses | currencyFormatter}}</span>
      </div>
      <div class="mt-2">
        <mat-form-field class="mr-2 mat-form-field-double">
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name">
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field class="mr-2">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let jobStatus of jobStatuses"
                        [value]="jobStatus.value">{{jobStatus.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-slide-toggle formControlName="isDeleted">Deleted</mat-slide-toggle>
      </div>
      <div class="mt-2">
        <ng-container formGroupName="customer">
          <div>
            <mat-form-field class="mat-form-field-double" *ngIf="customers">
              <mat-label>Customer</mat-label>
              <input type="text"
                     #customerInput
                     matInput
                     placeholder="Search for customer . . ."
                     formControlName="customerKey"
                     [matAutocomplete]="auto">
              <mat-autocomplete [displayWith]="customerDisplayFn.bind(this)" autoActiveFirstOption
                                #auto="matAutocomplete">
                <mat-option class="bold" value="-1">Add New Customer</mat-option>
                <mat-option *ngFor="let customer of filteredCustomers" [value]="customer.customerKey">
                  {{customer.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div *ngIf="customerAddress" class="smaller">
            <div>
              {{customerAddress}}
            </div>
            <div>
              {{customerContact}}
            </div>
          </div>
        </ng-container>
      </div>

      <div class="mt-2">
        <mat-form-field class="mat-form-field-date mr-1">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="startPicker" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="mat-form-field-date">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="endPicker" formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field class="mat-form-field-double">
          <mat-label>Notes</mat-label>
          <textarea
            #autosize="cdkTextareaAutosize"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="15"
            formControlName="notes">
          </textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
