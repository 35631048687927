import { Routes } from '@angular/router';
import { SandboxPageComponent } from 'src/app/pages/application/admin/sandbox-page/sandbox-page.component';
import { StaticPageComponent } from 'src/app/pages/static/static-page.component';
import { ApplicationPageComponent } from 'src/app/pages/application/application-page.component';
import { DashboardPageComponent } from 'src/app/pages/application/dashboard-page/dashboard-page.component';
import {
  DocumentsPageComponent
} from 'src/app/pages/application/shared/documents/documents-page/documents-page.component';
import { BusinessesPageComponent } from 'src/app/pages/application/businesses-page/businesses-page.component';
import {
  BusinessServicesPageComponent
} from 'src/app/pages/shared/business-services-page/business-services-page.component';
import {
  AdminDashboardPageComponent
} from 'src/app/pages/application/admin/admin-dashboard-page/admin-dashboard-page.component';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { PrivacyPolicyPageComponent } from 'src/app/pages/shared/privacy-policy-page/privacy-policy-page.component';
import {
  TermsConditionsPageComponent
} from 'src/app/pages/shared/terms-conditions-page/terms-conditions-page.component';
import {
  DocumentPageComponent
} from 'src/app/pages/application/shared/documents/documents-page/document-page/document-page.component';
import { ProfilePageComponent } from 'src/app/pages/application/profile-page/profile-page.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import {
  BusinessesAdminPageComponent
} from 'src/app/pages/application/admin/businesses-admin-page/businesses-admin-page.component';
import { BusinessPageComponent } from 'src/app/pages/application/shared/business-page/business-page.component';
import { ClientsPageComponent } from 'src/app/pages/application/admin/clients-page/clients-page.component';
import { InvoicesPageComponent } from 'src/app/pages/application/accounting/invoices-page/invoices-page.component';
import { ProfileSetupPageComponent } from 'src/app/pages/application/profile-setup-page/profile-setup-page.component';
import { RedirectPageComponent } from 'src/app/pages/application/redirect-page/redirect-page.component';
import {
  BusinessAccessPageComponent
} from 'src/app/pages/application/shared/business-page/business-access-page/business-access-page.component';
import { AuthAdminGuard } from 'src/app/core/guards/auth-admin.guard';
import {
  InvoicePageComponent
} from 'src/app/pages/application/accounting/invoices-page/invoice-page/invoice-page.component';
import {
  ClientPageComponent
} from 'src/app/pages/application/admin/clients-page/client-page/client-page.component';
import { BusinessGuard } from 'src/app/core/guards/business.guard';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes.guard';
import {
  TransactionsPageComponent
} from 'src/app/pages/application/accounting/transactions-page/transactions-page.component';
import {
  TransactionPageComponent
} from 'src/app/pages/application/accounting/transactions-page/transaction-page/transaction-page.component';
import {
  BusinessServiceRequestPageComponent
} from 'src/app/pages/shared/business-services-page/business-service-request-page/business-service-request-page.component';
import {
  BusinessServiceRequestsPageComponent
} from 'src/app/pages/application/shared/business-service-requests-page/business-service-requests-page.component';
import { LoginComponent } from 'src/app/pages/shared/login/login.component';
import { CustomersPageComponent } from 'src/app/pages/application/accounting/customers-page/customers-page.component';
import {
  CustomerPageComponent
} from 'src/app/pages/application/accounting/customers-page/customer-page/customer-page.component';
import { VendorsPageComponent } from 'src/app/pages/application/accounting/vendors-page/vendors-page.component';
import {
  VendorPageComponent
} from 'src/app/pages/application/accounting/vendors-page/vendor-page/vendor-page.component';
import { OfferingsPageComponent } from 'src/app/pages/application/accounting/offerings-page/offerings-page.component';
import {
  OfferingPageComponent
} from 'src/app/pages/application/accounting/offerings-page/offering-page/offering-page.component';
import {
  ChartOfAccountPageComponent
} from 'src/app/pages/application/accounting/chart-of-accounts-page/chart-of-account-page/chart-of-account-page.component';
import {
  ChartOfAccountsPageComponent
} from 'src/app/pages/application/accounting/chart-of-accounts-page/chart-of-accounts-page.component';
import {
  RegistriesPageComponent
} from 'src/app/pages/application/accounting/registries-page/registries-page.component';
import {
  RegistryPageComponent
} from 'src/app/pages/application/accounting/registries-page/registry-page/registry-page.component';
import { ReportsPageComponent } from 'src/app/pages/application/accounting/reports-page/reports-page.component';
import {
  ReportPageComponent
} from 'src/app/pages/application/accounting/reports-page/report-page/report-page.component';
import { JobsPageComponent } from '../pages/application/accounting/jobs-page/jobs-page.component';
import { JobPageComponent } from 'src/app/pages/application/accounting/jobs-page/job-page/job-page.component';
import { EmployeePageComponent } from 'src/app/pages/application/employees-page/employee-page/employee-page.component';
import { EmployeesPageComponent } from 'src/app/pages/application/employees-page/employees-page.component';
import { FilesPageComponent } from 'src/app/pages/application/shared/documents/files-page/files-page.component';
import {
  ReconciliationsPageComponent
} from '../pages/application/accounting/reconciliations-page/reconciliations-page.component';
import {
  ReconciliationPageComponent
} from '../pages/application/accounting/reconciliations-page/reconciliation-page/reconciliation-page.component';
import { HelpPageComponent } from 'src/app/pages/application/help-page/help-page.component';
import {
  TransactionRulesPageComponent
} from 'src/app/pages/application/accounting/transactions-page/transaction-rules-page/transaction-rules-page.component';
import { BusinessSubscriptionsPageComponent } from 'src/app/pages/application/subscriptions/business-subscriptions-page.component';
import { TicketPageComponent } from 'src/app/pages/application/ticket-page/ticket-page.component';
import { SubscriptionGuard } from 'src/app/core/guards/subscription-guard.service';
import { PricingPageComponent } from 'src/app/pages/shared/pricing-page/pricing-page.component';

export const routes: Routes = [
  {
    path: RouteUtilities.routes.application.base.modulePath,
    canActivate: [AuthGuard],
    component: ApplicationPageComponent,
    children: [
      {
        path: '',
        redirectTo: RouteUtilities.routes.application.dashboard.modulePath,
        pathMatch: 'full'
      },
      {
        path: RouteUtilities.routes.application.redirect.modulePath,
        component: RedirectPageComponent
      },
      {
        path: RouteUtilities.routes.application.dashboard.modulePath,
        canActivate: [AuthGuard, SubscriptionGuard],
        component: DashboardPageComponent
      },
      {
        path: RouteUtilities.routes.application.businesses.modulePath,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: BusinessesPageComponent,
          },
          {
            path: RouteUtilities.routes.application.businessEdit.modulePath,
            children: [
              {
                path: '',
                canDeactivate: [PendingChangesGuard],
                component: BusinessPageComponent,
              },
              {
                path: RouteUtilities.routes.application.businessAccess.modulePath,
                canActivate: [SubscriptionGuard],
                component: BusinessAccessPageComponent,
              },
            ]
          },
          {
            path: RouteUtilities.routes.application.businessAdd.modulePath,
            canActivate: [SubscriptionGuard],
            canDeactivate: [PendingChangesGuard],
            component: BusinessPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.customers.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: CustomersPageComponent,
          },
          {
            path: RouteUtilities.routes.application.customerEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: CustomerPageComponent,
          },
          {
            path: RouteUtilities.routes.application.customerAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: CustomerPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.jobs.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: JobsPageComponent,
          },
          {
            path: RouteUtilities.routes.application.jobEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: JobPageComponent,
          },
          {
            path: RouteUtilities.routes.application.jobAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: JobPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.offerings.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: OfferingsPageComponent,
          },
          {
            path: RouteUtilities.routes.application.offeringEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: OfferingPageComponent,
          },
          {
            path: RouteUtilities.routes.application.offeringAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: OfferingPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.chartOfAccounts.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: ChartOfAccountsPageComponent,
          },
          {
            path: RouteUtilities.routes.application.chartOfAccountEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: ChartOfAccountPageComponent,
          },
          {
            path: RouteUtilities.routes.application.chartOfAccountAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: ChartOfAccountPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.reconciliations.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: ReconciliationsPageComponent,
          },
          {
            path: RouteUtilities.routes.application.reconciliationEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: ReconciliationPageComponent,
          },
          {
            path: RouteUtilities.routes.application.reconciliationAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: ReconciliationPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.reports.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: ReportsPageComponent,
          },
          {
            path: RouteUtilities.routes.application.reportEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: ReportPageComponent
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.vendors.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: VendorsPageComponent,
          },
          {
            path: RouteUtilities.routes.application.vendorEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: VendorPageComponent,
          },
          {
            path: RouteUtilities.routes.application.vendorAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: VendorPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.employees.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: EmployeesPageComponent,
          },
          {
            path: RouteUtilities.routes.application.employeeEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: EmployeePageComponent,
          },
          {
            path: RouteUtilities.routes.application.employeeAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: EmployeePageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.invoices.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: InvoicesPageComponent,
          },
          {
            path: RouteUtilities.routes.application.invoiceEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: InvoicePageComponent,
          },
          {
            path: RouteUtilities.routes.application.invoiceAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: InvoicePageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.transactions.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: TransactionsPageComponent,
          },
          {
            path: RouteUtilities.routes.application.transactionEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: TransactionPageComponent,
          },
          {
            path: RouteUtilities.routes.application.transactionAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: TransactionPageComponent,
          },
          {
            path: RouteUtilities.routes.application.transactionRules.modulePath,
            component: TransactionRulesPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.registries.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: RegistriesPageComponent,
          },
          {
            path: RouteUtilities.routes.application.registryEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: RegistryPageComponent,
          },
          {
            path: RouteUtilities.routes.application.registryAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: RegistryPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.documents.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: DocumentsPageComponent,
          },
          {
            path: RouteUtilities.routes.application.documentEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: DocumentPageComponent,
          },
          {
            path: RouteUtilities.routes.application.documentAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: DocumentPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.files.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: FilesPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.services.modulePath,
        canActivate: [AuthGuard, BusinessGuard],
        children: [
          {
            path: '',
            component: BusinessServicesPageComponent,
          },
          {
            path: RouteUtilities.routes.application.service.modulePath,
            canDeactivate: [PendingChangesGuard],
            children: [
              {
                path: '',
                component: BusinessServiceRequestPageComponent,
              },
              {
                path: RouteUtilities.routes.application.serviceEdit.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: BusinessServiceRequestPageComponent,
              },
            ]
          },
        ]
      },
      {
        path: RouteUtilities.routes.application.businessServiceRequests.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: BusinessServiceRequestsPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.myServiceRequests.modulePath,
        canActivate: [AuthGuard, BusinessGuard],
        children: [
          {
            path: '',
            component: BusinessServiceRequestsPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.admin.base.modulePath,
        canActivate: [AuthAdminGuard],
        children: [
          {
            path: RouteUtilities.routes.application.admin.dashboard.modulePath,
            component: AdminDashboardPageComponent
          },
          {
            path: RouteUtilities.routes.application.admin.clients.modulePath,
            children: [
              {
                path: '',
                component: ClientsPageComponent,
              },
              {
                path: RouteUtilities.routes.application.admin.clientAdd.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: ClientPageComponent
              },
              {
                path: RouteUtilities.routes.application.admin.clientEdit.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: ClientPageComponent
              }
            ]
          },
          {
            path: RouteUtilities.routes.application.admin.businesses.modulePath,
            children: [
              {
                path: '',
                component: BusinessesAdminPageComponent,
              },
              {
                path: RouteUtilities.routes.application.admin.businessEdit.modulePath,
                children: [
                  {
                    path: '',
                    canDeactivate: [PendingChangesGuard],
                    component: BusinessPageComponent,
                  },
                  {
                    path: RouteUtilities.routes.application.admin.businessAccess.modulePath,
                    component: BusinessAccessPageComponent,
                  }
                ]
              },
              {
                path: RouteUtilities.routes.application.admin.businessAdd.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: BusinessPageComponent,
              }
            ]
          },
          {
            path: RouteUtilities.routes.application.admin.businessServiceRequests.modulePath,
            children: [
              {
                path: '',
                component: BusinessServiceRequestsPageComponent,
              },
              {
                path: RouteUtilities.routes.application.admin.businessServiceRequestsEdit.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: BusinessServiceRequestPageComponent
              }
            ]
          },
          {
            path: RouteUtilities.routes.application.admin.documents.modulePath,
            children: [
              {
                path: '',
                component: DocumentsPageComponent,
              },
              {
                path: RouteUtilities.routes.application.admin.documentEdit.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: DocumentPageComponent,
              },
              {
                path: RouteUtilities.routes.application.admin.documentAdd.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: DocumentPageComponent,
              }
            ]
          },
          {
            path: RouteUtilities.routes.application.admin.files.modulePath,
            children: [
              {
                path: '',
                component: FilesPageComponent,
              },
              // {
              //   path: RouteUtilities.routes.application.admin.documentEdit.modulePath,
              //   canDeactivate: [PendingChangesGuard],
              //   component: DocumentPageComponent,
              // },
              // {
              //   path: RouteUtilities.routes.application.admin.documentAdd.modulePath,
              //   canDeactivate: [PendingChangesGuard],
              //   component: DocumentPageComponent,
              // }
            ]
          },
          {
            path: RouteUtilities.routes.application.admin.sandbox.modulePath,
            component: SandboxPageComponent
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.profile.modulePath,
        canActivate: [AuthGuard],
        component: ProfilePageComponent
      },
      {
        path: RouteUtilities.routes.application.profileSetup.modulePath,
        component: ProfileSetupPageComponent
      },
      {
        path: RouteUtilities.routes.application.help.modulePath,
        canActivate: [AuthGuard, SubscriptionGuard],
        component: HelpPageComponent
      },
      {
        path: RouteUtilities.routes.application.ticket.modulePath,
        component: TicketPageComponent
      },
      {
        path: RouteUtilities.routes.application.subscriptions.modulePath,
        component: BusinessSubscriptionsPageComponent
      },
      {
        path: RouteUtilities.routes.application.pricing.modulePath,
        component: PricingPageComponent
      },
      {
        path: RouteUtilities.routes.application.privacyPolicy.modulePath,
        component: PrivacyPolicyPageComponent
      },
      {
        path: RouteUtilities.routes.application.terms.modulePath,
        component: TermsConditionsPageComponent
      }
    ]
  },
  {
    path: '',
    component: StaticPageComponent,
    children: [
      {
        path: '',
        redirectTo: RouteUtilities.routes.static.base.modulePath,
        pathMatch: 'full'
      },
      {
        path: RouteUtilities.routes.static.login.modulePath,
        component: LoginComponent
      },
      {
        path: RouteUtilities.routes.static.services.modulePath,
        children: [
          {
            path: '',
            component: BusinessServicesPageComponent,
          },
          {
            path: RouteUtilities.routes.static.service.modulePath,
            canDeactivate: [PendingChangesGuard],
            children: [
              {
                path: '',
                component: BusinessServiceRequestPageComponent,
              },
              {
                path: RouteUtilities.routes.static.serviceEdit.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: BusinessServiceRequestPageComponent,
              },
            ]
          },
        ]
      },
      {
        path: RouteUtilities.routes.static.pricing.modulePath,
        component: PricingPageComponent
      },
      {
        path: RouteUtilities.routes.static.privacyPolicy.modulePath,
        component: PrivacyPolicyPageComponent
      },
      {
        path: RouteUtilities.routes.static.terms.modulePath,
        component: TermsConditionsPageComponent
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
]
