import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SalesTaxRateModel } from 'src/app/shared/models/domain/sales-tax-rate.model';
import { SalesTaxRateSearchModel } from 'src/app/shared/models/domain/sales-tax-rate-seach.model';

@Injectable()
export class SalesTaxRatesService {
  private url = environment.apiUrl + '/sales-tax-rates/';

  constructor(private http: HttpClient) {
  }

  search(model: SalesTaxRateSearchModel): Observable<SalesTaxRateModel> {
    return this.http.post<SalesTaxRateModel>(`${this.url}search`, model);
  }
}
