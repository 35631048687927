<app-base-entry-page-template entryTitle="Manage Transaction" [value]="value" [formGroupRef]="formGroupRef"
                              [isSaving]="isSaving" [isDisabled]="isSaving || isDeleting || value?.isDeleted" (saveClick)="onSaveClicked(true)">
  <app-base-entry-page-content-template>
    <div>
      <app-transaction-form *ngIf="value"
                        [(formGroupRef)]="formGroupRef"
                        [(value)]="value">
      </app-transaction-form>
    </div>
  </app-base-entry-page-content-template>
  <app-base-entry-page-footer-template>
    <span class="mr-2" *ngIf="!value?.isRegistry">
      <app-save-button (click)="onSaveClicked(true, false, true)" [text]="'Save & Add to Registry'" [isDisabled]="isSaving || isDeleting || value?.isDeleted" [isSaving]="isSaving"></app-save-button>
    </span>
    <span class="mr-2">
      <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving || isDeleting || value?.isDeleted">Cancel</button>
    </span>
  </app-base-entry-page-footer-template>
</app-base-entry-page-template>
