import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-base-grid-template',
  templateUrl: './base-grid-template.component.html',
  styleUrls: ['./base-grid-template.component.scss']
})
export class BaseGridTemplateComponent extends BaseComponent {
  @Input() gridOptions: GridOptions;
  @Input() showAddButton = true;
  @Input() showDeleteButton = true;

  @Input() gridTitle: string;

  @Input() filterText: string;
  @Output() filterTextChange = new EventEmitter<string>();

  @Output() addButtonClick = new EventEmitter();
  @Output() resetGridClick = new EventEmitter();
  @Output() exportGridClick = new EventEmitter();
  @Output() showDeletedClick = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit(): void {

  }

  onFilterTextChanged(): void {
    this.filterTextChange.emit(this.filterText);
  }

  onResetGridClicked(): void {
    this.filterText = '';
    this.resetGridClick.emit();
  }

  onAddButtonClicked(): void {
    this.addButtonClick.emit();
  }

  onExportButtonClicked(): void {
    this.exportGridClick.emit();
  }

  onShowDeletedToggled($event): void {
    this.showDeletedClick.emit($event.checked);
  }
}
