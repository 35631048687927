<form [formGroup]="formGroupRef" [hidden]="isLoading">
  <div class="flex">
    <div class="flex flex-direction-column" style="flex: 1 1 50%">
      <div class="form-section">
        <div class="form-section-header">Base Subscription</div>
        <div class="form-section-content">
          <div class="mt-2">
            <div class="inline-block">
              <mat-checkbox formControlName="isBaseSubscription" disabled="true"></mat-checkbox>
            </div>
            <div class="ml-1 inline-block" style="color: black">
              Base Subscription - ${{basePrice}} - monthly recurring fee until you cancel
            </div>
          </div>
        </div>
      </div>
      <div class="form-section" style="margin-top: 40px">
        <div class="form-section-header">Additional Add Ons & Services</div>
        <div class="form-section-content">
          <div class="mt-2">
            <div>
              <mat-checkbox formControlName="includeOrientation">1-on-1 Orientation - $200.00 up to 2 hours in one
                session
              </mat-checkbox>
            </div>
          </div>
          <div class="mt-2">
            <mat-checkbox formControlName="includePayroll">Payroll Plus Add On</mat-checkbox>
          </div>
          <div class="mt-3">
            <a [href]="publicUrl" target="_blank">Want to learn more about our additional
              services?</a>
          </div>
        </div>
        <div class="mt-5" *ngIf="value.includeOrientation || value.includePayroll">
          <div class="bold mt-1">
            <div>A SimpliDivine representative will contact you within 3 to 5 business days to schedule a time for your
              additional services. During your scheduled appointment, you will be required to pay for any additional
              services.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex" style="flex: 1 1 50%" *ngIf="!hasValidSubscription">
      <div class="form-section">
        <div class="form-section-header">Credit Card Information</div>
        <div class="form-section-content mt-2">
          <div class="credit-card-container">
            <div class="credit-row" style="width:275px">
              <label class="mat-form-field-label">Credit Card #</label>
              <div id="card-number"></div>
            </div>
            <div class="credit-row flex">
              <div class="flex flex-direction-column">
                <label class="mat-form-field-label">Expiration Date</label>
                <div style="width:100px" id="card-date"></div>
              </div>
              <div class="flex flex-direction-column">
                <label class="mat-form-field-label">CVV</label>
                <div style="width:50px" id="card-cvv"></div>
              </div>
            </div>
            <div class="credit-row">
              <label class="mat-form-field-label">Zip Code</label>
              <div style="width:100px" id="card-postal-code"></div>
            </div>
            <div>
              <hr/>
            </div>
            <div class="mt-1">
              <label class="mat-form-field-label">Base Price</label>
              <div style="width:100px">{{basePrice | currencyFormatter}}</div>
            </div>
            <div class="mt-1" *ngIf="isCalculatingRate">
              Calculating tax rates . . .
            </div>
            <ng-container *ngIf="salesTaxRate?.value && totalPrice">
              <div class="mt-1">
                <label class="mat-form-field-label">Sales Tax Rate</label>
                <div *ngIf="!salesTaxRate?.text" style="width:100px">(Calculated)</div>
                <div *ngIf="salesTaxRate?.text" style="width:150px">{{salesTaxRate?.text}}</div>
              </div>
              <div class="mt-3">
                <label class="mat-form-field-label bold">Total Monthly Subscription Fee</label>
                <div *ngIf="totalPrice" style="width:100px; font-weight: bold">{{totalPrice | currencyFormatter}}</div>
              </div>
            </ng-container>
          </div>
          <div class="mt-3 flex">
            <div class="flex">
              <mat-checkbox formControlName="hasAcceptedTerms">
              </mat-checkbox>
            </div>
            <div class="flex ml-2">By checking this box, you are accepting SimpliDivines privacy policy, terms of use,
              and acknowledging a monthly subscription fee will be incurred until you cancel.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex" style="flex: 1 1 50%" *ngIf="hasValidSubscription">
      <div class="form-section">
        <div class="form-section-header">Credit Card Details</div>
        <div class="form-section-content">
          <div class="mt-2">
            <div>
              Card # on file: {{value.firstDigits.toString().substring(0, 4)}}-****-****-{{value.lastDigits}}
            </div>
            <div class="mt-2">
              <span>Need to update your credit card</span>
              <ng-container *ngIf="!hasOrientation || !hasPayroll">
                <span> or add additional services such as </span>
                <span *ngIf="!hasOrientation">an orientation</span> <span *ngIf="hasOrientation && hasPayroll">or</span>
                <span *ngIf="!hasPayroll">Payroll Plus</span>
              </ng-container>?<br/><br/><a href="javascript:void(0)" (click)="onSupportClicked()">Contact Support</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
