export enum DocumentTypesEnum {
  Unknown,
  Legal = 1,
  Letter = 2,
  BusinessCard = 4,
  InvoiceTemplate = 5,
  //GAP
  Form = 8,
  Template = 9
}

