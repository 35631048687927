import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class RoutingService {
  public previousRoutes$ = new BehaviorSubject<string>(null);

  constructor (private router: Router) {

  }

  navigateToPreviousRoute(): boolean {
    if (this.previousRoutes$.value) {
      this.router.navigateByUrl(this.previousRoutes$.value);
      return true;
    }

    return false;
  }
}
