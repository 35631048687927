import { Component, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ClientModel } from 'src/app/shared/models/domain/client.model';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class ClientFormComponent extends BaseFormComponent<ClientModel> implements OnInit {
  @Input() isProfileSetup: boolean = false;
  constructor() {
    super()
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      clientKey: new UntypedFormControl(this.value?.clientKey),
      authUserId: new UntypedFormControl(this.value?.authUserId),
      firstName: new UntypedFormControl(this.value?.firstName),
      lastName: new UntypedFormControl(this.value?.lastName),
      phoneNumber: new UntypedFormControl(this.value?.phoneNumber),
      email: new UntypedFormControl(this.value?.email),
      policies: new UntypedFormControl(this.value?.policies),
      isLockedOut: new UntypedFormControl(this.value?.isLockedOut ?? false),
      isEmailVerified: new UntypedFormControl(this.value?.isEmailVerified ?? false),
      picture: new UntypedFormControl(this.value?.picture),
      address: new UntypedFormGroup({
        fullAddress: new UntypedFormControl(this.value?.address?.fullAddress),
        addressLineOne: new UntypedFormControl(this.value?.address?.addressLineOne),
        addressLineTwo: new UntypedFormControl(this.value?.address?.addressLineTwo),
        city: new UntypedFormControl(this.value?.address?.city),
        state: new UntypedFormControl(this.value?.address?.state),
        postalCode: new UntypedFormControl(this.value?.address?.postalCode),
        country: new UntypedFormControl(this.value?.address?.country),
        latitude: new UntypedFormControl(this.value?.address?.latitude),
        longitude: new UntypedFormControl(this.value?.address?.longitude),
      })
    })
  };

}
