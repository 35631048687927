import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import { DocumentRenderModel } from 'src/app/shared/models/domain/document-render.model';
import { DocumentSearchModel } from 'src/app/shared/models/domain/document-search.model';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-download-document-renderer-dialog',
  templateUrl: './download-document-renderer-dialog.component.html',
  styleUrls: ['./download-document-renderer-dialog.component.scss']
})
export class DownloadDocumentRendererDialogComponent extends BaseComponent implements OnInit {
  isAdmin: boolean = false;
  businessKey: string = null;
  value: DocumentRenderModel;
  documentTemplates: DocumentModel[] = [];
  selectedTemplateDocumentKey: string;

  constructor(
    private dialogRef: MatDialogRef<DownloadDocumentRendererDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {document: DocumentRenderModel, isAdmin: boolean},
    protected notificationService: NotificationService, private documentService: DocumentService) {
    super();
  }

  ngOnInit(): void {
    this.isAdmin = this.dialogData.isAdmin;
    this.businessKey = this.dialogData.document.businessKey;
    this.value = this.dialogData.document;

    const searchModel: DocumentSearchModel = {
      documentTypes: [DocumentTypesEnum.Template],
      businessKeys: this.isAdmin ? null : [this.businessKey]
    }

    this.documentService.search(searchModel).subscribe(documentTemplates => {
      this.documentTemplates = documentTemplates;
    })
  }
  ngAfterViewInit()
  {
    this.selectedTemplateDocumentKey = this.getDefaultDocTemplate();
  }

  onDownloadClicked(): void {
    this.showBlockingLoader = true;
    this.value.documentTemplateKey = this.selectedTemplateDocumentKey;

    this.documentService.downloadRendered(this.value).subscribe(_ => {
      this.showBlockingLoader = false;

      this.notificationService.showSuccessNotification('Document downloaded successfully.');
      this.dialogRef.close();
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close();
  }

  private readonly defaultDocTemplateKey = 'defaultDocTemplate' + this.businessKey;

  onTemplateChange($event: MatSelectChange) {
    localStorage.setItem(this.defaultDocTemplateKey, $event.value);
  }

  getDefaultDocTemplate() : string {
    return localStorage.getItem(this.defaultDocTemplateKey) ?? null;
  }
}
