import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { ClientModel } from 'src/app/shared/models/domain/client.model';

@Component({
  selector: 'app-clients',
  templateUrl: './clients-page.component.html',
  styleUrls: ['./clients-page.component.scss']
})
export class ClientsPageComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  onRowClicked(event: {data: ClientModel}): void {
     this.router.navigateByUrl(this.routeUtilities.routes.application.admin.clientEdit.getNavigateUrl(event.data.clientKey));
  }

  onAddButtonClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.admin.clientAdd.getNavigateUrl());
  }
}
