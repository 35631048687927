import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import { DocumentRenderModel } from 'src/app/shared/models/domain/document-render.model';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-document-renderer-view',
  templateUrl: './document-render-component-view.component.html',
  styleUrls: ['./document-render-component-view.component.scss']
})
export class DocumentRenderComponentView extends BaseComponent implements OnInit, OnChanges {
  @Input() documentRender: DocumentRenderModel;

  defaultHtml = '<p>Loading . . . </p>';
  documentHtml: string = this.defaultHtml ;

  constructor(private notificationService: NotificationService, private documentService: DocumentService) {
    super();
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.documentRender) {
      this.documentService.getRenderedHtml(this.documentRender).subscribe(html => {
        this.documentHtml = html.renderedHtml;
      }, err => {
        this.documentHtml = "<p class='error'>Invalid document content, please correct content and try again.</p>";
        this.onHttpFailure(this.notificationService, err);
      });
    }
  }
}
