import { InvoiceItemModel } from 'src/app/shared/models/domain/invoice-item.model';
import { InvoiceStatusesEnum } from 'src/app/shared/enums/domain/invoice-statuses.enum';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';
import { TaxRateModel } from 'src/app/shared/models/domain/tax-rate.model';

export class InvoiceModel {
  invoiceKey?: string;
  businessKey: string;
  type: InvoiceTypesEnum;
  isCredit: boolean;
  isDeleted: boolean;
  status: InvoiceStatusesEnum;
  number: string;
  date: Date;
  dueDate: Date;
  paidDate: Date;
  customer: CustomerModel;
  vendor: VendorModel;
  issuedPendingPaymentOnDateTime: Date;
  notes: string;
  subTotal: number;
  totalTax: number;
  taxRate: TaxRateModel;
  amountPaid: number;
  total: number;
  balanceDue: number;
  totalPayments: number;
  items: InvoiceItemModel[] = [];
}

