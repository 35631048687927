import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { DashboardTransactionModel } from 'src/app/shared/models/domain/dashboard-transaction.model';

//https://valor-software.com/ng2-charts/
//https://www.npmjs.com/package/ng2-charts/v/4.1.1

@Component({
  selector: 'app-dashboard-transactions',
  templateUrl: './dashboard-transactions.component.html',
  styleUrls: ['./dashboard-transactions.component.scss', '../shared/dashboard.component.scss']
})
export class DashboardTransactionsComponent implements OnInit {
  @Input() data: DashboardTransactionModel[] = null;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  hasData: boolean = false;

  barChartOptions: ChartConfiguration['options'] = null;
  barChartData: ChartData<'bar'> = null;
  chartType = 'bar';

  constructor() {
  }

  ngOnInit(): void {
    this.hasData = !!this.data.length;

    this.initBarChartOptions();
    this.initBarChartData();
  }

  initBarChartOptions(): void {
    let that = this;

    this.barChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            // footer: (tooltipItems) => {
            //   let currentLabel = tooltipItems[0].label;
            //
            //   let value = 0;
            //   if (that.isInvoice) {
            //     if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Draft) === currentLabel) {
            //       value = that.data.paidInvoicesTotal;
            //     } else if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Issued) === currentLabel) {
            //       value = that.data.issuedInvoicesTotal;
            //     } else if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Paid) === currentLabel) {
            //       value = that.data.paidInvoicesTotal;
            //     }
            //   } else {
            //     if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Draft) === currentLabel) {
            //       value = that.data.paidBillsTotal;
            //     } else if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.PendingPayment) === currentLabel) {
            //       value = that.data.pendingPaymentBillsTotal;
            //     } else if (EnumUtilities.getDisplayName(InvoiceStatusesEnum, InvoiceStatusesEnum.Paid) === currentLabel) {
            //       value = that.data.paidBillsTotal;
            //     }
            //   }
            //
            //   return `Total: ${NumberUtilities.formatAsCurrency(value)}`
            // }
          }
        }
      },
    };
  }

  initBarChartData(): void {
    this.barChartData = {
      labels: [],
      datasets: [{
        data: [],
        label: 'Credits',
        backgroundColor: ['green']
       },
      {
        data: [],
        label: 'Debits',
        backgroundColor: ['red']
      }]
    }

    for (let datum of this.data) {
      this.barChartData.labels.push(datum.month);
      this.barChartData.datasets[0].data.push(datum.credits);
      this.barChartData.datasets[1].data.push(datum.debits);
    }

  }
}
