import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { ChartOfAccountTypesEnum } from 'src/app/shared/enums/domain/chart-of-account-types.enum';
import { NumberUtilities } from 'src/app/core/utilities/number.utilities';
import { ColorUtilities } from 'src/app/core/utilities/color.utilities';

//https://valor-software.com/ng2-charts/
//https://www.npmjs.com/package/ng2-charts/v/4.1.1

@Component({
  selector: 'app-dashboard-chart-of-accounts',
  templateUrl: './dashboard-chart-of-accounts.component.html',
  styleUrls: ['./dashboard-chart-of-accounts.component.scss', '../shared/dashboard.component.scss']
})
export class DashboardChartOfAccounts implements OnInit {
  @Input() data: ChartOfAccountModel[] = [];

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  hasData: boolean = false;

  doughnutChartOptions: ChartConfiguration['options'] = null;
  doughnutChartData: ChartData<'doughnut', number[], string | string[]> = null;
  chartType = 'doughnut';

  constructor() {
  }

  ngOnInit(): void {
    this.hasData = !!this.data?.find(t => t.totalAmount > 0);

    this.initDoughnutChartOptions();
    this.initDoughnutChartData();
  }

  initDoughnutChartOptions(): void {
    let that = this;

    this.doughnutChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: false
        }
      }
    };
  }

  initDoughnutChartData(): void {
    let colors = ColorUtilities.listColors();

    this.doughnutChartData = {
      labels: [],
      datasets: []
    }

    let groupedData = {};
    for (let chartOfAccount of this.data.filter(t => t.totalAmount > 0)) {
      if (!groupedData[chartOfAccount.type]) {
        groupedData[chartOfAccount.type] = [];
      }

      groupedData[chartOfAccount.type].push({
        label: chartOfAccount.name,
        data: chartOfAccount.totalAmount
      });
    }

    let i = 0;
    for (let groupedDataKey of Object.keys(groupedData)) {
      if (i >= colors.length) {
        i = 0;
      }

      let label = EnumUtilities.getDisplayName(ChartOfAccountTypesEnum, parseInt(groupedDataKey, 10));
      //this.doughnutChartData.labels.push(label);

      let totalData = groupedData[groupedDataKey].data;
      let totalDataLabel = groupedData[groupedDataKey].label;

      for (let groupedDataPoint of groupedData[groupedDataKey]) {
        this.doughnutChartData.datasets.push({
          label: `${label} - ${totalDataLabel}`,
          backgroundColor: colors[i],
          data: totalData
        })
      }
    }

    console.log(this.doughnutChartData.datasets);
  }
}
