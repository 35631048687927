import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessTypesEnum } from 'src/app/shared/enums/domain/business-types.enum';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { EmployeeModel } from 'src/app/shared/models/domain/employee.model';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss']
})
export class EmployeeFormComponent extends BaseFormComponent<EmployeeModel> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      employeeKey: new UntypedFormControl(this.value?.employeeKey),
      businessKey: new UntypedFormControl(this.value?.businessKey),

      email: new UntypedFormControl(this.value?.email),
      firstName: new UntypedFormControl(this.value?.firstName),
      lastName: new UntypedFormControl(this.value?.lastName),

      address: new UntypedFormGroup({
        fullAddress: new UntypedFormControl(this.value?.address?.fullAddress),
        addressLineOne: new UntypedFormControl(this.value?.address?.addressLineOne),
        addressLineTwo: new UntypedFormControl(this.value?.address?.addressLineTwo),
        city: new UntypedFormControl(this.value?.address?.city),
        state: new UntypedFormControl(this.value?.address?.state),
        postalCode: new UntypedFormControl(this.value?.address?.postalCode),
        country: new UntypedFormControl(this.value?.address?.country)
      }),
      phoneNumber: new UntypedFormControl(this.value?.phoneNumber),
      title: new UntypedFormControl(this.value?.title),
      startDate: new UntypedFormControl(this.value?.startDate),
      endDate: new UntypedFormControl(this.value?.endDate)
    })
  };
}
