import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { VendorService } from 'src/app/core/services/domain/vendor.service';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { BusinessFileModel } from 'src/app/shared/models/domain/business-file.model';
import { BusinessFileService } from 'src/app/core/services/domain/business-file.service';

@Component({
  selector: 'app-business-file-form-dialog',
  templateUrl: './business-file-form-dialog.component.html'
})
export class BusinessFileFormDialogComponent extends BaseEntryPageComponent<BusinessFileModel> {
  fileList: FileList;

  constructor(
    public dialogRef: MatDialogRef<BusinessFileFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { businessFile: BusinessFileModel },
    private businessContextService: BusinessContextService,
    private businessFileService: BusinessFileService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.value = this.dialogData.businessFile;
  }

  onSaveClicked(): void {
    this.isSaving = true;
    this.showBlockingLoader = true;

    this.value.isAdmin = this.isAdminRoute();

    if (!this.value.businessFileKey) {
      this.businessFileService.create(this.value, this.fileList).subscribe(businessFileKey => {
        this.onSaveSuccess('File was created successfully.');
        this.showBlockingLoader = false;
        this.dialogRef.close(businessFileKey);
      }, err => {
        this.showBlockingLoader = false;
        this.onSaveFailed(err);
      });
    } else {
      this.businessFileService.update(this.value, this.fileList).subscribe(_ => {
        this.onSaveSuccess('File was updated successfully.');
        this.showBlockingLoader = false;
        this.dialogRef.close(true);

      }, err => {
        this.showBlockingLoader = false;
        this.onSaveFailed(err);
      });
    }
  }

  onCancelClicked(): void {
    this.dialogRef.close(null);
  }
}
