<app-base-entry-page-template entryTitle="Manage Document" [value]="value" [formGroupRef]="formGroupRef"
                              [saveText]="isGlobalDocument ? 'Copy & Save' : 'Save'"
                              [isSaving]="isSaving" [isDisabled]="isSaving || isDeleting" (saveClick)="onSaveClicked()" (cancelClick)="onCancelClicked()">
  <app-base-entry-page-content-template>
      <app-document-form *ngIf="value"
                         [businessKey]="businessKey"
                         [isAdmin]="isAdmin"
                         [(formGroupRef)]="formGroupRef"
                         [(value)]="value" [(fileList)]="fileList"
                         (downloadRenderedClick)="onDownloadRenderedClicked($event)"
                         (emailRenderedClick)="onEmailRenderedClicked($event)">
      </app-document-form>
  </app-base-entry-page-content-template>
  <app-base-entry-page-footer-template>
    <app-save-button *ngIf="value?.documentKey && ((this.isAdmin && !this.businessKey) || (!this.isAdmin && this.businessKey))" text="Delete" processingText="Deleting" [isSaving]="isDeleting" [isDisabled] ="isSaving || isDeleting" (click)="onDeleteClicked()"></app-save-button>
  </app-base-entry-page-footer-template>
</app-base-entry-page-template>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
