import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';
import { VendorSearchModel } from 'src/app/shared/models/domain/vendor-search.model';

@Injectable()
export class VendorService {
  private url = environment.apiUrl + '/vendors/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<VendorModel> {
    return this.http.get<VendorModel>(`${this.url}${key}`);
  }

  search(model: VendorSearchModel): Observable<VendorModel[]> {
    return this.http.post<VendorModel[]>(`${this.url}search`, model);
  }

  create(model: VendorModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model)
  }

  update(model: VendorModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model)
  }
}
