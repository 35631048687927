<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-header">Send Invoice via Email</div>
    <div class="form-section-content">
      <div class="mt-2">
        <mat-form-field>
          <mat-label>Template</mat-label>
          <mat-select formControlName="invoiceTemplateDocumentKey"
                      (selectionChange)="onInvoiceTemplateChange($event)"
                      [(value)]="defaultInvoiceTemplateValue">
            <mat-option [value]="null">(No Template)</mat-option>
            <mat-option *ngFor="let template of invoiceTemplates"
                        [value]="template.documentKey">{{template.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container formGroupName="email">
        <div class="mt-2">
          <mat-form-field class="mr-2">
            <mat-label>To Address</mat-label>
            <input matInput type="text" formControlName="toEmailAddress">
          </mat-form-field>
          <mat-form-field>
            <mat-label>To Address Alias</mat-label>
            <input matInput type="text" formControlName="toEmailAddressAlias">
          </mat-form-field>
        </div>
        <div class="mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label>Subject</mat-label>
            <input matInput type="text" formControlName="subject">
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </div>
</form>
