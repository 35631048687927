<div class="grid-container">
  <div class="grid-header flex flex-wrap">
    <div class="flex grid-header-left-container">
      <h1 *ngIf="gridTitle" class="grid-title-container">{{gridTitle}}</h1>
      <button mat-mini-fab color="primary" *ngIf="showAddButton && addButtonClick.observers.length" (click)="onAddButtonClicked()">
        <mat-icon>add</mat-icon>
      </button>
      <div class="flex ml-2">
        <ng-content select="app-base-grid-header-template"></ng-content>
        <span class="pt-1" *ngIf="showDeleteButton && showDeletedClick.observers.length">
          <mat-slide-toggle class="mr-1" (change)="onShowDeletedToggled($event)">Show Deleted</mat-slide-toggle>
        </span>
      </div>
    </div>
    <div class="filter-container">
      <mat-form-field class="example-form-field mr-1">
        <mat-label>Filter by text . . .</mat-label>
        <input matInput type="text" [(ngModel)]="filterText" (input)="onFilterTextChanged()">
        <button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="filterText='';onFilterTextChanged()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button [matTooltip]="'Reset'" mat-icon-button (click)="onResetGridClicked()">
        <mat-icon>restart_alt</mat-icon>
      </button>&nbsp;
      <button [matTooltip]="'Export'" mat-icon-button (click)="onExportButtonClicked()">
        <mat-icon>download</mat-icon>
      </button>
    </div>
  </div>
  <mat-card class="mat-card-grid">
    <mat-card-content class="mat-card-grid-content">
      <ng-content select="app-base-grid-content-template"></ng-content>
    </mat-card-content>
  </mat-card>
</div>

