import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class AuthAdminGuard implements CanActivate {
  constructor(
    private authClientService: AuthClientService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router) {
  }

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(first(), tap(isAuthenticated => {
      if (!isAuthenticated || !this.authClientService.isAdmin) {
        this.notificationService.showErrorNotification('You are not authorized to see this view.');
        this.router.navigateByUrl(RouteUtilities.routes.static.base.getNavigateUrl());
      }

      return isAuthenticated;
    }));
  }
}
