<div class="entry-content">
  <ng-container *ngIf="!value">
    <app-loader></app-loader>
  </ng-container>
  <ng-container *ngIf="value">
    <ng-content></ng-content>
  </ng-container>
</div>
<div class="entry-footer" *ngIf="showFooter">
  <ng-container *ngIf="value">
          <span class="mr-2">
            <app-save-button (click)="onSaveClicked()" [text]="saveText" [processingText]="savingText" [isDisabled]="!formGroupRef.dirty || isSaving || isDisabled" [isSaving]="isSaving"></app-save-button>
          </span>
    <span class="mr-2" *ngIf="showDelete">
            <button mat-flat-button color="primary" (click)="onDeleteClicked()" [disabled]="isSaving || isDisabled">Delete</button>
          </span>
    <span class="mr-2" *ngIf="showCancel">
            <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving || isDisabled">Cancel</button>
          </span>
    <ng-content select="app-base-entry-page-footer-template"></ng-content>
  </ng-container>
</div>
