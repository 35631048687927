export class AddressModel {
  fullAddress: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  latitude?: number;
  longitude?: number;
  distance?: number;

  isMailingAddressSame: boolean;
  fullAddressMailing: string;
  addressLineOneMailing: string;
  addressLineTwoMailing: string;
  cityMailing: string;
  stateMailing: string;
  postalCodeMailing: string;
  countryMailing: string;
  latitudeMailing?: number;
  longitudeMailing?: number;
  distanceMailing?: number;
}
