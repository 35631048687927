<app-base-page-template pageTitle="Manage Businesses">
  <app-base-page-content-template>
    <div class="businesses-container">
        <mat-card *ngFor="let b of businesses" class="mb-1 business-card" (click)="onEditBusinessClick(b)">
          <div class="business-card-header">
            {{b.name}}
          </div>
          <mat-card-content class="business-card-content">
            <div class="business-card-logo" *ngIf="b?.file?.uri">
              <img [src]="b.file.uri" />
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="business-card business-card-add" (click)="onAddBusinessClick()">
          <mat-card-content>
            <mat-icon>add</mat-icon>
          </mat-card-content>
        </mat-card>
    </div>
  </app-base-page-content-template>
</app-base-page-template>
