import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '@auth0/auth0-angular';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { Router } from '@angular/router';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { BankingConstants } from 'src/app/shared/models/auth/constants/banking.constants';
import { BankingService } from 'src/app/core/services/domain/banking.service';
import { environment } from 'src/environments/environment';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { SupportService } from 'src/app/core/services/domain/support.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { ClientToBusinessAccessService } from 'src/app/core/services/domain/client-to-business-access.service';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { ScriptService } from 'src/app/core/services/app/script.service';

@Component({
  selector: 'app-application-page',
  templateUrl: './application-page.component.html',
  styleUrls: ['./application-page.component.scss']
})
export class ApplicationPageComponent extends BasePageComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  year = new Date().getFullYear();
  documentTypesEnum = DocumentTypesEnum;
  invoiceTypesEnum = InvoiceTypesEnum;
  hasValidLicense = false;

  constructor(
    public authClientService: AuthClientService,
    public businessContextService: BusinessContextService,
    private businessSubscriptionService: BusinessSubscriptionService,
    private authService: AuthService,
    private dialogService: DialogService,
    private bankingService: BankingService,
    private notificationService: NotificationService,
    private supportService: SupportService,
    private scriptService: ScriptService,
    private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.scriptService.loadScript(window.location.origin + '/assets/files/chat-bot.js').subscribe();

    if (!this.authClientService.client.isEmailVerified) {
      this.router.navigate([this.routeUtilities.routes.application.profileSetup.getNavigateUrl()]);
    } else {
      this.subscriptions.add(this.businessContextService.currentBusiness$.subscribe(t => {
        this.syncTransactions();

        this.businessSubscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey).subscribe(result => {
          this.hasValidLicense = result;
        });
      }));
    }

    if (this.businessContextService.currentBusiness?.businessKey) {
        this.syncTransactions();

      this.businessSubscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey).subscribe(result => {
        this.hasValidLicense = result;
      });
    }

    this.subscriptions.add(this.businessSubscriptionService.subscriptionChanged$.subscribe(_ => {
      this.businessSubscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey).subscribe(result => {
        this.hasValidLicense = result;
      });
    }));
  }

  onChangeBusinessClicked(business: BusinessModel): void {
    this.businessContextService.updateBusinessContext(business);

    this.reloadPage(this.router, RouteUtilities.routes.application.dashboard.getNavigateUrl());
  }

  onNavigateClicked(route: string): void {
    this.router.navigateByUrl(route);
  }

  onPayrollClicked(): void {
    if (this.hasValidLicense) {
      this.dialogService.openPayroll();
    } else {
      this.router.navigateByUrl(this.routeUtilities.routes.application.subscriptions.getNavigateUrl());
    }
  }

  getInvoiceTypeQueryParams(invoiceType: InvoiceTypesEnum, customerKey: string = null): any {
    const obj = {};
    obj[this.routingKeysUtilities.queryParams.invoiceType] = invoiceType;
    if(customerKey) {
      obj[this.routingKeysUtilities.queryParams.customerKey] = customerKey;
    }

    return obj;
  }

  getDocumentTypeQueryParams(documentType: DocumentTypesEnum): any {
    const obj = {};
    obj[this.routingKeysUtilities.queryParams.documentType] = documentType;

    return obj;
  }

  onLogoutClicked(): void {
    this.authClientService.client = null;
    this.authService.logout({ returnTo: document.location.origin })
  }

  onNavigateToHelpSchedulingClicked(): void {
    this.supportService.initScheduleSupport();
  }

  private syncTransactions(): void {
    if (environment.enableTransactionSync) {
      this.bankingService.sync(this.businessContextService.currentBusiness.businessKey).subscribe(() => {
      }, (err) => {
        if (err?.length) {
          let errorMsg = err[0].message;

          if (errorMsg == BankingConstants.BankingLoginRequired) {
            this.notificationService.showErrorNotification('A banking institution link has expired.');
          } else {
            this.notificationService.showErrorNotification('Transactions failed to be synced.');
          }
        }
      });
    }
  }
}
