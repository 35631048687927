import { Component } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';
import { VendorService } from 'src/app/core/services/domain/vendor.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';

@Component({
  selector: 'app-vendors-grid',
  templateUrl: './vendors-grid.component.html',
  styleUrls: ['./vendors-grid.component.scss']
})
export class VendorsGridComponent extends BaseGridComponent<VendorModel> {
  constructor(public authClientService: AuthClientService,
              private businessContextService: BusinessContextService,
              private vendorService: VendorService) {
    super();
    this.gridKey = 'Vendors_v2';
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: 'Key',
        field: 'vendorKey',
        hide: !this.authClientService.hasAuthorization(this.authorization.superAdmin)
      },
      {
        headerName: 'Name',
        field: 'name'
      },
      {
        headerName: 'Email',
        field: 'email',
      },
      {
        headerName: 'Phone #',
        field: 'phoneNumber',
      }
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((data: {data: TransactionModel, event: CellClickedEvent }) => {

    }));
  }

  protected setRowData(): Observable<any[]> {
    return this.vendorService.search({businessKey: this.businessContextService.currentBusiness.businessKey, includeDeleted: this.includeDeleted});
  }
}
