import { Component, Input } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { CellClickedEvent, ColDef, ICellRendererParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { JobModel } from 'src/app/shared/models/domain/job.model';
import { JobService } from 'src/app/core/services/domain/job.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { JobStatusesEnum } from 'src/app/shared/enums/domain/job-statuses.enum';
import { NumberUtilities } from 'src/app/core/utilities/number.utilities';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jobs-grid',
  templateUrl: './jobs-grid.component.html',
  styleUrls: ['./jobs-grid.component.scss']
})
export class JobsGridComponent extends BaseGridComponent<JobModel> {
  @Input() customerKey: string = null;

  private readonly customerNameFieldName = 'customer.name';

  constructor(private businessContextService: BusinessContextService,
              private jobService: JobService,
              private router: Router) {
    super();
    this.gridKey = 'Jobs_v1';
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: 'Name',
        field: 'name'
      },
      this.getEnumColumn('status', 'Status', JobStatusesEnum),
      this.getLinkColumn(this.customerNameFieldName, 'Customer', null, !!this.customerKey),
      this.getDateColumn('startDate', 'Start Date'),
      this.getDateColumn('endDate', 'End Date'),
      {
        headerName: 'Profit',
        field: 'profit',
        hide: false,
        cellRenderer: function(params: ICellRendererParams): string {
          if (params.data.profit > 0) {
            return `<span style="color:green">${NumberUtilities.formatAsCurrency(params.data.profit)}</span>`;
          } else if (params.data.profit < 0) {
            return `<span style="color:red">${NumberUtilities.formatAsCurrency(params.data.profit)}</span>`;
          }

          return NumberUtilities.formatAsCurrency(params.data.profit);
        },
      },
      this.getCurrencyColumn('income', 'Income'),
      this.getCurrencyColumn('expenses', 'Expenses')
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((data: { data: JobModel, event: CellClickedEvent }) => {
      if (data.event.colDef.field === this.customerNameFieldName) {
        if (data.data.customer?.customerKey) {
          this.router.navigateByUrl(RouteUtilities.routes.application.customerEdit.getNavigateUrl(data.data.customer.customerKey));
        } else {
          this.rowClick.emit(data);
        }
      }
    }));
  }

  protected setRowData(): Observable<any[]> {
    return this.jobService.search({businessKey: this.businessContextService.currentBusiness.businessKey, includeDeleted: this.includeDeleted, customerKey: this.customerKey});
  }
}
