import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EmployeeModel } from 'src/app/shared/models/domain/employee.model';
import { EmployeeSearchModel } from 'src/app/shared/models/domain/employee-search.model';

@Injectable()
export class EmployeeService {
  private url = environment.apiUrl + '/employees/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<EmployeeModel> {
    return this.http.get<EmployeeModel>(`${this.url}${key}`);
  }

  search(model: EmployeeSearchModel): Observable<EmployeeModel[]> {
    return this.http.post<EmployeeModel[]>(`${this.url}search`, model);
  }

  create(model: EmployeeModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model)
  }

  update(model: EmployeeModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model)
  }
}
