import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';

@Component({
  selector: 'app-request-service-view',
  templateUrl: './request-service-view.component.html',
  styleUrls: ['./request-service-view.component.scss']
})
export class RequestServiceViewComponent extends BaseComponent implements OnInit {
  @Input() business: BusinessModel;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
