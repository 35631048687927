export const environment = {
  production: true,
  enableTransactionSync: false,
  isSandboxBanking: false,
  appInsights: {
    instrumentationKey: '4c0acbd9-b80d-4afa-ace8-9447763c8c2e'
  },
  apiUrl: 'https://simplidivine-prod-app.azurewebsites.net/api',
  googleMapsApiUrl: 'https://maps.googleapis.com/maps/api/js',
  googlePlacesApiUrl: 'https://places.googleapis.com/v1/places',
  googleMapApiKey: 'AIzaSyCZM-WKM7QdPeQ462y9Vgm_dhxVWMMhbM0',
  basePrice: 89.99,
  publicSiteUrl: 'https://simplidivine.com/',
  auth0: {
    domain: 'prod-simplidivine.us.auth0.com',
    clientId: 'gcSmNVMWQujc8hG81AnJRYc86rcrN8TB',
    audience: 'https://simplidivine-api.com'
  },
  clover:  {
    iFrameUrl: 'https://checkout.clover.com/sdk.js',
    eCommPublicToken: '70c094db5de4ef9a2df6caba62a83356'
  },
  tinyMceLicenseKey: 'qkrkr2ubck15zucupms124zc2zwc883p0rn8tvnj23zqtrdg',
  payrollLink: 'https://www.runpayroll.com/SPF/Login/Auth1.aspx?PC=TVLM'
};


