import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents-page.component.html',
  styleUrls: ['./documents-page.component.scss']
})
export class DocumentsPageComponent extends BasePageComponent implements OnInit {
  isAdmin = false;
  businessKey: string;
  documentType: DocumentTypesEnum = null;

  constructor(
    private router: Router,
    private businessContextService: BusinessContextService,
    private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.isAdmin = this.isAdminRoute();
    this.documentType = +this.getQueryParameterFromRoute(this.routingKeysUtilities.queryParams.documentType, this.activatedRoute.snapshot) || DocumentTypesEnum.Unknown;

    if (!this.isAdmin) {
      this.businessKey = this.businessContextService.currentBusiness.businessKey
    }

    this.activatedRoute.queryParams.subscribe(_ => {
      this.documentType = +this.getQueryParameterFromRoute(this.routingKeysUtilities.queryParams.documentType, this.activatedRoute.snapshot) || DocumentTypesEnum.Unknown;
    });
  }

  onRowClicked(event: {data: DocumentModel}): void {
    if (this.isAdmin) {
      this.router.navigateByUrl(this.routeUtilities.routes.application.admin.documentEdit.getNavigateUrl(event.data.documentKey));
    } else {
      this.router.navigateByUrl(this.routeUtilities.routes.application.documentEdit.getNavigateUrl(event.data.documentKey));
    }
  }

  onAddButtonClicked(): void {
    if (this.isAdmin) {
      this.router.navigateByUrl(this.routeUtilities.routes.application.admin.documentAdd.getNavigateUrl(this.documentType));
    } else {
      this.router.navigateByUrl(this.routeUtilities.routes.application.documentAdd.getNavigateUrl(this.documentType));
    }
  }
}
