import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OfferingModel } from 'src/app/shared/models/domain/offering.model';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { OfferingTypesEnum } from 'src/app/shared/enums/domain/offering-types.enum';
import { OfferingServiceFrequencyEnum } from 'src/app/shared/enums/domain/offering-service-frequency.enum';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { OfferingService } from 'src/app/core/services/domain/offering.service';
import { tap } from 'rxjs/operators';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { StringUtilities } from 'src/app/core/utilities/string.utilities';

@Component({
  selector: 'app-offering-form',
  templateUrl: './offering-form.component.html',
  styleUrls: ['./offering-form.component.scss']
})
export class OfferingFormComponent extends BaseFormComponent<OfferingModel> implements OnInit {
  private chartOfAccountTrigger: MatAutocompleteTrigger;
  @ViewChild('chartOfAccountInput', { read: MatAutocompleteTrigger }) set chartOfAccountContent(content: MatAutocompleteTrigger) {
    if (content && !this.chartOfAccountTrigger) {
      this.chartOfAccountTrigger = content;
      this.subscriptions.add(this.chartOfAccountTrigger.panelClosingActions.subscribe(e => {
        let textValue = this.value.chartOfAccount.chartOfAccountKey;

        //a text value is already selected don't take the text, in this case the value is a guid.
        if (StringUtilities.isUUID(textValue)) {
          textValue = null;
        }

        if (!(e && e.source)) {
          this.chartOfAccountKeyControl.setValue(null);
          this.chartOfAccountTrigger.closePanel();
        } else {
          let chartOfAccount = this.chartOfAccounts.find(t => t.chartOfAccountKey === e.source.value);

          if (!chartOfAccount) {
            this.chartOfAccountKeyControl.setValue(null);
            this.chartOfAccountTrigger.closePanel();

            if (e.source.value === '-1') {
              this.addChartOfAccountClicked(textValue);
            }
          } else {
            this.chartOfAccountKeyControl.setValue(e.source.value);
          }
        }
      }));
    }
  }

  offeringTypes: EnumModel[] = [];
  offeringServiceFrequencies: EnumModel[] = [];

  filteredCategories: string[] = [];
  categories: string[];

  filteredChartOfAccounts: ChartOfAccountModel[] = [];
  chartOfAccounts: ChartOfAccountModel[];
  chartOfAccountKeyControl: UntypedFormControl;

  showServiceFields = false;
  showProductFields = false;

  constructor(private offeringService: OfferingService, private dialogService: DialogService, private chartOfAccountService: ChartOfAccountService) {
    super();
  }

  ngOnInit(): void {
    this.offeringTypes = EnumUtilities.convertToSelectModels(OfferingTypesEnum, true);
    this.offeringServiceFrequencies = EnumUtilities.convertToSelectModels(OfferingServiceFrequencyEnum, true);

    this.chartOfAccountService.search({businessKey: this.value.businessKey}).subscribe(chartOfAccounts => {
      this.chartOfAccounts = this.chartOfAccountService.filterWithoutBankAccounts(chartOfAccounts);;
      this.filteredChartOfAccounts = this.chartOfAccountService.filterWithoutBankAccounts(chartOfAccounts);;
    });

    this.offeringService.listCategories(this.value.businessKey).subscribe(categories => {
      this.categories = categories;
      this.filteredCategories = categories;
    });

    super.ngOnInit();

    this.onOfferingTypeChanged();

    this.subscriptions.add(this.chartOfAccountKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredChartOfAccounts = this.chartOfAccounts;
        } else {
          this.filteredChartOfAccounts = this.chartOfAccounts.filter(coa => coa.fullDisplayPath.toLowerCase()
            .indexOf(value.toLowerCase()) !== -1);
        }
      })
    ).subscribe());
  }

  chartOfAccountDisplayFn(chartOfAccountKey: string): string {
    let foundResult = this.chartOfAccounts?.find(t => t.chartOfAccountKey === chartOfAccountKey);
    return foundResult?.fullDisplayPath ?? '';
  }

  onCategoryKeyUp(): void {
    if (this.value?.category) {
      this.filteredCategories = this.categories.filter(t => t.toLowerCase().indexOf(this.value.category.toLowerCase()) > -1);
    } else {
      this.filteredCategories = this.categories;
    }
  }

  onCategoryChanged(): void {
    let value = this.value.category;
    if (value && !this.categories.find(t => t && t.toLowerCase() == value.toLowerCase())) {
      this.categories.push(value);
      this.filteredCategories.push(value);
    }

    this.formGroupRef.get('category').setValue(value);
  }

  onOfferingTypeChanged(): void {
    if (this.value.type === OfferingTypesEnum.Product) {
      this.showProductFields = true;
      this.showServiceFields = false;
      this.formGroupRef.get('frequency').setValue(0);
    }

    if (this.value.type === OfferingTypesEnum.Service) {
      this.showServiceFields = true;
      this.showProductFields = false;
      this.formGroupRef.get('inventoryTotal').setValue(null);
    }

    if (!this.value.type) {
      this.showServiceFields = false;
      this.showProductFields = false;
      this.formGroupRef.get('inventoryTotal').setValue(null);
      this.formGroupRef.get('frequency').setValue(0);
    }
  }

  public getFormGroup(value: OfferingModel = null): UntypedFormGroup {
    this.chartOfAccountKeyControl = new UntypedFormControl(this.value?.chartOfAccount?.chartOfAccountKey);

    return OfferingFormHelper.getFormGroup(this.value, this.chartOfAccountKeyControl);
  };

  addChartOfAccountClicked(name: string): void {
    const dialogRef = this.dialogService.openChartOfAccountForm(name);
    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.chartOfAccountService.search({businessKey: this.value.businessKey}).subscribe(chartOfAccounts => {
          this.chartOfAccounts = [...chartOfAccounts];
          this.filteredChartOfAccounts = [...chartOfAccounts];
          this.chartOfAccountKeyControl.setValue(resultOrKey);
        });
      }
    })
  }
}

export class OfferingFormHelper {
  //SEE INVOICE FORM
  static getFormGroup(value: OfferingModel, chartOfAccountKeyControl = null): UntypedFormGroup {
    return new UntypedFormGroup({
      offeringKey: new UntypedFormControl(value?.offeringKey),
      businessKey: new UntypedFormControl(value?.businessKey),
      name: new UntypedFormControl(value?.name),
      type: new UntypedFormControl(value?.type ?? OfferingTypesEnum.Unknown),
      sku: new UntypedFormControl(value?.sku),
      chartOfAccount: new UntypedFormGroup({
        chartOfAccountKey: chartOfAccountKeyControl ?? new UntypedFormControl(value?.chartOfAccount?.chartOfAccountKey)
      }),
      category: new UntypedFormControl(value?.category),
      description: new UntypedFormControl(value?.description),
      price: new UntypedFormControl(value?.price),
      isTaxable: new UntypedFormControl(value?.isTaxable ?? false),
      taxRate: new UntypedFormControl(value?.taxRate),
      frequency: new UntypedFormControl(value?.frequency ?? OfferingServiceFrequencyEnum.Unknown),
      inventoryTotal: new UntypedFormControl(value?.inventoryTotal),
    })
  }
}
