<app-base-entry-page-template entryTitle="Manage Job" [value]="value" [formGroupRef]="formGroupRef"
                              [isSaving]="isSaving" (saveClick)="onSaveClicked()" (cancelClick)="onCancelClicked()" [showFooter]="selectedTabIndex === 0">
  <app-base-entry-page-content-template>
    <mat-tab-group #matTabGroup [(selectedIndex)]="selectedTabIndex">
      <mat-tab label="General Information">
        <app-job-form *ngIf="value"
                      [(formGroupRef)]="formGroupRef"
                      [(value)]="value">
        </app-job-form>
      </mat-tab>
      <mat-tab label="Invoices" *ngIf="value?.jobKey">
        <div class="mt-2">
          <app-invoices-grid
            [invoiceType]="invoiceTypes.Invoice"
            [jobKey]="value?.jobKey"
            (addButtonClick)="onAddInvoiceClicked()"
            (rowClick)="onEditInvoiceClicked($event)">
          </app-invoices-grid>
        </div>
      </mat-tab>
      <mat-tab label="Bills" *ngIf="value?.jobKey">
        <div class="mt-2">
          <app-invoices-grid
            [invoiceType]="invoiceTypes.Bill"
            [jobKey]="value?.jobKey"
            (addButtonClick)="onAddBillClicked()"
            (rowClick)="onEditBillClicked($event)">
          </app-invoices-grid>
        </div>
      </mat-tab>
    </mat-tab-group>
  </app-base-entry-page-content-template>
</app-base-entry-page-template>




