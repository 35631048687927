<div [formGroup]="formGroupRef">
  <div>
    <button mat-flat-button color="primary" (click)="onBackToReportsClicked()" [disabled]="isSaving">Back to Reports</button>
  </div>
  <div>
    <div class="float-left mt-2" *ngIf="reportType !== reportTypes.ARAPSummary && reportType !== reportTypes.ARAPInvoice && reportType !== reportTypes.ARAPRegistry">
      <mat-form-field class="mat-form-field-date mr-2">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startDtPicker" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="startDtPicker"></mat-datepicker-toggle>
        <mat-datepicker #startDtPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="mat-form-field-date mr-2">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endDtPicker" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="endDtPicker"></mat-datepicker-toggle>
        <mat-datepicker #endDtPicker></mat-datepicker>
      </mat-form-field>

      <div class="inline-block">
        <mat-chip-list>
          <mat-chip (click)="setThisMonth()">This Month</mat-chip>
          <mat-chip (click)="setLastMonth()">Last Month</mat-chip>
          <mat-chip (click)="setThisYear()">This Year</mat-chip>
          <mat-chip (click)="setLastYear()">Last Year</mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="float-right mt-2">
      <mat-form-field class="mr-2">
        <mat-label>Template</mat-label>
        <mat-select formControlName="templateKey">
          <mat-option [value]="null">(No Template)</mat-option>
          <mat-option *ngFor="let template of templates"
                      [value]="template.documentKey">{{template.name}}</mat-option>
        </mat-select>
      </mat-form-field>
<!--      <div class="inline-block mr-2">-->
<!--        <mat-button-toggle-group (change)="setRenderedReport()" [(ngModel)]="selectedView"-->
<!--                                 [ngModelOptions]="{standalone: true}">-->
<!--          <mat-button-toggle value="html">-->
<!--            <mat-icon>article</mat-icon>-->
<!--          </mat-button-toggle>-->
<!--          <mat-button-toggle value="pdf">-->
<!--            <mat-icon>picture_as_pdf</mat-icon>-->
<!--          </mat-button-toggle>-->
<!--        </mat-button-toggle-group>-->
<!--      </div>-->
      <button matTooltip="Download Document" mat-mini-fab color="primary" (click)="onDownloadAsPdfClicked()">
        <mat-icon>download</mat-icon>
      </button>&nbsp;&nbsp;
      <button matTooltip="Email Document" mat-mini-fab color="primary" (click)="onEmailReportClicked()">
        <mat-icon>email</mat-icon>
      </button>
    </div>
    <div class="clear-both"></div>
  </div>
  <div>
    <mat-form-field *ngIf="reportType === reportTypes.ChartOfAccountDetail && chartOfAccounts?.length">
      <mat-label>Chart of Account</mat-label>
      <mat-select formControlName="chartOfAccountKey">
        <mat-option *ngFor="let chartOfAccount of chartOfAccounts"
                    [value]="chartOfAccount.chartOfAccountKey">{{chartOfAccount.fullDisplayPath}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="reportType === reportTypes.CustomerInvoices && customers?.length">
      <mat-label>Customer</mat-label>
      <mat-select formControlName="customerKey">
        <mat-option *ngFor="let customer of customers"
                    [value]="customer.customerKey">{{customer.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div *ngIf="stream" class="mt-2">
  <app-pdf-viewer
    *ngIf="stream"
    [pdfSrc]="stream">
  </app-pdf-viewer>
</div>
<div *ngIf="html" class="mt-2">
  <div [innerHtml]="html | safeHtml"></div>
</div>
<div class="text-center full-width bold" *ngIf="reportType === reportTypes.ChartOfAccountDetail && !formGroupRef.value.chartOfAccountKey">
  Select a chart of account . . .
</div>
<div class="text-center full-width bold" *ngIf="reportType === reportTypes.CustomerInvoices && !formGroupRef.value.customerKey">
  Select a customer . . .
</div>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>

