import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { ReportService } from 'src/app/core/services/domain/report.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { DocumentSearchModel } from 'src/app/shared/models/domain/document-search.model';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { DateUtilities } from 'src/app/core/utilities/date.utilities';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { ReportTypesEnum } from 'src/app/shared/enums/domain/report-types.enum';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';
import { CustomerService } from 'src/app/core/services/domain/customer.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss']
})
export class ReportPageComponent extends BasePageComponent implements OnInit {
  stream: Blob;
  html: string;
  formGroupRef: UntypedFormGroup;
  templates: DocumentModel[] = [];
  selectedView = 'html';
  reportTypes = ReportTypesEnum;
  reportType: ReportTypesEnum = ReportTypesEnum.Unknown;
  chartOfAccounts: ChartOfAccountModel[];
  customers: CustomerModel[];

  constructor(
    private router: Router,
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private documentService: DocumentService,
    private customerService: CustomerService,
    private dialogService: DialogService,
    private chartOfAccountService: ChartOfAccountService,
    private businessContextService: BusinessContextService) {
    super();
  }

  ngOnInit(): void {
    const reportType = +this.getParameterFromRoute(RoutingKeysUtilities.routeParams.reportTypeId, this.activatedRoute.snapshot);
    const reportKey = this.getQueryParameterFromRoute(RoutingKeysUtilities.queryParams.reportKey, this.activatedRoute.snapshot);

    this.reportType = reportType;

    const searchModel: DocumentSearchModel = {
      documentTypes: [DocumentTypesEnum.Template],
      businessKeys: [this.businessContextService.currentBusiness.businessKey]
    }

    this.documentService.search(searchModel).subscribe(documentTemplates => {
      this.templates = documentTemplates;
    })

    if (reportType) {
      this.initReportTypeData(reportType);
      this.initFormGroup(reportType, reportKey);
      this.setRenderedReport();
    } else {
      this.router.navigateByUrl(RouteUtilities.routes.application.reports.getNavigateUrl());
    }

    this.subscriptions.add(this.formGroupRef.valueChanges.subscribe(_ => {
          this.setRenderedReport();
    }));
  }

  public onBackToReportsClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.reports.getNavigateUrl());
  }

  public setRenderedReport(): void {
    if ((this.reportType !== ReportTypesEnum.ChartOfAccountDetail && this.reportType !== ReportTypesEnum.CustomerInvoices) ||
      (this.reportType === ReportTypesEnum.ChartOfAccountDetail && this.formGroupRef.value.chartOfAccountKey) || (this.reportType === ReportTypesEnum.CustomerInvoices && this.formGroupRef.value.customerKey)) {
      this.showBlockingLoader = true;

      if (this.selectedView === 'html') {
        this.reportService.getRenderedHtml(this.formGroupRef.value).subscribe(data => {
          this.stream = null;
          this.html = data.renderedHtml;
          this.showBlockingLoader = false;
        }, err => {
          this.onHttpFailure(this.notificationService, err);
          this.showBlockingLoader = false;
        });
      } else if (this.selectedView === 'pdf') {
        this.reportService.downloadStream(this.formGroupRef.value).subscribe(data => {
          this.stream = data;
          this.html = null;
          this.showBlockingLoader = false;
        }, err => {
          this.onHttpFailure(this.notificationService, err);
          this.showBlockingLoader = false;
        });
      }
    }
  }

  public onDownloadAsPdfClicked(): void {
    this.showBlockingLoader = true;
      this.reportService.download(this.formGroupRef.value).subscribe(data => {
        this.showBlockingLoader = false;
      }, err => {
        this.onHttpFailure(this.notificationService, err);
        this.showBlockingLoader = false;
      });
  }

  onEmailReportClicked(): void {
    this.dialogService.openEmailReport(this.formGroupRef.value, this.businessContextService.currentBusiness.businessKey).afterClosed().subscribe();
  }

  public setThisMonth(): void  {
    let dates = DateUtilities.getThisMonth();

    this.formGroupRef.patchValue({
      startDate: dates.startDate,
      endDate: dates.endDate
    });
  }

  public setLastMonth(): void  {
    let dates = DateUtilities.getLastMonth();
    this.formGroupRef.patchValue({
      startDate: dates.startDate,
      endDate: dates.endDate
    });
  }

  public setThisYear(): void  {
    let dates = DateUtilities.getThisYear();
    this.formGroupRef.patchValue({
      startDate: dates.startDate,
      endDate: dates.endDate
    });
  }

  public setLastYear(): void  {
    let dates = DateUtilities.getLastYear();

    this.formGroupRef.patchValue({
      startDate: dates.startDate,
      endDate: dates.endDate
    });
  }

  private initReportTypeData(reportType: ReportTypesEnum): void {
    if (reportType === ReportTypesEnum.ChartOfAccountDetail) {
      this.chartOfAccountService.search({businessKey: this.businessContextService.currentBusiness.businessKey}).subscribe(chartOfAccounts => {
        this.chartOfAccounts = chartOfAccounts;
      });
    } else if (reportType === ReportTypesEnum.CustomerInvoices) {
      this.customerService.search({businessKey: this.businessContextService.currentBusiness.businessKey}).subscribe(customers => {
        this.customers = customers;
      });
    }
  }

  private initFormGroup(reportType: number, reportKey: string): void {
    let today = new Date();

    this.formGroupRef = new UntypedFormGroup({
      businessKey: new FormControl(this.businessContextService.currentBusiness.businessKey),
      type: new FormControl(reportType),
      startDate: new FormControl(new Date(today.getFullYear(), 0, 1)),
      endDate: new FormControl(new Date(today.getFullYear(), 11, 31)),
      templateKey: new FormControl(null),
      chartOfAccountKey: new FormControl((reportType === ReportTypesEnum.ChartOfAccountDetail ? (reportKey ?? null) : null)),
      customerKey: new FormControl(null)
    })
  }
}
