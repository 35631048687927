import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import {
  CustomerPaymentSubscriptionModel
} from 'src/app/shared/models/domain/customer-payment-subscription.model';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { CustomerPaymentSubscriptionFormComponent } from 'src/app/shared/components/forms/credit-card-form/customer-payment-subscription-form.component';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';

@Component({
  selector: 'app-business-subscriptions-page',
  templateUrl: './business-subscriptions-page.component.html',
  styleUrls: ['./business-subscriptions-page.component.scss']
})
export class BusinessSubscriptionsPageComponent extends BaseEntryPageComponent<CustomerPaymentSubscriptionModel> implements OnInit {
  @ViewChild(CustomerPaymentSubscriptionFormComponent) creditCardForm: CustomerPaymentSubscriptionFormComponent;

  hasValidSubscription: boolean = null;
  hasAnySubscription: boolean = true;

  constructor(
    private businessSubscriptionService: BusinessSubscriptionService,
    private businessContextService: BusinessContextService,
    private router: Router,
    routingService: RoutingService,
    notificationService: NotificationService,
    pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    //CLEAR THIS IF THEY ARE PUSHED TO THIS SCREEN BASED ON BE ERROR
    this.businessSubscriptionService.clearCache();
    this.showBlockingLoader = true;
    this.businessSubscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey).subscribe(result => {
      this.hasValidSubscription = result;

      this.businessSubscriptionService.hasAnySubscription(this.businessContextService.currentBusiness.businessKey).subscribe(hasAnySubscription => {
        this.hasAnySubscription = hasAnySubscription;
      });

      if (this.hasValidSubscription) {
        this.businessSubscriptionService.getSubscriptionPayment(this.businessContextService.currentBusiness.businessKey).subscribe(payment => {
          this.value = payment;
          this.showBlockingLoader = false;
        });
      } else {
        this.value = {
          businessKey: this.businessContextService.currentBusiness.businessKey
        };
      }
    });
  }

  onCreditCardLoaded(): void {
    this.showBlockingLoader = false;
  }

  onSaveClicked(): void {
    this.isSaving = true;
    this.showBlockingLoader = true;

    if (!this.hasValidSubscription) {
      this.creditCardForm.validateCreditCard().subscribe(result => {
        if (result.errors?.length) {
          this.onSaveFailed(result.errors);
          this.isSaving = false;
          this.showBlockingLoader = false;
          return;
        }

        this.businessSubscriptionService.createSubscription(this.value).subscribe(_ => {
          this.businessSubscriptionService.clearCache();
          this.onSaveSuccess('Subscription was created successfully.');
          this.reloadPage(this.router);
          this.isSaving = false;
          this.showBlockingLoader = false;
          this.hasAnySubscription = true;
        }, err => {
          this.onSaveFailed(err);
          this.isSaving = false;
          this.showBlockingLoader = false;
        });
      }, err => {
        this.onSaveFailed([{message: 'An error occurred while trying to process your payment or data is missing.  If the problem persists, please refresh the page or try another card.'}]);
        this.isSaving = false;
        this.showBlockingLoader = false;
      });
    } else {
      this.businessSubscriptionService.updateSubscription(this.value).subscribe(_ => {
        this.businessSubscriptionService.clearCache();
        this.onSaveSuccess('Subscription was updated successfully.');
        this.reloadPage(this.router);
        this.isSaving = false;
        this.showBlockingLoader = false;
      }, err => {
        this.onSaveFailed(err);
        this.isSaving = false;
        this.showBlockingLoader = false;
      });
    }


  }

  onCancelClicked() {
    this.router.navigateByUrl(RouteUtilities.routes.application.dashboard.getNavigateUrl());
  }
}



