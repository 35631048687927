import { Component, Inject, OnInit } from '@angular/core';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import { EmailDocumentModel } from 'src/app/shared/models/domain/email-document.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { UntypedFormGroup } from '@angular/forms';
import { LoggingService } from 'src/app/core/services/app/logging.service';

@Component({
  selector: 'app-email-document-renderer-dialog',
  templateUrl: './email-document-renderer-dialog.component.html',
  styleUrls: ['./email-document-renderer-dialog.component.scss']
})
export class EmailDocumentRendererDialogComponent extends BaseComponent implements OnInit {
  isAdmin: boolean = false;
  businessKey: string = null;
  value: EmailDocumentModel;
  formGroupRef: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private dialogRef: MatDialogRef<EmailDocumentRendererDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {emailDocument: EmailDocumentModel, isAdmin: boolean},
    protected notificationService: NotificationService, private documentService: DocumentService, private loggingService: LoggingService) {
    super();
  }

  ngOnInit(): void {
    this.isAdmin = this.dialogData.isAdmin;
    this.businessKey = this.dialogData.emailDocument.businessKey;
    this.value = this.dialogData.emailDocument;
  }

  onSendEmailClicked(): void {
    if (!this.formGroupRef.dirty) {
      return;
    }

    this.isSaving = true;

    this.documentService.emailRendered(this.value).subscribe(result => {
      this.isSaving = false;

      if (result?.length === 0) {
        this.notificationService.showSuccessNotification('Email sent successfully.');
        this.dialogRef.close();
      } else {
        result.unshift('Email(s) failed to be sent.');
        this.notificationService.showErrorNotification(result, 99999999);
        this.loggingService.logException(new Error(JSON.stringify(result)));
      }
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    });
  }

  onCancelClicked(): void {
    if (this.isSaving) {
      return;
    }

    this.dialogRef.close();
  }
}
