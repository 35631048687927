import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';
import { TransactionService } from 'src/app/core/services/domain/transaction.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { TransactionRuleService } from 'src/app/core/services/domain/transaction-rule.service';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-transaction-page',
  templateUrl: './transaction-page.component.html',
  styleUrls: ['./transaction-page.component.scss']
})
export class TransactionPageComponent extends BaseEntryPageComponent<TransactionModel> implements OnInit {
  isFinalized = false

  constructor(private router: Router,
              private dialogService: DialogService,
              private activatedRoute: ActivatedRoute,
              private transactionService: TransactionService,
              private transactionRuleService: TransactionRuleService,
              private businessContextService: BusinessContextService,
              routingService: RoutingService,
              notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    const transactionKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.transactionKey, this.activatedRoute.snapshot);

    if (transactionKey) {
      this.transactionService.get(transactionKey).subscribe(transaction => {
        this.value = transaction;
      })
    } else {
      this.value = <TransactionModel>{
        businessKey: this.businessContextService.currentBusiness.businessKey
      };
    }
  }

  onSaveClicked(redirect: boolean = false, isUndeleting = false, isRegistry = false): void {
    if (this.isSaving || this.isDeleting) {
      return;
    }

    this.isSaving = true;
    this.value.isRegistry = isRegistry;

    if (isUndeleting) {
      this.value.isDeleted = false;
    }

    this.transactionService.update(this.value).subscribe(_ => {
      this.formGroupRef.get('isDeleted').setValue(false);
      this.formGroupRef.get('isRegistry').setValue(isRegistry);

      this.openTransactionRuleDialog().subscribe(result => {
        if (result) {
          this.onSaveSuccess('Transaction rule was created successfully.');
        } else {
          this.onSaveSuccess('Transaction was updated successfully.');
        }

        if (redirect) {
          this.redirectAfterSave();
        }
      });
    }, err => {
      this.onSaveFailed(err);
      this.value.isRegistry = false;
    });
  }

  redirectAfterSave(): boolean {
    let hasRedirect = super.redirectAfterSave();

    if (!hasRedirect) {
      if (this.value.isRegistry) {
        this.router.navigateByUrl(RouteUtilities.routes.application.registries.getNavigateUrl());
      } else {
        this.router.navigateByUrl(RouteUtilities.routes.application.transactions.getNavigateUrl());
      }
    }
    return true;
  }

  private openTransactionRuleDialog(): Observable<boolean> {
    let thatValue = this.value;

    if (!this.value.isRegistry && !this.value.transactionRuleKey && !!this.value.vendor?.vendorKey && !!this.value?.chartOfAccount?.chartOfAccountKey) {
      return this.transactionRuleService.search({
        businessKey: this.businessContextService.currentBusiness.businessKey,
        vendorKey: this.value.vendor.vendorKey,
        chartOfAccountKey: this.value.chartOfAccount.chartOfAccountKey
      }).pipe(concatMap(results => {
        if (results.length === 0) {
          let confirmDialogRef = this.dialogService.openConfirmationDialog('No matching transaction rule found.<br/>Would you like to create a new rule for other transactions?', 'Yes', 'No', '165px');

          return confirmDialogRef.afterClosed().pipe(concatMap(result => {
            if (result) {
              let dialogRef = this.dialogService.openTransactionRule(null, this.value.merchantName, this.value.vendor.vendorKey, this.value.chartOfAccount.chartOfAccountKey);
              return dialogRef.afterClosed().pipe(concatMap(_ => {
                return of(true);
              }));
            } else {
              return of(false);
            }
          }));
        }

        return of(false);
      }));
    }

    return of(false);
  }
}


