import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { OfferingService } from 'src/app/core/services/domain/offering.service';

@Component({
  selector: 'app-manage-categories-dialog',
  templateUrl: './manage-categories-dialog.component.html',
  styleUrls: ['./manage-categories-dialog.component.scss']
})
export class ManageCategoriesDialogComponent extends BaseComponent implements OnInit {
  categories: string[];
  categoryToAdd: string = '';
  isDirty = false;

  constructor(
    private dialogRef: MatDialogRef<ManageCategoriesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { businessKey: string },
    protected notificationService: NotificationService,
    private offeringService: OfferingService) {
    super();
  }

  ngOnInit(): void {
    this.offeringService.listCategories(this.dialogData.businessKey).subscribe(categories => {
      this.categories = categories;
    });
  }

  onAddCategoryClicked(): void {
    if (this.categoryToAdd && this.categoryToAdd.trim() !== '') {
      this.categories.push(this.categoryToAdd)
      this.categories.sort();
      this.categoryToAdd = '';
      this.isDirty = true;
    }
  }

  onRemoveCategoryClicked(category: string): void {
    this.categories.splice(this.categories.indexOf(category), 1);
    this.isDirty = true;
  }

  onSaveClicked(): void {
    this.showBlockingLoader = true;
    this.offeringService.updateCategories(this.dialogData.businessKey, this.categories).subscribe(result => {
      this.notificationService.showSuccessNotification('Categories updated successfully.');
      this.dialogRef.close(true);
      this.showBlockingLoader = false;
    }, err => {
      this.onHttpFailure(this.notificationService, err);
      this.showBlockingLoader = false;
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
