<app-base-entry-page-template entryTitle="Manage Reconciliation" [value]="value" [formGroupRef]="formGroupRef"
                              saveText="Save & Finalize"
                              [isSaving]="isSaving" (saveClick)="onSaveClicked()"
                              [showSave]="false">
  <app-base-entry-page-content-template>
    <div>
      <app-reconciliation-form
        *ngIf="value"
        [(formGroupRef)]="formGroupRef"
        [(value)]="value"
        (endingBankBalanceChange)="calculateAggregates()"
        (transactionDataChange)="onReconciliationTransactionDataChanged()">
      </app-reconciliation-form>
    </div>
    <ng-container>
      <div *ngIf="isLoadingTransactions" class="full-width text-center subtle-text mb-5 mt-5">
        Loading transactions, this may take a while . . .
      </div>
      <div class="full-width" *ngIf="!isLoadingTransactions">
        <div>
          <app-roll-up-hero [rollupItems]="rollUpItems"></app-roll-up-hero>
        </div>
      </div>
      <div class="mb-5" *ngIf="value" [hidden]="isLoadingTransactions">
        <app-transactions-grid
          #transactionsGrid
          *ngIf="value && value.chartOfAccount?.chartOfAccountKey"
          [showTitle]="false"
          [showHasLinkedTransaction]="false"
          [showLinkedInvoices]="true"
          [enableRowClick]="true"
          [showHeroNumbers]="false"
          [disableLinks]="true"
          [isRegistry]="true"
          [hideBankingActions]="true"
          [showBulkUpdate]="false"
          [showCheckbox]="value?.isDraft"
          [showAddButton]="false"
          [showDeleteButton]="false"
          [startDate]="value.startDate"
          [endDate]="value.endDate"
          [bankingChartOfAccountKey]="value?.chartOfAccount.chartOfAccountKey"
          [columnsToHide]="transactionColumnsToHide"
          [disableSorting]="true"
          [startingBalance]="value?.startingBankBalance"
          [transactions]="transactions"
          [getRowClassFunc]="getRowClassFunc"
          gridKey="{{transactionReconciliationKey}}"
          (rowClick)="onTransactionsRowClicked($event)"
          [(selectedRows)]="selectedTransactions"
          (selectedRowsChange)="calculateAggregates()">
        </app-transactions-grid>
      </div>
    </ng-container>
    <div class="full-width text-center bold mb-4 mt-4"
         *ngIf="!(value && value.chartOfAccount?.chartOfAccountKey)">
      Select a bank chart of account to begin.
    </div>
  </app-base-entry-page-content-template>
  <app-base-entry-page-footer-template>
    <span class="mr-2">
      <button mat-flat-button color="primary" *ngIf="value?.isDraft" (click)="onSaveClicked(true)"
              [disabled]="!formGroupRef.dirty || isSaving">Save as Draft</button>
    </span>
    <span class="mr-2">
      <button mat-flat-button color="primary" *ngIf="value?.isDraft ? true : !value?.reconciliationKey" (click)="onSaveClicked(false)"
              [disabled]="!formGroupRef.dirty || isSaving">Save & Finalize</button>
    </span>
    <span class="mr-2">
      <button mat-flat-button color="primary" *ngIf="!value?.isDraft && value?.reconciliationKey" (click)="onDeleteClicked()"
              [disabled]="isSaving">Delete</button>
    </span>
    <span class="mr-2">
      <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving">Cancel</button>
    </span>

  </app-base-entry-page-footer-template>
</app-base-entry-page-template>
