import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { map, tap } from 'rxjs/operators';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';

@Injectable()
export class BusinessContextInitializationService {
  constructor(
    private authClientService: AuthClientService,
    private businessContextService: BusinessContextService,
    private businessService: BusinessService
  ) {
  }

  initBusinessContext(): Observable<boolean> {
    return this.businessService.search({ includeMyBusinessesOnly: !this.authClientService.isSuperAdmin }).pipe(tap((businesses) => {
      this.businessContextService.initBusinessContext(businesses);
    }), map(_ => {
      return true;
    }));
  }
}
