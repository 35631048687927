import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { AddressModel } from 'src/app/shared/models/app/address.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { GoogleLocationService } from 'src/app/core/services/app/google-location.service';
import { GoogleAddressPredictionModel } from 'src/app/shared/models/app/google-address-prediction.model';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent extends BaseComponent implements OnInit {
  @Input() placeholderText = 'Search . . . ';
  @Input() cssClass: string = 'mat-form-field-double';
  @Output() locationSelect = new EventEmitter<AddressModel>();

  selectedLocation: GoogleAddressPredictionModel = null;
  locationSearchResults: GoogleAddressPredictionModel[] = null;
  isLoadingLocations: boolean = false;

  locationSearchChanged: Subject<string> = new Subject<string>();

  constructor(private googleLocationService: GoogleLocationService) {
    super();
  }

  ngOnInit(): void {
    this.locationSearchChanged.pipe(debounceTime(1000),  distinctUntilChanged(), tap(_ => {
      this.isLoadingLocations = true;
    }))
      .subscribe(searchTerm => {
        this.googleLocationService.getLocationPredictions(searchTerm).subscribe(results => {
          this.locationSearchResults = results;
          this.isLoadingLocations = false;
        });
      });
  }

  displayFnc(value): string {
    return value?.address ?? '';
  }

  onLocationSearchChanged(text: string): void {
    this.locationSearchChanged.next(text);
  }

  onOptionSelected(): void {
    if (this.selectedLocation.placeId) {
      this.googleLocationService.getLocationByPlaceId(this.selectedLocation.placeId).subscribe(result => {
        this.locationSearchResults = null;
        this.locationSelect.emit(result);
      });
    }
  }
}
