import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';
import { TransactionTypesEnum } from 'src/app/shared/enums/domain/transaction-types.enum';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';
import { VendorService } from 'src/app/core/services/domain/vendor.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { TransactionService } from 'src/app/core/services/domain/transaction.service';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';
import { CustomerService } from 'src/app/core/services/domain/customer.service';
import { forkJoin, Observable } from 'rxjs';
import { StringUtilities } from 'src/app/core/utilities/string.utilities';
import { TransactionRuleService } from 'src/app/core/services/domain/transaction-rule.service';

@Component({
  selector: 'app-transaction-form',
  templateUrl: './transaction-form.component.html',
  styleUrls: ['./transaction-form.component.scss']
})
export class TransactionFormComponent extends BaseFormComponent<TransactionModel> implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  private bankingChartOfAccountTrigger: MatAutocompleteTrigger;

  @ViewChild('bankingChartOfAccountInput', {read: MatAutocompleteTrigger}) set bankingChartOfAccountContent(content: MatAutocompleteTrigger) {
    if (content && !this.bankingChartOfAccountTrigger) {
      this.bankingChartOfAccountTrigger = content;
      this.subscriptions.add(this.bankingChartOfAccountTrigger.panelClosingActions.subscribe(e => {
        if (!(e && e.source)) {
          this.bankingChartOfAccountKeyControl.setValue(null);
          this.bankingChartOfAccountTrigger.closePanel();
        } else {
          let foundBankingChartOfAccount = this.bankingChartOfAccounts.find(t => t.chartOfAccountKey === e.source.value);

          if (foundBankingChartOfAccount) {
            this.formGroupRef.get('bankingChartOfAccount').get('name').setValue(foundBankingChartOfAccount.name);
          }
        }

        this.showBankingChartOfAccountToLink = false;
      }));
    }
  }

  private chartOfAccountTrigger: MatAutocompleteTrigger;

  @ViewChild('chartOfAccountInput', {read: MatAutocompleteTrigger}) set chartOfAccountContent(content: MatAutocompleteTrigger) {
    if (content && !this.chartOfAccountTrigger) {
      this.chartOfAccountTrigger = content;

      this.subscriptions.add(this.chartOfAccountTrigger.panelClosingActions.subscribe(e => {
        let textValue = this.value.chartOfAccount.chartOfAccountKey;

        //a text value is already selected don't take the text, in this case the value is a guid.
        if (StringUtilities.isUUID(textValue)) {
          textValue = null;
        }

        this.formGroupRef.get('transactionRuleKey').setValue(null);

        if (!(e && e.source)) {
          this.chartOfAccountKeyControl.setValue(null);
          this.chartOfAccountTrigger.closePanel();
        } else {
          let chartOfAccount = this.chartOfAccounts.find(t => t.chartOfAccountKey === e.source.value);

          if (!chartOfAccount) {
            this.chartOfAccountKeyControl.setValue(null);
            this.chartOfAccountTrigger.closePanel();

            if (e.source.value === '-1') {
              this.addChartOfAccountClicked(textValue);
            }
          } else {
            this.chartOfAccountKeyControl.setValue(e.source.value);
          }
        }
      }));
    }
  }

  private customerVendorTrigger: MatAutocompleteTrigger;

  @ViewChild('customerVendorInput', {read: MatAutocompleteTrigger}) set customerVendorContent(content: MatAutocompleteTrigger) {
    if (content && !this.customerVendorTrigger) {
      this.customerVendorTrigger = content;
      this.subscriptions.add(this.customerVendorTrigger.panelClosingActions.subscribe(e => {
        let textValue = this.customerVendorKeyControl.value;

        //a text value is already selected don't take the text, in this case the value is a guid.
        if (StringUtilities.isUUID(textValue)) {
          textValue = null;
        }

        this.formGroupRef.get('transactionRuleKey').setValue(null);

        if (!(e && e.source)) {
          this.setVendorFormValues(null);
          this.customerVendorTrigger.closePanel();
          this.customerVendorLabel = 'Multiple Vendors/Customers';
        } else {
          let customerVendor = this.customerVendors.find(t => t.key === e.source.value);

          if (!customerVendor) {
            this.setVendorFormValues(null);
            this.customerVendorTrigger.closePanel();
            this.customerVendorLabel = 'Multiple Vendors/Customers';

            if (e.source.value === '-1') {
              this.onAddCustomerClicked(textValue);
            }

            if (e.source.value === '-2') {
              this.onAddVendorClicked(textValue);
            }

            this.setVendorFormValues(null);
            this.setCustomerFormValues(null);
          } else {
            if (customerVendor.isVendor) {
              this.setVendorFormValues(e.source.value);
            } else {
              this.setCustomerFormValues(e.source.value);
            }
          }
        }
      }));
    }
  }

  transactionTypes: EnumModel[] = [];
  categories: string = null;
  filteredCustomerVendors: CustomerVendorViewModel[] = [];
  customerVendorLabel: string = 'Multiple Vendors/Customers';
  customerVendors: CustomerVendorViewModel[];
  customerVendorKeyControl: UntypedFormControl;

  filteredChartOfAccounts: ChartOfAccountModel[] = [];
  chartOfAccounts: ChartOfAccountModel[];
  chartOfAccountKeyControl: UntypedFormControl;

  filteredBankingChartOfAccounts: ChartOfAccountModel[] = [];
  bankingChartOfAccounts: ChartOfAccountModel[];
  bankingChartOfAccountKeyControl: UntypedFormControl;
  showBankingChartOfAccountToLink = false;

  constructor(private vendorService: VendorService, private customerService: CustomerService,
              private dialogService: DialogService,
              private transactionService: TransactionService,
              private transactionRulesService: TransactionRuleService,
              private chartOfAccountService: ChartOfAccountService, private router: Router,
              private notificationService: NotificationService,
              private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.transactionTypes = EnumUtilities.convertToSelectModels(TransactionTypesEnum, true);

    if (this.value.categories) {
      this.categories = this.value.categories.map(t => t.name).join(', ');
    }

    this.setCustomerVendors().subscribe();

    super.ngOnInit();

    this.chartOfAccountService.search({businessKey: this.value.businessKey}).subscribe(chartOfAccounts => {
      this.chartOfAccounts = this.chartOfAccountService.filterWithoutBankAccounts(chartOfAccounts);
      this.filteredChartOfAccounts = this.chartOfAccountService.filterWithoutBankAccounts(chartOfAccounts);

      this.bankingChartOfAccounts = this.chartOfAccountService.filterToBankAccounts(chartOfAccounts);
      this.filteredBankingChartOfAccounts = this.chartOfAccountService.filterToBankAccounts(chartOfAccounts);

      if (this.value.bankingChartOfAccount?.chartOfAccountKey && !this.value.bankingChartOfAccount?.name) {
        let foundBankingCOA = this.bankingChartOfAccounts.find(t => t.chartOfAccountKey === this.value.bankingChartOfAccount.chartOfAccountKey);
        this.formGroupRef.get('bankingChartOfAccount').patchValue({name: foundBankingCOA.name});
      }
    });

    this.subscriptions.add(this.customerVendorKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredCustomerVendors = this.customerVendors;
        } else {
          this.filteredCustomerVendors = this.customerVendors.filter(customerVendor => customerVendor.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        }
      })
    ).subscribe());

    this.subscriptions.add(this.chartOfAccountKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredChartOfAccounts = this.chartOfAccounts;
        } else {
          this.filteredChartOfAccounts = this.chartOfAccounts.filter(coa => coa.fullDisplayPath.toLowerCase()
            .indexOf(value.toLowerCase()) !== -1);
        }
      })
    ).subscribe());

    this.subscriptions.add(this.bankingChartOfAccountKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredBankingChartOfAccounts = this.bankingChartOfAccounts;
        } else {
          this.filteredBankingChartOfAccounts = this.bankingChartOfAccounts.filter(coa => coa.fullDisplayPath.toLowerCase()
            .indexOf(value.toLowerCase()) !== -1);
        }
      })
    ).subscribe());
  }

  getTransactionStatus(transaction: TransactionModel): string {
    return this.transactionService.getStatus(transaction);
  }

  onChartOfAccountClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.chartOfAccountEdit.getNavigateUrl(this.value.bankingChartOfAccount.chartOfAccountKey))
  }

  onLinkToTransactionClicked(): void {
    if (!this.value.amount) {
      this.notificationService.showErrorNotification('Amount is required to link.');
    } else {
      let matDialogRef = this.dialogService.openTransactionLink(this.value.businessKey, null, this.value);

      matDialogRef.afterClosed().subscribe((transaction: TransactionModel) => {
        if (transaction) {
          let transactionInEdit = this.formGroupRef.value;
          let mergedTransaction = this.transactionService.merge(transactionInEdit, transaction);

          if (!transactionInEdit.isRegistry) {
            this.showBlockingLoader = true;
            this.transactionService.update(mergedTransaction).subscribe(_ => {
              this.notificationService.showSuccessNotification('Transaction was linked successfully.');
              this.formGroupRef.markAsPristine();
              this.formGroupRef.updateValueAndValidity();
              this.showBlockingLoader = false;
              this.router.navigateByUrl(RouteUtilities.routes.application.registryEdit.getNavigateUrl(mergedTransaction.transactionKey));
            }, err => {
              this.onHttpFailure(this.notificationService, err);
              this.formGroupRef.markAsPristine();
              this.formGroupRef.updateValueAndValidity();
              this.showBlockingLoader = false;
              this.router.navigateByUrl(RouteUtilities.routes.application.transactions.getNavigateUrl());
            });
          } else {
            this.formGroupRef.patchValue(mergedTransaction);
            this.formGroupRef.markAsDirty();
            this.formGroupRef.updateValueAndValidity();
          }
        }
      });
    }
  }

  onUnlinkTransactionClick(): void {
    this.formGroupRef.patchValue({
      hasLinkedTransaction: false,
      clearedDate: null,
      bankingChartOfAccount: {
        chartOfAccountKey: null,
        name: null
      },
      merchantName: null,
      merchantLocation: null,
      bankingAccountId: null,
      bankingAccountName: null,
      bankingTransactionId: null,
    });

    this.formGroupRef.markAsDirty();
    this.formGroupRef.updateValueAndValidity();
  }

  customerVendorDisplayFn(customerVendorKey: string): string {
    return this.customerVendors.find(t => t.key === customerVendorKey)?.name ?? '';
  }

  chartOfAccountDisplayFn(chartOfAccountKey: string): string {
    let foundResult = this.chartOfAccounts?.find(t => t.chartOfAccountKey === chartOfAccountKey);
    return foundResult?.fullDisplayPath ?? '';
  }

  bankingChartOfAccountDisplayFn(chartOfAccountKey: string): string {
    let foundResult = this.bankingChartOfAccounts?.find(t => t.chartOfAccountKey === chartOfAccountKey);
    return foundResult?.fullDisplayPath ?? '';
  }

  onAddVendorClicked(name: string): void {
    const dialogRef = this.dialogService.openVendorForm(name);
    dialogRef.afterClosed().subscribe((vendorKey: string) => {
      if (vendorKey) {
        this.setCustomerVendors().subscribe(_ => {
          this.setVendorFormValues(vendorKey);
          this.formGroupRef.markAsDirty();
          this.formGroupRef.updateValueAndValidity();
        });
      }
    })
  }

  onManageTransactionRulesClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.transactionRules.getNavigateUrl());
  }

  onEditTransactionRuleClicked(): void {
    let dialogRef = this.dialogService.openTransactionRule(this.value.transactionRuleKey);
    dialogRef.afterClosed().subscribe();
  }

  onAddCustomerClicked(name: string): void {
    const dialogRef = this.dialogService.openCustomerForm(name);
    dialogRef.afterClosed().subscribe((customerKey: string) => {
      if (customerKey) {
        this.setCustomerVendors().subscribe(_ => {
          this.setCustomerFormValues(customerKey);
          this.formGroupRef.markAsDirty();
          this.formGroupRef.updateValueAndValidity();
        });
      }
    })
  }

  addChartOfAccountClicked(name: string): void {
    const dialogRef = this.dialogService.openChartOfAccountForm(name);
    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.chartOfAccountService.search({businessKey: this.value.businessKey}).subscribe(chartOfAccounts => {
          this.chartOfAccounts = [...chartOfAccounts];
          this.filteredChartOfAccounts = [...chartOfAccounts];
          this.chartOfAccountKeyControl.setValue(resultOrKey);
        });
      }
    })
  }


  protected getFormGroup(): UntypedFormGroup {
    if (this.value?.vendor?.vendorKey) {
      this.customerVendorLabel = 'Vendor';
    } else if (this.value?.customer?.customerKey) {
      this.customerVendorLabel = 'Customer';
    } else {
      this.customerVendorLabel = 'Multiple Vendors/Customers';
    }

    this.customerVendorKeyControl = new UntypedFormControl(this.value?.vendor?.vendorKey ?? this.value?.customer?.customerKey);
    this.chartOfAccountKeyControl = new UntypedFormControl(this.value?.chartOfAccount?.chartOfAccountKey);
    this.bankingChartOfAccountKeyControl = new UntypedFormControl(this.value?.bankingChartOfAccount?.chartOfAccountKey);

    const vendorFormGroup = new UntypedFormGroup({
      vendorKey: new UntypedFormControl(this.value?.vendor?.vendorKey),
      notes: new UntypedFormControl(this.value?.vendor?.notes)
    })

    const customerFormGroup = new UntypedFormGroup({
      customerKey: new UntypedFormControl(this.value?.customer?.customerKey),
      notes: new UntypedFormControl(this.value?.customer?.notes)
    })

    const formGroup = new UntypedFormGroup({
      transactionKey: new UntypedFormControl(this.value?.transactionKey),
      businessKey: new UntypedFormControl(this.value?.businessKey),
      chartOfAccount: new UntypedFormGroup({
        chartOfAccountKey: this.chartOfAccountKeyControl,
        name: new UntypedFormControl(this.value?.chartOfAccount?.name)
      }),
      bankingChartOfAccount: new UntypedFormGroup({
        chartOfAccountKey: this.bankingChartOfAccountKeyControl,
        name: new UntypedFormControl(this.value?.bankingChartOfAccount?.name)
      }),
      bankingAccountId: new UntypedFormControl(this.value?.bankingAccountId),
      bankingAccountName: new UntypedFormControl(this.value?.bankingAccountName),
      bankingTransactionId: new UntypedFormControl(this.value?.bankingTransactionId),
      isDeleted: new UntypedFormControl(this.value?.isDeleted ?? false),
      isRegistry: new UntypedFormControl(this.value?.isRegistry ?? false),
      isReconciled: new UntypedFormControl(this.value?.isReconciled ?? false),
      reconciliationKey: new UntypedFormControl(this.value?.reconciliationKey),
      type: new UntypedFormControl(this.value?.type ?? TransactionTypesEnum.Unknown),
      date: new UntypedFormControl(this.value?.date || new Date()),
      clearedDate: new UntypedFormControl(this.value?.clearedDate),
      referenceNumber: new UntypedFormControl(this.value?.referenceNumber),
      customerVendorKey: this.customerVendorKeyControl,
      customer: customerFormGroup,
      vendor: vendorFormGroup,
      merchantName: new UntypedFormControl(this?.value?.merchantName),
      merchantLocation: new UntypedFormControl(this?.value?.merchantLocation),
      linkedInvoicesCount: new UntypedFormControl(this.value?.linkedInvoicesCount || 0),
      notes: new UntypedFormControl(this.value?.notes),
      transactionRuleKey: new UntypedFormControl(this.value?.transactionRuleKey),
      hasLinkedTransaction: new UntypedFormControl(this.value.hasLinkedTransaction ?? false),
      hasMatchedTransactions: new UntypedFormControl(this.value.hasMatchedTransactions ?? false),
      description: new UntypedFormControl(this.value?.description),
      amount: new UntypedFormControl(this.value?.amount || 0),
      categories: this.getTransactionCategoryFormArray(),
    });

    return formGroup;
  };

  private getTransactionCategoryFormArray(): UntypedFormArray {
    const formArray = new UntypedFormArray([]);

    if (this.value?.categories?.length) {
      for (let item of this.value.categories) {
        formArray.push(new UntypedFormGroup({
          transactionCategoryKey: new UntypedFormControl(item?.transactionCategoryKey),
          name: new UntypedFormControl(item?.name)
        }));
      }
    }

    return formArray;
  }

  private setCustomerVendors(): Observable<{ vendors: VendorModel[], customers: CustomerModel[] }> {
    return forkJoin({
      vendors: this.vendorService.search({businessKey: this.value.businessKey}),
      customers: this.customerService.search({businessKey: this.value.businessKey})
    }).pipe(tap(customersVendors => {
      let mappedVendors: CustomerVendorViewModel[] = customersVendors.vendors.map(t => {
        return {
          key: t.vendorKey,
          name: t.name,
          isVendor: true,
          data: t
        }
      });

      let mappedCustomers: CustomerVendorViewModel[] = customersVendors.customers.map(t => {
        return {
          key: t.customerKey,
          name: t.name,
          isVendor: false,
          data: t
        }
      });

      this.customerVendors = mappedCustomers.concat(mappedVendors).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }

        if (a.name > b.name) {
          return 1;
        }

        return 0;
      });

      this.filteredCustomerVendors = this.customerVendors;
    }));
  }

  private setVendorFormValues(key: string): void {
    this.customerVendorLabel = 'Vendor';
    this.formGroupRef.get('customer').get('customerKey').setValue(null);
    this.formGroupRef.get('customer').get('notes').setValue(null);
    this.formGroupRef.get('vendor').get('vendorKey').setValue(key);
    this.formGroupRef.get('customerVendorKey').setValue(key);

    this.formGroupRef.updateValueAndValidity();
    this.changeDetectorRef.markForCheck();

    if (key && !this.value.chartOfAccount?.chartOfAccountKey) {
      this.transactionRulesService.search({businessKey: this.value.businessKey, vendorKey: key}).subscribe(rules => {
        if (rules.length && (rules.length === 1 || rules.every(t => t.chartOfAccount?.chartOfAccountKey == null || t.chartOfAccount.chartOfAccountKey === rules[0].chartOfAccount.chartOfAccountKey))) {
          let foundRule = rules.find(t => t.chartOfAccount?.chartOfAccountKey != null);
          this.filteredChartOfAccounts = [...this.chartOfAccounts];
          let foundChartOfAccount = this.chartOfAccounts.find(t => t.chartOfAccountKey === foundRule.chartOfAccount?.chartOfAccountKey);

          if (foundChartOfAccount) {
            this.chartOfAccountKeyControl.setValue(foundRule.chartOfAccount.chartOfAccountKey);
            this.formGroupRef.updateValueAndValidity();
            this.changeDetectorRef.markForCheck();
          }
        }
      });
    }
  }

  private setCustomerFormValues(key: string): void {
    this.customerVendorLabel = 'Customer';
    this.formGroupRef.get('customer').get('customerKey').setValue(key);
    this.formGroupRef.get('vendor').get('vendorKey').setValue(null);
    this.formGroupRef.get('vendor').get('notes').setValue(null);
    this.formGroupRef.get('customerVendorKey').setValue(key);
    this.formGroupRef.updateValueAndValidity();
    this.changeDetectorRef.markForCheck();
  }
}

export class CustomerVendorViewModel {
  key: string;
  name: string;
  isVendor: boolean = false;
  data: any
}
