import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { OfferingService } from 'src/app/core/services/domain/offering.service';
import { OfferingModel } from 'src/app/shared/models/domain/offering.model';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';

@Component({
  selector: 'app-offering-page',
  templateUrl: './offering-page.component.html',
  styleUrls: ['./offering-page.component.scss']
})
export class OfferingPageComponent extends BaseEntryPageComponent<OfferingModel> implements OnInit {
  fileList: FileList;

  constructor(
    public authClientService: AuthClientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private businessContextService: BusinessContextService,
    private offeringService: OfferingService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    const offeringKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.offeringKey, this.activatedRoute.snapshot);

    if (offeringKey) {
      this.offeringService.get(offeringKey).subscribe(offering => {
        this.value = offering;
      })
    } else {
      this.value = <OfferingModel>{
        offeringKey: null,
        businessKey: this.businessContextService.currentBusiness.businessKey
      };
    }
  }

  onSaveClicked(): void {
    this.isSaving = true;
    if (!this.value.offeringKey) {
      this.offeringService.create(this.value).subscribe(_ => {
        this.onSaveSuccess('Offering was created successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.offeringService.update(this.value).subscribe(_ => {
        this.onSaveSuccess('Offering was updated successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }

  onDeleteClicked(): void {
    if (this.isSaving || this.isDeleting) {
      return;
    }

    let matDialogRef = this.dialogService.openConfirmationDialog();

    matDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isDeleting = true;
        this.offeringService.delete(this.value.offeringKey).subscribe(result => {
          if (result) {
            this.onSaveSuccess('Offering has been deleted successfully.');
          } else {
            this.onSaveFailed('Offering failed to be deleted.')
          }

          this.redirectAfterSave();
        }, (err) => {
          this.onSaveFailed(err);
        });
      }
    });
  }
}
