import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { SupportService } from 'src/app/core/services/domain/support.service';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent extends BasePageComponent implements OnInit {
  selectedTabIndex = 0;
  helpDocuments = ['/assets/files/workflow-help.pdf', '/assets/files/accounting-help.pdf', '/assets/files/help-guide.pdf']
  helpBusinessVideos: {src: string, title: string, description: string}[];
  helpAccountingVideos: {src: string, title: string, description: string}[];
  helpServicesVideos: {src: string, title: string, description: string}[];
  helpDocumentsVideos: {src: string, title: string, description: string}[];
  miscellaneousVideos: {src: string, title: string, description: string}[];

  constructor(private supportService: SupportService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.helpAccountingVideos = [
      {
        title: 'Adding Registry Manually',
        description: 'This video will demonstrate the process of manually adding a registry if you choose not to link your bank account to the platform.',
        src: '/assets/videos/add-registry-manually.mp4'
      },
      {
        title: 'Adding Chart of Account Manually',
        description: 'This video will demonstrate how to manually add a chart of accounts if you choose not to link your bank account to the platform.',
        src: '/assets/videos/chart-of-account.mp4'
      },
      {
        title: 'Offerings',
        description: 'This video will guide you on how to add an offering to your customers, representing what you bill them for and serving as a component of the invoice.',
        src: '/assets/videos/offering.mp4'
      },
      {
        title: 'Customer Job Cost Tracking',
        description: 'This video will assist you in adding jobs for your customers, enabling you to track them, review payable invoices, and determine your profit.',
        src: '/assets/videos/customer-job.mp4'
      },
      {
        title: 'Services',
        description: 'This video demonstrates the procedure for incorporating a service into your business.',
        src: '/assets/videos/services.mp4'
      }
    ];



    this.helpBusinessVideos = [
      {
        title: 'My Business',
        description: 'This video demonstrates the steps to commence setting up your business information, including details such as the business name, phone number, email, and website.',
        src: '/assets/videos/my-business.mp4'
      },
      {
        title: 'Addresses',
        description: 'This video will provide guidance on adding your addresses.',
        src: '/assets/videos/business-addresses.mp4'
      },
      {
        title: 'Accounting',
        description: 'This video illustrates the process of initiating accounting information for your business. This includes adding the EIN/sole proprietorship number, specifying the first month of the income tax year, selecting the accounting method, and determining the closing date.',
        src: '/assets/videos/accounting.mp4'
      }
    ];

    this.helpServicesVideos = [
      {
        title: 'Service Requests and Finding a Service',
        description: 'This video will guide you on locating and editing the business requests you\'ve sent and received. Additionally, it demonstrates how to find a specific service within the network.',
        src: '/assets/videos/services-requests-finding-services.mp4'
      }
    ];

    this.helpDocumentsVideos = [
      {
        title: 'Letters',
        description: 'This video provides instructions on creating a customized letter using the available templates.',
        src: '/assets/videos/letters.mp4'
      },
      {
        title: 'Business Cards',
        description: 'This video will guide you through the process of creating business cards for both yourself and your team. It demonstrates how to utilize available templates, allowing you to customize the colors to align with your specific preferences and requirements.',
        src: '/assets/videos/business-cards.mp4'
      },
      {
        title: 'Flyers',
        description: 'This video guides you through the process of creating flyers, beginning with downloading the flyer template and customizing it according to your requirements.',
        src: '/assets/videos/flyers.mp4'
      }
    ];

    this.miscellaneousVideos = [
      {
        title: 'Grids',
        description: 'This video guides you through the process of how use the grids throughout the system focusing on sorting, filtering, and exporting your data.',
        src: '/assets/videos/grids.mp4'
      }
    ]
  }

  onScheduleSupportClicked(): void {
    this.supportService.initScheduleSupport();
  }

  onNavigateToTicketClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.ticket.getNavigateUrl());
  }

  onDownloadClicked(): void {
    window.open(this.helpDocuments[this.selectedTabIndex]);
  }
}

