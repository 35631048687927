import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClientToBusinessAccessSearchModel } from 'src/app/shared/models/domain/client-to-business-access-search.model';
import { ClientToBusinessAccessModel } from 'src/app/shared/models/domain/client-to-business-access.model';

@Injectable()
export class ClientToBusinessAccessService {
  private url = environment.apiUrl + '/client-to-business-access/';

  constructor(private http: HttpClient) {
  }

  search(searchModel: ClientToBusinessAccessSearchModel): Observable<ClientToBusinessAccessModel[]> {
    return this.http.post<ClientToBusinessAccessModel[]>(this.url + 'search', searchModel);
  }

  create(model: ClientToBusinessAccessSearchModel): Observable<string> {
    return this.http.post<string>(this.url, model);
  }

  delete(clientToBusinessAccessKey: string): Observable<boolean> {
    return this.http.delete<boolean>(this.url + clientToBusinessAccessKey);
  }
}
