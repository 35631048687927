import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors-page.component.html',
  styleUrls: ['./vendors-page.component.scss']
})
export class VendorsPageComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  onRowClicked(event: { data: VendorModel }): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.vendorEdit.getNavigateUrl(event.data.vendorKey));
  }

  onAddButtonClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.vendorAdd.getNavigateUrl());
  }
}
