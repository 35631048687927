import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { EmployeeModel } from 'src/app/shared/models/domain/employee.model';

@Component({
  selector: 'app-employees',
  templateUrl: './employees-page.component.html',
  styleUrls: ['./employees-page.component.scss']
})
export class EmployeesPageComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  onRowClicked(event: { data: EmployeeModel }): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.employeeEdit.getNavigateUrl(event.data.employeeKey));
  }

  onAddButtonClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.employeeAdd.getNavigateUrl());
  }
}
