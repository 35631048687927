import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect-page.component.html',
  styleUrls: ['./redirect-page.component.scss']
})
export class RedirectPageComponent extends BasePageComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
