import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BusinessServiceRequestModel } from 'src/app/shared/models/domain/business-service-request.model';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { PrioritiesEnum } from 'src/app/shared/enums/app/priorities.enum';
import { BusinessServiceRequestStatusesEnum } from 'src/app/shared/enums/domain/business-service-request-statuses.enum';

@Component({
  selector: 'app-business-service-request-form',
  templateUrl: './business-service-request-form.component.html',
  styleUrls: ['./business-service-request-form.component.scss']
})
export class BusinessServiceRequestFormComponent extends BaseFormComponent<BusinessServiceRequestModel> implements OnInit {
  constructor() {
    super();
  }

  priorities: EnumModel[] = [];

  ngOnInit(): void {
    this.priorities = EnumUtilities.convertToSelectModels(PrioritiesEnum, true);

    super.ngOnInit();
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      businessServiceRequestKey: new UntypedFormControl(this.value?.businessServiceRequestKey),
      business: new UntypedFormGroup({
        businessKey: new UntypedFormControl(this.value?.business?.businessKey),
      }),
      firstName: new UntypedFormControl(this.value?.firstName),
      lastName: new UntypedFormControl(this.value?.lastName),
      status: new UntypedFormControl(this.value?.status ?? BusinessServiceRequestStatusesEnum.New),
      stars: new UntypedFormControl(this.value?.stars ?? null),
      address: new UntypedFormGroup({
        fullAddress: new UntypedFormControl(this.value?.address?.fullAddress),
        addressLineOne: new UntypedFormControl(this.value?.address?.addressLineOne),
        addressLineTwo: new UntypedFormControl(this.value?.address?.addressLineTwo),
        city: new UntypedFormControl(this.value?.address?.city),
        state: new UntypedFormControl(this.value?.address?.state),
        postalCode: new UntypedFormControl(this.value?.address?.postalCode),
        country: new UntypedFormControl(this.value?.address?.country),
        latitude: new UntypedFormControl(this.value?.address?.latitude),
        longitude: new UntypedFormControl(this.value?.address?.longitude),
      }),
      requestedOn: new UntypedFormControl(this.value?.requestedOn ?? null),
      requestedBy: new UntypedFormControl(this.value?.requestedBy),
      phoneNumber: new UntypedFormControl(this.value?.phoneNumber),
      email: new UntypedFormControl(this.value?.email),
      priority: new UntypedFormControl(this.value?.priority ?? PrioritiesEnum.Low),
      description: new UntypedFormControl(this.value?.description),
    })
  };
}
