import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { InvoicePaymentModel } from 'src/app/shared/models/domain/invoice-payment.model';
import { InvoiceService } from 'src/app/core/services/domain/invoice.service';
import { InvoicePaymentService } from 'src/app/core/services/domain/invoice-payment.service';

@Component({
  selector: 'app-invoice-payment-form-dialog',
  templateUrl: './invoice-payment-form-dialog.component.html'
})
export class InvoicePaymentFormDialogComponent extends BaseEntryPageComponent<InvoicePaymentModel> {
  constructor(
    public dialogRef: MatDialogRef<InvoicePaymentFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { invoicePayment: InvoicePaymentModel },
    private businessContextService: BusinessContextService,
    private invoiceService: InvoiceService,
    private invoicePaymentService: InvoicePaymentService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    if (this.dialogData.invoicePayment?.invoicePaymentKey) {
      this.value = this.dialogData.invoicePayment;
    } else if (this.dialogData.invoicePayment?.invoice?.invoiceKey) {
      this.invoiceService.get(this.dialogData.invoicePayment.invoice.invoiceKey).subscribe(invoice => {
        this.value = <InvoicePaymentModel>{
          invoice: invoice
        };
      });
    } else {
      this.dialogRef.close();
    }
  }

  onSaveClicked(): void {
    this.isSaving = true;
    if (this.value?.invoicePaymentKey) {
      this.invoicePaymentService.update(this.value).subscribe(key => {
        this.onSaveSuccess('Payment was updated successfully.');
        this.dialogRef.close(key);
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.invoicePaymentService.create(this.value).subscribe(key => {
        this.onSaveSuccess('Payment was created successfully.');
        this.dialogRef.close(key);
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }

  onDeleteClicked(): void {
    this.isSaving = true;
    this.invoicePaymentService.delete(this.value.invoicePaymentKey).subscribe(key => {
      this.onSaveSuccess('Payment was deleted successfully.');
      this.dialogRef.close(key);
    }, err => {
      this.onSaveFailed(err);
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
