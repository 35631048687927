export enum TransactionTypesEnum {
  Unknown = 12,
  Adjustment = 0,
  Atm = 1,
  BankCharge = 2,
  BillPayment = 3,
  Cash = 4,
  Cashback = 5,
  Check = 6,
  DirectDebit = 7,
  Interest = 8,
  Purchase = 9,
  StandingOrder = 10,
  Transfer = 11,
  JournalEntry = 13
}
