import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { CustomerService } from 'src/app/core/services/domain/customer.service';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';

@Component({
  selector: 'app-customer-page',
  templateUrl: './customer-page.component.html',
  styleUrls: ['./customer-page.component.scss']
})
export class CustomerPageComponent extends BaseEntryPageComponent<CustomerModel> implements OnInit {
  fileList: FileList;

  constructor(
    public authClientService: AuthClientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private businessContextService: BusinessContextService,
    private customerService: CustomerService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    const customerKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.customerKey, this.activatedRoute.snapshot);

    if (customerKey) {
      this.customerService.get(customerKey).subscribe(document => {
        this.value = document;
      })
    } else {
      this.value = <CustomerModel>{
        businessKey: this.businessContextService.currentBusiness.businessKey
      };
    }
  }

  onSaveClicked(): void {
    this.isSaving = true;
    if (!this.value.customerKey) {
      this.customerService.create(this.value).subscribe(_ => {
        this.onSaveSuccess('Customer was created successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.customerService.update(this.value).subscribe(_ => {
        this.onSaveSuccess('Customer was updated successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }
}
