import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { AuthService } from '@auth0/auth0-angular';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';

@Injectable()
export class BusinessGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private businessContextService: BusinessContextService,
    private router: Router) {
  }

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(tap(isAuthenticated => {
      if (isAuthenticated) {
        if (!this.businessContextService.currentBusiness?.businessKey) {
          this.notificationService.showNotification('Complete your business profile before using services.');
          this.router.navigateByUrl(RouteUtilities.routes.application.profileSetup.getNavigateUrl());

          return false;
        }
      }

      return true;
    }));
  }
}
