import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { BankingService } from 'src/app/core/services/domain/banking.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import {
  BusinessContextInitializationService
} from 'src/app/core/services/domain/business-context-initialization.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';

@Component({
  selector: 'app-business-page',
  templateUrl: './business-page.component.html',
  styleUrls: ['./business-page.component.scss']
})
export class BusinessPageComponent extends BaseEntryPageComponent<BusinessModel> implements OnInit {
  fileList: FileList;
  hasValidSubscription = false;

  private businessKey: string;

  constructor(
    public authClientService: AuthClientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private businessService: BusinessService,
    private businessContextService: BusinessContextService,
    private businessContextInitializationService: BusinessContextInitializationService,
    private businessSubscriptionService: BusinessSubscriptionService,
    private dialogService: DialogService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.businessKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.businessKey, this.activatedRoute.snapshot);
    this.selectedTabIndex = +this.getQueryParameterFromRoute(this.routingKeysUtilities.queryParams.selectedTabIndex, this.activatedRoute.snapshot) || 0;

    if (this.businessKey) {
      this.businessService.get(this.businessKey).subscribe(business => {
        this.businessSubscriptionService.hasValidSubscription(this.businessKey).subscribe(result => {
          this.hasValidSubscription = result;
          this.value = business;
        });
      });
    } else {
      this.value = <BusinessModel>{};
    }
  }

  onSaveClicked(): void {
    this.isSaving = true;
    if (!this.value.businessKey) {
      this.businessService.create(this.value, this.fileList).subscribe(id => {
        this.onSaveSuccess('Business was created successfully.');
        this.value.businessKey = id;
        this.businessContextService.updateBusinessContext(this.value, this.router);

        this.businessContextInitializationService.initBusinessContext().subscribe(_ => {
          this.redirectAfterBusinessSave(id, this.selectedTabIndex);
        });
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.businessService.update(this.value, this.fileList).subscribe(_ => {
        this.onSaveSuccess('Business was updated successfully.');
        this.businessContextInitializationService.initBusinessContext().subscribe(_ => {
          this.redirectAfterBusinessSave(this.value.businessKey, this.selectedTabIndex);
        });
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }

  onGoToAccessClicked(): void {
    if (this.isAdminRoute()) {
      this.router.navigateByUrl(RouteUtilities.routes.application.admin.businessAccess.getNavigateUrl(this.value.businessKey));
    } else {
      this.router.navigateByUrl(RouteUtilities.routes.application.businessAccess.getNavigateUrl(this.value.businessKey));
    }
  }

  onDownloadLogoClicked(business: BusinessModel): void {
    this.businessService.downloadLogo(business).subscribe();
  }

  onCancelClicked(): void {
    this.redirectToBusinessesPage();
  }

  onDeleteClicked(): void {
    let matDialogRef = this.dialogService.openConfirmationDialog();

    matDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isSaving = true;
        this.businessService.delete(this.value.businessKey).subscribe(result => {
          if (result) {
            this.onSaveSuccess('Business has been deleted successfully.');

            this.businessContextInitializationService.initBusinessContext().subscribe(_ => {
              this.redirectToBusinessesPage();
            });
          } else {
            this.onSaveFailed('Business failed to be deleted.')
          }
        }, (err) => {
          this.onSaveFailed(err);
        });
      }
    });
  }

  private redirectAfterBusinessSave(id: string, tabIndex: number = null): void {
    if (id) {
      let url;
      if (this.isAdminRoute()) {
        url = RouteUtilities.routes.application.admin.businessEdit.getNavigateUrl(id, tabIndex);
      } else {
        url = RouteUtilities.routes.application.businessEdit.getNavigateUrl(id, tabIndex);
      }

      this.reloadPage(this.router, url);
    } else {
      this.reloadPage(this.router);
    }
  }

  private redirectToBusinessesPage() {
    if (this.isAdminRoute()) {
      this.router.navigateByUrl(RouteUtilities.routes.application.admin.businesses.getNavigateUrl());
    } else {
      this.router.navigateByUrl(RouteUtilities.routes.application.dashboard.getNavigateUrl());
    }
  }
}
