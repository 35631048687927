import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderComponent } from 'src/app/shared/components/controls/loader/loader.component';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-blocking-loader',
  templateUrl: './blocking-loader.component.html',
  styleUrls: ['./blocking-loader.component.scss']
})
export class BlockingLoaderComponent extends BaseComponent implements OnInit {
  dialogRef: MatDialogRef<LoaderComponent>;

  constructor(
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.dialogRef = this.dialog.open(LoaderComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }

    super.ngOnDestroy();
  }
}
