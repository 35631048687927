import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { JobStatusesEnum } from 'src/app/shared/enums/domain/job-statuses.enum';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';
import { CustomerService } from 'src/app/core/services/domain/customer.service';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { StringUtilities } from 'src/app/core/utilities/string.utilities';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { tap } from 'rxjs/operators';
import { JobModel } from 'src/app/shared/models/domain/job.model';
import { DialogService } from 'src/app/core/services/domain/dialog.service';

@Component({
  selector: 'app-job-form',
  templateUrl: './job-form.component.html',
  styleUrls: ['./job-form.component.scss']
})
export class JobFormComponent extends BaseFormComponent<JobModel> implements OnInit, AfterViewInit {
  private customerTrigger: MatAutocompleteTrigger;

  @ViewChild('customerInput', {read: MatAutocompleteTrigger}) set customerContent(content: MatAutocompleteTrigger) {
    if (content && !this.customerTrigger) {
      this.customerTrigger = content;
      this.subscriptions.add(this.customerTrigger.panelClosingActions.subscribe(e => {
        let textValue = this.value.customer.customerKey;

        //a text value is already selected don't take the text, in this case the value is a guid.
        if (StringUtilities.isUUID(textValue)) {
          textValue = null;
        }

        if (!(e && e.source)) {
          this.customerKeyControl.setValue(null);
          this.customerTrigger.closePanel();
        } else {
          let customer = this.customers.find(t => t.customerKey === e.source.value);

          if (!customer) {
            this.customerKeyControl.setValue(null);
            this.customerTrigger.closePanel();

            if (e.source.value === '-1') {
              this.onAddCustomerClicked(textValue);
            }
          } else {
            this.customerKeyControl.setValue(e.source.value);
          }
        }

        this.setCustomerContact(e?.source.value);
      }));
    }
  }

  jobStatuses: EnumModel[] = [];

  customers: CustomerModel[];
  filteredCustomers: CustomerModel[] = [];
  customerKeyControl: UntypedFormControl;
  customerAddress: string;
  customerContact: string;

  constructor(private customerService: CustomerService, private dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {
    this.jobStatuses = EnumUtilities.convertToSelectModels(JobStatusesEnum, false);

    this.customerService.search({businessKey: this.value.businessKey}).subscribe(customers => {
      this.customers = customers;
      this.filteredCustomers = customers;
      this.setCustomerContact();
    });

    super.ngOnInit();

    this.subscriptions.add(this.customerKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredCustomers = this.customers;
        } else {
          this.filteredCustomers = this.customers.filter(customer => customer.name.toLowerCase()
            .indexOf(value.toLowerCase()) !== -1);
        }
      }),
    ).subscribe());
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      //IF CREATE AND CUSTOMER IS PRESET (e.g: COMING FROM THE CUSTOMER INVOICE AREA)
      if (this.value?.customer?.customerKey && !this.value.jobKey) {
        this.formGroupRef.markAsDirty();
        this.formGroupRef.updateValueAndValidity();
      }
    })
  }

  customerDisplayFn(customerKey: string): string {
    return this.customers.find(t => t.customerKey === customerKey)?.name ?? '';
  }

  onAddCustomerClicked(name: string): void {
    const dialogRef = this.dialogService.openCustomerForm(name);
    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.customerService.search({businessKey: this.value.businessKey}).subscribe(customers => {
          this.customers = [...customers];
          this.filteredCustomers = [...customers];
          this.customerKeyControl.setValue(resultOrKey);
          this.setCustomerContact(resultOrKey);
        });
      }
    });
  }

  protected getFormGroup(): UntypedFormGroup {
    this.customerKeyControl = new UntypedFormControl(this.value?.customer?.customerKey);

    const customerFormGroup = new UntypedFormGroup({
      customerKey: this.customerKeyControl
    });

    return new UntypedFormGroup({
      jobKey: new UntypedFormControl(this.value?.jobKey),
      businessKey: new UntypedFormControl(this.value?.businessKey),

      name: new UntypedFormControl(this.value?.name),
      status: new UntypedFormControl(this.value?.status || JobStatusesEnum.InProgress),
      customer: customerFormGroup,
      notes: new UntypedFormControl(this.value?.notes),
      isDeleted: new UntypedFormControl(this.value?.isDeleted ?? false),
      startDate: new UntypedFormControl(this.value?.startDate ?? new Date()),
      endDate: new UntypedFormControl(this.value?.endDate),
      profit: new UntypedFormControl(this.value?.profit),
      income: new UntypedFormControl(this.value?.income),
      expenses: new UntypedFormControl(this.value?.expenses)
    })
  };

  private setCustomerContact(customerKey: string = null): void {
    let selectedCustomerKey = customerKey ?? this.customerKeyControl.value;

    if (StringUtilities.isUUID(selectedCustomerKey)) {
      let foundCustomer = this.customers?.find(t => t.customerKey == selectedCustomerKey);
      this.customerAddress = foundCustomer?.address?.fullAddress;
      let customerContactParts = [];
      if (foundCustomer?.phoneNumber) {
        customerContactParts.push(foundCustomer.phoneNumber);
      }

      if (foundCustomer?.email) {
        customerContactParts.push(foundCustomer.email);
      }
      this.customerContact = customerContactParts.join(' - ');
    } else {
      this.customerAddress = null;
      this.customerContact = null;
    }
  }
}
