<app-base-page-template pageTitle="Help/Training">
  <app-base-page-content-template>
    <div>
      <div>
        <div class="float-left">
          Not finding what you're looking for? <a href="javascript:void(0)" (click)="onScheduleSupportClicked()">Schedule
          Support</a> or <a href="javascript:void(0)" (click)="onNavigateToTicketClicked()">Create a Ticket</a>
        </div>

        <div class="float-right">
          <button matTooltip="Download Document" mat-mini-fab color="primary" (click)="onDownloadClicked()">
            <mat-icon>download</mat-icon>
          </button>
        </div>
        <div class="clear-both">
        </div>
      </div>
      <mat-tab-group #matTabGroup [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="Help Guide">
          <div class="resize-container">
            <app-pdf-viewer
              [pdfSrc]="helpDocuments[2]">
            </app-pdf-viewer>
          </div>
        </mat-tab>
        <mat-tab label="Workflow Terminology">
          <div class="resize-container">
            <app-pdf-viewer
              [pdfSrc]="helpDocuments[0]">
            </app-pdf-viewer>
          </div>
        </mat-tab>
        <mat-tab label="Accounting Terminology">
          <div class="resize-container">
            <div class="clear-both"></div>
            <app-pdf-viewer
              [pdfSrc]="helpDocuments[1]">
            </app-pdf-viewer>
          </div>
        </mat-tab>
        <mat-tab label="My Business Videos">
          <div class="video-collection-container">
            <div class="mt-3" *ngFor="let video of helpBusinessVideos">
              <app-video-player [content]="video"></app-video-player>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Accounting Videos">
          <div class="video-collection-container">
            <div class="mt-3" *ngFor="let video of helpAccountingVideos">
              <app-video-player [content]="video"></app-video-player>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Documents Videos">
          <div class="video-collection-container">
            <div class="mt-3" *ngFor="let video of helpDocumentsVideos">
              <app-video-player [content]="video"></app-video-player>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Services Videos">
          <div class="video-collection-container">
            <div class="mt-3" *ngFor="let video of helpServicesVideos">
              <app-video-player [content]="video"></app-video-player>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Miscellaneous Videos">
          <div class="video-collection-container">
            <div class="mt-3" *ngFor="let video of miscellaneousVideos">
              <app-video-player [content]="video"></app-video-player>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </app-base-page-content-template>
</app-base-page-template>

