<app-base-page-template pageTitle="Financial Reports">
  <app-base-page-content-template>
    <div class="data-container">
        <mat-card *ngFor="let dataCard of dataCards" class="mb-1 data-card" (click)="onDataCardClicked(dataCard.path)">
          <div class="data-card-header">
            {{dataCard.name}}
          </div>
          <mat-card-content class="data-card-logo">
            <i class="fa fa-5x {{dataCard.icon}}"></i>
          </mat-card-content>
        </mat-card>
    </div>
  </app-base-page-content-template>
</app-base-page-template>
