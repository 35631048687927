<app-base-grid-template gridTitle="Clients" [showAddButton]="authClientService.hasAuthorization(authorization.admin)" (addButtonClick)="onAddButtonClicked()" (filterTextChange)="onFilterTextChanged($event)" (resetGridClick)="onResetGridClicked()" (exportGridClick)="onExportGridClicked()">
  <app-base-grid-content-template>
    <ag-grid-angular *ngIf="gridOptions"
                     class="ag-theme-alpine"
                     [gridOptions]="gridOptions">
    </ag-grid-angular>
  </app-base-grid-content-template>
</app-base-grid-template>



