import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessSearchModel } from 'src/app/shared/models/domain/business-search.model';
import { BusinessServiceRequestModel } from 'src/app/shared/models/domain/business-service-request.model';
import { BusinessSearchRequestSearchModel } from 'src/app/shared/models/domain/business-service-request-search.model';

@Injectable()
export class BusinessServiceRequestService {
  private url = environment.apiUrl + '/business-service-requests/';

  constructor(private http: HttpClient) {
  }

  get(businessServiceRequestKey: string): Observable<BusinessServiceRequestModel> {
    return this.http.get<BusinessServiceRequestModel>(this.url + businessServiceRequestKey);
  }

  search(searchModel: BusinessSearchRequestSearchModel): Observable<BusinessServiceRequestModel[]> {
    return this.http.post<BusinessServiceRequestModel[]>(this.url + 'search', searchModel);
  }

  create(model: BusinessServiceRequestModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model);
  }
  update(model: BusinessServiceRequestModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model);
  }
}
