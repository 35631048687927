<app-base-entry-page-template entryTitle="Manage Employee" [value]="value" [formGroupRef]="formGroupRef"
                              [isSaving]="isSaving" (saveClick)="onSaveClicked()" (cancelClick)="onCancelClicked()">
  <app-base-entry-page-content-template>
    <app-employee-form *ngIf="value"
                       [(formGroupRef)]="formGroupRef"
                       [(value)]="value">
    </app-employee-form>
  </app-base-entry-page-content-template>
</app-base-entry-page-template>




