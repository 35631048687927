<mat-card class="data-card">
  <div class="data-card-header">
    <div class="float-left">
      {{isInvoice ? 'Invoices' : 'Bills'}} by Status
    </div>
    <div class="float-right">

    </div>
    <div class="clear-both"></div>
  </div>
  <mat-card-content>
    <div class="mt-2 data-container" >
      <canvas
        *ngIf="hasData"
        baseChart
        class="chart"
        [data]="pieChartData"
        [type]="chartType"
        [options]="pieChartOptions">
      </canvas>
      <div class="no-data subtle-text" *ngIf="!hasData">
        No data found.
      </div>
    </div>
  </mat-card-content>
</mat-card>
