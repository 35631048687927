<div class="mt-2">
  <div>
    <div class="float-left bold">
      Select a template . . .
    </div>
    <div class="float-right">
      <button mat-flat-button color="primary" (click)="onCancelClicked()" [disabled]="isSaving">Cancel</button>
    </div>
    <div class="clear-both"></div>
  </div>
</div>
<div>
  <div *ngIf="documentTemplates">
    <div class="image-container">
      <div *ngFor="let template of documentTemplates"
           style="height:300px; width: 200px;border: solid 1px black; margin: 5px; cursor: pointer"
            (click)="onTemplateSelected(template.documentKey)">
          <img height="100%" width="100%" [src]="template.file.uri">
      </div>
    </div>
  </div>
</div>
