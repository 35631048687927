import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { TransactionModel } from 'src/app/shared/models/domain/transaction.model';

@Component({
  selector: 'app-transaction',
  templateUrl: './transactions-page.component.html',
  styleUrls: ['./transactions-page.component.scss']
})
export class TransactionsPageComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router) {
    super();
  }

  ngOnInit(): void {

  }

  onRowClicked(event: {data: TransactionModel}): void {
      this.router.navigateByUrl(this.routeUtilities.routes.application.transactionEdit.getNavigateUrl(event.data.transactionKey));
  }
}
