import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BankingAccountModel } from 'src/app/shared/models/domain/banking-account.model';
import { BankingConnectionModel } from 'src/app/shared/models/domain/banking-connection.model';

@Injectable()
export class BankingService {
  private url = environment.apiUrl + '/banking/';

  constructor(private http: HttpClient) {
  }

  getAccessToken(businessKey: string, isRefresh = false): Observable<string> {
    let url = 'access-token/business/' + businessKey;

    if (isRefresh) {
      url += '?isRefresh=true';
    }

    return this.http.get<string>(this.url + url).pipe(map(t => {
      // @ts-ignore
      return t?.data;
    }));
  }

  listAccounts(businessKey: string): Observable<BankingAccountModel[]> {
    return this.http.get<BankingAccountModel[]>(this.url + 'accounts/business/' + businessKey);
  }

  listConnections(businessKey: string): Observable<BankingConnectionModel[]> {
    return this.http.get<BankingConnectionModel[]>(this.url + 'connections/business/' + businessKey + '?includeAccounts=true');
  }

  createConnection(businessKey: string, publicToken: string): Observable<boolean> {
    return this.http.post<boolean>(this.url + 'connections/business/' + businessKey + '/token/' + publicToken, null);
  }

  updateBankingAccountIsActive(businessKey: string, bankingAccountId: string, isActive: boolean): Observable<boolean>  {
     let postUrl = this.url + 'accounts/business/' + businessKey + '/account/' + bankingAccountId + '?isActive=' + isActive;
     return this.http.post<boolean>(postUrl, null);
  }

  updateBankingAccountTxImportStartDate(businessKey: string, bankingAccountId: string, dateVal: Date): Observable<boolean>  {
    let queryString = dateVal === null ? "" : "?val=" + dateVal.toISOString().split('T')[0];
    let postUrl = this.url + 'accounts/business/' + businessKey
      + '/account/' + bankingAccountId + '/txStartDate' + queryString;
    return this.http.post<boolean>(postUrl, null);
  }

  sync(businessKey: string, bankingConnectionKey: string = null): Observable<boolean> {
    let url = this.url + 'sync/' + businessKey;

    if (bankingConnectionKey) {
      url += '?bankingConnectionKey=' + bankingConnectionKey;
    }

    return this.http.put<boolean>(url, {});
  }

  upsertAllBankAccounts(businessKey: string): Observable<boolean> {
    let url = this.url + 'upsertallbankaccounts/' + businessKey;

    return this.http.put<boolean>(url, {});
  }

  deleteConnection(bankingConnectionKey: string): Observable<boolean> {
    return this.http.delete<boolean>(this.url + bankingConnectionKey);
  }

  hasInvalidBankingAccountsToSync(bankingAccounts: BankingAccountModel[]): boolean {
    if (!bankingAccounts?.length) {
      return false;
    }

    for (let bankingAccount of bankingAccounts) {
      if (bankingAccount.isActive) {
        if (!bankingAccount.hasStartingBalance) {
          return true;
        }
      }
    }

    return false;
  }
}
