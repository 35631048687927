import * as moment from 'moment';

export class DateUtilities {
  static defaultDateFormat = 'MMM DD, YYYY';

  static format(date: Date | string | null, format: string = this.defaultDateFormat): string {
    if (date === null || date === undefined) {
      return '';
    }

    const momentDate = moment.utc(date);

    if (momentDate.isValid()) {
      return momentDate.format(format);
    }

    return '';
  }

  static parseDate(date: Date | string | null): Date | null {
    if (!date) {
      return null;
    }

    return moment(date).toDate();
  }

  static dateComparator(dateA: Date, dateB: Date): number {
    if (dateA == null) {
      return -1;
    }

    if (dateB == null) {
      return 1;
    }

    return dateA.valueOf() - dateB.valueOf();
  }

  static add(date: Date, amount: number, unit: string = 'days'): Date {
    // @ts-ignore
    return (moment(date, "DD-MM-YYYY").add(amount, unit)).toDate();
  }

  static getDateFileString() {
    const date = new Date();
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day =`${date.getDate()}`.padStart(2, '0');
    return `${year}${month}${day}`
  }

  static getFirstDayOfCurrentYear(): Date {
    return new Date(new Date().getFullYear(), 0, 1);
  }

  static getThisMonth(): {startDate: Date, endDate: Date} {
    let today = new Date();
    let startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    let endDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    endDate = DateUtilities.add(endDate, -1);

    return {startDate, endDate};
  }

  static getLastMonth(): {startDate: Date, endDate: Date} {
    let today = new Date();
    let startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    let endDate = new Date(today.getFullYear(), today.getMonth(), 1);
    endDate = DateUtilities.add(endDate, -1);

    return {startDate, endDate};
  }

  static getThisYear(): {startDate: Date, endDate: Date} {
    let today = new Date();
    let startDate = new Date(today.getFullYear(), 0, 1);
    let endDate = new Date(today.getFullYear(), 11, 31);

    return {startDate, endDate};
  }

  static getLastYear(): {startDate: Date, endDate: Date} {
    let today = new Date();
    let startDate = new Date(today.getFullYear() - 1, 0, 1);
    let endDate = new Date(today.getFullYear() - 1, 11, 31);

    return {startDate, endDate};
  }




}
