import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { JobService } from 'src/app/core/services/domain/job.service';
import { JobModel } from 'src/app/shared/models/domain/job.model';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';

@Component({
  selector: 'app-job-page',
  templateUrl: './job-page.component.html',
  styleUrls: ['./job-page.component.scss']
})
export class JobPageComponent extends BaseEntryPageComponent<JobModel> implements OnInit {
  customerKey = null;
  invoiceTypes = InvoiceTypesEnum;

  constructor(
    public authClientService: AuthClientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private businessContextService: BusinessContextService,
    private jobService: JobService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    const jobKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.jobKey, this.activatedRoute.snapshot);
    this.customerKey = this.getQueryParameterFromRoute(RoutingKeysUtilities.queryParams.customerKey, this.activatedRoute.snapshot);

    if (jobKey) {
      this.jobService.get(jobKey).subscribe(job => {
        this.value = job;
      })
    } else {
      this.value = <JobModel>{
        businessKey: this.businessContextService.currentBusiness.businessKey,
        customer: {
          customerKey: this.customerKey ?? null
        }
      };
    }
  }

  onAddInvoiceClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.invoiceAdd.getNavigateUrl(InvoiceTypesEnum.Invoice));
  }

  onEditInvoiceClicked(event): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.invoiceEdit.getNavigateUrl(event.data.invoiceKey));
  }

  onAddBillClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.invoiceAdd.getNavigateUrl(InvoiceTypesEnum.Bill));
  }

  onEditBillClicked(event): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.invoiceEdit.getNavigateUrl(event.data.invoiceKey));
  }

  onSaveClicked(): void {
    this.isSaving = true;
    if (!this.value.jobKey) {
      this.jobService.create(this.value).subscribe(_ => {
        this.onSaveSuccess('Job was created successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      this.jobService.update(this.value).subscribe(_ => {
        this.onSaveSuccess('Job was updated successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }
}
