import { Component, Input } from '@angular/core';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { BusinessFileModel } from 'src/app/shared/models/domain/business-file.model';
import { BusinessFileSearchModel } from 'src/app/shared/models/domain/business-file-search.model';
import { BusinessFileService } from 'src/app/core/services/domain/business-file.service';
import { FileTypesConstants } from 'src/app/shared/constants/file-types.constants';
import { BusinessFileClassificationsEnum } from 'src/app/shared/enums/domain/business-file-classifications.enum';

@Component({
  selector: 'app-business-files-grid',
  templateUrl: './business-files-grid.component.html',
  styleUrls: ['./business-files-grid.component.scss']
})
export class BusinessFilesGridComponent extends BaseGridComponent<BusinessFileModel> {
  @Input() title = 'Files';
  @Input() classification = BusinessFileClassificationsEnum.BusinessFile;
  @Input() showAdd = true;

  @Input() vendorKey: string = null;
  @Input() customerKey: string = null;
  @Input() invoiceKey: string = null;
  @Input() showEntity = false;

  constructor(public authClientService: AuthClientService,
              private businessContextService: BusinessContextService,
              private businessFileService: BusinessFileService,
              private dialogService: DialogService) {
    super();
    this.gridKey = `BusinessFiles_v2_${this.classification}_${this.isAdminRoute()}`;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected setColumnDefinitions(): ColDef[] {
    let columns = [];

    columns.push({
      headerName: '',
      field: this.actionFieldName,
      width: 80,
      sortable: false,
      cellClass: this.preventRowClickClass,
      cellRenderer: params => {
        let html = '';
        const rowData = <DocumentModel>params.data;
        html += '<i title="Download" class="cursor-pointer fas fa-download prevent-row-click download-file mr-1"></i>';

        // if (rowData.file?.contentType === FileTypesConstants.pdf) {
        //   html += '<i title="View" class="cursor-pointer fas fa-eye prevent-row-click view-file"></i>';
        // }

        return html;
      }
    });


    columns.push(this.getDefaultColumn('entityName', 'Entity', this.classification === BusinessFileClassificationsEnum.Flyer));
    columns.push(this.getDefaultColumn('name', 'Name', this.classification === BusinessFileClassificationsEnum.Flyer));
    columns.push(this.getDefaultColumn('description', 'Description'));
    columns.push(this.getDefaultColumn('file.name', 'File Name', this.classification === BusinessFileClassificationsEnum.Flyer));
    columns.push(this.getDefaultColumn('file.contentType', 'Type'));
    columns.push(this.getDateColumn('uploadDateTime', 'Uploaded Date'))
    columns.push(this.getYesNoColumn('businessKey', 'My File', this.classification !== BusinessFileClassificationsEnum.Flyer || this.isAdminRoute()))

    return columns;
  }

  onAddButtonClicked(): void {
    let model = {businessKey: this.isAdminRoute() ? null : this.businessContextService.currentBusiness.businessKey, vendorKey: this.vendorKey, customerKey: this.customerKey, invoiceKey: this.invoiceKey, classification: this.classification};
    this.openFileDialog(model, true);
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((cellClick: { data: BusinessFileModel, event: CellClickedEvent }) => {
      if (cellClick.event.colDef.field === this.actionFieldName) {
        if (this.hasCellClass(cellClick.event, 'download-file')) {
          this.showBlockingLoader = true;
          this.businessFileService.download(cellClick.data.businessFileKey).subscribe(_ => {
            this.showBlockingLoader = false;
            this.refreshGridData().subscribe();
          });
        } else if (this.hasCellClass(cellClick.event, 'view-file')) {
          this.showBlockingLoader = true;
          this.businessFileService.getFile(cellClick.data.businessFileKey).subscribe(file => {
            this.dialogService.openPdfViewer(file);
            this.showBlockingLoader = false;
          });
        }
      }
    }));

    this.rowClick.subscribe(data => {
      if (this.isAdminRoute() || (!this.isAdminRoute() && data.data.businessKey != null)) {
        this.openFileDialog(data.data);
      }

    })
  }

  protected setRowData(): Observable<any[]> {
    const searchModel = new BusinessFileSearchModel();
    searchModel.vendorKey = this.vendorKey;
    searchModel.customerKey = this.customerKey;
    searchModel.invoiceKey = this.invoiceKey;
    searchModel.businessKey = this.isAdminRoute() ? null : this.businessContextService.currentBusiness.businessKey;
    searchModel.includeDeleted = this.includeDeleted;
    searchModel.classification = this.classification;
    searchModel.isAdminOnly = this.isAdminRoute();

    return this.businessFileService.search(searchModel);
  }

  private openFileDialog(businessFile: BusinessFileModel, isAddButton: boolean = false): void {

    if(!isAddButton && !this.isAdminRoute() && businessFile?.isAdmin) {
      return;
    }


    let matDialogRef = this.dialogService.openBusinessFileForm(businessFile);

    matDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshGridData().subscribe();
      }
    });
  }
}
