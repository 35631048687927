<div class="message-container cursor-pointer" (click)="onNotificationClicked()">
  <div  class="snackbar-icon">
    <span *ngIf="data?.icon"><i [ngClass]="data.icon"></i> </span>
  </div>
  <ng-container *ngIf="message">
    {{message}}
  </ng-container>
  <ng-container *ngIf="messages">
    <div class="snackbar-messages">
      <div *ngFor="let message of messages">
        {{messages.length > 1 ? '-' : ''}} {{message}}
      </div>
    </div>
  </ng-container>
</div>
