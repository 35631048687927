import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogService } from 'src/app/core/services/domain/dialog.service';

export interface ComponentCanDeactivate {
  canDeactivate: (nextUrl: string, router: Router, dialogService: DialogService) => Observable<boolean> | boolean;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private router: Router, private dialogService: DialogService) {
  }

  canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | Observable<boolean> {
    return component?.canDeactivate ? component.canDeactivate(nextState?.url, this.router, this.dialogService) : true;
  }
}
