import { Component, Input } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { Router } from '@angular/router';
import { ReconciliationModel } from 'src/app/shared/models/domain/reconciliation.model';
import { ReconciliationService } from 'src/app/core/services/domain/reconciliation.service';

@Component({
  selector: 'app-reconciliation-grid',
  templateUrl: './reconciliation-grid.component.html',
  styleUrls: ['./reconciliation-grid.component.scss']
})
export class ReconciliationGridComponent extends BaseGridComponent<ReconciliationModel> {
  @Input() customerKey: string = null;

  private readonly chartOfAccountNameFieldName = 'chartOfAccount.name';

  constructor(private businessContextService: BusinessContextService,
              private reconciliationService: ReconciliationService,
              private router: Router) {
    super();
    this.gridKey = 'Reconciliation_v3';
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      this.getLinkColumn(this.chartOfAccountNameFieldName, 'Chart of Account', null),
      this.getYesNoColumn('isDraft', 'Draft'),
      this.getDateColumn('startDate', 'Start Date'),
      this.getDateColumn('endDate', 'End Date'),
      this.getCurrencyColumn('debits', 'Debits'),
      this.getCurrencyColumn('credits', 'Credits'),
      this.getCurrencyColumn('startingBankBalance', 'Starting Balance'),
      this.getCurrencyColumn('endingBankBalance', 'Ending Balance'),
      this.getCurrencyColumn('calculatedEndingBankBalance', 'Calculated Ending Balance')
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((data: { data: ReconciliationModel, event: CellClickedEvent }) => {
      if (data.event.colDef.field === this.chartOfAccountNameFieldName) {
        if (data.data.chartOfAccount) {
          this.router.navigateByUrl(RouteUtilities.routes.application.chartOfAccountEdit.getNavigateUrl(data.data.chartOfAccount.chartOfAccountKey));
        } else {
          this.rowClick.emit(data);
        }
      }
    }));
  }

  protected setRowData(): Observable<any[]> {
    return this.reconciliationService.search({businessKey: this.businessContextService.currentBusiness.businessKey, includeDeleted: this.includeDeleted});
  }
}
