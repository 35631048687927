import { Component, Input } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable, of } from 'rxjs';
import { ClientToBusinessAccessModel } from 'src/app/shared/models/domain/client-to-business-access.model';
import { ClientToBusinessAccessService } from 'src/app/core/services/domain/client-to-business-access.service';
import { ClientToBusinessAccessSearchModel } from 'src/app/shared/models/domain/client-to-business-access-search.model';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { ClientService } from 'src/app/core/services/domain/client.service';
import { mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-business-access-grid',
  templateUrl: './business-access-grid.component.html',
  styleUrls: ['./business-access-grid.component.scss']
})
export class BusinessAccessGridComponent extends BaseGridComponent<ClientToBusinessAccessModel> {
  @Input() isAdmin: boolean = false;
  @Input() businessKey: string;

  emailToAdd: string = null;

  constructor(public authClientService: AuthClientService,
              private clientService: ClientService,
              private clientToBusinessAccessService: ClientToBusinessAccessService,
              private notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super();
    this.gridKey = 'BusinessAccess_v1';
  }

  onAddClientAccessByEmailClicked(): void {
    this.clientService.getClientKeyByEmail(this.emailToAdd).pipe(tap(existingClientKey => {
      if (!existingClientKey) {
        this.notificationService.showErrorNotification('The client has not setup their account or does not exist.');
      }
    }), mergeMap(existingClientKey => {
      if (existingClientKey) {
        return this.clientToBusinessAccessService.create(<ClientToBusinessAccessModel>{clientKey: existingClientKey, businessKey: this.businessKey});
      }
      return of(null);
    })).subscribe(result => {
      if (result) {
        this.notificationService.showSuccessNotification('Access added successfully.')
        this.refreshGridData().subscribe();
      }
    }, err => {
      this.notificationService.showErrorNotification('Access failed to be created.');
    });
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: '',
        field: this.actionFieldName,
        width: 100,
        cellClass: this.preventRowClickClass,
        cellRenderer: params => {
          const rowData = <ClientToBusinessAccessModel>params.data;

          if (this.authClientService.client.clientKey.toLowerCase() !== rowData.clientKey.toLowerCase()) {
            if (rowData.isDeleted) {
              return '<i title="Add access" class="cursor-pointer fas fa-user-plus prevent-row-click"></i>';
            } else {
              return '<i title="Remove access" class="cursor-pointer fas fa-user-slash prevent-row-click"></i>';
            }
          }

          return '';
        }
      },
      {
        headerName: 'Last Name',
        field: 'clientReference.lastName'
      },
      {
        headerName: 'First Name',
        field: 'clientReference.firstName'
      },
      {
        headerName: 'Email',
        field: 'clientReference.email'
      },
      this.getYesNoColumn('isDeleted', 'Active', false, true),
      {
        headerName: 'Role',
        field: 'placeholder'
      },
      {
        headerName: 'Last Login',
        field: 'placeholder'
      },
      {
        headerName: 'Created On',
        field: 'placeholder'
      },
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((data: {data: ClientToBusinessAccessModel, event: CellClickedEvent }) => {
      if (data.event.colDef.field === this.actionFieldName) {
        if (data.data.isDeleted) {
          this.clientToBusinessAccessService.create(<ClientToBusinessAccessModel>{clientKey: data.data.clientKey, businessKey: this.businessKey}).subscribe(result => {
            this.notificationService.showSuccessNotification('Access updated successfully.');
            this.refreshGridData().subscribe();
          }, err => {
            this.notificationService.showErrorNotification('Access failed to be updated.');
          });
        } else {
          this.clientToBusinessAccessService.delete(data.data.clientToBusinessAccessKey).subscribe(_ => {
            this.notificationService.showSuccessNotification('Access removed successfully.');
            this.refreshGridData().subscribe();
          }, err => {
            this.notificationService.showErrorNotification('Access failed to be removed.');
          });
        }
      }
    }));
  }

  protected setRowData(): Observable<ClientToBusinessAccessModel[]> {
    return this.clientToBusinessAccessService.search(<ClientToBusinessAccessSearchModel>{businessKey: this.businessKey, includeDeleted: true});
  }
}
