import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import { Observable } from 'rxjs';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { LanguagesEnum } from 'src/app/shared/enums/domain/languages.enum';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { DocumentSearchModel } from 'src/app/shared/models/domain/document-search.model';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';

@Component({
  selector: 'app-documents-grid',
  templateUrl: './documents-grid.component.html',
  styleUrls: ['./documents-grid.component.scss']
})
export class DocumentsGridComponent extends BaseGridComponent<DocumentModel> implements OnChanges {
  @Input() businessKey: string = null;
  @Input() documentType: DocumentTypesEnum = null;
  @Input() isAdminOnly = false;

  title: string = 'Documents';

  constructor(public authClientService: AuthClientService,
              private documentService: DocumentService,
              private dialogService: DialogService) {
    super();
    this.gridKey = 'Documents_v2';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documentType && !changes.documentType.isFirstChange()) {
      this.refreshGridData().subscribe();
    }

    if (this.documentType == DocumentTypesEnum.Unknown) {
      this.title = 'Documents';
    } else {
      this.title = EnumUtilities.getDisplayName(DocumentTypesEnum, this.documentType) + ' Documents';
    }

    super.ngOnChanges(changes);
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: '',
        field: this.actionFieldName,
        width: 50,
        hide: this.documentType === DocumentTypesEnum.Template,
        cellClass: this.preventRowClickClass,
        cellRenderer: params => {
          let html = '';
          const rowData = <DocumentModel>params.data;

          if (rowData.type !== DocumentTypesEnum.Template && rowData.type !== DocumentTypesEnum.InvoiceTemplate) {
            html += '<i title="Download Document" class="cursor-pointer fas fa-download prevent-row-click download-document mr-1"></i>';
            html += '<i title="Email Document" class="cursor-pointer fas fa-envelope email-document prevent-row-click"></i>'
          }

          return html;
        }
      },
      {
        headerName: 'Name',
        field: 'name'
      },
      this.getEnumColumn('type', 'Type', DocumentTypesEnum),
      this.getEnumColumn('language', 'Language', LanguagesEnum),
      this.getYesNoColumn('businessKey', 'My Document', this.isAdminOnly)
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((cellClick: {data: DocumentModel, event: CellClickedEvent }) => {
      if (cellClick.event.colDef.field === this.actionFieldName) {
        if (this.hasCellClass(cellClick.event, 'download-document')) {
          const model = {
            businessKey: this.businessKey,
            documentKey: cellClick.data.documentKey,
          }

          this.dialogService.openDownloadDocumentRenderer(model, this.isAdminOnly);
        } else if (this.hasCellClass(cellClick.event, 'email-document')) {
          const model = {
            businessKey: this.businessKey,
            documentKey: cellClick.data.documentKey,
            email: {
              toEmailAddress: null,
              subject: cellClick.data.name,
              htmlContent: null
            }
          }

          this.dialogService.openEmailDocumentRenderer(model, this.isAdminOnly);
        }
      }
    }));
  }

  protected setRowData(): Observable<any[]> {
    const searchModel = <DocumentSearchModel>{ isAdminOnly: this.isAdminOnly};

    if (this.documentType) {
      searchModel.documentTypes = [this.documentType];
    }

    if (!this.isAdminOnly && this.businessKey) {
      searchModel.businessKeys = [this.businessKey];
    }

    return this.documentService.search(searchModel);
  }
}
