<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-header">Personal Information</div>
    <div class="form-section-content">
      <div class="mt-2">
        <div class="flex flex-wrap-responsive">
          <div class="flex">
            <mat-form-field class="mr-2">
              <mat-label>First Name</mat-label>
              <input matInput type="text" [readonly]="value?.businessServiceRequestKey" formControlName="firstName">
            </mat-form-field>
          </div>
          <div class="flex">
            <mat-form-field class="mr-2">
              <mat-label>Last Name</mat-label>
              <input matInput type="text" [readonly]="value?.businessServiceRequestKey" formControlName="lastName">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="flex flex-wrap-responsive">
            <div class="flex">
            <mat-form-field class="mr-2">
              <mat-label>Phone Number</mat-label>
              <input matInput type="text" [readonly]="value?.businessServiceRequestKey" formControlName="phoneNumber">
            </mat-form-field>
          </div>
          <div class="flex">
            <mat-form-field class="mr-2">
              <mat-label>Email</mat-label>
              <input matInput type="email" [readonly]="value?.businessServiceRequestKey" formControlName="email">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <div class="form-section-header">Address</div>
      <div class="form-section-content is-last">
        <ng-container formGroupName="address">
          <app-address-sub-form
            cssClass="mat-form-field-full"
            [readonly]="value?.businessServiceRequestKey"
            [(value)]="value.address"
            [showSecondaryAddress]="false"
            [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('address'))"></app-address-sub-form>
        </ng-container>
      </div>
    </div>
    <div class="mt-2">
      <div class="form-section-header">Service Information</div>
      <div class="form-section-content">
        <div class="mt-2">
          <mat-form-field class="mr-2">
            <mat-label>Priority</mat-label>
            <mat-select [disabled]="!!value?.businessServiceRequestKey" formControlName="priority">
              <mat-option *ngFor="let priority of priorities"
                          [value]="priority.value">{{priority.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mt-2">
          <mat-form-field class="mat-form-field-full">
            <mat-label>What do you need service for?</mat-label>
            <textarea
              [readonly]="value?.businessServiceRequestKey"
              #autosize="cdkTextareaAutosize"
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="5"
              formControlName="description">
              </textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
