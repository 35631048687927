import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AddressModel } from 'src/app/shared/models/app/address.model';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { GoogleLocationService } from 'src/app/core/services/app/google-location.service';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';

//https://github.com/angular/components/blob/master/src/google-maps/README.md

@Component({
  selector: 'app-services-page',
  templateUrl: './business-services-page.component.html',
  styleUrls: ['./business-services-page.component.scss']
})
export class BusinessServicesPageComponent extends BasePageComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  servicesWarningText: string = null;
  options: any = null;
  currentLocation: { latitude: number, longitude: number } = null;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  myMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: 'https://maps.google.com/mapfiles/kml/paddle/ylw-stars.png'
  };
  markers: { position: google.maps.LatLngLiteral, business: BusinessModel }[] = [];
  selectedBusinessMarker: BusinessModel;

  displayedColumns: string[] = ['name', 'description'];
  dataSource: any;
  hasValidSubscription = false;

  private readonly defaultLatitude = 44.986656;
  private readonly defaultLongitude = -93.258133;

  private filtersChanged: Subject<string> = new Subject<string>();

  constructor(public locationService: GoogleLocationService,
              private authClientService: AuthClientService,
              private businessService: BusinessService,
              private businessSubscriptionService: BusinessSubscriptionService,
              private businessContextService: BusinessContextService,
              private router: Router) {
    super();
  }

  ngOnInit(): void {
    if (this.authClientService.isAuthenticated) {
      if (this.businessContextService.currentBusiness) {
        let subscriptionRequest = this.businessSubscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey);
        let businessRequest = this.businessService.get(this.businessContextService.currentBusiness.businessKey);

        forkJoin({hasValidSubscription: subscriptionRequest, business: businessRequest}).subscribe(result => {
          let business = result.business;
          let hasValidSubscription = result.hasValidSubscription;

          this.hasValidSubscription = hasValidSubscription;

          if (!hasValidSubscription) {
            this.servicesWarningText = 'Your business requires a subscription to be shown on leads/services.';
          } else if (business.service.isDisabled) {
            this.servicesWarningText = 'Your business has been hidden from leads/services, to make it visible click here.';
          } else if (!business.address?.latitude || !business.address.longitude) {
            this.servicesWarningText = 'Your business has been hidden as you have not specified a latitude and longitude, to add a latitude and longitude click here.';
          } else if (!business.description && !business.file?.fileKey) {
            this.servicesWarningText = 'Your business has not been fully setup, click here to finish adding a business bio and logo.';
          } else if (!business.description ) {
            this.servicesWarningText = 'Your business has not been fully setup, click here to add a business bio.';
          } else if (!business.file?.fileKey ) {
            this.servicesWarningText = 'Your business has not been fully setup, click here to add a logo.';
          }
        });
      }
    }

    this.filtersChanged.pipe(debounceTime(1000),  distinctUntilChanged())
      .subscribe(_ => {
        this.markers = [];
        if (this.dataSource.filteredData) {
          for (let business of this.dataSource.filteredData) {
            this.addBusinessMarker(business);
          }
        }
      });
  }

  ngAfterViewInit(): void {
    this.locationService.loadApi().subscribe(_ => {
      this.setCurrentLocationByGps().subscribe(_ => {

      });
    });
  }

  onNavigateToBusinessClicked(): void {
    if (!this.hasValidSubscription) {
      this.router.navigateByUrl(RouteUtilities.routes.application.subscriptions.getNavigateUrl());
    } else {
      this.router.navigateByUrl(this.routeUtilities.routes.application.businessEdit.getNavigateUrl(this.businessContextService.currentBusiness.businessKey));
    }
  }

  onFindMeClicked(): void {
    this.setCurrentLocationByGps().subscribe(_ => {

    });
  }

  onLocationSelected(address: AddressModel): void {
    this.currentLocation.latitude = address.latitude;
    this.currentLocation.longitude = address.longitude;
    this.initializeMap();
  }

  onOpenInfoWindowClicked(marker: MapMarker, business: BusinessModel) {
    if (business) {
      this.selectedBusinessMarker = business;
      this.infoWindow.open(marker);
    }
  }

  onApplyFilterKeyUp(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.filtersChanged.next(this.dataSource.filter);
  }

  onMapClicked(): void {
    this.infoWindow.close();
  }

  onRowClicked(business: BusinessModel): void {
    if (this.isApplicationRoute()) {
      this.router.navigateByUrl(this.routeUtilities.routes.application.service.getNavigateUrl(business.businessKey));
    } else {
      this.router.navigateByUrl(this.routeUtilities.routes.static.service.getNavigateUrl(business.businessKey));
    }
  }

  private setCurrentLocationByGps(): Observable<{ latitude: number, longitude: number }> {
    return this.locationService.getCurrentLocation().pipe(tap(pos => {
      this.currentLocation = pos;
      this.currentLocation.latitude ??= this.defaultLatitude;
      this.currentLocation.longitude ??= this.defaultLongitude;

      this.initializeMap();
    }), map(_ => {
      return this.currentLocation;
    }), err => {
      this.currentLocation =  {
        latitude: this.defaultLatitude,
        longitude: this.defaultLongitude
      };

      this.initializeMap();

      return of(this.currentLocation);
    });
  }

  private initializeMap(): void {
    this.setMapOptions();

    const searchModel = {
      includeMyBusinessesOnly: false,
      includeActiveServiceOnly: true,
      latitude: this.currentLocation.latitude,
      longitude: this.currentLocation.longitude
    };

    this.businessService.search(searchModel).subscribe(businesses => {
      businesses.map(business => {
        this.addBusinessMarker(business);
      });

      this.dataSource = new MatTableDataSource(businesses);
      this.dataSource.paginator = this.paginator;
    });
  }

  private setMapOptions(): void {
    this.options = {
      center: {lat: this.currentLocation?.latitude ?? this.defaultLatitude, lng: this.currentLocation?.longitude ?? this.defaultLongitude},
      zoom: 10
    };
  }

  private addBusinessMarker(business: BusinessModel): void {
    this.markers.push({
      position: {
        lat: business.address.latitude,
        lng: business.address.longitude
      },
      business: business
    });
  }
}

