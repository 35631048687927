import { Component, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { DocumentSearchModel } from 'src/app/shared/models/domain/document-search.model';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import { EmailInvoiceModel } from 'src/app/shared/models/domain/email-invoice.model';
import { InvoiceService } from 'src/app/core/services/domain/invoice.service';
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-email-invoice-form',
  templateUrl: './email-invoice-form.component.html',
  styleUrls: ['./email-invoice-form.component.scss']
})
export class EmailInvoiceFormComponent extends BaseFormComponent<EmailInvoiceModel> implements OnInit {
  @Input() isAdmin: boolean = false;
  @Input() businessKey: string = null;
  defaultInvoiceTemplateValue: string = '';

  invoiceTemplates: DocumentModel[] = [];

  constructor(
    private documentService: DocumentService,
    private invoiceService: InvoiceService,
  ) {
    super();
  }

  ngOnInit(): void {
    const searchModel: DocumentSearchModel = {
      documentTypes: [DocumentTypesEnum.InvoiceTemplate],
      businessKeys: [this.businessKey]
    }

    this.documentService.search(searchModel).subscribe(documentTemplates => {
      this.invoiceTemplates = documentTemplates;
    })

    super.ngOnInit();

    this.invoiceService.get(this.value.invoiceKey).subscribe(invoice => {
      if (invoice.customer != null) {
        this.formGroupRef.patchValue({
          email: {
            toEmailAddress: invoice.customer.email,
            toEmailAddressAlias: invoice.customer.name
          }
        });
        this.formGroupRef.markAsDirty();
        this.formGroupRef.updateValueAndValidity();
      }
    });
  }

  ngAfterViewInit()
  {
    this.defaultInvoiceTemplateValue = this.getDefaultInvoiceTemplate();
  }

  protected sanitizeValue(value: EmailInvoiceModel): EmailInvoiceModel {
    value.invoiceTemplateDocumentKey === "null" ? value.invoiceTemplateDocumentKey = null : value.invoiceTemplateDocumentKey;
    return value;
  }

  protected getFormGroup(): UntypedFormGroup {
     return new UntypedFormGroup({
      invoiceKey: new UntypedFormControl(this.value.invoiceKey ?? null),
      email: new UntypedFormGroup({
        toEmailAddress: new UntypedFormControl(this.value.email?.toEmailAddress ?? null),
        toEmailAddressAlias: new UntypedFormControl(this.value.email?.toEmailAddressAlias ?? null),
        subject: new UntypedFormControl(this.value.email?.subject ?? null)
      }),
      invoiceTemplateDocumentKey: new UntypedFormControl(this.value.invoiceTemplateDocumentKey ?? null)
    });
  }

  private readonly _defaultInvoiceTemplateKey = 'defaultInvoiceTemplate' + this.businessKey;

  onInvoiceTemplateChange($event: MatSelectChange) {
    localStorage.setItem(this._defaultInvoiceTemplateKey , $event.value);
  }

  getDefaultInvoiceTemplate() : string {
    let keyName = this._defaultInvoiceTemplateKey;
    return localStorage.getItem(keyName) ?? null;
  }
}
