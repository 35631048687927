import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { ChartOfAccountConstants } from 'src/app/shared/constants/chart-of-account.constants';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';

@Component({
  selector: 'app-chart-of-account-page',
  templateUrl: './chart-of-account-page.component.html',
  styleUrls: ['./chart-of-account-page.component.scss']
})
export class ChartOfAccountPageComponent extends BaseEntryPageComponent<ChartOfAccountModel> implements OnInit {
  chartOfAccountKey: string;
  bankingChartOfAccountKey: string;
  chartOfAccountConstants = ChartOfAccountConstants;
  invoiceTypes = InvoiceTypesEnum;

  constructor(
    public authClientService: AuthClientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private businessContextService: BusinessContextService,
    public chartOfAccountService: ChartOfAccountService,
    private dialogService: DialogService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    const chartOfAccountKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.chartOfAccountKey, this.activatedRoute.snapshot);

    if (chartOfAccountKey) {
      this.chartOfAccountService.get(chartOfAccountKey).subscribe(chartOfAccount => {
        this.value = chartOfAccount;
        if (this.chartOfAccountService.isBankAccount(chartOfAccount)) {
          this.bankingChartOfAccountKey = chartOfAccount.chartOfAccountKey;
        } else {
          this.chartOfAccountKey = chartOfAccount.chartOfAccountKey;
        }

        this.selectedTabIndex = +this.getQueryParameterFromRoute(this.routingKeysUtilities.queryParams.selectedTabIndex, this.activatedRoute.snapshot) || 0;
      })
    } else {
      this.value = <ChartOfAccountModel>{
        businessKey: this.businessContextService.currentBusiness.businessKey
      };
    }
  }

  onSaveClicked(isUndeleting: boolean = false): void {
    this.isSaving = true;
    if (!this.value.chartOfAccountKey) {
      this.chartOfAccountService.create(this.value).subscribe(_ => {
        this.onSaveSuccess('Chart of account was created successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    } else {
      if (isUndeleting) {
        this.value.isDeleted = false;
      }

      this.chartOfAccountService.update(this.value).subscribe(_ => {
        this.onSaveSuccess('Chart of account was updated successfully.');
        this.redirectAfterSave();
      }, err => {
        this.onSaveFailed(err);
      });
    }
  }

  onDeleteClicked(): void {
    if (this.isSaving || this.isDeleting) {
      return;
    }

    if (!this.value.isDeleted) {
      let matDialogRef = this.dialogService.openConfirmationDialog();

      matDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isDeleting = true;
          this.chartOfAccountService.delete(this.value.chartOfAccountKey).subscribe(result => {
            if (result) {
              this.onSaveSuccess('Chart of account has been deleted successfully.');
            } else {
              this.onSaveFailed('Transaction failed to be deleted.')
            }

            this.redirectAfterSave();
          }, (err) => {
            this.onSaveFailed(err);
          });
        }
      });
    } else {
      this.onSaveClicked(true);
    }
  }

  onTransactionAddClicked(): void {
    let chartOfAccountKey = null;
    let bankingChartOfAccountKey = null;

    let isCalculatedAccount = this.chartOfAccountService.listCalculatedChartOfAccounts().indexOf(this.value.name) > -1;
    if (!isCalculatedAccount) {
      if (!this.chartOfAccountService.isBankAccount(this.value)) {
        chartOfAccountKey = this.value.chartOfAccountKey;
      } else {
        bankingChartOfAccountKey = this.value.chartOfAccountKey;
      }
    }

    this.router.navigateByUrl(RouteUtilities.routes.application.registryAdd.getNavigateUrl(chartOfAccountKey, bankingChartOfAccountKey));
  }

  onTransactionsRowClicked(event): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.registryEdit.getNavigateUrl(event.data.transactionKey));
  }

  onAddInvoiceClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.invoiceAdd.getNavigateUrl(this.value.name === ChartOfAccountConstants.accountsPayable ? InvoiceTypesEnum.Bill : InvoiceTypesEnum.Invoice));
  }

  onInvoiceRowClicked(event): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.invoiceEdit.getNavigateUrl(event.data.invoiceKey));
  }

  redirectAfterSave(): boolean {
    let hasRedirect = super.redirectAfterSave();

    if (!hasRedirect) {
      this.router.navigateByUrl(RouteUtilities.routes.application.chartOfAccounts.getNavigateUrl());
    }

    return true;
  }
}
