<form [formGroup]="formGroupRef">
  <ng-container *ngIf="isProfileSetup">
    <div class="flex flex-wrap">
      <div class="flex">
        <div class="form-section">
          <div class="form-section-header">
            <span>Business Profile</span>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2  mat-form-field-double">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name">
              <mat-icon matSuffix
                        matTooltip="Enter business name">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field-double">
              <mat-label>Legal Name</mat-label>
              <input matInput type="text" formControlName="legalName">
              <mat-icon matSuffix
                        matTooltip="Enter business legal name">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field-double">
              <mat-label>Phone</mat-label>
              <input matInput type="text" formControlName="phoneNumber">
              <mat-icon matSuffix
                        matTooltip="Enter business phone">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field-double">
              <mat-label>Email</mat-label>
              <input matInput type="text" formControlName="email">
              <mat-icon matSuffix
                        matTooltip="Enter email address">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field-double">
              <mat-label>Website</mat-label>
              <input matInput type="text" (blur)="onWebsiteBlurred()" formControlName="website"
                     placeholder="https://google.com">
              <mat-icon matSuffix
                        matTooltip="Enter business URL">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="flex">
        <div>
          <div class="form-section-header">
            <span>Business Address</span>
          </div>
          <app-address-sub-form
            [showGPS]="true"
            [showSecondaryAddress]="!isProfileSetup"
            [(value)]="value.address"
            [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('address'))">
          </app-address-sub-form>
          <div class="subtle-text">
            The latitude and longitude will be required for others to find you in leads/services.<br/>
            Using the search field above will autofill the latitude and longitude for your business.
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isProfileSetup">
    <mat-tab-group #matTabGroup [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onSelectedTabChanged()">
      <mat-tab label="My Business">
        <div class="form-section mt-2">
          <div class="mt-2">
            <mat-form-field class="mr-2  mat-form-field-double">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name">
              <mat-icon matSuffix
                        matTooltip="Enter business name">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field-double">
              <mat-label>Legal Name</mat-label>
              <input matInput type="text" formControlName="legalName">
              <mat-icon matSuffix
                        matTooltip="Enter business legal name">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field-double">
              <mat-label>Phone</mat-label>
              <input matInput type="text" formControlName="phoneNumber">
              <mat-icon matSuffix
                        matTooltip="Enter business phone">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field-double">
              <mat-label>Email</mat-label>
              <input matInput type="text" formControlName="email">
              <mat-icon matSuffix
                        matTooltip="Enter email address">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field-double">
              <mat-label>Website</mat-label>
              <input matInput type="text" (blur)="onWebsiteBlurred()" formControlName="website" placeholder="https://google.com">
              <mat-icon matSuffix
                        matTooltip="Enter business URL">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Addresses">
        <div class="form-section mt-2" formGroupName="address">
          <app-address-sub-form
            [showGPS]="true"
            [showSecondaryAddress]="!isProfileSetup"
            [(value)]="value.address"
            [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('address'))">
          </app-address-sub-form>
        </div>
      </mat-tab>
      <mat-tab label="Branding" *ngIf="!isProfileSetup && hasValidSubscription">
        <div class="form-section" style="margin-top:20px">
          <div class="mt-2" *ngIf="previewFileUrl">
            <img alt="Business logo" [src]="previewFileUrl | safeUrl">
          </div>
          <div class="mt-2" *ngIf="!previewFileUrl && value?.file?.uri && !fileList?.length">
            <img alt="Business logo" [src]="value.file?.uri">
          </div>
          <div>
          <span class="d-flex align-items-center mt-2">
            <app-upload class="me-1" [label]="fileList?.length || value.file?.fileKey ? 'Replace Logo' : 'Upload Logo'"
                        accept="image/*"
                        (fileListChange)="onFilesUploaded($event)"></app-upload>
            <mat-icon matSuffix
                      matTooltip="Click & upload logo">
              help_outline
            </mat-icon>
          </span>
            <span>
            <button mat-flat-button *ngIf="value.file?.fileKey && !previewFileUrl"
                    (click)="onDownloadLogoClicked()">Download {{value.file?.name}}</button>
          </span>
          </div>
        </div>
        <div class="form-section mt-2" style="height: 400px;">
          <div>
            <mat-form-field class="mr-2 mat-form-field">
              <mat-label>Primary Color</mat-label>
              <input matInput type="text" placeholder="#ffffff" formControlName="primaryColor">
              <mat-icon matSuffix
                        matTooltip="You can scroll right or left to find your company's primary color">
                help_outline
              </mat-icon>
            </mat-form-field>
            <span class="mr-1" *ngIf="colors.primaryColor" [style.background]="colors.primaryColor">&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <div class="inline-block">
              <a href="javascript:void(0)"
                 [cpPosition]="'right'"
                 [cpPositionOffset]="'50%'"
                 [cpPositionRelativeToArrow]="true"
                 [(colorPicker)]="colors.primaryColor"
                 (colorPickerChange)="onColorChanged('primaryColor', $event)">
                Need help finding your primary color? </a>
            </div>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field">
              <mat-label>Secondary Color</mat-label>
              <input matInput type="text" placeholder="#ffffff" formControlName="secondaryColor">
              <mat-icon matSuffix
                        matTooltip="You can scroll right or left to find your company's secondary color">
                help_outline
              </mat-icon>
            </mat-form-field>
            <span class="mr-1" *ngIf="colors.secondaryColor" [style.background]="colors.secondaryColor">&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <div class="inline-block">
              <a href="javascript:void(0)"
                 [cpPosition]="'right'"
                 [cpPositionOffset]="'50%'"
                 [cpPositionRelativeToArrow]="true"
                 [(colorPicker)]="colors.secondaryColor"
                 (colorPickerChange)="onColorChanged('secondaryColor', $event)">
                Need help finding your secondary color? </a>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Accounting" *ngIf="!isProfileSetup && hasValidSubscription">
        <div class="form-section mt-2">
          <ng-container formGroupName="accounting">
            <div class="mt-2">
              <mat-form-field>
                <mat-label>EIN</mat-label>
                <input matInput formControlName="employerIdentificationNumber">
                <mat-icon matSuffix
                          matTooltip="Enter Employee Identification Number">
                  help_outline
                </mat-icon>
              </mat-form-field>
            </div>
            <div class="mt-2">
              <mat-form-field class="mr-2">
                <mat-label>First Month of Fiscal Year</mat-label>
                <mat-select formControlName="fiscalTaxYearMonth">
                  <mat-option *ngFor="let month of months"
                              [value]="month.value">{{month.label}}</mat-option>
                </mat-select>
                <mat-icon matSuffix
                          matTooltip="A fiscal year is a period of 12 months that a company uses for accounting purposes">
                  help_outline
                </mat-icon>
              </mat-form-field>
            </div>
            <div class="mt-2">
              <mat-form-field class="mr-2">
                <mat-label>First month of Income Tax Year</mat-label>
                <mat-select formControlName="incomeTaxYearMonth">
                  <mat-option *ngFor="let incomeTaxYearType of incomeTaxYearTypes"
                              [value]="incomeTaxYearType.value">{{incomeTaxYearType.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mt-2">
              <mat-form-field class="mr-2">
                <mat-label>Accounting Method</mat-label>
                <mat-select formControlName="accountingMethod">
                  <mat-option *ngFor="let accountingMethod of accountingMethods"
                              [value]="accountingMethod.value">{{accountingMethod.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mt-2">
              <mat-form-field class="mat-form-field-date">
                <mat-label>Closing Date</mat-label>
                <input matInput [matDatepicker]="closingDtPicker" formControlName="closingDate">
                <mat-datepicker-toggle matSuffix [for]="closingDtPicker"></mat-datepicker-toggle>
                <mat-datepicker #closingDtPicker></mat-datepicker>
                <mat-icon matSuffix
                          matTooltip="Choose closing date of the account">
                  help_outline
                </mat-icon>
              </mat-form-field>
            </div>
            <div class="mt-2">
              <mat-form-field class="mat-form-field-double">
                <mat-label>Default Invoice Notes</mat-label>
                <textarea
                  #autosize="cdkTextareaAutosize"
                  matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="5"
                  cdkAutosizeMaxRows="5"
                  formControlName="defaultInvoiceNotes">
              </textarea>
                <mat-icon matSuffix
                          matTooltip="Enter a notation on this field and it populates on all invoices">
                  help_outline
                </mat-icon>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab label="Services" *ngIf="!isProfileSetup && hasValidSubscription">
        <div class="form-section mt-2">
          <ng-container formGroupName="service">
            <div class="d-flex align-items-center mt-2">
              <mat-slide-toggle class="me-1" formControlName="isDisabled">Hide</mat-slide-toggle>
              <mat-icon matSuffix
                        matTooltip="Other clients & consumers can view your services if you desire them to">
                help_outline
              </mat-icon>
            </div>
            <div class="mt-2">
              <mat-form-field class="mr-2" style="width:120px">
                <mat-label>Discount %</mat-label>
                <input matInput type="text" currencyMask formControlName="discountRate" [options]="{ prefix: '%', precision: 0 }"
                       min="0" max="100" maxLength="3">
                <mat-icon matSuffix
                          matTooltip="Offer potential clients discount for your services">
                  help_outline
                </mat-icon>
              </mat-form-field>
            </div>
          </ng-container>
          <div class="mt-2">
            <mat-form-field class="mat-form-field-double">
              <mat-label>Business Bio</mat-label>
              <textarea
                #autosize="cdkTextareaAutosize"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="5"
                formControlName="description">
              </textarea>
              <mat-icon matSuffix
                        matTooltip="Describe what your business offers and client will view these notes">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <ng-container formGroupName="service">
            <div class="mt-2">
              <mat-form-field class="mat-form-field-double">
                <mat-label>Services Notes</mat-label>
                <textarea
                  #autosize="cdkTextareaAutosize"
                  matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="5"
                  cdkAutosizeMaxRows="5"
                  formControlName="notes">
              </textarea>
                <mat-icon matSuffix
                          matTooltip="Internal notes about your company">
                  help_outline
                </mat-icon>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab label="Attachments" *ngIf="value?.businessKey && !this.isProfileSetup && hasValidSubscription">
        <div class="mt-2">
          <app-business-files-grid
            [showEntity]="true">
          </app-business-files-grid>
        </div>
      </mat-tab>
      <mat-tab label="Link Banking Information" *ngIf="value?.businessKey && !this.isProfileSetup && hasValidSubscription">
        <div class="form-section mt-2">
          <div class="subtle-text error" *ngIf="isSandboxBanking">
            SANDBOX CREDENTIALS: USER: user_good PASSWORD: pass_good MOBILE SECURITY CODE: 1234
          </div>
          <div class="d-flex align-items-center">
            <div class="flex subtle-text">
              Link your bank account(s) to have Divine Management manage your transactions. <span class="error">Only link the accounts which apply to your business.</span>
            </div>
            <div class="float-right">
              <div class="inline-block mr-2">
                <button class="mr-1" color="primary" *ngIf="bankingConnections?.length" mat-flat-button
                        (click)="onSyncInstitutionClicked()">Sync All Institution(s)
                  <mat-icon matSuffix
                            matTooltip="Integrate/refresh application with multiple business bank accounts associated with your business">
                    help_outline
                  </mat-icon>
                </button>
              </div>
              <div class="inline-block">
                <button class="mr-1" color="primary" mat-flat-button (click)="onConnectBankAccountClicked()">Link New
                  Institution
                  <mat-icon matSuffix
                            matTooltip="Update application with your business bank financial information">
                    help_outline
                  </mat-icon>
                </button>

              </div>
            </div>
            <div class="clear-both"></div>
          </div>
          <br/>
          <div class="error" *ngIf="bankingConnections?.length">If transaction import start date is used,
            then once sync is run, transactions earlier than the import date (which were skipped) cannot be re-imported.
          </div>
          <div class="mt-2" *ngFor="let bankingConnection of bankingConnections">
            <div class="flex-full-auto">
              <div class="flex bold mt-2 font-size-18">
                <span>{{bankingConnection.institution.name}} -&nbsp;</span>
                {{bankingConnection.bankingAccounts?.length ?? 0}} Account(s)&nbsp;
                <span
                  *ngIf="bankingConnection.transactionsLastProcessedDateTime"> - Last synced on {{bankingConnection.transactionsLastProcessedDateTime | dateFormatter}}</span>
              </div>
              <div class="flex-end">
              <span>
                <button mat-flat-button color="primary" (click)="onSyncInstitutionClicked(bankingConnection)">Sync Institution</button>
              </span>
                <span class="ml-2">
                <button mat-flat-button color="primary"
                        (click)="onConnectBankAccountClicked()">Manage Institution</button>
              </span>
                <span class="ml-2">
                <button mat-flat-button color="primary"
                        (click)="onDeleteBankAccountClicked(bankingConnection.bankingConnectionKey)">Delete Institution</button>
              </span>
              </div>
            </div>
            <ng-container *ngIf="bankingConnection.bankingAccounts?.length">
              <div class="mt-2">
                <div class="subtle-text bold" *ngIf="!bankingConnection.bankingAccounts?.length">
                  You currently have no accounts linked.
                </div>
                <div *ngIf="bankingConnection.bankingAccounts?.length">
                  <table>
                    <thead>
                    <tr>
                      <td style="width:10%;font-weight:bold">Institution</td>
                      <td style="width:20%;font-weight:bold">Account Name</td>
                      <td style="width:15%;font-weight:bold">Type</td>
                      <td style="width:20%;font-weight:bold">Current / Available / Limit</td>
                      <td style="width:5%;font-weight:bold">Sync</td>
                      <td style="width:10%;font-weight:bold">Import Start Date</td>
                      <td style="width:10%;font-weight:bold"></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="height:45px;" *ngFor="let bankingAccount of bankingConnection.bankingAccounts">
                      <td style="width:10%;">
                        {{bankingAccount.institution?.name}}
                      </td>
                      <td style="width:20%;">
                        <a href="javascript:void(0)"
                           (click)="onNavigateToBankingChartOfAccountClicked(bankingAccount.bankingAccountId)"
                           *ngIf="bankingAccount.bankingAccountId">{{bankingAccount.name}}
                          ({{bankingAccount.numberMask}})</a>
                      </td>
                      <td style="width:15%;">
                        {{stringUtilities.convertFromCamelCase(bankingAccount.type)}}
                        ({{stringUtilities.convertFromCamelCase(bankingAccount.subType)}})
                      </td>
                      <td style="width:20%;">
                        {{numberUtilities.formatAsCurrency(bankingAccount.currentBalance)}}
                        / {{numberUtilities.formatAsCurrency(bankingAccount.availableBalance)}}
                        / {{numberUtilities.formatAsCurrency(bankingAccount.limit)}}
                      </td>
                      <td style="width:5%;">
                        <mat-checkbox
                          (change)="onBankAccountEnableChange($event, value?.businessKey, bankingAccount.bankingAccountId)"
                          [checked]="bankingAccount.isActive">
                        </mat-checkbox>
                      </td>
                      <td style="width:10%;">
                        <ng-container *ngIf="!bankingAccount?.transactionsLastProcessedDateTime">
                          <mat-form-field>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <input matInput [matDatepicker]="picker"
                                   (dateChange)="onTxStartDateChange($event.value,value?.businessKey, bankingAccount.bankingAccountId)"
                                   value={{bankingAccount?.transactionsProcessingStartDate}}
                                   placeholder="All Transactions">
                            <mat-datepicker
                              [startAt]="bankingAccount?.transactionsProcessingStartDate ?? dateUtilities.getFirstDayOfCurrentYear()"
                              #picker>
                            </mat-datepicker>
                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="bankingAccount.transactionsLastProcessedDateTime">
                          {{bankingAccount.transactionsProcessingStartDate | dateFormatter}}
                        </ng-container>
                      </td>
                      <td style="width:10%;">
                        <mat-icon class="cursor-pointer" style="margin-left: 60px; color:red"
                                  *ngIf="!bankingAccount.hasStartingBalance"
                                  matTooltip="Starting balance has not been set."
                                  (click)="onStartingBalanceModalClicked(bankingAccount)">warning
                        </mat-icon>
                        <mat-icon style="margin-left: 60px; color:green" *ngIf="bankingAccount.hasStartingBalance"
                                  matTooltip="Starting balance has been set.">check_circle
                        </mat-icon>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <hr/>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!bankingConnection.bankingAccounts?.length">
              <div class="mt-2 text-center subtle-text bold">
                No bank accounts found or your banking account link has expired, please click manage institution to add
                bank accounts.
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="!bankingConnections?.length">
            <div class="text-center">
              No linked institutions found.
            </div>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</form>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
