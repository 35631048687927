import { BusinessFileClassificationsEnum } from 'src/app/shared/enums/domain/business-file-classifications.enum';

export class BusinessFileSearchModel {
  vendorKey?: string;
  customerKey?: string;
  invoiceKey?: string;
  businessKey?: string;

  classification: BusinessFileClassificationsEnum;

  includeDeleted: boolean = false;
  isAdminOnly: boolean = false;
}
