import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';

@Component({
  selector: 'app-businesses-admin',
  templateUrl: './businesses-admin-page.component.html',
  styleUrls: ['./businesses-admin-page.component.scss']
})
export class BusinessesAdminPageComponent extends BasePageComponent implements OnInit {

  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  onRowClicked(event: {data: BusinessModel}): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.admin.businessEdit.getNavigateUrl(event.data.businessKey));
  }

  onAddButtonClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.admin.businessAdd.getNavigateUrl());
  }

}
