import { Component, OnInit } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { TransactionRuleService } from 'src/app/core/services/domain/transaction-rule.service';
import { TransactionRuleModel } from 'src/app/shared/models/domain/transaction-rule.model';
import { TransactionRuleFieldsEnum } from 'src/app/shared/enums/domain/transaction-rule-fields.enum';
import { TransactionRuleConditionsEnum } from 'src/app/shared/enums/domain/transaction-rule-conditions.enum';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';

@Component({
  selector: 'app-transaction-rules-grid',
  templateUrl: './transaction-rules-grid.component.html',
  styleUrls: ['./transaction-rules-grid.component.scss']
})
export class TransactionRulesGridComponent extends BaseGridComponent<TransactionRuleModel> implements OnInit {
  constructor(private businessContextService: BusinessContextService,
              private transactionRuleService: TransactionRuleService,
              private dialogService: DialogService,
              private router: Router) {
    super();
    this.gridKey = 'transaction-rules_v1';
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.add(this.addButtonClick.subscribe(_ => {
      this.openFormDialog();
    }));

    this.subscriptions.add(this.rowClick.subscribe(data => {
      this.openFormDialog(data.data.transactionRuleKey);
    }));
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      this.getEnumColumn('ruleOneField', 'Rule #1 Field', TransactionRuleFieldsEnum),
      this.getEnumColumn('ruleOneCondition', 'Rule #1 Condition', TransactionRuleConditionsEnum),
      this.getDefaultColumn('ruleOneTerm', 'Rule #1 Term'),
      this.getEnumColumn('ruleTwoField', 'Rule #2 Field', TransactionRuleFieldsEnum),
      this.getEnumColumn('ruleTwoCondition', 'Rule #2 Condition', TransactionRuleConditionsEnum),
      this.getDefaultColumn('ruleTwoTerm', 'Rule #2 Term'),
      this.getDefaultColumn('chartOfAccount.name', 'Chart of Account'),
      this.getDefaultColumn('vendor.name', 'Vendor'),
      {
        headerName: 'Name',
        field: 'name'
      },
      {
        headerName: 'Priority',
        field: 'priority'
      },
      this.getYesNoColumn('continueExecuting', 'Continue to Execute')
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((data: { data: TransactionRuleModel, event: CellClickedEvent }) => {
      this.openFormDialog(data.data.transactionRuleKey);
    }));
  }

  protected setRowData(): Observable<any[]> {
    return this.transactionRuleService.search({businessKey: this.businessContextService.currentBusiness.businessKey, includeDeleted: this.includeDeleted});
  }

  private openFormDialog(transactionRuleKey: string = null): void {
    const dialogRef = this.dialogService.openTransactionRule(transactionRuleKey);

    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.refreshGridData().subscribe();
      }
    });
  }
}
