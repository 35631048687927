import { Component, OnInit } from '@angular/core';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';

@Component({
  selector: 'app-businesses-page',
  templateUrl: './businesses-page.component.html',
  styleUrls: ['./businesses-page.component.scss']
})
export class BusinessesPageComponent extends BasePageComponent implements OnInit {
  businesses: BusinessModel[] = [];

  constructor(
    private router: Router,
    private businessService: BusinessService) {
    super();
  }

  ngOnInit(): void {
    this.businessService.search({includeMyBusinessesOnly: true}).subscribe((res)=> {
      this.businesses = res;
    },error => {

    })
  }

  onAddBusinessClick(): void {
    this.router.navigate([RouteUtilities.routes.application.businessAdd.getNavigateUrl()]);
  }

  onEditBusinessClick(business: BusinessModel): void {
    this.router.navigate([RouteUtilities.routes.application.businessEdit.getNavigateUrl(business.businessKey)]);

  }

}
