import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent extends BaseComponent implements OnInit {
  @Input() label: string = 'Upload File';
  @Input() accept: string = '*.*';
  @Output() fileListChange = new EventEmitter<FileList>();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  onFileSubmitted(fileList: FileList): void {
    this.fileListChange.emit(fileList);
  }
}
