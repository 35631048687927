import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { MatStepper } from '@angular/material/stepper';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { ClientService } from 'src/app/core/services/domain/client.service';
import { Observable, of } from 'rxjs';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ClientModel } from 'src/app/shared/models/domain/client.model';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { BusinessFormComponent } from 'src/app/shared/components/forms/business-form/business-form.component';

@Component({
  selector: 'app-profile-setup-page',
  templateUrl: './profile-setup-page.component.html',
  styleUrls: ['./profile-setup-page.component.scss']
})
export class ProfileSetupPageComponent extends BasePageComponent implements OnInit, AfterViewInit {
  isCheckingVerification: boolean = true;
  clientFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  businessFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  clientValue: ClientModel;
  businessValue: BusinessModel = <BusinessModel>{};
  businessFileList: FileList;
  showBusinessCard = false;
  isSendingVerificationEmail: boolean = false;
  showBlockingLoader = true;
  walkthroughVideoConfig = {
    src: '/assets/videos/complete-walkthrough.mp4',
    title: 'Complete Walkthrough',
    description: 'This video will help you understand how to utilize the application.'
  };

  private readonly clientProfileStep = 1;

  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild('businessForm') private businessForm: BusinessFormComponent;

  constructor(
    public authClientService: AuthClientService,
    public businessContextService: BusinessContextService,
    private router: Router,
    private businessService: BusinessService,
    private clientService: ClientService,
    private notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit() {
    this.initClientProfile().subscribe(_ => {
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    });
    this.initBusiness().subscribe(_ => {
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    });
  }

  ngAfterViewInit() {
    this.checkVerification(false);
  }

  onCheckVerificationStatusClicked(): void {
    this.isSaving = true;
    this.checkVerification(true);
  }

  onSendVerifyEmailClicked(): void {
    this.isSendingVerificationEmail = true;
    this.clientService.sendVerificationEmail().subscribe(result => {
      if (result) {
        this.notificationService.showSuccessNotification('Email verification was sent successfully.');
      } else {
        this.notificationService.showErrorNotification('Email was already verified or there was a problem sending your email.');
      }

      this.isSendingVerificationEmail = false;
    }, err => {
      this.isSendingVerificationEmail = false;
      this.onHttpFailure(this.notificationService, err);
    });
  }

  onSaveClientProfileClicked(): void {
    if (!this.clientFormGroup.dirty && this.clientValue.clientKey) {
      this.stepper.next();
      this.isSaving = false;
      return;
    }

    let request;
    let isUpdate = false;
    this.isSaving = true;

    if (!this.clientValue.clientKey) {
      request = this.clientService.create(this.clientValue)
    } else {
      request = this.clientService.update(this.clientValue);
      isUpdate = true;
    }

    request.pipe(
      mergeMap(_ => {
        return this.authClientService.refreshUser();
      }), mergeMap(_ => {
        return this.initClientProfile();
      })).subscribe(_ => {
      const message = `Client has been ${(isUpdate ? 'updated' : 'created')} successfully.`;
      this.notificationService.showSuccessNotification(message);
      this.stepper.next();
      this.isSaving = false;
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    });
  }

  onBusinessPreviousClicked(): void {
    if (this.businessForm.matTabGroup.selectedIndex === 0) {
      this.stepper.previous();
    } else {
      // @ts-ignore
      this.businessForm.matTabGroup.selectedIndex--;
    }
  }

  onSaveBusinessClicked(): void {
    if (!this.businessFormGroup.dirty && this.businessValue.businessKey) {
      this.stepper.next();
      this.isSaving = false;
      return;
    }

    this.isSaving = true;

    if (!this.businessValue.businessKey) {
      this.businessService.create(this.businessValue, this.businessFileList).subscribe(businessKey => {
        this.businessFormGroup.get('businessKey').setValue(businessKey);
        this.businessValue.businessKey = businessKey;
        this.businessContextService.updateBusinessContext(this.businessValue);
        this.notificationService.showSuccessNotification('Your business has been created successfully.')
        this.stepper.next();
        this.businessFormGroup.markAsPristine();
        this.isSaving = false;
      }, err => {
        this.onHttpFailure(this.notificationService, err);
      });
    } else {
      this.businessService.update(this.businessValue, this.businessFileList).subscribe(_ => {
        this.businessContextService.updateBusinessContext(this.businessValue);
        this.notificationService.showSuccessNotification('Your business has been updated successfully.')
        this.stepper.next();
        this.businessFormGroup.markAsPristine();
        this.isSaving = false;
      }, err => {
        this.onHttpFailure(this.notificationService, err);
      });
    }
  }

  onFinishClicked(): void {
    if (!this.authClientService.client?.isEmailVerified || !this.authClientService.client?.clientKey) {
      this.notificationService.showNotification('Please verify your email and setup your profile before finishing.')
      this.stepper.selectedIndex = this.clientProfileStep;
    }

    this.router.navigateByUrl(this.routeUtilities.routes.application.services.getNavigateUrl());
  }

  onStepperChanged(): void {
  }

  private checkVerification(showNotification: boolean): void {
    this.authClientService.refreshUser().subscribe((res) => {
      this.isCheckingVerification = false;
      this.isSaving = false;
      if (this.authClientService.client.isEmailVerified) {
        if (showNotification) {
          this.notificationService.showSuccessNotification('Your email has been verified successfully.');
        }

        setTimeout(_ => {
          this.stepper.next();
          this.showBlockingLoader = false;
        }, 500);
      } else if (showNotification) {
        this.notificationService.showErrorNotification('Your email has failed to be verified.  Please try again.');
        this.showBlockingLoader = false;
      } else {
        this.showBlockingLoader = false;
      }
    }, err => {
      this.onHttpFailure(this.notificationService, err);
      this.showBlockingLoader = false;
    })
  }

  private initClientProfile(): Observable<ClientModel> {
    return this.clientService.search({email: this.authClientService.client.email}).pipe(tap(clients => {
      if (clients.length === 1) {
        this.clientValue = <ClientModel>clients[0];
      } else {
        this.clientValue = <ClientModel>this.authClientService.client;
      }
    }), map(_ => {
      return this.clientValue;
    }));
  }

  private initBusiness(): Observable<BusinessModel[]> {
    return this.businessService.search({includeMyBusinessesOnly: true}).pipe(tap(businesses => {
      //this.showBusinessCard = !this.authClientService.isAdmin && businesses.length < 2;
      this.showBusinessCard = true;

      if (businesses.length === 1) {
        this.businessValue = businesses[0];
      }
    }));
  }
}
