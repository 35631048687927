import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DocumentModel } from 'src/app/shared/models/domain/document.model';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { DocumentTypesEnum } from 'src/app/shared/enums/domain/document-types.enum';
import { LanguagesEnum } from 'src/app/shared/enums/domain/languages.enum';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DocumentService } from 'src/app/core/services/domain/document.service';
import { environment } from 'src/environments/environment';
import { DocumentRenderModel } from 'src/app/shared/models/domain/document-render.model';
import { DocumentSearchModel } from 'src/app/shared/models/domain/document-search.model';
import { EmailDocumentModel } from 'src/app/shared/models/domain/email-document.model';
import { DocumentTokenConstants } from 'src/app/shared/constants/document-token.constants';
import { MatSelectChange } from '@angular/material/select';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { DialogService } from '../../../../core/services/domain/dialog.service';
import { EmailInvoiceModel } from 'src/app/shared/models/domain/email-invoice.model';

@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss']
})
export class DocumentFormComponent extends BaseFormComponent<DocumentModel> implements OnInit, AfterViewInit {
  @Input() businessKey: string = null;
  @Input() isAdmin: boolean = false;
  @Input() fileList: FileList;
  @Output() fileListChange = new EventEmitter<FileList>();
  @Output() downloadRenderedClick = new EventEmitter<DocumentRenderModel>();
  @Output() emailRenderedClick = new EventEmitter<EmailDocumentModel>();

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  readonly tinyMceLicenseKey: string = environment.tinyMceLicenseKey;
  documentTypeTemplate = DocumentTypesEnum.Template;
  documentTypeInvoiceTemplate = DocumentTypesEnum.InvoiceTemplate;
  editor = null;
  editorConfig: any = null;
  documentTypes: EnumModel[] = [];
  languages: EnumModel[] = [];
  documentTemplates: DocumentModel[] = [];
  selectedPreviewTemplateDocumentKey: string = null;
  documentTokens: { value: string, label: string }[] = [];
  selectedDocumentToken: string = null;
  documentRender: DocumentRenderModel = null;
  showBlockingLoader = false;

  constructor(private documentService: DocumentService, private notificationService: NotificationService, private dialogService: DialogService, private elementRef :ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.documentTypes = EnumUtilities.convertToSelectModels(DocumentTypesEnum, true);
    this.languages = EnumUtilities.convertToSelectModels(LanguagesEnum, true);

    const searchModel: DocumentSearchModel = {
      documentTypes: [DocumentTypesEnum.Template],
      businessKeys: this.isAdmin ? null : [this.businessKey]
    }

    this.documentService.search(searchModel).subscribe(documentTemplates => {
      this.documentTemplates = documentTemplates.sort(function (a, b) {
        if ((a.businessKey ?? '') === (b.businessKey ?? '')) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        } else if (a.businessKey && !b.businessKey) {
          return -1;
        } else if (!a.businessKey && b.businessKey) {
          return 1;
        }

        return 0;
      });
    });

    this.onDocumentTypeChanged();

    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.editorConfig = {
        menubar: true,
        height: this.elementRef.nativeElement.offsetHeight - 100,
        width: '100%',
        setup: (editor) => {
          this.editor = editor;
        },
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
      }
    });

      this.selectedPreviewTemplateDocumentKey = this.getDefaultDocTemplate();
  }

  public onDocumentTypeChanged(): void {
    this.documentTokens = [];

    if (this.value.type === DocumentTypesEnum.Template) {
      this.documentTokens.push({value: DocumentTokenConstants.mainContent, label: 'Main Content'});
    } else if (this.value.type === DocumentTypesEnum.InvoiceTemplate) {
      this.documentTokens.push({value: DocumentTokenConstants.invoiceNumber, label: 'Invoice Number'});
      this.documentTokens.push({value: DocumentTokenConstants.invoiceDate, label: 'Invoice Date'});
      this.documentTokens.push({value: DocumentTokenConstants.invoiceDueDate, label: 'Invoice Due Date'});
      this.documentTokens.push({value: DocumentTokenConstants.invoiceCustomerName, label: 'Invoice Customer Name'});
      this.documentTokens.push({value: DocumentTokenConstants.invoiceCustomerEmail, label: 'Invoice Customer Email'});
      this.documentTokens.push({value: DocumentTokenConstants.invoiceCustomerPhone, label: 'Invoice Customer Phone'});
      this.documentTokens.push({
        value: DocumentTokenConstants.invoiceCustomerAddress,
        label: 'Invoice Customer Address'
      });
      this.documentTokens.push({value: DocumentTokenConstants.invoiceItemsContent, label: 'Invoice Items'});
    }

    this.documentTokens.push({value: DocumentTokenConstants.businessLogo, label: 'Business Logo'});
    this.documentTokens.push({value: DocumentTokenConstants.businessName, label: 'Business Name'});
    this.documentTokens.push({value: DocumentTokenConstants.businessEmail, label: 'Business Email'});
    this.documentTokens.push({value: DocumentTokenConstants.businessPhone, label: 'Business Phone'});
    this.documentTokens.push({value: DocumentTokenConstants.businessAddress, label: 'Business Address'});
    this.documentTokens.push({value: DocumentTokenConstants.today, label: 'Today'});
    this.documentTokens.push({value: DocumentTokenConstants.myFirstName, label: 'My First Name'});
    this.documentTokens.push({value: DocumentTokenConstants.myLastName, label: 'My Last Name'});
  }

  public onDocumentTokenChanged(): void {
    if (this.selectedDocumentToken) {
      this.editor.execCommand('mceInsertContent', false, this.selectedDocumentToken);
    }

    setTimeout(()=> {
      this.selectedDocumentToken = null;
    });
  }

  onPreviewClicked(): void {
    if (!this.validateDocument()) {
      return;
    }

    if (this.documentRender !== null) {
      this.documentRender = null;
    } else {
      this.documentRender = <DocumentRenderModel>{
        html: this.value.htmlContent,
        businessKey: this.businessKey,
        isInvoiceTemplate: this.value.type === DocumentTypesEnum.InvoiceTemplate,
        documentTemplateKey: this.value.type === DocumentTypesEnum.Template || this.value.type === DocumentTypesEnum.InvoiceTemplate || this.selectedPreviewTemplateDocumentKey === "null" ? null : this.selectedPreviewTemplateDocumentKey
      };
    }
  }

  onDownloadRenderedClicked(): void {
    if (!this.validateDocument()) {
      return;
    }

    this.downloadRenderedClick.emit(<DocumentRenderModel>{
      documentKey: this.value.documentKey,
      html: this.value.htmlContent,
      businessKey: this.businessKey,
      documentTemplateKey: this.value.type === DocumentTypesEnum.Template || this.value.type === DocumentTypesEnum.InvoiceTemplate || this.selectedPreviewTemplateDocumentKey === "null" ? null : this.selectedPreviewTemplateDocumentKey
    });
  }

  onEmailRenderedClicked(): void {
    if (!this.validateDocument()) {
      return;
    }

    this.emailRenderedClick.emit(<EmailDocumentModel>{
      documentKey: this.value.documentKey,
      html: this.value.htmlContent,
      businessKey: this.businessKey,
      documentTemplateKey: this.value.type === DocumentTypesEnum.Template || this.value.type === DocumentTypesEnum.InvoiceTemplate || this.selectedPreviewTemplateDocumentKey === "null" ? null : this.selectedPreviewTemplateDocumentKey,
      email: {
        subject: this.value.name
      }
    });
  }

  onEditorChanged($event): void {
    //console.log('EditorChanged', $event);
  }

  onFilesUploaded(fileList: FileList): void {
    this.fileList = fileList;
    this.fileListChange.emit(this.fileList);
    this.formGroupRef.markAsDirty();
    this.value = this.formGroupRef.value;
    this.valueChange.emit(this.value);
  }

  private readonly defaultDocTemplateKey = 'defaultDocTemplate' + this.businessKey;

  onTemplateChange($event: MatSelectChange) {
    localStorage.setItem(this.defaultDocTemplateKey, $event.value);
  }

  onTemplateSelectorClicked(): void {
    const dialogRef = this.dialogService.openTemplateSelector(DocumentTypesEnum.Template);
    dialogRef.afterClosed().subscribe((templateKey) => {
      if (templateKey) {
        this.selectedPreviewTemplateDocumentKey = templateKey;
        localStorage.setItem(this.defaultDocTemplateKey, templateKey);
      } else {
        this.selectedPreviewTemplateDocumentKey = null;
      }
    });
  }

  getDefaultDocTemplate() : string {
    return localStorage.getItem(this.defaultDocTemplateKey) ?? null;
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      documentKey: new UntypedFormControl(this.value?.documentKey ?? null),
      isActive: new UntypedFormControl(this.value?.isActive === false ? 0 : 1),
      name: new UntypedFormControl(this.value?.name ?? null),
      type: new UntypedFormControl(this.value?.type || 0),
      language: new UntypedFormControl(this.value?.language || 0),
      description: new UntypedFormControl(this.value?.description ?? null),
      file: new UntypedFormGroup({
        fileKey: new UntypedFormControl(this.value?.file?.fileKey ?? null),
        name: new UntypedFormControl(this.value?.file?.name ?? null),
        contentType: new UntypedFormControl(this.value?.file?.contentType ?? null),
        uri: new UntypedFormControl(this.value?.file?.uri ?? null)
      }),
      htmlContent: new UntypedFormControl(this.value?.htmlContent ?? null),
      businessKey: new UntypedFormControl(!this.isAdmin ? this.businessKey ?? null : null)
    })
  };

  private validateDocument(): boolean {
    if (!this.value.htmlContent && !this.documentRender) {
      this.notificationService.showErrorNotification('Content is required.')
      return false;
    }

    return true;
  }

}
