<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-header">Personal Information</div>
    <div class="form-section-content">
      <div class="mt-2">
        <mat-form-field class="mr-2">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="firstName">
        </mat-form-field>
        <mat-form-field class="mr-2">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="lastName">
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field class="mr-2">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" formControlName="phoneNumber">
        </mat-form-field>
        <mat-form-field class="mr-2">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" [readonly]="!!value.clientKey || isProfileSetup">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="form-section mt-2">
    <div class="form-section-header">Address</div>
    <div class="form-section-content is-last">
      <ng-container formGroupName="address">
        <app-address-sub-form [showSecondaryAddress]="false" [(value)]="value.address" [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('address'))"></app-address-sub-form>
      </ng-container>
    </div>
  </div>
</form>
