import { Injectable } from '@angular/core';
import {
  MatSnackBar, MatSnackBarConfig,
} from '@angular/material/snack-bar';
import { NotificationComponent } from 'src/app/shared/components/controls/notification/notification.component';
import { SnackbarDataModel } from 'src/app/shared/models/app/snackbar-data.model';

@Injectable()
export class NotificationService {
  constructor(private _snackBar: MatSnackBar) {
  }

  showNotification(message: string | string[], duration: number = 10000): void {
    this._snackBar.dismiss();
    const snackbarData = new SnackbarDataModel(message, 'fa fa-info-circle');
    const config = this.getBaseConfig(snackbarData, duration);
    this._snackBar.openFromComponent(NotificationComponent, config);
  }

  showErrorNotification(message: string | string[], duration: number = 10000): void {
    this._snackBar.dismiss();
    const snackbarData = new SnackbarDataModel(message, 'fa fa-exclamation-circle');
    const config = this.getBaseConfig(snackbarData, duration, 'snackbar-custom-error');
    this._snackBar.openFromComponent(NotificationComponent, config);
  }

  showSuccessNotification(message: string | string[], duration: number = 10000): void {
    this._snackBar.dismiss();
    const snackbarData = new SnackbarDataModel(message, 'fa fa-check-circle');
    const config = this.getBaseConfig(snackbarData, duration, 'snackbar-custom-success');
    this._snackBar.openFromComponent(NotificationComponent, config);
  }

  private getBaseConfig(data: SnackbarDataModel, duration: number = 5000, panelClass: string = 'snackbar-custom'): MatSnackBarConfig {
    return {
      data: data,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: duration,
      panelClass: panelClass
    };
  }
}

