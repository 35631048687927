import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterViewInit, Component, EventEmitter, NgIterable, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { debounceTime, tap } from 'rxjs/operators';
import { CustomerService } from 'src/app/core/services/domain/customer.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { FormUtilities } from 'src/app/core/utilities/form.utilities';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { InvoiceStatusesEnum } from 'src/app/shared/enums/domain/invoice-statuses.enum';
import { InvoiceTypesEnum } from 'src/app/shared/enums/domain/invoice-types.enum';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';
import { InvoiceItemModel } from 'src/app/shared/models/domain/invoice-item.model';
import { InvoiceModel } from 'src/app/shared/models/domain/invoice.model';
import { StringUtilities } from 'src/app/core/utilities/string.utilities';
import { DateUtilities } from 'src/app/core/utilities/date.utilities';
import { OfferingModel } from 'src/app/shared/models/domain/offering.model';
import { OfferingService } from 'src/app/core/services/domain/offering.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { Router } from '@angular/router';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';
import { VendorService } from 'src/app/core/services/domain/vendor.service';
import { InvoiceService } from 'src/app/core/services/domain/invoice.service';
import { InvoiceItemTypesEnum } from 'src/app/shared/enums/domain/invoice-item-types.enum';
import { OfferingTypesEnum } from 'src/app/shared/enums/domain/offering-types.enum';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { OfferingServiceFrequencyEnum } from 'src/app/shared/enums/domain/offering-service-frequency.enum';
import { TaxRateModel } from 'src/app/shared/models/domain/tax-rate.model';
import { TaxRateService } from 'src/app/core/services/domain/tax-rate.service';
import { JobService } from 'src/app/core/services/domain/job.service';
import { JobModel } from 'src/app/shared/models/domain/job.model';
import { BusinessService } from 'src/app/core/services/domain/business.service';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss']
})
export class InvoiceFormComponent extends BaseFormComponent<InvoiceModel> implements OnInit, AfterViewInit {
  @Output() emailInvoiceClick = new EventEmitter();
  @Output() downloadInvoiceClick = new EventEmitter();

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  private customerTrigger: MatAutocompleteTrigger;

  @ViewChild('customerInput', {read: MatAutocompleteTrigger}) set customerContent(content: MatAutocompleteTrigger) {
    if (content && !this.customerTrigger) {
      this.customerTrigger = content;
      this.subscriptions.add(this.customerTrigger.panelClosingActions.subscribe(e => {
        let textValue = this.value.customer.customerKey;

        //a text value is already selected don't take the text, in this case the value is a guid.
        if (StringUtilities.isUUID(textValue)) {
          textValue = null;
        }

        if (!(e && e.source)) {
          this.customerKeyControl.setValue(null);
          this.customerTrigger.closePanel();
        } else {
          let customer = this.customers.find(t => t.customerKey === e.source.value);

          if (!customer) {
            this.customerKeyControl.setValue(null);
            this.customerTrigger.closePanel();

            if (e.source.value === '-1') {
              this.onAddCustomerClicked(textValue);
            }
          } else {
            this.customerKeyControl.setValue(e.source.value);
          }
        }

        this.setCustomerContact(e?.source.value);

        let items = this.getFormArrayItems();
        for (let i = 0; i < items.length; i++) {
          const currentValue = items.at(i);
          currentValue.get('jobKey').setValue(null);
        }

        this.setJobs();
      }));
    }
  }

  private vendorTrigger: MatAutocompleteTrigger;

  @ViewChild('vendorInput', {read: MatAutocompleteTrigger}) set vedorContent(content: MatAutocompleteTrigger) {
    if (content && !this.vendorTrigger) {
      this.vendorTrigger = content;
      this.subscriptions.add(this.vendorTrigger.panelClosingActions.subscribe(e => {
        let textValue = this.value.vendor.vendorKey;

        //a text value is already selected don't take the text, in this case the value is a guid.
        if (StringUtilities.isUUID(textValue)) {
          textValue = null;
        }

        if (!(e && e.source)) {
          this.vendorKeyControl.setValue(null);
          this.vendorTrigger.closePanel();
        } else {
          let vendor = this.vendors.find(t => t.vendorKey === e.source.value);

          if (!vendor) {
            this.vendorKeyControl.setValue(null);
            this.vendorTrigger.closePanel();

            if (e.source.value === '-1') {
              this.onAddVendorClicked(textValue);
            }
          } else {
            this.vendorKeyControl.setValue(e.source.value);
          }
        }
      }));
    }
  }

  private offeringTrigger: MatAutocompleteTrigger;

  @ViewChild('offeringInput', {read: MatAutocompleteTrigger}) set offeringContent(content: MatAutocompleteTrigger) {
    if (content && !this.offeringTrigger) {
      this.offeringTrigger = content;
      this.subscriptions.add(this.offeringTrigger.panelClosingActions.subscribe(e => {
        let textValue = this.offeringKeyControl.value;

        //a text value is already selected don't take the text, in this case the value is a guid.
        if (StringUtilities.isUUID(textValue)) {
          textValue = null;
        }

        if (!(e && e.source)) {
          this.offeringKeyControl.setValue(null);
          this.offeringTrigger.closePanel();
        } else {
          let offering = this.offerings.find(t => t.offeringKey === e.source.value);

          if (!offering) {
            this.offeringKeyControl.setValue(null);
            this.offeringTrigger.closePanel();

            if (e.source.value === '-1') {
              this.addOfferingClicked(textValue);
            }

            if (e.source.value === '-2') {
              this.onAddFreeFormClicked(textValue);
            }
          } else {
            this.offeringKeyControl.setValue(e.source.value);
          }
        }

        this.addInvoiceItem(e?.source.value);
      }));
    }
  }

  invoiceTypes = InvoiceTypesEnum;
  invoiceStatuses: EnumModel[] = [];
  title: string = 'Invoice';
  filteredCustomers: CustomerModel[] = [];
  customers: CustomerModel[];
  customerKeyControl: UntypedFormControl;
  customerAddress: string;
  customerContact: string;

  offeringTypes = OfferingTypesEnum;

  filteredVendors: VendorModel[] = [];
  vendors: VendorModel[];
  vendorKeyControl: UntypedFormControl;

  offeringFormGroupRef: UntypedFormGroup;
  filteredOfferings: OfferingModel[] = [];
  offerings: OfferingModel[] = [];
  offeringKeyControl: UntypedFormControl;
  invoiceItemTypes: EnumModel[] = [];
  serviceFrequencyTypes: EnumModel[] = [];

  taxRates: TaxRateModel[] = [];

  filteredChartOfAccounts: ChartOfAccountModel[] = [];
  chartOfAccounts: ChartOfAccountModel[];
  chartOfAccountText: string;
  categories: string[];
  filteredCategories: string[];
  jobs: JobModel[] = null;
  filteredJobs: JobModel[] = null;
  jobText: string;

  balanceDue: number;
  amountPaid: number;
  status: string;

  constructor(private offeringService: OfferingService, private invoiceService: InvoiceService, private customerService: CustomerService,
              private vendorService: VendorService, private dialogService: DialogService, private router: Router, private chartOfAccountService: ChartOfAccountService,
              private taxRateService: TaxRateService, private jobService: JobService, private businessService: BusinessService) {
    super();
  }

  ngOnInit(): void {
    this.invoiceStatuses = EnumUtilities.convertToSelectModels(InvoiceStatusesEnum, true);
    this.invoiceItemTypes = EnumUtilities.convertToSelectModels(InvoiceItemTypesEnum, true);
    this.serviceFrequencyTypes = EnumUtilities.convertToSelectModels(OfferingServiceFrequencyEnum, true);

    this.setJobs();

    this.offeringKeyControl = new UntypedFormControl(null);
    this.offeringFormGroupRef = new UntypedFormGroup({
      offeringKey: this.offeringKeyControl
    })
    this.offeringService.search({businessKey: this.value.businessKey}).subscribe(offerings => {
      this.offerings = offerings;
      this.filteredOfferings = offerings;
    });

    this.taxRateService.search({businessKey: this.value.businessKey}).subscribe(taxRates => {
      this.taxRates = taxRates;
    });

    this.title = EnumUtilities.getDisplayName(InvoiceTypesEnum, this.value.type);

    this.customerService.search({businessKey: this.value.businessKey}).subscribe(customers => {
      this.customers = customers;
      this.filteredCustomers = customers;
      this.setCustomerContact();
    });

    this.vendorService.search({businessKey: this.value.businessKey}).subscribe(vendors => {
      this.vendors = vendors;
      this.filteredVendors = vendors;
    });

    this.offeringService.listCategories(this.value.businessKey).subscribe(categories => {
      this.categories = categories;
      this.filteredCategories = categories;
    });

    super.ngOnInit();

    if (this.value.type === InvoiceTypesEnum.Invoice && this.value.invoiceKey) {
      this.businessService.get(this.value.businessKey).subscribe(business => {
        if (business.accounting?.defaultInvoiceNotes) {
          this.formGroupRef.get('notes').setValue(business.accounting.defaultInvoiceNotes);
        }
      })
    }

    this.chartOfAccountService.search({businessKey: this.value.businessKey}).subscribe(chartOfAccounts => {
      if (this.value.type === InvoiceTypesEnum.Invoice) {
        this.chartOfAccounts = chartOfAccounts;
        this.filteredChartOfAccounts = chartOfAccounts;
      } else {
        this.chartOfAccounts = this.chartOfAccountService.filterToExpenseAccounts(chartOfAccounts);
        this.filteredChartOfAccounts = this.chartOfAccountService.filterToExpenseAccounts(chartOfAccounts);
      }
    });

    this.subscriptions.add(this.customerKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredCustomers = this.customers;
        } else {
          this.filteredCustomers = this.customers.filter(customer => customer.name.toLowerCase()
            .indexOf(value.toLowerCase()) !== -1);
        }
      }),
    ).subscribe());

    this.subscriptions.add(this.vendorKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredVendors = this.vendors;
        } else {
          this.filteredVendors = this.vendors.filter(vendor => vendor.name.toLowerCase()
            .indexOf(value.toLowerCase()) !== -1);
        }
      }),
    ).subscribe());

    this.subscriptions.add(this.offeringKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredOfferings = this.offerings;
        } else {
          this.filteredOfferings = this.offerings.filter(offering => offering.name?.toLowerCase()?.indexOf(value.toLowerCase()) > -1 || offering.description?.toLowerCase()?.indexOf(value.toLowerCase()) > -1 || offering.sku?.toLowerCase()?.indexOf(value.toLowerCase()) > -1);
        }
      }),
    ).subscribe());

    this.setStatus();
    this.subscriptions.add(this.formGroupRef.get('status').valueChanges.subscribe(value => {
      this.setStatus(value);
    }));

    this.balanceDue = this.value.balanceDue ?? 0;
    this.amountPaid = this.value.amountPaid ?? 0;
    this.setInvoiceItemAmounts(this.value.items);
  }

  ngAfterViewInit(): void {
    if (this.value.type === InvoiceTypesEnum.Bill) {
      if (!this.value.items?.length) {
        this.onAddFreeFormClicked(null);
      }
    } else {
      //PLACEHOLDER
    }

    setTimeout(_ => {
      //IF CREATE AND CUSTOMER IS PRESET (e.g: COMING FROM THE CUSTOMER INVOICE AREA)
      if (this.value?.customer?.customerKey && !this.value.invoiceKey) {
        this.formGroupRef.markAsDirty();
        this.formGroupRef.updateValueAndValidity();
      }

      if (this.value?.vendor?.vendorKey && !this.value.invoiceKey) {
        this.formGroupRef.markAsDirty();
        this.formGroupRef.updateValueAndValidity();
      }
    })
  }

  onEmailInvoiceClicked(): void {
    this.emailInvoiceClick.emit();
  }

  onDownloadInvoiceClicked(): void {
    this.downloadInvoiceClick.emit();
  }

  customerDisplayFn(customerKey: string): string {
    return this.customers.find(t => t.customerKey === customerKey)?.name ?? '';
  }

  jobDisplayFn(jobKey: string): string {
    let foundJob = this.jobs.find(t => t.jobKey === jobKey);

    if (foundJob) {
      return `${foundJob.name}`;
    }

    return '';
  }

  vendorDisplayFn(vendorKey: string): string {
    return this.vendors.find(t => t.vendorKey === vendorKey)?.name ?? '';
  }

  offeringDisplayFn(offeringKey: string): string {
    return this.offerings.find(t => t.offeringKey === offeringKey)?.name ?? '';
  }

  chartOfAccountDisplayFn(chartOfAccount: ChartOfAccountModel): string {
    let foundResult = this.chartOfAccounts?.find(t => t.chartOfAccountKey === chartOfAccount?.chartOfAccountKey);

    return foundResult?.fullDisplayPath ?? '';
  }

  onAddChartOfAccountClicked(item: FormGroup): void {
    const dialogRef = this.dialogService.openChartOfAccountForm(this.chartOfAccountText);
    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.chartOfAccountService.search({businessKey: this.value.businessKey}).subscribe(chartOfAccounts => {
          this.chartOfAccounts = [...chartOfAccounts];
          this.filteredChartOfAccounts = [...chartOfAccounts];

          let chartOfAccount = chartOfAccounts.find(t => t.chartOfAccountKey === resultOrKey);
          item.get('chartOfAccount').setValue(chartOfAccount);
        });
      }
    })
  }

  onAddJobClicked(item: FormGroup): void {
    const dialogRef = this.dialogService.openJobForm(this.jobText);
    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.jobService.search({businessKey: this.value.businessKey}).subscribe(jobs => {
          this.jobs = [...jobs];
          this.filteredJobs = [...jobs];

          let job = jobs.find(t => t.jobKey === resultOrKey);
          item.get('job').setValue(job);
        });
      }
    })
  }

  onChartOfAccountChanged($event: any, item: any): void {
    let value = $event.target.value;
    this.chartOfAccountText = value;

    let foundResult = this.chartOfAccounts?.find(t => t.fullDisplayPath === value);

    if (!foundResult) {
      item.get('chartOfAccount').setValue(null);
    }
  }

  onChartOfAccountKeyUp(item: FormGroup): void {
    if (item.value?.chartOfAccount && typeof item.value.chartOfAccount === 'string') {
      this.filteredChartOfAccounts = this.chartOfAccounts.filter(t => t.name.toLowerCase().indexOf(item.value.chartOfAccount.toLowerCase()) > -1);
    } else {
      this.filteredChartOfAccounts = this.chartOfAccounts;
    }
  }

  onCategoryKeyUp(item: FormGroup): void {
    if (item.value?.category) {
      this.filteredCategories = this.categories.filter(t => t.toLowerCase().indexOf(item.value.category.toLowerCase()) > -1);
    } else {
      this.filteredCategories = this.categories;
    }
  }

  onJobChanged($event, item): void {
    let value = $event.target.value;

    this.jobText = value;

    let foundResult = this.jobs?.find(t => t.name === value);

    if (!foundResult) {
      item.get('jobKey').setValue(null);
    }
  }

  onJobKeyUp(item: FormGroup): void {
    if (item?.value?.jobKey) {
      this.filteredJobs = this.jobs.filter(t => t.name.toLowerCase().indexOf(item.value.jobKey.toLowerCase()) > -1 && (this.value.customer.customerKey === null || this.value.customer.customerKey === t.customer.customerKey));
    } else {
      this.filteredJobs = this.jobs.filter(t => this.value.customer.customerKey === null || this.value.customer.customerKey === t.customer.customerKey);
    }
  }

  getFormArrayItems(): UntypedFormArray & NgIterable<InvoiceItemModel> {
    return this.formGroupRef.get('items') as UntypedFormArray & NgIterable<InvoiceItemModel>;
  }

  onItemDropped(event: CdkDragDrop<string[]>) {
    const formArray = this.getFormArrayItems();
    FormUtilities.moveItemInFormArray(formArray, event.previousIndex, event.currentIndex);
    formArray.markAsDirty();
  }

  onAddCustomerClicked(name: string): void {
    const dialogRef = this.dialogService.openCustomerForm(name);
    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.customerService.search({businessKey: this.value.businessKey}).subscribe(customers => {
          this.customers = [...customers];
          this.filteredCustomers = [...customers];
          this.customerKeyControl.setValue(resultOrKey);
          this.setCustomerContact(resultOrKey);
        });
      }
    });
  }

  onAddVendorClicked(name: string): void {
    const dialogRef = this.dialogService.openVendorForm(name);
    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.vendorService.search({businessKey: this.value.businessKey}).subscribe(vendors => {
          this.vendors = [...vendors];
          this.filteredVendors = [...vendors];
          this.vendorKeyControl.setValue(resultOrKey);
        });
      }
    })
  };

  addOfferingClicked(name: string): void {
    const dialogRef = this.dialogService.openOfferingForm(name);
    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.offeringService.search({businessKey: this.value.businessKey}).subscribe(offerings => {
          this.offerings = [...offerings];
          this.filteredOfferings = [...offerings];
          this.offeringKeyControl.setValue(null);
          this.addInvoiceItem(resultOrKey);
        });
      }
    })
  }

  onAddFreeFormClicked(name: string): void {
    const formArray = this.getFormArrayItems();
    formArray.push(this.getInvoiceItemFormGroup({name: name ?? null}));

    formArray.markAsDirty();
  }

  onRemoveInvoiceItemClicked(index: number): void {
    const formArray = this.getFormArrayItems();
    formArray.removeAt(index);

    formArray.markAsDirty();
  }

  onTransactionsRowClicked(event): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.transactionEdit.getNavigateUrl(event.data.transactionKey));
  }

  onIsTaxableChanged(): void {
    this.onTaxRateChanged({value: this.value?.taxRate});
  }

  onTaxRateChanged($event): void {
    let items = this.getFormArrayItems();
    for (let i = 0; i < items.length; i++) {
      const currentValue = items.at(i);
      let taxRate = currentValue.value.isTaxable && $event?.value?.rate ? $event.value?.rate : null;
      currentValue.get('taxRate').setValue(taxRate);
    }
  }

  onManageTaxRatesClicked(): void {
    const dialogRef = this.dialogService.openTaxRateForm();
    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.taxRates =  null;
        this.taxRateService.search({businessKey: this.value.businessKey}).subscribe(taxRates => {
          this.taxRates = taxRates;

          let foundTaxRate = taxRates.find(t => t.taxRateKey === resultOrKey);

          if (foundTaxRate) {
            this.formGroupRef.get('taxRate').setValue(foundTaxRate);
            this.onTaxRateChanged({value: foundTaxRate});
          } else {
            this.formGroupRef.get('taxRate').setValue(null);
            this.onTaxRateChanged(null);
          }
        });
      }
    });
  }

  onCategoryChanged(item: FormGroup): void {
    let value = item.value.category;
    if (value && !this.categories.find(t => t && t.toLowerCase() == value.toLowerCase())) {
      this.categories.push(value);
      this.filteredCategories.push(value);
    }

    item.get('category').setValue(value);
  }

  taxRateComparer(obj1: TaxRateModel, obj2: TaxRateModel): boolean {
    return obj1?.taxRateKey === obj2?.taxRateKey;
  }

  onTotalTaxChanged(): void {
    if (this.value.type === this.invoiceTypes.Bill) {
      let items = this.getFormArrayItems();

      for (let i = 0; i < items.length; i++) {
        const currentValue = items.at(i);
        currentValue.get('totalTax').setValue(null);
      }
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    this.customerKeyControl = new UntypedFormControl(this.value?.customer?.customerKey);
    this.vendorKeyControl = new UntypedFormControl(this.value?.vendor?.vendorKey);

    const customerFormGroup = new UntypedFormGroup({
      customerKey: this.customerKeyControl
    });

    const vendorFormGroup = new UntypedFormGroup({
      vendorKey: this.vendorKeyControl
    });

    const dueDate = DateUtilities.add(new Date(), (this.value?.type == InvoiceTypesEnum.Bill ? 0 : 60));

    const formGroup = new UntypedFormGroup({
      invoiceKey: new UntypedFormControl(this.value?.invoiceKey),
      businessKey: new UntypedFormControl(this.value?.businessKey),
      type: new UntypedFormControl(this.value?.type),
      isCredit: new UntypedFormControl(this.value?.isCredit ?? false),
      isDeleted: new UntypedFormControl(this.value?.isDeleted ?? false),
      status: new UntypedFormControl(this.value?.status ?? InvoiceStatusesEnum.Draft),
      number: new UntypedFormControl(this.value?.number),
      date: new UntypedFormControl(this.value?.date ?? new Date()),
      dueDate: new UntypedFormControl(this.value?.dueDate ?? dueDate),
      paidDate: new UntypedFormControl(this.value?.paidDate),
      customer: customerFormGroup,
      vendor: vendorFormGroup,
      issuedPendingPaymentOnDateTime: new UntypedFormControl(this.value?.issuedPendingPaymentOnDateTime),
      notes: new UntypedFormControl(this.value?.notes),
      subTotal: new UntypedFormControl(this.value?.subTotal || 0),
      taxRate: new UntypedFormControl(this.value?.taxRate),
      totalTax: new UntypedFormControl(this.value?.totalTax || 0),
      amountPaid: new UntypedFormControl(this.value?.amountPaid || 0),
      total: new UntypedFormControl(this.value?.total || 0),
      balanceDue: new UntypedFormControl(this.value?.balanceDue || 0),
      totalPayments: new UntypedFormControl(this.value?.totalPayments || 0),
      items: this.getInvoiceItemFormArray()
    });

    return formGroup;
  };

  private getInvoiceItemFormGroup(value: InvoiceItemModel = null): UntypedFormGroup {
    return new UntypedFormGroup({
      invoiceItemKey: new UntypedFormControl(value?.invoiceItemKey),
      invoiceKey: new UntypedFormControl(this.value?.invoiceKey),
      offeringKey: new UntypedFormControl(value?.offeringKey),
      chartOfAccount: new UntypedFormControl(value?.chartOfAccount ?? null),
      jobKey: new UntypedFormControl(value?.jobKey ?? null),
      type: new UntypedFormControl(value?.type ?? InvoiceItemTypesEnum.Unknown),
      name: new UntypedFormControl(value?.name),
      sku: new UntypedFormControl(value?.sku),
      isTaxable: new UntypedFormControl(value?.isTaxable ?? false),
      taxRate: new UntypedFormControl(value?.taxRate),
      totalTax: new UntypedFormControl(value?.totalTax),
      category: new UntypedFormControl(value?.category),
      frequency: new UntypedFormControl(value?.frequency ?? OfferingServiceFrequencyEnum.Unknown),
      description: new UntypedFormControl(value?.description),
      unitPrice: new UntypedFormControl(value?.unitPrice ?? 0),
      quantity: new UntypedFormControl(value?.quantity ?? 1),
      total: new UntypedFormControl(value?.total || 0)
    });
  }

  private setJobs(): void {
    this.jobs = null
    this.filteredJobs = null;
    this.jobService.search({businessKey: this.value.businessKey}).subscribe(jobs => {
      if (this.value.customer?.customerKey) {
        this.jobs = jobs.filter(t => t.customer.customerKey == this.value.customer.customerKey)
        this.filteredJobs = jobs;
      } else {
        this.jobs = jobs;
        this.filteredJobs = jobs;
      }
    });
  }

  private setStatus(value = null): void {
    if (this.value?.isDeleted) {
      this.status = 'Deleted'.toUpperCase();
    } else {
      this.status = EnumUtilities.getDisplayName(InvoiceStatusesEnum, value ?? this.value.status).toUpperCase();
    }
  }

  private addInvoiceItem(offeringKey: string): void {
    if (StringUtilities.isUUID(offeringKey)) {
      let offering = this.offerings.find(t => t.offeringKey == offeringKey);
      const formArray = this.getFormArrayItems();
      formArray.insert(0, this.getInvoiceItemFormGroup({
        offeringKey,
        chartOfAccount: offering?.chartOfAccount,
        type: offering?.type === OfferingTypesEnum.Product ? InvoiceItemTypesEnum.Product : InvoiceItemTypesEnum.Service,
        description: offering?.description,
        name: offering?.name,
        category: offering?.category,
        frequency: offering?.frequency,
        sku: offering?.sku,
        unitPrice: offering?.price,
        isTaxable: offering?.isTaxable,
        taxRate: offering?.taxRate,
      }));

      formArray.markAsDirty();
    }
  }

  private setCustomerContact(customerKey: string = null): void {
    let selectedCustomerKey = customerKey ?? this.customerKeyControl.value;

    if (StringUtilities.isUUID(selectedCustomerKey)) {
      let foundCustomer = this.customers?.find(t => t.customerKey == selectedCustomerKey);
      this.customerAddress = foundCustomer?.address?.fullAddress;
      let customerContactParts = [];
      if (foundCustomer?.phoneNumber) {
        customerContactParts.push(foundCustomer.phoneNumber);
      }

      if (foundCustomer?.email) {
        customerContactParts.push(foundCustomer.email);
      }
      this.customerContact = customerContactParts.join(' - ');
    } else {
      this.customerAddress = null;
      this.customerContact = null;
    }
  }

  private getInvoiceItemFormArray(): UntypedFormArray {
    const formArray = new UntypedFormArray([]);

    if (this.value?.items?.length) {
      for (let item of this.value.items) {
        formArray.push(this.getInvoiceItemFormGroup(item));
      }
    }

    this.subscriptions.add(formArray.valueChanges.pipe(debounceTime(1000)).subscribe(val => {
      this.setInvoiceItemAmounts(val);
    }));

    return formArray;
  }

  private setInvoiceItemAmounts(value: InvoiceItemModel[]): void {
    let total = 0, totalTax = 0, subTotal = 0;
    const formArray = this.getFormArrayItems();

    for (let i = 0; i < value.length; i++) {
      const currentValue = value[i];

      if (currentValue.unitPrice && currentValue.quantity) {
        const formArrayItem = formArray.at(i);

        let tax = 0;
        let amount = (currentValue.unitPrice * currentValue.quantity);
        subTotal += amount;
        if (currentValue.taxRate) {
          tax = amount * (currentValue.taxRate / 100);
          totalTax += tax;
        }

        formArrayItem.get('total').setValue(amount + tax);
      } else if (currentValue.total) {
        subTotal += currentValue.total;
      }

      if (currentValue.totalTax) {
        totalTax += currentValue.totalTax;
      }
    }

    if (!totalTax && this.value.type === InvoiceTypesEnum.Bill) {
      totalTax = this.formGroupRef.get('totalTax').value ?? 0;
    }

    totalTax = parseFloat(totalTax.toFixed(2));
    subTotal = parseFloat(subTotal.toFixed(2));

    total = subTotal + totalTax;

    this.formGroupRef.patchValue({
      subTotal: subTotal || 0,
      totalTax: totalTax || 0,
      total: total || 0
    });

    this.balanceDue = total - this.value.totalPayments;

    if (this.value.status === InvoiceStatusesEnum.Issued && this.balanceDue === 0) {
      this.formGroupRef.get('status').setValue(InvoiceStatusesEnum.Paid);
    }
  }
}


