<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-header">Personal Information</div>
    <div class="form-section-content">
      <div class="mt-2">
        <mat-form-field class="mr-2">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="firstName">
        </mat-form-field>
        <mat-form-field class="mr-2">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="lastName">
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field class="mr-2">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" formControlName="phoneNumber">
        </mat-form-field>
        <mat-form-field class="mr-2">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="form-section mt-2">
    <div class="form-section-header">Address</div>
    <div class="form-section-content is-last">
      <ng-container formGroupName="address">
        <app-address-sub-form [(value)]="value.address" [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('address'))"></app-address-sub-form>
      </ng-container>
    </div>
  </div>
  <div class="form-section">
    <div class="form-section-header">Employment Information</div>
    <div class="form-section-content is-last">
      <div class="mt-2">
        <mat-form-field class="mr-2">
          <mat-label>Title</mat-label>
          <input matInput type="text" formControlName="title">
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field class="mat-form-field-date mr-1">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="startPicker" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="mat-form-field-date">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="endPicker" formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
