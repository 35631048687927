import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { VendorModel } from 'src/app/shared/models/domain/vendor.model';
import { VendorService } from 'src/app/core/services/domain/vendor.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { ChartOfAccountService } from 'src/app/core/services/domain/chart-of-account.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { CustomerModel } from 'src/app/shared/models/domain/customer.model';
import { CustomerService } from 'src/app/core/services/domain/customer.service';
import { forkJoin, Observable } from 'rxjs';
import { TransactionRuleModel } from 'src/app/shared/models/domain/transaction-rule.model';
import { TransactionRuleService } from 'src/app/core/services/domain/transaction-rule.service';
import { TransactionRuleFieldsEnum } from 'src/app/shared/enums/domain/transaction-rule-fields.enum';
import { EnumUtilities } from 'src/app/core/utilities/enum.utilities';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { TransactionRuleConditionsEnum } from 'src/app/shared/enums/domain/transaction-rule-conditions.enum';
import { StringUtilities } from 'src/app/core/utilities/string.utilities';

@Component({
  selector: 'app-transaction-rule-form',
  templateUrl: './transaction-rule-form.component.html',
  styleUrls: ['./transaction-rule-form.component.scss']
})
export class TransactionRuleFormComponent extends BaseFormComponent<TransactionRuleModel> implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  private chartOfAccountTrigger: MatAutocompleteTrigger;

  @ViewChild('chartOfAccountInput', {read: MatAutocompleteTrigger}) set chartOfAccountContent(content: MatAutocompleteTrigger) {
    if (content && !this.chartOfAccountTrigger) {
      this.chartOfAccountTrigger = content;
      this.subscriptions.add(this.chartOfAccountTrigger.panelClosingActions.subscribe(e => {
        let textValue = this.value.chartOfAccount.chartOfAccountKey;

        //a text value is already selected don't take the text, in this case the value is a guid.
        if (StringUtilities.isUUID(textValue)) {
          textValue = null;
        }

        if (!(e && e.source)) {
          this.chartOfAccountKeyControl.setValue(null);
          this.chartOfAccountTrigger.closePanel();
        } else {
          let chartOfAccount = this.chartOfAccounts.find(t => t.chartOfAccountKey === e.source.value);

          if (!chartOfAccount) {
            this.chartOfAccountKeyControl.setValue(null);
            this.chartOfAccountTrigger.closePanel();

            if (e.source.value === '-1') {
              this.addChartOfAccountClicked(textValue);
            }
          } else {
            this.chartOfAccountKeyControl.setValue(e.source.value);
          }
        }
      }));
    }
  }

  private customerVendorTrigger: MatAutocompleteTrigger;

  @ViewChild('customerVendorInput', {read: MatAutocompleteTrigger}) set customerVendorContent(content: MatAutocompleteTrigger) {
    if (content && !this.customerVendorTrigger) {
      this.customerVendorTrigger = content;
      this.subscriptions.add(this.customerVendorTrigger.panelClosingActions.subscribe(e => {
        let textValue = this.customerVendorKeyControl.value;

        //a text value is already selected don't take the text, in this case the value is a guid.
        if (StringUtilities.isUUID(textValue)) {
          textValue = null;
        }

        if (!(e && e.source)) {
          this.customerVendorKeyControl.setValue(null);
          this.customerVendorTrigger.closePanel();
          this.customerVendorLabel = 'Multiple Vendors/Customers';
        } else {
          let customerVendor = this.customerVendors.find(t => t.key === e.source.value);

          if (!customerVendor) {
            this.customerVendorKeyControl.setValue(null);
            this.customerVendorTrigger.closePanel();
            this.customerVendorLabel = 'Multiple Vendors/Customers';

            if (e.source.value === '-1') {
              this.onAddCustomerClicked(textValue);
            }

            if (e.source.value === '-2') {
              this.onAddVendorClicked(textValue);
            }

            this.setVendorFormValues(null);
            this.setCustomerFormValues(null);
          } else {
            if (customerVendor.isVendor) {
              this.setVendorFormValues(e.source.value);
            } else {
              this.setCustomerFormValues(e.source.value);
            }
          }
        }
      }));
    }
  }

  filteredCustomerVendors: CustomerVendorViewModel[] = [];
  customerVendorLabel: string = 'Multiple Vendors/Customers';
  customerVendors: CustomerVendorViewModel[];
  customerVendorKeyControl: UntypedFormControl;

  filteredChartOfAccounts: ChartOfAccountModel[] = [];
  chartOfAccounts: ChartOfAccountModel[];
  chartOfAccountKeyControl: UntypedFormControl;

  transactionRuleFields: EnumModel[] = [];
  transactionRuleConditions: EnumModel[] = [];

  constructor(private vendorService: VendorService, private customerService: CustomerService,
              private dialogService: DialogService,
              private transactionRuleService: TransactionRuleService,
              private chartOfAccountService: ChartOfAccountService,
              private router: Router,
              private notificationService: NotificationService,
              private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.transactionRuleFields = EnumUtilities.convertToSelectModels(TransactionRuleFieldsEnum, false);
    this.transactionRuleConditions = EnumUtilities.convertToSelectModels(TransactionRuleConditionsEnum, false);

    this.setCustomerVendors().subscribe();

    this.chartOfAccountService.search({businessKey: this.value.businessKey}).subscribe(chartOfAccounts => {
      this.chartOfAccounts = this.chartOfAccountService.filterWithoutBankAccounts(chartOfAccounts);
      this.filteredChartOfAccounts = this.chartOfAccountService.filterWithoutBankAccounts(chartOfAccounts);
    });

    super.ngOnInit();

    this.subscriptions.add(this.customerVendorKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredCustomerVendors = this.customerVendors;
        } else {
          this.filteredCustomerVendors = this.customerVendors.filter(customerVendor => customerVendor.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        }
      })
    ).subscribe());

    this.subscriptions.add(this.chartOfAccountKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredChartOfAccounts = this.chartOfAccounts;
        } else {
          this.filteredChartOfAccounts = this.chartOfAccounts.filter(coa => coa.fullDisplayPath.toLowerCase()
            .indexOf(value.toLowerCase()) !== -1);
        }
      })
    ).subscribe());
  }

  customerVendorDisplayFn(customerVendorKey: string): string {
    return this.customerVendors.find(t => t.key === customerVendorKey)?.name ?? '';
  }

  chartOfAccountDisplayFn(chartOfAccountKey: string): string {
    let foundResult = this.chartOfAccounts?.find(t => t.chartOfAccountKey === chartOfAccountKey);
    return foundResult?.fullDisplayPath ?? '';
  }

  onAddVendorClicked(name: string): void {
    const dialogRef = this.dialogService.openVendorForm(name);
    dialogRef.afterClosed().subscribe((vendorKey: string) => {
      if (vendorKey) {
        this.setCustomerVendors().subscribe(_ => {
          this.setVendorFormValues(vendorKey);
          this.formGroupRef.markAsDirty();
          this.formGroupRef.updateValueAndValidity();
        });
      }
    })
  }

  onAddCustomerClicked(name: string): void {
    const dialogRef = this.dialogService.openCustomerForm(name);
    dialogRef.afterClosed().subscribe((customerKey: string) => {
      if (customerKey) {
        this.setCustomerVendors().subscribe(_ => {
          this.setCustomerFormValues(customerKey);
          this.formGroupRef.markAsDirty();
          this.formGroupRef.updateValueAndValidity();
        });
      }
    })
  }

  addChartOfAccountClicked(name: string): void {
    const dialogRef = this.dialogService.openChartOfAccountForm(name);
    dialogRef.afterClosed().subscribe((resultOrKey) => {
      if (resultOrKey) {
        this.chartOfAccountService.search({businessKey: this.value.businessKey}).subscribe(chartOfAccounts => {
          this.chartOfAccounts = [...chartOfAccounts];
          this.filteredChartOfAccounts = [...chartOfAccounts];
          this.chartOfAccountKeyControl.setValue(resultOrKey);
        });
      }
    })
  }


  protected getFormGroup(): UntypedFormGroup {
    if (this.value?.vendor?.vendorKey) {
      this.customerVendorLabel = 'Vendor';
    } else if (this.value?.customer?.customerKey) {
      this.customerVendorLabel = 'Customer';
    } else {
      this.customerVendorLabel = 'Multiple Vendors/Customers';
    }

    this.customerVendorKeyControl = new UntypedFormControl(this.value?.vendor?.vendorKey ?? this.value?.customer?.customerKey);
    this.chartOfAccountKeyControl = new UntypedFormControl(this.value?.chartOfAccount?.chartOfAccountKey);

    const vendorFormGroup = new UntypedFormGroup({
      vendorKey: new UntypedFormControl(this.value?.vendor?.vendorKey),
    })

    const customerFormGroup = new UntypedFormGroup({
      customerKey: new UntypedFormControl(this.value?.customer?.customerKey)
    })

    const formGroup = new UntypedFormGroup({
      transactionRuleKey: new UntypedFormControl(this.value?.transactionRuleKey),
      businessKey: new UntypedFormControl(this.value?.businessKey),
      name: new UntypedFormControl(this.value?.name),
      chartOfAccount: new UntypedFormGroup({
        chartOfAccountKey: this.chartOfAccountKeyControl
      }),
      isDeleted: new UntypedFormControl(this.value?.isDeleted ?? false),
      continueExecuting: new UntypedFormControl(this.value?.continueExecuting ?? false),

      priority: new UntypedFormControl(this.value.priority ?? 100),

      ruleOneField: new UntypedFormControl(this.value.ruleOneField ?? TransactionRuleFieldsEnum.Merchant),
      ruleOneCondition: new UntypedFormControl(this.value.ruleOneField ?? TransactionRuleConditionsEnum.Equals),
      ruleOneTerm: new UntypedFormControl(this.value.ruleOneTerm),

      ruleTwoField: new UntypedFormControl(this.value.ruleTwoField ?? TransactionRuleFieldsEnum.NotSet),
      ruleTwoCondition: new UntypedFormControl(this.value.ruleTwoCondition ?? TransactionRuleConditionsEnum.Equals),
      ruleTwoTerm: new UntypedFormControl(this.value.ruleTwoTerm),

      customerVendorKey: this.customerVendorKeyControl,
      customer: customerFormGroup,
      vendor: vendorFormGroup,

      notes: new UntypedFormControl(this.value?.notes)
    });

    return formGroup;
  };

  private setCustomerVendors(): Observable<{ vendors: VendorModel[], customers: CustomerModel[] }> {
    return forkJoin({
      vendors: this.vendorService.search({businessKey: this.value.businessKey}),
      customers: this.customerService.search({businessKey: this.value.businessKey})
    }).pipe(tap(customersVendors => {
      let mappedVendors: CustomerVendorViewModel[] = customersVendors.vendors.map(t => {
        return {
          key: t.vendorKey,
          name: t.name,
          isVendor: true,
          data: t
        }
      });

      let mappedCustomers: CustomerVendorViewModel[] = customersVendors.customers.map(t => {
        return {
          key: t.customerKey,
          name: t.name,
          isVendor: false,
          data: t
        }
      });

      this.customerVendors = mappedCustomers.concat(mappedVendors).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }

        if (a.name > b.name) {
          return 1;
        }

        return 0;
      });

      this.filteredCustomerVendors = this.customerVendors;
    }));
  }

  private setVendorFormValues(key: string): void {
    this.customerVendorLabel = 'Vendor';
    this.formGroupRef.get('customer').get('customerKey').setValue(null);
    this.formGroupRef.get('vendor').get('vendorKey').setValue(key);
    this.formGroupRef.get('customerVendorKey').setValue(key);

    this.formGroupRef.updateValueAndValidity();
    this.changeDetectorRef.markForCheck();

    if (key && !this.value.chartOfAccount?.chartOfAccountKey) {
      let vendor = this.customerVendors.find(t => t.key === key);
      if (vendor.data.chartOfAccount?.chartOfAccountKey) {
        let foundChartOfAccount = this.chartOfAccounts.find(t => t.chartOfAccountKey === vendor.data.chartOfAccount?.chartOfAccountKey);

        if (foundChartOfAccount) {
          this.chartOfAccountKeyControl.setValue(foundChartOfAccount.chartOfAccountKey);
          this.formGroupRef.updateValueAndValidity();
          this.changeDetectorRef.markForCheck();
        } else {
          this.chartOfAccountService.search({businessKey: this.value.businessKey}).subscribe(chartOfAccounts => {
            this.chartOfAccounts = [...chartOfAccounts];
            this.filteredChartOfAccounts = [...chartOfAccounts];

            let foundChartOfAccount = this.chartOfAccounts.find(t => t.chartOfAccountKey === vendor.data.chartOfAccount?.chartOfAccountKey);
            this.chartOfAccountKeyControl.setValue(foundChartOfAccount.chartOfAccountKey);
          });
        }
      }
    }
  }

  private setCustomerFormValues(key: string): void {
    this.customerVendorLabel = 'Customer';
    this.formGroupRef.get('customer').get('customerKey').setValue(key);
    this.formGroupRef.get('vendor').get('vendorKey').setValue(null);
    this.formGroupRef.get('customerVendorKey').setValue(key);
    this.formGroupRef.updateValueAndValidity();
    this.changeDetectorRef.markForCheck();
  }
}

export class CustomerVendorViewModel {
  key: string;
  name: string;
  isVendor: boolean = false;
  data: any
}

