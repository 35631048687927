import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  CustomerPaymentSubscriptionModel
} from 'src/app/shared/models/domain/customer-payment-subscription.model';
import { environment } from 'src/environments/environment';
import { from, Observable } from 'rxjs';
import { SalesTaxRatesService } from 'src/app/core/services/domain/sales-tax-rates.service';
import { SalesTaxRateTextValueModel } from 'src/app/shared/models/domain/sales-tax-rate.model';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { SupportService } from 'src/app/core/services/domain/support.service';

@Component({
  selector: 'app-customer-payment-subscription-form',
  templateUrl: './customer-payment-subscription-form.component.html',
  styleUrls: ['./customer-payment-subscription-form.component.scss']
})
export class CustomerPaymentSubscriptionFormComponent extends BaseFormComponent<CustomerPaymentSubscriptionModel> implements OnInit {
  @Input() hasValidSubscription: boolean = false;
  @Output() loaded = new EventEmitter<boolean>();

  clover: any = null;
  isLoading = true;
  salesTaxRate: SalesTaxRateTextValueModel = null;
  basePrice: number = null;
  hasOrientation = false;
  hasPayroll = false;
  totalPrice: number = null;
  isCalculatingRate = false;
  publicUrl = environment.publicSiteUrl;

  constructor(private salesTaxRateService: SalesTaxRatesService,
              private notificationService: NotificationService,
              private supportService: SupportService) {
    super()
  }

  ngOnInit(): void {
    this.basePrice = environment.basePrice;
    super.ngOnInit();

    this.hasPayroll = this.value.includePayroll;
    this.hasOrientation = this.value.includeOrientation;

    this.subscriptions.add(this.formGroupRef.get('includePayroll').valueChanges.subscribe(_ => {
      this.formGroupRef.get('numberOfPayrollEmployees').setValue(null);
    }));
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.isLoading = false;
      this.loaded.emit(true);
    }, 2500)

    setTimeout(_ => {
      let that = this;
      this.clover = new window['Clover'](environment.clover.eCommPublicToken);
      const elements = this.clover.elements();

      const form = document.getElementById('payment-form');
      document.getElementById('payment-form');

      const styles = {
        input: {
          font: 'Roboto',
          background: 'rgba(0,0,0,0)',
          border: 'none',
          outline: 'none',
          padding: 0,
          'margin-top': '13px',
          'border-bottom': '1px solid black',
          width: '100%',
          'max-width': '100%',
          'vertical-align': 'bottom',
          'box-sizing': 'content-box'
        }
      };
      const cardNumber = elements.create('CARD_NUMBER', styles);
      const cardDate = elements.create('CARD_DATE', styles);
      const cardCvv = elements.create('CARD_CVV', styles);
      const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);

      cardNumber.mount('#card-number');
      cardDate.mount('#card-date');
      cardCvv.mount('#card-cvv');
      cardPostalCode.mount('#card-postal-code');

      // Handle real-time validation errors from the card element
      cardNumber.addEventListener('change', function(event) {
        that.formGroupRef.markAsDirty();
      });

      cardDate.addEventListener('change', function(event) {
        that.formGroupRef.markAsDirty();
      });

      cardCvv.addEventListener('change', function(event) {
        that.formGroupRef.markAsDirty();
      });

      cardPostalCode.addEventListener('change', function(event) {
        that.formGroupRef.markAsDirty();
      });

      cardNumber.addEventListener('blur', function(event) {
        that.validateCreditCard();
      });

      cardDate.addEventListener('blur', function(event) {
        that.validateCreditCard();
      });

      cardCvv.addEventListener('blur', function(event) {
        that.validateCreditCard();
      });

      cardPostalCode.addEventListener('blur', function(event) {
        that.salesTaxRate = null;
        that.validateCreditCard();
      });
    });
  }

  onSupportClicked(): void {
    this.supportService.initScheduleSupport();
  }

  public validateCreditCard(): Observable<any> {
    let that = this;
    let result: {errors: {message: string}[]} = {errors: []};

    return from(
      this.clover.createToken()
        .then(function(cloverResult) {
          if (cloverResult.errors) {
            let errors = [];
            Object.values(cloverResult.errors).forEach(function (value: string) {
              errors.push({message: value.replace('postal code', 'zip') + '.'});
            });

            result.errors = errors;
          } else {
            that.formGroupRef.patchValue({
              creditCardToken: cloverResult.token,
              addressZip: cloverResult.card.address_zip,
              cardType: cloverResult.card.brand,
              expirationMonth: cloverResult.card.exp_month,
              expirationYear: cloverResult.card.exp_year,
              firstDigits: cloverResult.card.first6,
              lastDigits: cloverResult.card.last4,
              salesTaxRate: that.salesTaxRate?.value,
              basePrice: that.basePrice,
              totalPrice: that.totalPrice
            });
          }

          if (that.salesTaxRate === null && cloverResult.card.address_zip) {
            that.setTaxRates(cloverResult.card.address_zip);
          }

          return result;
      }));
  }

  private setTaxRates(zipCode: string): void {
    if (zipCode && zipCode.length === 5) {
      this.isCalculatingRate = true;
      this.salesTaxRateService.search({zipCode}).subscribe(result => {
        this.salesTaxRate = result?.combinedRate;
        if (this.salesTaxRate?.value) {
          this.totalPrice = (this.basePrice * this.salesTaxRate.value) + this.basePrice;
        }

        this.isCalculatingRate = false
      }, err => {
        this.onHttpFailure(this.notificationService, err);
        this.isCalculatingRate = false
      });
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      businessKey: new UntypedFormControl(this.value?.businessKey),
      isBaseSubscription: new UntypedFormControl(true),
      includeOrientation: new UntypedFormControl(this.value?.includeOrientation ?? false),
      hasAcceptedTerms: new UntypedFormControl(false),
      includePayroll: new UntypedFormControl(this.value?.includePayroll ?? false),
      creditCardToken: new UntypedFormControl(this.value?.creditCardToken),
      addressZip: new UntypedFormControl(this.value?.addressZip),
      cardType: new UntypedFormControl(this.value?.cardType),
      expirationMonth: new UntypedFormControl(this.value?.expirationMonth),
      expirationYear: new UntypedFormControl(this.value?.expirationYear),
      firstDigits: new UntypedFormControl(this.value?.firstDigits),
      lastDigits: new UntypedFormControl(this.value?.lastDigits),
      salesTaxRate: new UntypedFormControl(this.value?.salesTaxRate),
      basePrice: new UntypedFormControl(this.value?.basePrice ?? this.basePrice),
      totalPrice: new UntypedFormControl(this.value?.totalPrice)
    });
  };
}
