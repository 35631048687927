import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { BusinessFileModel } from 'src/app/shared/models/domain/business-file.model';
import { BusinessFileSearchModel } from 'src/app/shared/models/domain/business-file-search.model';
import { FileModel } from 'src/app/shared/models/domain/file.model';

@Injectable()
export class BusinessFileService {
  private url = environment.apiUrl + '/business-files/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<BusinessFileModel> {
    return this.http.get<BusinessFileModel>(this.url + key);
  }

  search(searchModel: BusinessFileSearchModel): Observable<BusinessFileModel[]> {
    return this.http.post<BusinessFileModel[]>(this.url + 'search', searchModel);
  }

  download(businessFileKey: string): Observable<void>{
    return this.get(businessFileKey).pipe(flatMap(businessFile => {
      return this.http.get(`${this.url}${businessFileKey}/download`, { observe: 'response', responseType: 'blob' }).pipe(tap((data: any) => {
        if (data.type === HttpEventType.Response) {
          saveAs(data.body, businessFile.file?.name);
        }
      }));
    }));
   }

  getFile(businessFileKey: string): Observable<FileModel>{
    return this.get(businessFileKey).pipe(flatMap(businessFile => {
      return this.http.get(`${this.url}${businessFileKey}/download`, { responseType: 'blob' }).pipe(tap((data: any) => {
        businessFile.file.stream = data;
        return businessFile.file;
      }), map(_ => {
        return businessFile.file;
      }));
    }));
  }

  create(model: BusinessFileModel, fileList: FileList): Observable<string> {
    return this.http.post<string>(this.url, this.buildFormData(model, fileList));
  }

  update(model: BusinessFileModel, fileList: FileList): Observable<string> {
    return this.http.put<string>(this.url, this.buildFormData(model, fileList));
  }

  delete(key: string): Observable<boolean> {
    return this.http.delete<boolean>(this.url + key);
  }

  private buildFormData(document: BusinessFileModel, fileList: FileList): FormData {
    const formData = new FormData();
    formData.append('model', JSON.stringify(document));

    if (fileList != null && fileList.length === 1) {
      formData.append(fileList[0].name, fileList[0]);
    }

    return formData;
  }
}
