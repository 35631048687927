import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChartOfAccountModel } from 'src/app/shared/models/domain/chart-of-account.model';
import { ChartOfAccountSearchModel } from 'src/app/shared/models/domain/chart-of-account-search.model';
import { ChartOfAccountTypesEnum } from 'src/app/shared/enums/domain/chart-of-account-types.enum';
import { map, tap } from 'rxjs/operators';
import { ChartOfAccountConstants } from 'src/app/shared/constants/chart-of-account.constants';

@Injectable()
export class ChartOfAccountService {
  private url = environment.apiUrl + '/chart-of-accounts/';

  constructor(private http: HttpClient) {
  }

  get(key: string): Observable<ChartOfAccountModel> {
    return this.http.get<ChartOfAccountModel>(`${this.url}${key}`);
  }

  getLatestAccountNumberByType(businessKey: string, type: ChartOfAccountTypesEnum): Observable<number> {
    return this.search({businessKey, type}).pipe(map(chartOfAccounts => {
      if (!chartOfAccounts.length) {
        return type;
      }

      return Math.max(...chartOfAccounts.map(chartOfAccount => chartOfAccount.number)) + 1;
    }));
  }

  search(model: ChartOfAccountSearchModel): Observable<ChartOfAccountModel[]> {
    return this.http.post<ChartOfAccountModel[]>(`${this.url}search`, model);
  }

  create(model: ChartOfAccountModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model);
  }

  update(model: ChartOfAccountModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model);
  }

  delete(key: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}${key}`);
  }

  filterToExpenseAccounts(chartOfAccounts: ChartOfAccountModel[]): ChartOfAccountModel[] {
    return chartOfAccounts.filter(t => t?.fullDisplayPath?.startsWith(`${ChartOfAccountConstants.expenses} >>`));
  }

  filterToInvoiceItemAccounts(chartOfAccounts: ChartOfAccountModel[]): ChartOfAccountModel[] {
    chartOfAccounts = this.filterWithoutBankAccounts(chartOfAccounts);

    return chartOfAccounts.filter(t => t?.fullDisplayPath?.startsWith(`${ChartOfAccountConstants.income} >>`) && t.name !== ChartOfAccountConstants.sales);
  }

  filterToTransactionAccounts(chartOfAccounts: ChartOfAccountModel[]): ChartOfAccountModel[] {
    chartOfAccounts = this.filterWithoutBankAccounts(chartOfAccounts);
    return chartOfAccounts.filter(t => !t?.fullDisplayPath?.startsWith(`${ChartOfAccountConstants.income} >> ${ChartOfAccountConstants.products}`) && !t?.fullDisplayPath?.startsWith(`${ChartOfAccountConstants.income} >> ${ChartOfAccountConstants.services}`));
  }

  filterWithoutBankAccounts(chartOfAccounts: ChartOfAccountModel[]): ChartOfAccountModel[] {
    return chartOfAccounts.filter(t => !this.isBankAccount(t));
  }

  filterToBankAccounts(chartOfAccounts: ChartOfAccountModel[]): ChartOfAccountModel[] {
    return chartOfAccounts.filter(this.isBankAccount).filter(t => t.name !== ChartOfAccountConstants.banks && t.name !== ChartOfAccountConstants.creditCards);
  }

  isBankAccount(chartOfAccount: ChartOfAccountModel): boolean {
    //KEEP IN SYNC WITH BE CHART OF ACCOUNT SERVICE
    return chartOfAccount?.bankingAccount?.bankingAccountKey != null || chartOfAccount?.fullDisplayPath?.startsWith(`${ChartOfAccountConstants.banks} >>`)  || chartOfAccount?.parentChartOfAccountName === ChartOfAccountConstants.banks || chartOfAccount?.name === ChartOfAccountConstants.banks || chartOfAccount?.fullDisplayPath?.startsWith(`${ChartOfAccountConstants.creditCards} >>`) || chartOfAccount?.name === ChartOfAccountConstants.creditCards;
  }

  listCalculatedChartOfAccounts(): string[] {
    return [
      ChartOfAccountConstants.accountsReceivable,
      ChartOfAccountConstants.accountsPayable
    ];
  }
}
